/* eslint-disable camelcase */
import axios from "axios";
import {
  parse, parseISO, format, isValid
} from "date-fns";
import esmx from "date-fns/locale/es";
import { unformat } from "accounting-js";
import store from "@/store";

const murmur = require("murmurhash-js");

const Api = {
  baseurl() {
    return `${store.state.API_ROOT}/api/banks`;
  },

  create(items) {
    const query = items;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/banks`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating bank line", err);
          reject(err);
        });
    });
  },
  find(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  getByHash(hash) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/banks/${hash}`;
      axios.get(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding by hash", err);
          reject(err);
        });
    });
  },
  updateByHash(hash, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/banks/${hash}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  searchRecons(query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  addReconByHash(hash, data) {
    const query = data;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/banks/${hash}/recons`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating banks array", err);
          reject(err);
        });
    });
  },
  removeReconByHash(hash, data) {
    const { reconid = "" } = data;
    return new Promise((resolve, reject) => {
      if (!reconid || !hash) {
        reject(new Error("Missing reconid or hash"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/banks/${hash}/recons/${reconid}`;
      axios.delete(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating banks array", err);
          reject(err);
        });
    });
  },
  getSumMontly(dates, query) {
    // Necesitamos un Array 1 o mas con mes y año
    // dates = [{year:2000, month: 1}, {year:2000, month: 2}, {year:2000, month: 3}]
    return new Promise((resolve, reject) => {
      if (!Array.isArray(dates)) {
        reject(new Error("Missing array year and month"));
        return;
      }
      const urls = dates.map(d => `${store.state.API_ROOT}/api/banks/by/${d.year}/${d.month}?status=${d.status}`);
      if (!urls) {
        reject(new Error("Empty array"));
        return;
      }
      axios.all(urls.map((url) => axios.get(url, { params: query, withCredentials: true })))
        .then((res) => {
          const data = res.map((it) => {
            const { data: d = [] } = it;
            return d;
          });
          resolve(data);
        }).catch(err => {
          console.log("ERROR get BANKS sum", err);
          reject(err);
        });
    });
  },
  umsatzLine2Record(row) {
    // Convierte un renglon UMSATZ a un objeto
    const fields = row.split(";");
    if (fields.length < 34) {
      console.warn(
        "UMSATZ ignorando línea, campos insuficientes: [",
        row,
        "]"
      );
      return null;
    }
    // PASO 1: Desmenuzar el UMSATZ a campos decentes
    // TODO: Verificar que los campos existan y tener ciertas
    //       validaciones previas y fechas o valores sensatos
    const record = {
      srcbankid: fields[0], // ID Banco
      srcaccount: fields[1], // Cuenta
      statement: fields[2], // Num de estado de cuenta
      bankdate: parse(fields[3], "dd.MM.yyyy", new Date()).toISOString(), // Fecha del banco
      primnote: fields[4], // [Sin uso] nota primaria
      titlenote: fields[5], // Nota al beneficiario 1
      reference: fields[6], // (Affil) referencia numerica o folio de operacion con tarjeta
      // 7 sin uso oficial
      keytext: fields[8], // [Sin uso] texto clave
      checknum: fields[9], //  Numero de cheque
      amount: fields[10], //  Monto $ a dos digitos como cadena
      // 11, 12 sin uso oficial
      accountingdate: parse(fields[13], "dd.MM.yyyy", new Date()).toISOString(), // Fecha de contabilidad
      // 14, 15 sin uso oficial
      extranote: fields.slice(16, 28).join(""), // Comentarios largos segmentados
      partner1: fields[29], // [Sin uso aparente] Socio de negocio
      partner2: fields[30], // [Sin uso aparente] Socio de negocio
      dstbankid: fields[31], // Clave del banco del socio de negocio
      dstaccount: fields[32], // Num de cuenta dle socio de negocio
      dsttransid: fields[33], // Esto indica el codigo de transacción
      // 34 sin uso
      // ---- Metadatos propios ----
      bankname: fields[1].trim() === "062650163410076195" ? "Afirme" : "", // Nombre del banco
      fullnotes: `${fields[5].trim()}\n${fields
        .slice(16, 28)
        .join("")}`.trim(),
      multicashrow: row, // El original
      hash: "" // Hash para detectar duplicados basados en contenido
    };
    // PASO 2: Calcular un hash del contenido para poder buscar posibles duplicados
    //         Para que éste hash se repita tendria que haber una colisión exactamente
    //         el mismo mes, porque estamos haciendo yyyyMM.xxxxxxx
    const acountDate = parseISO(record.accountingdate);
    const yyyymm = format(acountDate, "yyyyMM");
    let hashSrc = `${record.dsttransid}|${record.titlenote}${record.extranote}${record.checknum}|${record.reference}`;
    hashSrc = hashSrc.replace(/\s+/g, ""); // \s is the regex for "whitespace"
    record.hash = `${yyyymm}.${murmur
      .murmur3(hashSrc)
      .toString(36)
      .toUpperCase()}`;

    // console.log(record.hash, yyyymm, hashSrc);
    // console.log("tr", transactions);
    return record;
  },
  amex2Record(row) {
    // Convierte un renglon sheets o tabla a un objeto
    const fields = row.split("\t");
    if (fields.length < 10) {
      console.warn(`amex2Record ignorando línea, campos insuficientes: [${row}]`);
      return null;
    }
    // console.log(fields);
    let bankdate = parse(fields[0], "dd MMM yyyy", new Date(), { locale: esmx });
    bankdate = bankdate instanceof Date && !Number.isNaN(bankdate) && isValid(bankdate) ? bankdate.toISOString() : "";
    let accountingdate = parse(fields[1], "dd MMM yyyy", new Date(), { locale: esmx });
    accountingdate = accountingdate instanceof Date && !Number.isNaN(accountingdate) && isValid(accountingdate) ? accountingdate.toISOString() : "";
    if (!bankdate || !accountingdate) {
      console.warn(`amex2Record ignorando línea, fechas invalidas [${row}]`);
      return null;
    }

    const record = {
      srcbankid: 103, // ID Banco AMEX=103, N/A=999
      bankdate, // Fecha del banco
      accountingdate, // Fecha de contabilidad
      titlenote: fields[2], // Descripcion
      partner1: fields[3].trim(), // Tarjetahabiente
      srcaccount: "376702480221008", // Cuenta
      amount: unformat(fields[4], ".").toFixed(2), //  Monto $ a dos digitos como cadena
      // 5 Monto en moneda extranjera
      // 6 Tipo de cambio
      // 7 Negocios como
      // 8 Direccion de establecimiento
      reference: fields[9], // referencia numerica o folio de operacion con tarjeta
      // ---- Metadatos propios ----
      fullnotes: [
        fields[7].trim(),
        fields[2].trim(),
        fields[9].trim(),
        fields[8].trim().replace(/['"]+/g, ""),
        fields[5].trim(),
        fields[3].trim()
      ].filter(Boolean).join("\n"),
      bankname: "American Express", // Nombre del banco
      multicashrow: row, // El original
      hash: "" // Hash para detectar duplicados basados en contenido
    };

    // En AMEX se voltea el signo del amount
    const amount = parseFloat(record.amount);
    record.amount = (amount * -1).toFixed(2);

    const acountDate = parseISO(record.accountingdate);
    const yyyymm = format(acountDate, "yyyyMM");
    let hashSrc = `${record.srcaccount}|${record.reference}${record.fullnotes}${record.amount}|${record.accountingdate}`;
    hashSrc = hashSrc.replace(/\s+/g, ""); // \s is the regex for "whitespace"
    record.hash = `${yyyymm}.${murmur
      .murmur3(hashSrc)
      .toString(36)
      .toUpperCase()}`;

    return record;
  },
  amex2RecordV2(row) {
    // Convierte un renglon sheets o tabla a un objeto
    const fields = row.split("\t");
    // console.log(fields);
    if (fields.length < 15) {
      console.warn(`amex2RecordV2 ignorando línea, campos insuficientes: [${row}]`);
      return null;
    }
    /*
    0 Fecha del banco
    1 Fecha de contabilidad
    2 Descripcion
    3 Titular de tarjeta
    4 Cuenta
    5 Monto
    6 Monto en moneda extranjera
    7 Tipo de cambio
    8 Informacion adicional
    9 Aparece en estado de cuenta
    10 Direccion de establecimiento
    11 Poblacion/provincia
    12 Codigo postal
    13 Pais
    14 Referencia
    */
    let bankdate = parse(fields[0], "dd MMM yyyy", new Date()); // intenta parsear mes en ingles
    bankdate = bankdate instanceof Date && !Number.isNaN(bankdate) && isValid(bankdate) ? bankdate.toISOString() : "";
    if (!bankdate) {
      bankdate = parse(fields[0], "dd MMM yyyy", new Date(), { locale: esmx }); // intenta parsear mes en español
      bankdate = bankdate instanceof Date && !Number.isNaN(bankdate) && isValid(bankdate) ? bankdate.toISOString() : "";
    }
    let accountingdate = parse(fields[1], "dd MMM yyyy", new Date()); // intenta parsear mes en ingles
    accountingdate = accountingdate instanceof Date && !Number.isNaN(accountingdate) && isValid(accountingdate) ? accountingdate.toISOString() : "";
    if (!accountingdate) {
      accountingdate = parse(fields[1], "dd MMM yyyy", new Date(), { locale: esmx }); // intenta parsear mes en español
      accountingdate = accountingdate instanceof Date && !Number.isNaN(accountingdate) && isValid(accountingdate) ? accountingdate.toISOString() : "";
    }
    if (!bankdate || !accountingdate) {
      console.warn(`amex2RecordV2 ignorando línea, fechas invalidas [${row}]`);
      return null;
    }

    const record = {
      srcbankid: 103, // ID Banco AMEX=103, N/A=999
      bankdate, // Fecha del banco
      accountingdate, // Fecha de contabilidad
      titlenote: fields[2].replace(/ +(?= )/g, ""), // Descripcion
      partner1: fields[3].trim(), // Tarjetahabiente
      srcaccount: "376702480221008", // Cuenta
      amount: unformat(fields[5], ".").toFixed(2), //  Monto $ a dos digitos como cadena
      reference: fields[14], // referencia numerica o folio de operacion con tarjeta
      // ---- Metadatos propios ----
      fullnotes: [
        fields[2].trim(),
        fields[14].trim(),
        fields[8].trim().replace(/['"]+/g, ""),
        fields[6].trim(),
        fields[10].trim().replace(/['"]+/g, ""),
        fields[3].trim(),
        fields[4].trim()
      ].filter(Boolean) // Quitamos null o espacios blancos
        .join("\n") // Unimos cada field con un enter
        .replace(/ +(?= )/g, ""), // Quitamos espacios dobles ya unido
      bankname: "American Express", // Nombre del banco
      multicashrow: row, // El original
      hash: "" // Hash para detectar duplicados basados en contenido
    };

    // En AMEX se voltea el signo del amount
    const amount = parseFloat(record.amount);
    record.amount = (amount * -1).toFixed(2);

    const acountDate = parseISO(record.accountingdate);
    const yyyymm = format(acountDate, "yyyyMM");
    let hashSrc = `${record.srcaccount}|${record.reference}${record.fullnotes}${record.amount}|${record.accountingdate}`;
    hashSrc = hashSrc.replace(/\s+/g, ""); // \s is the regex for "whitespace"
    record.hash = `${yyyymm}.${murmur
      .murmur3(hashSrc)
      .toString(36)
      .toUpperCase()}`;

    return record;
  },
  banbajio2Record(row) {
    // Convierte un renglon sheets o tabla a un objeto
    const fields = row.split("\t");
    if (fields.length < 7) {
      console.warn(`banbajio2Record ignorando línea, campos insuficientes: [${row}]`);
      return null;
    }

    const firstcell = fields[0] ?? "";
    if (!(/^\d+$/.test(firstcell))) { // Si la primera celda NO tiene solo numeros
      fields.unshift("1");
    }

    const [,
      date = "",
      hour = "",
      recibo = "",
      descripcion = "",
      cargos = "", // negativos
      abonos = "", // positivos
    ] = fields ?? [];

    let bankdate = `${date} ${hour}`;
    bankdate = parse(`${date} ${hour}`, "dd-MMM-yyyy HH:mm:ss", new Date(), { locale: esmx });
    bankdate = bankdate instanceof Date && !Number.isNaN(bankdate) && isValid(bankdate) ? bankdate.toISOString() : "";
    if (!bankdate) {
      console.warn(`banbajio2Record ignorando línea, fechas y hora invalidas [${row}]`);
      return null;
    }

    const cargosNumber = unformat(cargos, ".");
    const abonosNumber = unformat(abonos, ".");
    let amount = abonosNumber.toFixed(2);
    if (cargosNumber !== 0) {
      amount = (cargosNumber * -1).toFixed(2);
    }

    const record = {
      srcbankid: 30, // ID Banco BAJIO=30, N/A=999
      bankdate, // Fecha del banco
      accountingdate: bankdate, // Fecha de contabilidad
      titlenote: descripcion, // Descripcion
      partner1: "CARAQUITA GAMES SA DE CV", // Tarjetahabiente
      srcaccount: "030650900037432116", // Cuenta
      amount, //  Monto $ a dos digitos como cadena
      reference: recibo, // referencia numerica o folio de operacion con tarjeta
      // ---- Metadatos propios ----
      fullnotes: descripcion,
      bankname: "BanBajio", // Nombre del banco
      multicashrow: row, // El original
      hash: "" // Hash para detectar duplicados basados en contenido
    };

    const acountDate = parseISO(record.accountingdate);
    const yyyymm = format(acountDate, "yyyyMM");
    let hashSrc = `${record.srcaccount}|${record.reference}${record.fullnotes}${record.amount}|${record.accountingdate}`;
    hashSrc = hashSrc.replace(/\s+/g, ""); // \s is the regex for "whitespace"
    record.hash = `${yyyymm}.${murmur
      .murmur3(hashSrc)
      .toString(36)
      .toUpperCase()}`;

    return record;
  },
  mergeTableIntoTSV(rows) {
    // Tab separated values (TSV) sirve para pegar en Sheets o Excel
    const content = rows.map((it) => {
      // console.log("uniendo");
      const {
        amount = "0",
        accountingdate = null,
        fullnotes = " ",
        recons = [],
        cfdis = []
      } = it;

      let accountingDate = "-";
      if (accountingdate) {
        accountingDate = parseISO(accountingdate);
        accountingDate = format(accountingDate, "yyyy-MM-dd HH:mm:ss",  { locale: esmx } );
      }

      let amountNum = parseFloat(amount);
      if (Number.isNaN(amountNum)) {
        amountNum = 0;
      }
      const tipo = amountNum >= 0 ? "I" : "E"; // Ingreso, Egreso
      const ingreso_amount = tipo === "I" ? amount : " ";
      const egreso_amount = tipo === "E" ? amount : " ";

      const reconsCnn = recons.map(r => `C${r.recon_sequence}`).join(",");

      const cfdiUuids = cfdis.map(c => c.uuid).join(",");
      const cfdiIvaSum = cfdis.reduce((acc, curr) => {
        const { iva_16 = 0 } = curr;
        // console.log("SUM", accountingDate, iva_16);
        const ivaNum = parseFloat(iva_16) || 0;
        return acc + ivaNum;
      }, 0);
      const ingreso_iva = tipo === "I" ? cfdiIvaSum : " ";
      const egreso_iva = tipo === "E" ? cfdiIvaSum : " ";

      // Agregar todos los campos y en el orden correcto
      let merged = `${accountingDate}\t"${fullnotes}"\t${ingreso_amount}\t${egreso_amount}`;
      merged += `\t${reconsCnn}`;
      merged += `\t${ingreso_iva}\t${egreso_iva}\t${cfdiUuids}`;
      return merged;
    }).join("\n");
    return content;
  },
  accountsAlias: {
    "062650163410076195": "Afirme MXN 6195",
    "062650163410006149": "Afirme USD 6149",
    376702480221008: "TC Amex",
    "030650900037432116": "BanBajío MXN 2116"
  },
  accountsClientNames: {
    "062650163410076195": "DESARROLLOS NRG SA DE CV",
    "062650163410006149": "DESARROLLOS NRG SA DE CV",
    376702480221008: "DESARROLLOS NRG SA DE CV",
    "030650900037432116": "CARAQUITA GAMES SA DE CV"
  }
};

export default Api;
