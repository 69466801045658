<style>
.text-striked{
  text-decoration: line-through;
}
</style>

<style scoped>
.flex-grow-col {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}
.main-header .logo {
  font-weight: 400;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  text-align: center;
}
.sidebar-menu li > a {
  font-weight: 400;
}
.vuetable-body {
  color: white;
}
</style>
<style>
.skin-dark .main-header .logo {
  background-color: unset;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-dark .main-header .logo:hover {
  background-color: #3456b8;
}
.skin-dark .box-footer.table-footer {
  background-color: hsla(215, 13%, 18%, 0.8);
  padding: 0.25rem;
}
.skin-dark .box {
  background-color: hsla(215, 13%, 18%, 0.8);
}

.skin-dark .main-sidebar,
.wrapper {
  background-color: #273345;
}
h1,
h2,
h3,
h4,
body,
.box-title {
  font-weight: 400;
}
.form-control {
  color: rgb(122, 122, 122);
  background-color: #25354c !important;
}
.form-control:focus {
  color: rgb(201, 201, 201);
}
.nav-tabs .nav-link.active,
.nav-tabs.nav-tabs-info .nav-link:hover,
.nav-tabs.nav-tabs-info .nav-link.active,
.nav-tabs.nav-tabs-info .nav-link.active:focus,
.nav-tabs.nav-tabs-info .nav-link.active:hover {
  background-color: #398bf7;
  color: aliceblue;
}
.sidebar-collapse .sidebar-menu li > a {
  /* TODO: Arreglar los elementos colapsados (o salen transparentes)*/
  background-color: #273345;
}

.message-link.mail-content > h4 {
  /* color:#929daf; */
  color: unset;
  background-color: red;
}
.message-link:hover {
  background-color: #24292f !important;
}
</style>

<template>
  <div class="wrapper" style="/*height: auto; min-height: 100%;*/">
    <header class="main-header">
      <!-- Logo -->
      <a href="#/" class="logo">
        <b class="logo-mini">
          <sup>
            <!-- <i aria-hidden="true" class="fa fa-rocket"></i> -->
            <span class="iconify" data-icon="fa:rocket" data-inline="false"></span>
          </sup>
        </b>
        <span class="logo-lg">
          ROCKET
        </span>
      </a>
      <!-- Header Navbar: style can be found in header.less -->
      <nav class="navbar navbar-static-top d-block d-md-none">
        <a
          href="#"
          class="sidebar-toggle"
          data-toggle="push-menu"
          role="button"
          @click="toggleSidebar"
        >
          <span class="iconify" data-icon="fa-solid:bars" data-inline="false"></span>
          <span class="sr-only">Toggle navigation</span>
        </a>

        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <!--
              SE DESACTIVO PORQUE SE ENCIMABA, REACTIVAR CUANDO SE ARREGLE
              <li class="search-box" style>
              <a class="nav-link hidden-sm-down" href="#">
                <i class="mdi mdi-magnify"></i>
              </a>
              <form class="app-search" style>
                <input type="text" class="form-control" placeholder="Buscar..." />
                <a class="srh-btn">
                  <i class="ti-close"></i>
                </a>
              </form>
            </li> -->
            <li class="dropdown messages-menu">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true" @click="triggerNotifications">
                <Icon icon="ri:chat-4-fill" />
              </a>
              <Notifications :refresh="refreshNotif" />
            </li>

            <li v-if="user" class="dropdown user user-menu" style>
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <img
                  v-if="user&&user.avatar"
                  :src="user.avatar"
                  class="user-image useravatar rounded-circle"
                  alt="👤"
                >
                <span v-else>👤</span>
              </a>
              <ul class="dropdown-menu scale-up">
                <li class="user-header">
                  <img
                    v-if="user&&user.avatar"
                    :src="user.avatar"
                    class="float-left rounded-circle useravatar"
                    alt="👤"
                  >
                  <span v-else>👤</span>
                  <p>
                    <span class="userfullname">{{ user?user.name:"" }}</span>
                    <small class="mb-5 useremail">{{ user?user.email:"" }}</small>
                  </p>
                </li>

                <li class="user-body">
                  <div class="row no-gutters">
                    <div role="separator" class="divider col-12"></div>
                    <div class="col-12 text-left">
                      <GoogleLogin :params="params" :render-params="renderParams" :on-success="onLogin" :on-failure="onLoginFail" />
                    </div>
                  </div>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#" data-toggle="control-sidebar"
                @click="toolsidebar=!toolsidebar"
              >
                <Icon icon="gg:sidebar-right" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <span v-touch:swipe="swipeHandler">
      <aside ref="controlsidebar" class="main-sidebar">
        <!-- sidebar: style can be found in sidebar.less -->
        <section class="sidebar">
          <!-- sidebar menu: : style can be found in sidebar.less -->
          <ul class="sidebar-menu tree" data-widget="tree">
            <li class="user-profile treeview">
              <router-link v-if="user?.email" to="/profile">
                <img :src="user?user.avatar:''" alt="user">
                <span>{{ user?user.name:"" }}</span>
                <span class="pull-right-container">
                  <i class="fa fa-angle-right pull-right"></i>
                </span>
              </router-link>
              <div v-else>
                <GoogleLogin :params="params" :render-params="renderParams" :on-success="onLogin" :on-failure="onLoginFail" />
              </div>
              <ul class="treeview-menu" style>
                <router-link
                  v-if="privileges && privileges.indexOf('ADCAST-USER') >-1"
                  to="/profile"
                  tag="li"
                  class="treeview"
                >
                  <a>
                    <span class="iconify" data-icon="bi:person-circle" data-inline="false"></span>
                    Mi perfil
                  </a>
                </router-link>
                <router-link
                  v-if="privileges && privileges.indexOf('ROCKET-ARBITER') >-1"
                  to="/adcadmin"
                  tag="a"
                  class="treeview"
                >
                  <!-- <router-link
                    v-if="privileges && privileges.indexOf('ROCKET-USER') >-1"
                    to="/profile"
                    tag="li"
                  >
                    <span>
                      Administrar

                    </span>
                  </router-link> -->
                  <!-- <li>
                <a href="#">
                  <span class="iconify" data-icon="mdi:exit-run" data-inline="false"></span> Salir
                </a>
              </li> -->
                </router-link></ul>
            </li>
            <li class="d-none">
              <a href="#" @click="toggleSidebar">
                <span
                  class="iconify"
                  data-icon="clarity:bars-line"
                  data-width="22px"
                  data-height="22px"
                  data-inline="false"
                >

                </span>
              </a>

            </li>
            <!-- <li class="header nav-small-cap">ADMINISTRADOR</li> -->
            <router-link
              v-if="privileges && privileges.indexOf('LOGS-USER') >-1"
              to="/logs"
              tag="li"
            >
              <a>
                <!-- <span class="iconify" data-icon="codicon:list-unordered" data-inline="false"></span> -->
                <span
                  style="margin-right: 10px;"
                  class="iconify"
                  data-icon="codicon:list-unordered"
                  data-width="20px"
                  data-height="20px"
                  data-inline="false"
                ></span>
                <span>Registro</span>
              </a>
            </router-link>
            <!-- Enseguida vienen rutas dinámicas basadas en plugins -->
            <li v-for="route in routes" :key="route.path" class="treeview" :to="`${route.path}`">
              <router-link
                v-if="(privileges && route.privileges.every(r => privileges.includes(r)))"
                :to="{ path: route.path, query: route.query ?? {} }"
                tag="a"
                class="tredeview"
              >
                <span
                  :data-icon="route.icon"
                  style="margin-right: 10px;"
                  class="iconify"
                  data-width="20px"
                  data-height="20px"
                  data-inline="false"
                ></span>
                <span>
                  {{ route.label }}
                </span>
                <!-- {{route.privileges}} -->
              </router-link>
            </li>
          </ul>
        </section>
      </aside>
    </span>
    <span v-touch:swipe="swipeHandler">
      <div class="content-wrapper">
        <section class="content-heder" style="display: none;">
          <!-- Hi: {{this.user?this.user.name:""}}. ➜ {{this.user?this.user.privileges:"[]"}}. -->
        </section>
        <section class="content py-1 px-1" style="flex-flow: column; ">
          <router-view class="flex-grow-col" />
        </section>
      </div>
    </span>
    <footer class="main-footer ">
      <div class="d-none d-md-inline-block my-0">
        <small>
          EDIS Rocket {{ version.front }}
        </small>
      </div>
    </footer>
    <aside
      class="control-sidebar control-sidebar-dark "
      :class="{'control-sidebar-open': toolsidebar}"
    >
      <RightSidebar />
    </aside>
  </div>
</template>

<style>
@import "/css/m.css";
@import "/css/noanimation.css";
</style>

<script>
import { mapState, mapActions } from "vuex";
import GoogleLogin from "vue-google-login";
import { Icon } from "@iconify/vue2";
import Notifications from "@/components/Notifications.vue";
import RightSidebar from "@/components/RightSidebar.vue";

export default {
  components: {
    GoogleLogin,
    Notifications,
    RightSidebar,
    Icon
  },
  data() {
    return {
      refreshNotif: 45,
      fbmodel: {},
      toolsidebar: false,
      minisidebar: true,
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id: ""
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 260,
        height: 50,
        longtitle: true
      }
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT", "GOOG_CLIENTID", "version", "privileges", "user", "routes", "socket"]),
    // iswsConnected() {
    //   return this.$store.state.homePage.inHomePage;
    // }
  },
  watch: {
    $route(to, /* from */) {
      const { id = "" } = to.params;
      // console.log(to);
      document.title = `${to.meta.title} ${id}` || "Rocket";
    },
    "socket.isConnected": {
      // requiere el mapState porque socket viene del $store
      // deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // state => state.isConnected,
        // (oldVal, newVal) => {
        console.log("##### Watcher on ws", oldVal, newVal);
      }
    }
  },
  methods: {
    // mapState hace que this.func() sea como llamar this.$store.func()
    // Y ya no hace falta llamar a `dispatch` se llama directo
    ...mapActions(["sendMessage"]), // Vienen de VueX
    swipeHandler(direction) {
      console.log("swipe", direction);
      if (direction === "right") {
        const el = document.getElementsByTagName("body")[0];
        el.classList.add("sidebar-open");
        el.classList.remove("sidebar-collapse");
        return;
      }
      if (direction === "left") {
        const el = document.getElementsByTagName("body")[0];
        el.classList.remove("sidebar-open");
        el.classList.add("sidebar-collapse");
      }
    },
    triggerNotifications() {
      this.refreshNotif = Math.floor(Math.random() * 999999);
    },
    toggleSidebar() {
      // OJO: Ésto no deberia hacerse, pero como Vue no puede montarse
      //      en <body> te obligada a manipular el DOM
      const el = document.getElementsByTagName("body")[0];
      // TODO No se por qué usa dos clases (open y collapse), pero al parecer si es muy
      //      chico solo usa sidebar-open. Arreglar un dia de estos.
      el.classList.toggle("sidebar-open");
      el.classList.toggle("sidebar-collapse"); // Interesante estas estan al reves una de la otra
    },
    requestLogout() {
      console.log("Requested a session destroy");
    },
    onLogin(googleUser) {
      // Aqui se recibe un GoogleUser directo
      console.log("[G] Logged in", googleUser, googleUser.getAuthResponse());
      this.$store.dispatch(
        "InitSessionFromGoogle",
        googleUser.getAuthResponse()
      );
    },
    onLoginFail(res) {
      console.log("[G] Failure on login", res);
    }
  },
  mounted() {
    console.log("PRIVILEGES", this.privileges, this.GOOG_CLIENTID);
    this.params.client_id = this.GOOG_CLIENTID;
  },
};
</script>
