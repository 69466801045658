import axios from "axios";
import store from "@/store";

const UsersApi = {
  baseurl() {
    return `${store.state.API_ROOT}/api/contacts`;
  },
  updateById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/contacts/${id}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          console.log("ERROR updating contact", err);
          reject(err);
        });
    });
  },
  getById(id) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/contacts/${id}`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching recon", err);
          reject(err);
        });
    });
  },
  create(contact) {
    const query = contact;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/contacts`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating contact", err);
          reject(err);
        });
    });
  },
  search(query) {
    // console.log("Find contacts", query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/contacts`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding contacts", err);
          reject(err);
        });
    });
  }
};

export default UsersApi;
