<style>
/* el hijo, queremos que se vea siempre */
.box.single-contact{
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}
</style>
<template>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Contactos
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              <a class="btn btn-sm btn-default d-sm-none" href="#" @click="confirmAddContact()">
                <span class="iconify" data-icon="carbon:add-alt" data-inline="true "></span>
              </a>
              <SearchBar
                :value="search.search"
                @search-changed="onSearchChanged"
              />
              <a class="btn btn-sm btn-default d-none d-sm-block" href="#" @click="confirmAddContact()">
                <span class="iconify" data-icon="carbon:add-alt" data-inline="true "></span>
                Nuevo &nbsp;
              </a>
            </div>
          </div>
        </div>
        <div class="box-body no-padding">
          <Vuetable
            ref="contactsTable"
            :api-url="`${API_ROOT}/api/contacts`"
            :css="css"
            :sort-order="sort"
            :per-page="10"
            :fields="fields"
            :append-params="search"
            :http-options="{withCredentials: true}"
            :row-class="onRowClass"
            data-path="rows"
            pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:row-clicked="onRowClicked"
          />
          <div class="box-footer table-footer">
            <vuetable-pagination
              ref="pagination"
              class="dataTables_paginate paging_simple_numbers float-right"
              @vuetable-pagination:change-page="onChangePage"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 ">
      <SingleContact
        v-if="typeof this.$route.params.id !== 'undefined' && currentData"
        @fieldChanged="onFieldChanged"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Swal from "sweetalert2";
import { mapState } from "vuex";
import Vuetable from "vuetable-2";
import SearchBar from "@/components/SearchBar.vue";
import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";
import DefaultTable from "@/components/rocket/CnfTableDefaults";
import SingleContact from "./ContactsSingle.vue";

import ContactsApi from "./Contacts.service";

export default {
  name: "Contacts",
  components: {
    Vuetable,
    SearchBar,
    SingleContact,
    VuetablePagination
  },
  data() {
    return {
      fields:[
        {
          name: "name",
          title: "Nombre",
          // titleClass: "text-info",
          // dataClass:"h6 text-primary",
          // callback: DefaultTable.fmt.reconId
        },
        {
          name: "rfc",
          title: "RFC",
          // titleClass: "text-info",
          // dataClass:"h6 text-primary",
          // callback: DefaultTable.fmt.reconId
        },
        {
          name: "email",
          title: "email",
          // titleClass: "text-info",
          // dataClass:"h6 text-primary",
          // callback: DefaultTable.fmt.reconId
        },
        // {
        //   name: "phone",
        //   title: "email",
        //   // titleClass: "text-info",
        //   // dataClass:"h6 text-primary",
        //   // callback: DefaultTable.fmt.reconId
        // }
      ],
      css: DefaultTable.css,
      cssPages: DefaultTable.cssPagination,
      sortOrder: [
        {
          field: "createdAt",
          direction: "asc"
        }
      ],
      sort: [
        {
          field: "recon_sequence",
          sortField: "recon_sequence",
          direction: "desc"
        }
      ],
      search: { search: "" },
      currentData: {},
      currentId: {}
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT", "user"]),
    totalAdsQuota: () => this.totalAdsOnAir * 10 + 20,
    totalAdsQuotaPercent: () => {
      if (this.totalAds > 0) {
        return this.totalAds / (this.totalAdsOnAir * 10 + 20);
      }
      return 0;
    }
  },
  watch: {
    $route: {
      handler(to, from) {
        console.log("Route changes", from, to, to.params.id, this);
        if (typeof to.params.id === "undefined") {
          this.currentReconData = null; // hide single
          return;
        }
        if (this.currentReconData) {
          const { recon_sequence = null } = this.currentReconData;
          // eslint-disable-next-line camelcase
          if (recon_sequence && recon_sequence === to.params.id) {
            return;
          }
        }
        this.currentId = to.params.id;
      },
      immediate: true
    }
  },
  methods: {
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.contactsTable.changePage(page);
    },
    confirmAddContact() {
      this.$swal({
        title: "Crear contacto",
        text: "¿Quieres crear uno nuevo?",
        icon: "question",
        confirmButtonText: "Crear",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true
      }).then((result) => {
        console.log(result);
        if (result.value) {
          ContactsApi.create({ name: "Nuevo contacto" })
            .then(res => {
              this.$refs.contactsTable.refresh();
              this.currentData = res;
              this.$router.push({
                name: "ContactSingle",
                params: { id: res._id }
              });
            });
        }
        else if (result.isDismissed && result.dismiss === "cancel") {
          // this.$emit("cancelled"); // Hay cambios pero se cancelaron
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onRowClass(dataItem, index) {
      const { _id = null } = this.currentData;
      return _id === dataItem._id ? "bg-dark" : "cursor-pointer";
    },
    // eslint-disable-next-line no-unused-vars
    onRowClicked(row, ev) {
      const { params } = this.$route;
      const { id } = params;
      this.currentData = row;
      const { data :{ _id: cid } } = row;
      console.log("Clic", row, cid);

      if (cid === id) {
        return;
      }

      this.$router.push({
        name: "ContactSingle",
        params: { id: cid }
      });
    },
    onFieldChanged(id, field, value) {
      console.log("Saving, ", field, value);
      const query = {};
      query[field] = value;
      ContactsApi.updateById(id, query)
        .then(() => {
          this.$refs.contactsTable.refresh();
        });
    },
    onSearchChanged(val) {
      this.search.search = val;
      this.$refs.contactsTable.refresh();
    }
  }
};
</script>

<style scoped>
.bg-adcast {
  background-color: #3456b8 !important;
}
</style>
