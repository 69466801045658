<style scoped>
.file-droparea {
  background-color: #754a744f;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}

.file-droparea.active {
  background-color: #754a74ff;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}

.file-droparea>*>.icon {
  font-size: 200%;
  /* display: block;
  margin: auto; */
}

.file-preupload>.thumb {
  min-height: 80px;
  min-width: 80px;
  display: block;
  margin: auto;
  border: unset;
}

.file-preupload>.filename {
  font-weight: 500;
  font-size: 125%;
}

.media a {
  color: unset !important;
}
</style>
<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="box">
            <ul class="nav nav-tabs nav-tabs-info" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active" data-toggle="tab" href="#cfdi" role="tab"
                  @click="onClickedTab('cfdi')"
                >CFDI</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link" data-toggle="tab" href="#cfdi-anual" role="tab"
                  @click="onClickedTab('cfdi-anual')"
                >Resumen Anual</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link" data-toggle="tab" href="#cfdi-pagos" role="tab"
                  @click="onClickedTab('cfdi-pagos')"
                >Pagos</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link" data-toggle="tab" href="#cfdi-nominas" role="tab"
                  @click="onClickedTab('cfdi-nominas')"
                >N&oacute;minas</a>
              </li>
            </ul>
            <div class="box-body tab-content">
              <div id="cfdi" class="tab-pane fade active show">
                <CfdiTablePanel
                  v-if="currentTab=='cfdi'"
                  ref="cfdiPanel"
                  :my-rfc="COMPANYRFC"
                />
              </div>
              <div id="cfdi-anual" class="tab-pane fade">
                <CfdiAnualPanel v-if="currentTab == 'cfdi-anual'" ref="cfdiAnualPanel" :my-rfc="COMPANYRFC" />
              </div>
              <div id="cfdi-pagos" class="tab-pane fade">
                <CfdiPagosPanel v-if="currentTab == 'cfdi-pagos'" ref="cfdiPagosPanel" :my-rfc="COMPANYRFC" />
              </div>
              <div id="cfdi-nominas" class="tab-pane fade">
                <CfdiNominasPanel v-if="currentTab == 'cfdi-nominas'" ref="cfdiNominasPanel" :my-rfc="COMPANYRFC" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import CfdiTablePanel from "@/plugins/Cfdi/CfdiTablePanel.vue";
import CfdiAnualPanel from "@/plugins/Cfdi/CfdiAnualPanel.vue";
import CfdiPagosPanel from "@/plugins/Cfdi/CfdiPagosPanel.vue";
import CfdiNominasPanel from "@/plugins/Cfdi/CfdiNominasPanel.vue";

export default {
  name: "RocketCFDI",
  components: {
    CfdiTablePanel,
    CfdiAnualPanel,
    CfdiPagosPanel,
    CfdiNominasPanel,
  },
  data() {
    return {
      currentTab: "cfdi",
      cfdiList: []
    };
  },
  computed: {
    ...mapState(["API_ROOT", "COMPANYRFC"]),
  },
  methods: {
    refreshListCfdi() {
      if (this.currentTab === "cfdi") {
        this.$nextTick(() => {
          this.$refs.cfdiPanel.refreshListCfdi();
        });
      }
    },
    onClickedTab(tab) {
      this.currentTab = tab;
      console.log(this.$route);
      const { name = "" } = this.$route;
      if (name === "CFDI") {
        return;
      }
      this.$router.push({
        name: "CFDI"
      });
    },
  }
};
</script>
