import axios from "axios";
import store from "@/store";

const UsersApi = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true; // Si no se pone no se manda la `cookie-session`
  },

  getUserList(query) {
    const url = `${store.state.API_ROOT}/api/users`;
    return new Promise((resolve, reject) => {
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  getfiles() {
    axios.get("/files")
      .then((res) => {
      });
    return "";
  },

  getPrivileges() {
    return new Promise((resolve, reject) => {
      axios.get("/availableprivileges")
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  updatePrivileges(updatedUserid, newPrivileges) {
    console.log("Privilege post -> ", newPrivileges);
    return new Promise((resolve, reject) => {
      axios.post(`${updatedUserid}`, { privileges: newPrivileges })
        .then((res) => {
          // console.log("Privilege post", res);
          resolve(res.data);
        })
        .catch(e => {
          console.log("Privilege post ERR", e);
          reject(e);
        });
    });
  }

};

export default UsersApi;
