/* eslint-disable camelcase */
import axios from "axios";
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import store from "@/store";

const Api = {
  baseurl() {
    return `${store.state.API_ROOT}/api/cfdi`;
  },
  find(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  getByUuid(uuid) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/${uuid}`;
      axios.get(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding by uuid", err);
          reject(err);
        });
    });
  },
  create(items) {
    const query = items;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  updateByUuid(uuid, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/${uuid}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  searchRecons(query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  addReconByUuid(uuid, data) {
    const query = data;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/${uuid}/recons`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  removeReconByUuid(uuid, data) {
    const { reconid = "" } = data;
    return new Promise((resolve, reject) => {
      if (!reconid || !uuid) {
        reject(new Error("Missing reconid or uuid"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/cfdi/${uuid}/recons/${reconid}`;
      axios.delete(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  verifyCfdi(uuid, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/cfdi/${uuid}/valid`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  getSumMontly(dates, query) {
    // Necesitamos un Array 1 o mas con mes y año
    // dates = [{year:2000, month: 1}, {year:2000, month: 2}, {year:2000, month: 3}]
    return new Promise((resolve, reject) => {
      if (!Array.isArray(dates)) {
        reject(new Error("Missing array year and month"));
        return;
      }
      const urls = dates.map(d => `${store.state.API_ROOT}/api/cfdi/by/${d.year}/${d.month}?estado_sat=${d.estado_sat}`);
      if (!urls) {
        reject(new Error("Empty array"));
        return;
      }
      axios.all(urls.map((url) => axios.get(url, { params: query, withCredentials: true })))
        .then((res) => {
          const data = res.map((it) => {
            const { data: d = [] } = it;
            return d;
          });
          resolve(data);
        }).catch(err => {
          console.log("ERROR get CFDI sum", err);
          reject(err);
        });
    });
  },
  mergeTableIntoTSV(rows) {
    // Tab separated values (TSV) sirve para pegar en Sheets o Excel
    const content = rows.map((it) => {
      // console.log("uniendo");
      const {
        verificado_asoc = " ", version = "-", tipo = "-", estado_sat = " ",
        fecha_emision = " ", fecha_timbrado = " ", estado_pago = "-", fecha_pago = "-",
        serie = " ", folio = " ", uuid = " ", uuid_relacion = " ",
        rfc_emisor = " ", nombre_emisor = " ", lugar_de_expedicion = " ", rfc_receptor = " ",
        nombre_receptor = " ", residencia_fiscal = " ", numregidtrib = " ", uso_cfdi = " ",
        subtotal = "0", descuento = "0", total_ieps = "0", iva_16 = "0",
        retenido_iva = "0", retenido_isr = "0", ish = "0", total = "0",
        total_original = "0", total_trasladados = "0", total_retenidos = "0", total_localtrasladado = "0",
        total_localretenido = "0", complemento = "-", moneda = "MXN", tipo_de_cambio = "1",
        forma_de_pago = "-", metodo_de_pago = "-", num_cta_pago = "-", condicion_de_pago = "-",
        conceptos = " ", combustible = " ", ieps_3 = " ", ieps_6 = " ",
        ieps_7 = "0", ieps_8 = "0 ", ieps_9 = "0", ieps_26_5 = "0",
        ieps_30 = "0", ieps_53 = "0", ieps_160 = "0", archivo_xml = "-",
        direccion_emisor = " ", localidad_emisor = " ", direccion_receptor = " ", localidad_receptor = "-",
        iva_8 = "0", ieps_30_4 = "0", iva_ret_6 = "0", regimenfiscal_receptor = " ", domiciliofiscal_receptor = "-"
      } = it;

      const dateEmision = parseISO(fecha_emision);
      const dateTimbrado = parseISO(fecha_timbrado);
      const emision = format(dateEmision, "yyyy-MM-dd",  { locale: esmx } );
      const timbrado = format(dateTimbrado, "yyyy-MM-dd HH:mm:ss",  { locale: esmx } );

      let merged = `${verificado_asoc}\t${estado_sat}\t${version}\t${tipo}`;
      merged += `\t${emision}\t${timbrado}\t${estado_pago}\t${fecha_pago}`;
      merged += `\t${serie}\t${folio}\t${uuid}\t${uuid_relacion}`;
      merged += `\t${rfc_emisor}\t${nombre_emisor}\t${lugar_de_expedicion}\t${rfc_receptor}`;
      merged += `\t${nombre_receptor}\t${residencia_fiscal}\t${numregidtrib}\t${uso_cfdi}`;
      merged += `\t${subtotal}\t${descuento}\t${total_ieps}\t${iva_16}`;
      merged += `\t${retenido_iva}\t${retenido_isr}\t${ish}\t${total}`;
      merged += `\t${total_original}\t${total_trasladados}\t${total_retenidos}\t${total_localtrasladado}`;
      merged += `\t${total_localretenido}\t${complemento}\t${moneda}\t${tipo_de_cambio}`;
      merged += `\t${forma_de_pago}\t${metodo_de_pago}\t${num_cta_pago}\t${condicion_de_pago}`;
      merged += `\t${conceptos}\t${combustible}\t${ieps_3}\t${ieps_6}`;
      merged += `\t${ieps_7}\t${ieps_8}\t${ieps_9}\t${ieps_26_5}`;
      merged += `\t${ieps_30}\t${ieps_53}\t${ieps_160}\t${archivo_xml}`;
      merged += `\t${direccion_emisor}\t${localidad_emisor}\t${direccion_receptor}\t${localidad_receptor}`;
      merged += `\t${iva_8}\t${ieps_30_4}\t${iva_ret_6}\t${regimenfiscal_receptor}\t${domiciliofiscal_receptor}`; // Agregar todos los campos y en el orden correcto
      return merged;
    })
      .reduce((accum = "", curr) => `${accum}\n${curr}`, "");
    return content;
  },
  taxClasses: {
    sinretencion: "Sin Retención",
    arrendamiento: "Arrendamiento",
    fletes: "Flete",
    honorarios: "Honorarios",
    asimilados: "Asimilado",
    nominas: "Nomina"
  }
};

export default Api;
