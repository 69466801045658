import Quotations from "./Quotations.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options.,
  install(Vue, options) {
    const {
      router = null, store = null, path = "/quotations", name = "Quotations"
    } = options;

    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Quotations,
      meta: {
        title: "Cotizaciones",
        label: "Cotizaciones",
        icon: "fluent:cart-20-regular",
        privileges: ["ROCKET-USER", "QUOTATIONS-USER"]
      },
      children: [
        {
          path: ":id",
          name: "QuotationsSingle",
          component: Quotations,
          meta: {
            title: "Cotizaciones",
            label: "Cotizaciones",
            icon: "fluent:cart-20-regular",
            privileges: ["ROCKET-USER", "QUOTATIONS-USER"]
          }
        }
      ]
    };
    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue
  }
};
