<style scoped>
/* Se requieren los dos porque uno es de mouseover,
 otro es de dragdrop */

.droparea:hover, .dragactive{
  cursor: pointer;
  border: 1px dotted #f96197 !important;
  /* background-color: hsla(337, 36%, 47%, 0.21); */
}
</style>

<template>
  <div
    class="droparea"
    :class="{ dragactive: active }"
    @dragenter="onDragEnter"
    @drop="onDrop"
    @dragover="onDrag"
    @dragleave="onDragLeave"
    @click="$refs.file.click()"
  >
    <input ref="file" type="file" style="display: none" @change="onFileSelected">
    <!-- el slot es el 'hueco' para lo que va dentro de la tag -->
    <slot></slot>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      active: false
    };
  },
  methods: {
    // Eventos: drag, dragend, dragenter, dragexit, dragleave, dragover, dragstart, and drop
    // Necesitamos manejar al menos:
    //   dragenter, dragleave, dragover y drop
    // o si no el browser los maneja solo.
    onDrop(event) {
      // console.log("Dropped", event.dataTransfer.files[0]);
      const { files } = event.dataTransfer;
      if (files) {
        this.$emit("fileDropped", files[0]);
      }
      this.active = false;
      event.preventDefault();
    },
    onDrag(event) {
      // console.log("DRAG", event.dataTransfer.files);
      this.active = true;
      event.preventDefault();
    },
    onDragEnter(event) {
      // console.log("DRAG enter", event.dataTransfer.files);
      event.preventDefault();
      this.active = true;
    },
    onDragLeave(event) {
      // console.log("DRAG LEAVE", event.dataTransfer.files);
      this.active = false;
      event.preventDefault();
    },
    onFileSelected(ev) {
      const { files } = ev.target;
      // console.log("FILE SELECTED via dialog", ev.target.files);
      if (files) {
        this.$emit("fileDropped", files[0]);
      }
      this.active = false;
      ev.preventDefault();
    }
  },
  mounted() {
  }
};
</script>
