<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
/* tr { */
  /* display: block; */
  /* cursor: pointer; */
  /* background-color: blue; */
/* } */
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
  color: #fff;
  border: 1px solid #398bf7;
  background-color: #398bf7;
}
.table-tools {
  display: inline-block;
  padding-top: 5px;
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
  color: #fff;
  border: 1px solid #398bf7;
  background-color: #398bf7;
}
a[disabled].pag-button {
  pointer-events: none;
}
.text-striked {
  text-decoration-line: line-through;
}
</style>
<template>
  <div>
    <table class="table table-hover inshadow ">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <th></th>
          <th v-if="tipo!=='egreso'">
            <span class="float-right">Ingreso</span>
          </th>
          <th v-if="tipo!=='ingreso'">
            <span class="float-right">Egreso</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="rows.length < 1">
          <td colspan="2" class="text-center">
            Sin registros...
          </td>
        </tr>
        <tr v-for="it in rows" :key="it._id" @click="onItemClick(it,...arguments)">
          <td>
            <div class="row pl-3">
              <div class="col-12">
                <small>
                  <span class="text-primary" title="Contable">
                    {{ it.accountingdate_fmt }}
                  </span>
                  <span v-if="it.bankdate_fmt != it.accountingdate_fmt" class="text-default pl-2" title="En banco">
                    <Icon icon="clarity:piggy-bank-line" :inline="true" />
                    {{ it.bankdate_fmt }} en banco
                  </span>
                  <span class="pl-3 pr-0 mr-0 text-purple" title="Huella Rocket">
                    <Icon icon="fa6-solid:fingerprint" :inline="true" class="mr-0" />
                    {{ it.hashdate }}.<strong>{{ it.hashid }}</strong>
                  </span>
                  <Icon
                    v-show="it.status==='warn'"
                    width="1.1em" height="1.1em"
                    icon="material-symbols:warning"
                    :inline="true"
                    class="text-danger mx-1"
                  />
                  <Icon
                    v-show="it.status==='trash'"
                    width="1.1em" height="1.1em"
                    icon="material-symbols:delete-sharp"
                    :inline="true"
                    class="text-danger mx-1"
                  />
                  <br>
                  <i class="text-muted">
                    {{ it.dsttransid }}: {{ it.dsttransid_fmt }}
                  </i>
                  <br>
                  <small v-if="it?.reference.length > 0" class="">
                    {{ it.reference }}
                    <br>
                  </small>
                  <small class="">
                    {{ it.fullnotes }}
                  </small>
                </small>
                <div class="col-12 mt-1 pl-0">
                  <p
                    v-for="(recon, idx) in it.recons"
                    :key="idx"
                    class="my-1"
                  >
                    <a
                      href="#"
                      class="d-inline"
                      @click.stop.prevent="redirectToRecon(recon.recon_sequence, ...arguments)"
                    >
                      <span class="badge badge-sm badge-pill badge-dark py-1">
                        <Icon icon="ph:arrows-in-simple-light" />
                        C{{ recon.recon_sequence }}
                      </span>
                      <small class="pl-1">{{ recon.title.substring(0, 50) }}</small>
                    </a>
                  </p>
                  <p v-if="it.recons.length < 1" class="text-danger my-0 px-1">
                    <Icon icon="material-symbols:warning-outline" :inline="true" class="text-danger" />
                    Sin asociar conciliación
                  </p>
                </div>
                <div v-if="it.cfdis.length" class="col-12 mt-1 pl-0">
                  <p
                    v-for="(cfdis, idx) in it.cfdis"
                    :key="idx"
                    class="my-1"
                  >
                    <a
                      href="#"
                      class="d-inline"
                      @click.stop.prevent="redirectToCfdi(cfdis.uuid, ...arguments)"
                    >
                      <span class="badge badge-sm badge-pill badge-dark py-1" :class="{'text-striked': cfdis.estado_sat=='C'}">
                        <Icon icon="la:file-invoice-dollar" :inline="true" />
                        {{ cfdis.total }}
                      </span>
                      <small>
                        {{ cfdis.rfc_emisor }}
                        <Icon icon="material-symbols:arrow-right" />
                        {{ cfdis.rfc_receptor }}
                      </small>
                    </a>
                    &nbsp;
                    <a href="#" @click.stop.prevent="openPdf(cfdis)">
                      <Icon icon="bi:file-pdf" :inline="true" /> PDF
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </td>
          <td v-if="tipo!=='egreso'">
            <span v-show="it.tipo==='ingreso'" class="text-dark">$</span>
            <strong class="float-right text-nowrap">
              {{ it.tipo==="ingreso" ? it.amount_fmt:"" }}
            </strong>
          </td>
          <td v-if="tipo!=='ingreso'">
            <span v-show="it.tipo==='egreso'" class="text-dark">$</span>
            <strong class="float-right text-nowrap">
              {{ it.tipo==="egreso" ? it.amount_fmt:"" }}
            </strong>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0 text-right">
            <!-- <Icon icon="mdi:sigma" :inline="true" /> -->
            Total
          </td>
          <td v-if="tipo!=='egreso'" class="pb-0 text-right">
            {{ sums.ingreso_amount }}
          </td>
          <td v-if="tipo!=='ingreso'" class="pb-0 text-right">
            {{ sums.egreso_amount }}
          </td>
        </tr>
      </tfoot>
    </table>
    <div v-if="enableTools" class="table-tools" role="status" aria-live="polite">
      <div class="btn-group">
        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
          <a
            class="dropdown-item" href="#"
            @click="copyTableToClipboard"
          >
            Copiar celdas
          </a>
        </div>
      </div>
    </div>
    <div class="paginate">
      Total: {{ data.total }}
      <a
        class="pag-button previous" aria-controls="pager" data-dt-idx="0"
        tabindex="0"
        :disabled="isFirstPage"
        @click.prevent="onPrevPage"
      >
        <Icon icon="ic:sharp-chevron-left" />
      </a>
      <span>
        <a
          v-for="(it) in shownpages" :key="it"
          class="pag-button" aria-controls="pager" :data-dt-idx="it" tabindex="0"
          :class="{current: it == data.current_page}"
          @click.prevent="onJumpToPage(it)"
        >
          {{ it }}
        </a>
      </span>
      <a
        class="pag-button next" aria-controls="pager" data-dt-idx="7"
        tabindex="0"
        :disabled="isLastPage"
        @click.prevent="onNextPage"
      >
        <Icon icon="ic:sharp-chevron-right" />
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import { Icon } from "@iconify/vue2";

import transactDict from "./transactions";
import ServiceApi from "./Banks.service";

export default {
  name: "BanksTable",
  components: {
    Icon
  },
  props: {
    apimode: {
      type: Boolean,
      default: true
    },
    apiurl: {
      type: String,
      default: "/banks"
    },
    limitItems: {
      type: Number,
      default: 200
    },
    enableTools: {
      type: Boolean,
      default: true
    },
    tipo: {
      type: String,
      default: "egreso"
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      data: [],
      orderBy: "accountingdate",
      orderAsc: false,
      page: 1,
      search: ""
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    rows() {
      const { rows = [] } = this.data ?? {};
      return rows;
    },
    shownpages() {
      const {
        per_page = 0, last_page = 0, current_page = 0, total = 0
      } = this.data;

      if (total <= per_page) {
        return [1];
      }
      const pages = [];
      let counter = 0;
      for (let p = current_page - 3; p <= last_page; ++p) {
        if (p > 0 ) {
          pages.push(p);
          counter++;
        }
        if (counter >= 5) {
          break;
        }
      }
      return pages;
    },
    isLastPage() {
      const {
        last_page = 1, current_page = 1
      } = this.data;
      return current_page >= last_page;
    },
    isFirstPage() {
      const {
        current_page = 1
      } = this.data;
      return current_page <= 1;
    },
    sums() {
      const { rows = [] } = this.data;
      const sum = rows.reduce((accum, it) => {
        let {
          amount = 0
        } = it;

        const {
          tipo = ""
        } = it;

        amount = parseFloat(amount) || 0;

        return {
          egreso_amount: accum.egreso_amount + (tipo === "egreso" ? amount : 0),
          ingreso_amount: accum.ingreso_amount + (tipo === "ingreso" ? amount : 0),
        };
      }, {
        egreso_amount: 0,
        ingreso_amount: 0
      });

      // Esto les pone comas a los numeros
      const moneyOpts = {
        symbol: "", precision: 2, thousand: ",", decimal: "."
      };
      sum.egreso_amount = formatMoney(Math.abs(sum.egreso_amount), moneyOpts);
      sum.ingreso_amount = formatMoney(Math.abs(sum.ingreso_amount), moneyOpts);
      return sum;
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val /* , old */ ) {
        if (!this.apimode) {
          return;
        }
        console.log("Reload API banks", val);
        this.orderBy = "accountingdate";
        this.orderAsc = false;
        this.fillInitial();
      }
    },
    items: {
      immediate: true,
      handler(val /* , old */ ) {
        if (this.apimode) {
          return;
        }
        console.log("Refilling from data", val);
        this.data = this.transformData({ rows: val, total: val.length });
      }
    }
  },
  methods: {
    copyTableToClipboard(ev) {
      ev.preventDefault();
      // Tab separated values sirve para pegar en Sheets o Excel
      const cells = ServiceApi.mergeTableIntoTSV(this.rows);
      // console.log(cells);
      navigator.clipboard.writeText(cells);
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Celdas copiadas a portapapeles",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    },
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    onNextPage() {
      console.log("Next page:");
      const {
        last_page = 1
      } = this.data;
      this.page = Math.min(last_page, this.page + 1);
      this.refresh();
    },
    onPrevPage() {
      console.log("Prev page");
      this.page = Math.max(1, this.page - 1);
      this.refresh();
    },
    onJumpToPage(i) {
      console.log("To page", i);
      const {
        last_page = 1
      } = this.data;
      let page = Math.min(last_page, i);
      page = Math.max(1, i);
      this.page = page;
      this.refresh();
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      ServiceApi.find(this.apiurl, {
        per_page: this.limitItems,
        page: this.page,
        sort:`${this.orderBy}|${this.orderAsc ? "asc" : "desc"}`
      }).then(res => {
        if (!res) {
          console.log("Got empty");
          return;
        }

        console.log("Got", res);
        this.data = this.transformData(res);
        // this.$emit("statsChanged", { count, total: rows.length });
      });
    },
    refresh() {
      if (!this.apimode) {
        return;
      }
      this.fillInitial();
    },
    transformData(dataInfo) {
      let  { rows = [] } = dataInfo;
      const transactionList = Object.keys(transactDict); // para no hacerlo tantas veces
      rows = rows.map((it) => {
        const {
          bankdate = null,
          accountingdate = null,
          dsttransid,
          amount = 0,
          hash = ".",
          recons = []
        } = it;

        // recons es un array porque el default lo es
        // y como solo son < 5 directo mas limpio un `for` que in `reduce`
        // sacar todos los cfdis de cada recon
        // para tener la variable expuesta toda junta
        let allcfdis = [];
        for (let i = 0; i < recons.length; ++i) {
          const { cfdis = [] } = recons[i];
          allcfdis = allcfdis.concat(cfdis);
        }
        // console.log("TOT", allcfdis.length);

        let bankdate_fmt = "";
        let accountingdate_fmt = "";
        let dsttransid_fmt = "";
        if (accountingdate) {
          const ad = parseISO(accountingdate);
          accountingdate_fmt = format(ad, "d LLL, yy",  { locale: esmx } );
        }
        if (bankdate) {
          const bd = parseISO(bankdate);
          bankdate_fmt = format(bd, "d LLL, yy",  { locale: esmx } );
        }
        if (transactionList.indexOf(dsttransid) > -1) {
          dsttransid_fmt = transactDict[dsttransid];
        }

        let amountNum = parseFloat(amount);
        if (Number.isNaN(amountNum)) {
          amountNum = 0;
        }
        const yyyymmHash = hash.split(".");
        const [hashdate, hashid] = yyyymmHash; // Para poder usarlos separados
        return {
          ...it,
          bankdate_fmt,
          accountingdate_fmt,
          dsttransid_fmt,
          cfdis: allcfdis, // todos los cfdi extraídos de las recons
          tipo: amountNum >= 0 ? "ingreso" : "egreso",
          hashdate,
          hashid,
          amount_fmt: formatMoney(Math.abs(amountNum), { symbol: "$", format: "%v" })
        };
      });

      // se copia funcion stats desde cfdiTable
      const stats = rows.reduce((accum, currentValue) => {
        const { recons = [] } = currentValue;
        // eslint-disable-next-line prefer-const
        let { assoc, classified } = accum;

        if (recons.length > 0) {
          assoc += 1;
        }

        return {
          assoc,
          classified
        };
      }, { assoc: 0, classified: 0 });

      this.$emit("statsChanged", { ...stats, total: rows.length });
      return { ...dataInfo, rows };
    },
    redirectToRecon(recSeq, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log("Redirect to C", recSeq);
      this.$router.push({
        name: "ReconsSingle",
        params: { id: recSeq }
      });
    },
    redirectToCfdi(uuid, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log("Redirect to Cfdi", uuid);
      this.$router.push({
        name: "CFDISingle",
        params: { uuid }
      });
    },
    openPdf(elem) {
      const { uuid = "" } = elem ?? {};
      const url = `${this.API_ROOT}/api/cfdi/${uuid}/pdf`;
      window.open(url, uuid);
    }
  }
};
</script>
