import Plugin from "./Tarengo.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    console.log("[Tarengo] Registering", options);
    const { router = null, store = null } = options;
    const { path = "/tarengo", name = "Tarengo" } = options;

    if (!router || !store) {
      console.warn("Tarengo Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Plugin,
      meta: {
        label: "Tarengo",
        title: "Tarengo ",
        icon: "bi:sign-intersection-t",
        privileges: ["ROCKET-USER"],
      }
    };

    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    // r.name += "Single";
    // r.path += "/:id";
    // router.addRoutes([r]);
  }
};
