<!-- eslint-disable vue/attribute-hyphenation -->
<style scoped>
.lookup-right::before {
  padding-top: 6px;
}
/* .paste-area{
  background-color: red;
  position: relative;
  right: -10em;
} */
.paste-margin-input {
  border: 1px solid #926dde;
}
</style>

<template>
  <div class="row">
    <div class=" col-xl-3 col-md-6 col-12 ">
      <div class="box box-body bg-red">
        <div class="flexbox">
          <p class="my-0 text-center">
            <Icon icon="iconoir:antenna" width="3em" height="3em" />
            <br>
            <span class="font-weight-200">PROGRAM</span>
          </p>
          <div class="btn-group">
            <button class="btn btn-danger dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              Mañana
            </button>
            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
              <a
                v-for="pl in playlists" :key="pl.id"
                class="dropdown-item"
                href="#"
                @click="onProgramChanged(pl)"
              >
                {{ pl.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" col-xl-3 col-md-6 col-12 ">
      <div class="box box-body bg-default">
        <div class="flexbox">
          <p class="my-0 text-center">
            <Icon icon="ic:baseline-qr-code-2" width="3em" height="3em" />
            <br>
            <span class="font-weight-200">Encuesta</span>
          </p>
          <div class="btn-group">
            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              Visible
            </button>
            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
              <a
                v-for="item in polls" :key="item.id"
                class="dropdown-item"
                href="#"
                @click="onPollChanged(item)"
              >
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" col-xl-3 col-md-6 col-12 ">
      <div class="box box-body bg-default">
        <div class="flexbox">
          <p class="my-0 text-center">
            <Icon icon="solar:pallete-2-bold" width="3em" height="3em" />
            <br>
            <span class="font-weight-200">Estilo</span>
          </p>
          <div class="btn-group">
            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              Noticias
            </button>
            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
              <a
                v-for="item in themes" :key="item.id"
                class="dropdown-item"
                href="#"
                @click="onThemeChanged(item)"
              >
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12" @paste="onPaste">
      <div class="box">
        <div class="box-header" @click="onHeaderClic">
          <h3 class="box-title">
            EDIS Redes Sociales
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions text-info">
              <input
                ref="pasteField"
                class="form-control paste-margin-input"
                type="text"
                value="" placeholder="Pega aquí"
              >
            </div>
          </div>
        </div>
        <div class="box-body">
          <ul class="nav nav-tabs" role="tablist">
            <li v-for="pl in playlists" :key="pl.id" class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="false"
                :class="{ active: playlist==pl.id, show: playlist==pl.id }"
                @click.prevent="onTabClicked(pl)"
              >
                <span class="hidden-sm-up">
                  <i class="ion-home"></i>
                </span> <span class="hidden-xs-down">
                  {{ pl.name }}
                </span>
              </a>
            </li>
          </ul>
          <div class="row">
            <div class="col-md-6">
              <div class="box border-top border-gray">
                <div class="box-header">
                  <h3 class="box-title text-primary">
                    PREVIEW
                  </h3>
                  <div class="box-tools">
                    <SearchBar
                      :value="search"
                      @search-changed="onSearchChanged"
                    />
                  </div>
                </div>
                <div class="box-body no-padding">
                  <RedesSocialesTable
                    ref="RedesSocialesTable"
                    :apiurl="`/api/redessoc?playlist=${playlist}`"
                    @message-clicked="onMessageClicked"
                  />
                </div>
              </div>
            </div>
            <div v-if="Object.keys(currentMessage).length>0" class="col-md-6">
              <div class="box border-top border-gray">
                <div class="box-header px-2">
                  <h3 class="box-title text-yellow">
                    Mensaje
                  </h3>
                  <div class="box-tools">
                    <button class="btn btn-xs btn-primary mr-1" @click.prevent="onEditClicked">
                      Editar
                    </button>
                    <!-- <button class="btn btn-xs btn-primary">
                      Guardar
                    </button> -->
                    <button class="btn btn-xs btn-danger mr-1">
                      Borrar
                    </button>
                    <button class="btn btn-xs btn-secondary">
                      <Icon icon="ph:x-light" />
                    </button>
                  </div>
                </div>
                <div class="box-body px-2">
                  <message-view
                    :message="currentMessage"
                  />
                </div>
                <div class="box-footer text-right">
                  <h5>
                    Control en vivo
                  </h5>
                  <!-- <button class="btn btn-secondary">
                    Descartar cambios
                  </button>
                  &nbsp; -->
                  <button class="btn btn-sm btn-danger float-right">
                    <Icon icon="iconoir:antenna" width="1em" height="1em" />
                    Enviar a Program
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <MessageEdit
        ref="MessageEdit"
        :message="messageToSave"
        @saveMessage="onSaveMessage"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format, addSeconds } from "date-fns";
import { es } from "date-fns/locale";
import { Icon } from "@iconify/vue2";
import SearchBar from "@/components/SearchBar.vue";
import RedesSocialesTable from "./RedesSocialesTable.vue";
import MessageEdit from "./MessageEdit.vue";

import { Detector, ServiceApi } from "./RedesSociales.service";
import MessageView from "./MessageView.vue";

export default {
  components: {
    Icon,
    "message-view": MessageView,
    RedesSocialesTable,
    MessageEdit,
    SearchBar
  },
  props: {
    currentId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      playlist: "",
      playlistProgram: "",
      playlists: [
        {
          id: "manana",
          name: "Mañana"
        },
        {
          id: "tarde",
          name: "Tarde"
        },
        {
          id: "noche",
          name: "Noche"
        },
        {
          id: "deportes",
          name: "Deportes"
        },
        {
          id: "espectaculos",
          name: "Espectáculos"
        },
        {
          id: "programa_especial",
          name: "Programa Especial"
        },
        {
          id: "pruebas",
          name: "Pruebas"
        }
      ],
      themes: [
        {
          id: 1,
          name: "Espectáculos",
        },
        {
          id: 2,
          name: "Deportes",
        },
        {
          id: 3,
          name: "Tarde",
        }
      ],
      polls: [
        {
          id: 1,
          name: "Al Cerrar",
        },
        {
          id: 2,
          name: "Oculta",
        }
      ],
      search: "",
      currentMessage: {},
      messageToSave: {
        name: "",
        user: "",
        msg: "",
        type: "whatsapp",
        playlist: "manana"
      },
      isSavingMsg: false
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        console.log("Route changes", from, to);
        const { playlist = "" } = to.params;
        if (!playlist) {
          this.playlist = "manana";
          return;
        }

        const pls = this.playlists.map(it => it.id);
        const pl = pls.indexOf(playlist) > -1 ? playlist : "manana";
        if (pls.indexOf(playlist) === -1) {
          this.$router.push({
            name: "RedesSoc",
            params: { playlist: "manana" },
            // replace: true
          });
          return;
        }
        this.playlist = pl;
      },
      immediate: true
    }
  },
  methods: {
    onHeaderClic() {
      console.log(this.$refs.pasteField);
    },
    onPaste(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        // console.log("kind", item.kind);
        if (item.kind === "string") {
          const text = ev.clipboardData.getData("Text");
          console.log("TEXT DETECTED", ev.clipboardData.getData("Text"));
          const res = Detector.extractWhatsapp2023(text);
          console.log("RES WHATS", res);
          const {
            total = 0, sender = "", messages = [], type = "whatsapp"
          } = res;
          if (total > 0) {
            const msg = {
              name: sender,
              user: sender,
              msg: messages.join("\n"),
              playlist: this.playlist,
              type
            };
            this.messageToSave = msg;
            this.$refs.MessageEdit.showModal();
            return true;
          }

          const restxt = Detector.detectText(text);
          console.log("RES Text", restxt);
          const { match = {} } = restxt ?? {};
          const { input: msgTxt = "" } = match ?? {};
          if (msgTxt) {
            const msg = {
              name: "",
              user: "",
              msg: msgTxt,
              playlist: this.playlist,
              type
            };
            this.messageToSave = msg;
            this.$refs.MessageEdit.showModal();
            return true;
          }
          // Ésto es solo para debug
          if (res) {
            this.$swal({
              position: "bottom-end",
              toast: true,
              icon: "success",
              title: `Detectado: ${res.id}`,
              text: res.comment,
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true
            });
          }
          return true;
        }
        if (item.kind === "file") {
          const blob = item.getAsFile();
          const reader = new FileReader();
          // Cargar en base64 para poder detectar
          reader.onload = (loadev) => {
            console.log("FILE DETECTED", loadev.target.result);
            return true;
          }; // data url!
          reader.readAsDataURL(blob);
        }
      }
      return true; // Se requiere retornar true
    },
    mouseMove(ev) {
      this.log = `${ev.target.className} \n${ev.x}, ${ev.y}`;
    },
    onTabClicked(playlist) {
      const { id = "manana" } = playlist;
      this.$router.push({
        name: "RedesSoc",
        params: { playlist: id },
        // replace: true
      });
    },
    onMessageClicked(msg) {
      console.log("MSG", msg);
      this.currentMessage = msg;
    },
    onEditClicked() {
      this.messageToSave = this.currentMessage;
      this.$refs.MessageEdit.showModal();
    },
    onSaveMessage(info) {
      this.isSavingMsg = true;
      const {
        _id = "", user, name, msg, type, playlist
      } = info ?? {};

      if (_id) {
        this.onUpdateMessage(info);
        return;
      }

      ServiceApi.create({
        user, name, msg, type, playlist
      })
        .then(res => {
          this.isSavingMsg = false;
          console.log("SAVED:", res);
          this.$refs.RedesSocialesTable.refresh();
          this.$refs.MessageEdit.closeModal();
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Mensaje guardado",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 500 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("MENSSAGE SAVING FAILED ", e);
          this.isSavingMsg = false;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo guardar mensaje",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    onUpdateMessage(info) {
      const {
        _id: id = "", user, name, msg, type, playlist
      } = info ?? {};

      ServiceApi.updateById(id, {
        user, name, msg, type, playlist
      })
        .then(res => {
          this.isSavingMsg = false;
          console.log("SAVED:", res);
          this.$refs.RedesSocialesTable.refresh();
          this.$refs.MessageEdit.closeModal();
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Mensaje actualizado",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 500 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("MESSAGE SAVING FAILED ", e);
          this.isSavingMsg = false;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo guardar mensaje",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    onProgramChanged(playlist) {
      ServiceApi.changeProgram(playlist);
    },
    onThemeChanged(theme) {
      ServiceApi.changeTheme(theme);
    },
    onPollChanged(theme) {
      ServiceApi.changePoll(theme);
    },
    onSearchChanged(val) {
      // FIXME creo que aqui va lo del router en lugar de un llamado a la api
      ServiceApi.getBySearch(val);
    }
  },
  beforeMount() {

  },
  mounted() {

  }
};
</script>
