<style scoped>

</style>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-10">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Calendario Tarengo
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row pt-0">
            <div class="col-12">
              <TarengoCalendar
                :disabled="false"
                @segment-selected="segmentSelected"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Programación
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row pt-0">
            <div class="col-12">
              {{ current }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import TarengoCalendar from "@/plugins/Tarengo/TarengoCalendar.vue";

export default {
  components: {
    TarengoCalendar
  },
  props: {
    apiurl: {
      type: String,
      default: "/tarengo"
    }
  },
  data() {
    return {
      current: null,
      rawPaste: ""
    };
  },
  computed: {
    // ...mapState(["API_ROOT"]),
  },
  methods: {
    segmentSelected(sel) {
      this.current = sel;
      console.log(sel);
    }
  },
  mounted() {}
};
</script>
