<style scoped>
.dotted-text {
  text-decoration: underline dotted var(--gray);
  /* color: unset; */
}
.dotted-text:hover {
  text-decoration: unset;
}

.dropdown-item {
  font-weight: 500;
  margin: unset;
  background-color: white;
}
.drop-search {
  display:inline-block;
  top: 0px;
  position: sticky;
  visibility: visible;
  left: 0px;
  width: calc(100%);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.liveinput{
  background-color: blue;
  max-width: calc(90%);
}
.livelist{
  margin: 0px; padding: 0px; list-style-type: none;
}
.livelist a:hover{
  background-color: var(--primary)
}
a.disabled {
  pointer-events: none;
  cursor: default;
}

</style>
<template>
  <div class="live-edit-container">
    <slot></slot>
    <a
      v-show="!editing"
      href="#" class="hover-text-info"
      :class="{'text-danger': (v.length<1), 'dotted-text': enabled, 'disabled':!enabled}"
      @click.prevent="toggleEdit"
    >
      {{ v.length>0?v:empty }}
    </a>
    <div v-show="editing" class="liveinput">
      <div class="dropdown" :class="{ 'has-error': (error ==true)}">
        <input
          ref="livefield"
          v-model="v"
          type="text"
          class="form-control"
          :placeholder="hint"
          :aria-label="hint"
          style="width:100%;"
          @keyup.enter.prevent="onAccepted"
          @keydown.esc="onCancel" @blur="onBlur"
        >
        <div v-show="livesearchValues.length>0" class="dropdown-menu dropdown-sm drop-search bg-dark text-secondary" style="">
          <ul
            class="livelist"
          >
            <li>
              <a
                v-for="val in livesearchValues" :key="val.id" href="#" class="dropdown-item"
                @click="onLiveItemClick(val,...arguments)"
              >
                {{ val.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: ""
    },
    empty: { type: String, default: "(sin llenar)" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 30 },
    showButtons: {
      type: Boolean,
      default: true
    },
    livesearchValues: {
      type: Array,
      default: () => [] // array de {id, label, item}
    }
  },
  data() {
    return {
      editing: false,
      v: "",
      old: "",
      error: false
    };
  },
  computed: {},
  watch: {
    v: {
      handler() {
        this.vWatcher(this.v);
        // Solo cuando esta en modo edición, evita que se lance al montar/crear
        if (this.v.length > 2 && this.editing) {
          this.$emit("searchValueChanged", this.v);
        }
      }
    },
    value(val, old) {
      // console.log("·Value changed", old, ">", val);
      Vue.nextTick(() => {
        // DOM updated
        this.v = val;
        this.old = old;
      });
    }
  },
  methods: {
    vWatcher(_v) {
      // console.log("watcher of v in edit");
      this.v = typeof this.v !== "undefined" ? this.v.trimLeft() : "";
    },
    isValid() {
      if (!this.v || this.v.length < this.min) {
        return false;
      }
      if (this.v.length > this.max) {
        return false;
      }
      return true;
    },
    toggleEdit() {
      if (!this.enabled) {
        // this.onCancel();
        return;
      }
      this.editing = !this.editing;
      if (this.editing) {
        this.old = this.v; // save old value to allow cancel
        const self = this;
        Vue.nextTick(() => {
          // No se puede directo porque se acaba de crear
          self.$refs.livefield.focus();
        });
      }
      this.$emit("editingChanged", this.editing);
    },
    onAccepted() {
      console.log("Accepted");
      if (this.error) {
        return;
      }
      this.editing = false;
      this.v = this.v.trim();
      this.$emit("valueChanged", this.v, this.value); // new and old ID value
    },
    onCancel(reset = true) {
      this.v = this.old;
      if (reset) {
        this.editing = false;
      }
    },
    onLiveItemClick(it, ev) {
      ev.preventDefault();  // Evita que se refresque la pagina
      console.log("Livesearch Item click", it);
      this.$emit("livesearchItemClick", it.id, it.item);
      this.$emit("valueChanged", it.id, this.value); // new and old ID value
      this.v = it.label;
      this.editing = false;
    },
    onBlur() {
      // console.log("lost focus");
    }
  },
  beforeMount() {
    this.v = this.value;
    this.old = this.value;
  }
};
</script>
