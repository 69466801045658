<style scoped>
</style>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-8">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Ciudades
          </h3>
          <div class="box-controls float-right">
            <div class="box-header-actions">
              <div class="col-msd-6 cols-12">
                <div class="form-group">
                  <label>Región</label>
                  <select
                    class="form-control select2 select2-hidden-accessible"
                    tabindex="-1"
                    aria-hidden="true"
                    @change="onRegionChange($event)"
                  >
                    <option
                      v-for="(content, key, idx) in regions"
                      :key="idx"
                      :value="key"
                      :selected="key == currentRegion"
                    >
                      {{ content }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body">
          <Vuetable
            ref="weatherTable"
            :api-mode="false"
            :data="weatherdata"
            data-path="places"
            :fields="fields"
            class="table-hover"
            pagination-path=""
            :data-manager="dataManager"
          >
            <template slot="name" slot-scope="props">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    {{ props.rowData.name }}
                    <br>
                    <small>
                      Lat: {{ props.rowData.lat }}
                      <br>
                      Lon: {{ props.rowData.lon }}
                      <br>
                      <a :href="'https://www.google.com/search?q=Weather%20in%20'+props.rowData.name" target="_blank"> Google Weather</a>
                    </small>
                  </div>
                  <div class="col-md-4">
                    <peity :type="'line'" :options="{ 'fill': ['#a97d100a'], stroke: '#a97d10fa', width: '90%', height:15, max: 35, strokewidth: 1 }" :data="getHourlyTempPlotData(props.rowData)" />
                    <h4>
                      Current: {{ getHourlyTemperature(props.rowData.forecast.hourly) }} °C
                      <Icon width="25" height="25" :icon="weatherIcons[getHourlyCondition(props.rowData.forecast.hourly)]" />
                    </h4>
                    (Feels:{{ getHourlyApparentTemperature(props.rowData.forecast.hourly) }})
                    <br> ({{ getHourlyCondition(props.rowData.forecast.hourly) }}) {{ getHourlyPressure(props.rowData.forecast.hourly) }} mbar
                    <br>Total: {{ props.rowData.forecast.hourly.length }}
                    <br>From: {{ getDateInitial(props.rowData.forecast.hourly) }}
                    <br>To: {{ getDateEnd(props.rowData.forecast.hourly) }};
                  </div>
                  <div class="col-md-4">
                    <Icon width="25" height="25" :icon="weatherIcons[getDailyCondition(props.rowData.forecast.daily, 0)]" />
                    <Icon width="25" height="25" :icon="weatherIcons[getDailyCondition(props.rowData.forecast.daily, 1)]" />
                    <Icon width="25" height="25" :icon="weatherIcons[getDailyCondition(props.rowData.forecast.daily, 2)]" />
                    <Icon width="25" height="25" :icon="weatherIcons[getDailyCondition(props.rowData.forecast.daily, 3)]" />
                    <Icon width="25" height="25" :icon="weatherIcons[getDailyCondition(props.rowData.forecast.daily, 4)]" />
                    <br>Total: {{ props.rowData.forecast.daily.length }}
                    <br>From: {{ getDateInitial(props.rowData.forecast.daily) }}
                    <br>To: {{ getDateEnd(props.rowData.forecast.daily) }};
                  </div>
                </div>
              </div>
            </template>

            <!-- <div slot="hourly-slot" slot-scope="props">
              <div class="row align-items-center">
                ads
              </div>
            </div> -->
          </Vuetable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import axios from "axios";
import { compareAsc, format } from "date-fns";
import parseISO from "date-fns/parseISO";

import Vuetable from "vuetable-2";
import Peity from "vue-peity";
import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";

export default {
  components: {
    Vuetable,
    Peity,
    Icon,
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/recon",
    },
  },
  data() {
    return {
      regions: {
        tvacapulco: "Acapulco",
        tvaguac: "Aguascalientes",
        tvcancun: "Cancún",
        tvchihuahua: "Chihuahua",
        tvguadalaj: "Guadalajara",
        tvguadalaj2: "Guadalajara 2",
        tvhermosillo: "Hermosillo",
        tvjuarez: "Juárez",
        tvleon: "León",
        tvmaramotos: "Matamoros",
        tvmerida: "Mérida",
        tvmonterrey: "Monterrey",
        tvmorelos: "Morelos",
        tvpuebla: "Puebla",
        tvpuebla2: "Puebla 2",
        tvqueretaro: "Querétaro",
        tvsinaloa: "Sinaloa",
        tvtampico: "Tampico",
        tvtijuana: "Tijuana",
        tvtoluca: "Toluca",
        tvtorreon: "Torreón",
        tvveracruz: "Veracruz",
        tvzacatecas: "Zacatecas",
        tvecortv: "Est. CORTV Oax",
        tvejalisco: "Est. Jalisco ",
        tvemicroweather: "Mic. Sabinas",
      },
      currentRegion: "tvpuebla",
      fields: [
        {
          name: "name",
          title: "",
        }
      ],
      sortDefault: [
        {
          field: "name",
          sortField: "name",
          direction: "asc",
        },
      ],
      weatherdata: [],
      weatherIcons: {
        CLEAR: "fluent:weather-sunny-20-regular",
        PARTLY_CLOUDY: "fluent:weather-partly-cloudy-day-20-regular",
        CLOUDY: "fluent:weather-cloudy-20-regular",
        OVERCAST: "fluent:weather-cloudy-20-filled",
        LIGHT_RAIN: "fluent:weather-drizzle-20-regular",
        RAIN: "fluent:weather-rain-20-regular",
        HEAVY_RAIN: "fluent:weather-rain-20-filled",
        SHOWERS: "fluent:weather-rain-showers-day-20-regular",
        SLEET: "fluent:weather-rain-showers-day-20-regular",
        LIGHT_SNOW: "fluent:weather-snow-shower-day-20-regular",
        SNOW: "fluent:weather-snow-20-regular",
        SNOWFALL: "fluent:weather-snow-20-regular",
        HAIL: "fluent:weather-hail-day-20-regular",
        THUNDERSTORM: "fluent:weather-thunderstorm-20-regular",
        THUNDERSTORM_WITH_RAIN: "fluent:weather-thunderstorm-20-regular",
        THUNDERSTORM_WITH_HAIL: "fluent:weather-thunderstorm-20-regular",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    regionurl() {
      return `https://s3.amazonaws.com/eos.edis.mx/cities/regions/${this.currentRegion}.json`;
    },
    plotData() {
      return "1,2,3,2,22,1,2,3,2,2,2,1,1,1,0,3,2,3,1,0,2,1,3,0,1";
    },

  },
  watch: {
    weatherdata(newVal, oldVal) {
      this.$refs.weatherTable.refresh();
    },
    $route: {
      handler(to, from) {
        console.log("Route changes", from, to, to.params.id, this);
      },
      immediate: true,
    },
    // $route(to /* ,from */) ,
    searchVal(val, old) {
      // Se necesita indirecto para hacer debounce y no sobrecargar con cada teclazo
      this.setSearchQuery(val);
    },
  },
  methods: {
    getHourlyTempPlotData(place) {
      // console.log("Filtering", place);
      const { forecast = {} } = place;
      const { hourly =  [] } = forecast;
      // hourlyTemps() {
      //   const { places } = this.weatherdata;
      const temps = hourly.map(h => {
        const { temperature = -20 } = h;
        return temperature;
      });
      // console.log(temps);
      return temps.toString();
    },
    getHourlyTemperature(value) {
      const nowdate = new Date();
      const dates = value.slice().sort((a, b) => {
        // Sort by distance to now
        const { isotimestamp: ta } = a;
        const { isotimestamp: tb } = b;
        // console.log("Compare", parseISO(ta), parseISO(tb));
        const distancea = Math.abs(nowdate - parseISO(ta));
        const distanceb = Math.abs(nowdate - parseISO(tb));
        return distancea - distanceb; // sort a before b when the distance is smaller
      });
      // console.log("-----------------------");
      // console.log(dates);
      return dates[0].temperature;
    },
    getHourlyApparentTemperature(value) {
      const nowdate = new Date();
      const dates = value.slice().sort((a, b) => {
        // Sort by distance to now
        const { isotimestamp: ta } = a;
        const { isotimestamp: tb } = b;
        // console.log("Compare", parseISO(ta), parseISO(tb));
        const distancea = Math.abs(nowdate - parseISO(ta));
        const distanceb = Math.abs(nowdate - parseISO(tb));
        return distancea - distanceb; // sort a before b when the distance is smaller
      });
      return dates[0].apparentTemperature;
    },
    getHourlyCondition(value) {
      const nowdate = new Date();
      const dates = value.slice().sort((a, b) => {
        // Sort by distance to now
        const { isotimestamp: ta } = a;
        const { isotimestamp: tb } = b;
        // console.log("Compare", parseISO(ta), parseISO(tb));
        const distancea = Math.abs(nowdate - parseISO(ta));
        const distanceb = Math.abs(nowdate - parseISO(tb));
        return distancea - distanceb; // sort a before b when the distance is smaller
      });
      return dates[0].condition;
    },
    getDailyCondition(value, idx) {
      const nowdate = new Date();
      const dates = value.slice().sort((a, b) => {
        // Sort by distance to now
        const { isotimestamp: ta } = a;
        const { isotimestamp: tb } = b;
        // console.log("Compare", parseISO(ta), parseISO(tb));
        const distancea = Math.abs(nowdate - parseISO(ta));
        const distanceb = Math.abs(nowdate - parseISO(tb));
        return distancea - distanceb; // sort a before b when the distance is smaller
      });
      return dates[idx].condition;
    },
    getHourlyPressure(value) {
      const nowdate = new Date();
      const dates = value.slice().sort((a, b) => {
        // Sort by distance to now
        const { isotimestamp: ta } = a;
        const { isotimestamp: tb } = b;
        // console.log("Compare", parseISO(ta), parseISO(tb));
        const distancea = Math.abs(nowdate - parseISO(ta));
        const distanceb = Math.abs(nowdate - parseISO(tb));
        return distancea - distanceb; // sort a before b when the distance is smaller
      });
      return dates[0].pressure;
    },
    getDateInitial(value) {
      const { isotimestamp: from } = value[0];
      const fromStr = format(parseISO(from), "dd MMM, yyyy HH:mm:ss z");
      return fromStr;
    },
    getDateEnd(value) {
      const { isotimestamp: to } = value[value.length - 1];
      const toStr = format(parseISO(to), "dd MMM, yyyy HH:mm:ss z");
      return toStr;
    },
    onRegionChange(ev) {
      console.log("Region:", ev.target.value, ev);
      this.currentRegion = ev.target.value;
      this.fetchWeather();
    },
    formatHourly(data) {
      return "";
    },
    fetchWeather() {
      axios
        .get(this.regionurl)
        .then((res) => {
          console.log("GOT JSON", res.data);
          const { places = [] } = res.data;
          this.weatherdata = res.data;
        })
        .catch((err) => {});
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return {};

      const local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      // if (sortOrder.length > 0) {
      //   console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
      //   local = _.orderBy(
      //     local,
      //     sortOrder[0].sortField,
      //     sortOrder[0].direction
      //   );
      // }

      // eslint-disable-next-line no-param-reassign
      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log("pagination:", pagination);
      const from = pagination.from - 1;
      const to = from + this.perPage;

      return {
        // pagination,
        // data: _.slice(local, from, to)
        places: this.weatherdata.places,
      };
    },
  },
  mounted() {
    // const { id = 0 } = this.$route.params;
    // axios.get("https://s3.amazonaws.com/eos.edis.mx/cities/latest.json")
    this.fetchWeather();
  },
};
</script>
