import axios from "axios";
import { addMinutes } from "date-fns";
import recorders from "@/plugins/Nemos/recorders.json";

const Api = {
  // eslint-disable-next-line no-unused-vars
  recs: {},
  localBaseUrl: "",
  init(baseURL, token = "INVALID") {
    Api.localBaseUrl = baseURL;
    axios.defaults.baseURL = baseURL;
    // axios.defaults.withCredentials = true; // Si no se pone no se manda la `cookie-session`
    // axios.defaults.headers = { "x-rocket-token": `${token}` };
    axios.defaults.headers = {};
    // console.log("NEMOS Service: ", baseURL);
    Api.recs = recorders.reduce((prev, curr) => {
      // eslint-disable-next-line no-param-reassign
      prev[curr.id] = curr.apiroot;
      return prev;
    }, {});
  },
  getVideos(selected = {}) {
    const {
      fromDate = undefined, duration = 1, tuner = "1", tolerance = 1, nemos = "local"
    } = selected ?? {};

    if (!(fromDate instanceof Date)) {
      // return null;
      throw new Error("date is invalid");
    }

    const nemosbaseurl = Api.recs[nemos] ?? "";
    axios.defaults.baseURL = Api.localBaseUrl;
    if (nemosbaseurl.length > 0) {
      axios.defaults.baseURL = `${nemosbaseurl}/api`;
    }

    const url = "/videos";
    const files = [];
    const tolMin = Math.abs(tolerance) * (-1);
    const tolMax = duration + Math.abs(tolerance);
    for (let i = tolMin; i < tolMax; i++) {
      const cDate = addMinutes(fromDate, i);
      files.push(cDate.toISOString());
      // console.log("current TZ", cDate.toISOString());
      // files.push(format(cDate, "yyyy-MM-dd HH:mm:ss"));
    }

    const data = {
      tuner,
      files,
      tz: "UTC",
      type: "filePath"
    };

    // console.log(data);
    return axios({
      method: "post",
      url,
      data,
      responseType: "json",
      // responseType: "blob",
      // headers: { Accept: "video/mp4;charset=UTF-8" }
    });
  },
  downloadVideo(selected = {}) {
    const {
      fromDate = undefined, duration = 1, tuner = "1", segment = [], tolerance = 1, nemos = "local"
    } = selected ?? {};

    if (!(fromDate instanceof Date)) {
      // return null;
      throw new Error("date is invalid");
    }

    const nemosbaseurl = Api.recs[nemos] ?? "";
    axios.defaults.baseURL = Api.localBaseUrl;
    if (nemosbaseurl.length > 0) {
      axios.defaults.baseURL = `${nemosbaseurl}/api`;
    }

    const url = "/videos";
    const files = [];
    const tolMin = Math.abs(tolerance) * (-1);
    const tolMax = duration + Math.abs(tolerance);
    for (let i = tolMin; i < tolMax; i++) {
      const cDate = addMinutes(fromDate, i);
      files.push(cDate.toISOString());
      // console.log("current TZ", cDate.toISOString());
    }

    const data = {
      tuner,
      files,
      segment,
      tz: "UTC",
      type: "cutVideo"
    };

    // console.log("downloadVideo", data);
    return axios({
      method: "post",
      url,
      data,
      responseType: "blob",
      headers: { Accept: "video/mp4;charset=UTF-8" }
    });
  },
  getChannels(nemos) {
    const nemosbaseurl = Api.recs[nemos] ?? "";
    axios.defaults.baseURL = Api.localBaseUrl;
    if (nemosbaseurl.length > 0) {
      axios.defaults.baseURL = `${nemosbaseurl}/api`;
    }
    return axios({
      method: "GET",
      url: "/channels",
      // responseType: "application/json"
    });
  }
};

export default Api;
