// import store from "@/store";
import Contacts from "./Contacts.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    const { router = null, store = null } = options;
    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path: "/contacts",
      name: "Contacts",
      component: Contacts,
      meta: {
        title: "Contactos",
        label: "Contactos",
        icon: "fluent:contact-card-20-regular",
        privileges: ["ROCKET-USER", "CONTACTS-USER"]
      },
      children: [
        {
          path: ":id", // /contacts/:id
          name: "ContactSingle",
          component: Contacts,
          meta: {
            title: "Contactos",
            label: "Contactos",
            icon: "fluent:contact-card-20-regular",
            privileges: ["ROCKET-USER", "CONTACTS-USER"]
          }
        }
      ]
    };
    store.dispatch("RegisterRoute", { path:r.path, ...r.meta });
    router.addRoutes([r]);
  }
};
