import Comp from "./Weather.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    console.log("[Repos] Registering", options);
    const { router = null, store = null } = options;
    const { path = "/weather", name = "Código" } = options;

    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Comp,
      meta: {
        label: "Weather",
        title: "Weather",
        icon: "fluent:weather-partly-cloudy-day-24-regular",
        privileges: ["ROCKET-USER"],
      }
    };

    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    r.name += "Single";
    r.path += "/:id";
    router.addRoutes([r]);
  }
};
