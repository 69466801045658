<style scoped>
.box-slot {
  background-color: unset;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0rem;
}
.nomargins {
  margin-bottom: 0px;
}
.breakable-text {
  white-space: break-spaces;
}
.table {
  color: unset;
}

</style>
<style>
@media screen and (max-width: 767px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    white-space: unset;
  }
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-8 d-print-none">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Cotizaciones
          </h3>
          <div class="box-controls float-right">
            <div class="box-header-actions">
              <SearchBar
                :value="quotationsParams.search"
                @search-changed="onSearchChanged"
              />
              <YearMonthDropdown
                class="float-right"
                :year="quotationsParams.filterDateYear"
                :month="quotationsParams.filterDateMonth"
                :applied="quotationsParams.filterDateApply"
                :fullyear="quotationsParams.filterDateFullYear"
                :two-months-checked="quotationsParams.filterTwoMonth"
                @dateChanged="onDateFilterChanged"
                @clearRequested="onDateFilterClear"
                @fullYearClicked="onDateFilterFullYear"
                @twoMonthClicked="onTwoMonthFilter"
              />
              <a class="btn btn-sm btn-default d-sm-none" href="#" @click.prevent="confirmAddContact()">
                <span class="iconify" data-icon="carbon:add-alt" data-inline="true ">

                </span>
              </a>
              <a class="btn btn-sm btn-default d-none d-sm-block" href="#" @click.prevent="confirmAddContact()">
                <span class="iconify" data-icon="carbon:add-alt" data-inline="true ">

                </span>
                Nueva
              </a>
              &nbsp;
            </div>
          </div>
        </div>
        <Vuetable
          ref="quotationsTable"
          :api-url="`${API_ROOT}/api/quotations?${params}`"
          :css="css"
          :sort-order="sort"
          :per-page="6"
          :fields="fields"
          :http-options="{withCredentials: true}"
          :row-class="onRowClass"
          :transform="quotationTransformData"
          data-path="rows"
          class="table-hover table-responsive"
          pagination-path=""
          @vuetable:pagination-data="onPaginationData"
          @vuetable:row-clicked="onRowClicked"
        >
          <template slot="description" slot-scope="props">
            <div
              class="box nomargins box-slot col breakable-text" :class="{'text-striked':props.rowData.status && props.rowData.status==='trash'}"
            >
              <div class="flexbox">
                <p class="nomargins" :class="[props.rowData.status==='open'?'':'text-muted']">
                  <span>
                    <strong :class="[(props.rowData.status && props.rowData.status!=='trash') ? 'text-info': 'text-default']">
                      SO{{ props.rowData.quote_sequence }}
                    </strong>
                  </span>
                  <span
                    :class="[(props.rowData.status && props.rowData.status!=='trash') ? 'text-info': 'text-default']"
                  >
                    {{ props.rowData.title }}
                  </span>
                  <svg
                    v-show="props.rowData.users && props.rowData.users.length>1" class="text-warning" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"
                  >
                    <path
                      d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                      fill="currentColor"
                    />
                  </svg>
                </p>
                <p class="nomargins">
                  $ {{ props.rowData.totalFmt }}
                  <Icon v-if="props.rowData.status && props.rowData.status==='open'" icon="bx:bx-lock-open-alt" :inline="true" class="text-default" />
                  <Icon v-if="props.rowData.status && props.rowData.status==='locked'" icon="bx:bxs-lock-alt" :inline="true" class="text-default" />
                  <Icon v-if="props.rowData.status && props.rowData.status==='trash'" icon="bx:bx-trash" :inline="true" class="text-default" />
                  <Icon v-if="props.rowData.status && props.rowData.status==='accepted'" icon="bx:bxs-like" :inline="true" class="text-primary" />
                </p>
              </div>
              <span class="nomargins">
                <span
                  v-if="(props.rowData.contact&&props.rowData.contact.name)"
                  class="nomargins small text-default"
                >
                  <!-- <Icon icon="carbon:caret-right" /> -->
                  {{ props.rowData.contact.name }}
                  <!-- <span class="text-default"> ({{props.rowData.contact.rfc}})</span> -->
                </span>
                <i v-else class="nomargins small text-warning">
                  Borrador
                </i>
                <span v-for="item in props.rowData.recons" :key="item._id" class="nomargins pl-2">
                  <a
                    class="badge badge-sm badge-pill badge-dark py-1" href="#"
                    @click.prevent="redirectToRecon(item.recon_sequence, ...arguments)"
                  >
                    <Icon icon="ph:arrows-in-simple-light" :inline="true" />
                    {{ `C${item.recon_sequence}` }}
                    <p class="d-inline pl-3 text-primary">$ {{ item.amount }}</p>
                  </a>
                </span>
              </span>
            </div>
          </template>
        </Vuetable>
        <VuetablePagination
          ref="quotationsTablePag"
          @vuetable-pagination:change-page="onChangePage"
        />
      </div>
    </div>
    <div class="col-md-8 col-sm-12">
      <SingleQuotation
        v-if="this.$route.params.id !== 'undefined' && currentData"
        :quotation="currentData"
        @quotationUnsaved="currentUnsaved = $event"
        @dbUpdated="onDbUpdatedRefresh"
        @pdf-created="onRefreshPdfs"
      />
    </div>
    <div v-if="this.$route.params.id !== 'undefined' && currentData" class="col-md-4 col-sm-6 d-print-none bg-altgray">
      <div class="box">
        <div class="box-header border-bottom border-dark">
          <h3 class="box-title">
            Detalles
          </h3>
        </div>
        <div class="box-body">
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-3 py-1 pl-2">
              Conciliación:
            </div>
            <div class="col-9">
              <span
                v-for="item in recons" :key="item._id"
                class="py-1 d-block xborder-bottom xborder-dark"
              >
                <a
                  class="badge badge-sm badge-pill badge-dark py-1" href="#"
                  @click.stop.prevent="redirectToRecon(item.recon_sequence, ...arguments)"
                >
                  <Icon class="icon-align" icon="ph:arrows-in-simple-light" />
                  {{ `C${item.recon_sequence}` }}
                  <p class="d-inline pl-3 text-primary">$ {{ item.amount }}</p>
                </a>
                <div class="box-tools float-right">
                  <button v-if="isOpen" type="button" class="btn btn-box-tool" title="Eliminar" @click.prevent="removeReconFromQuotation(item)">
                    <Icon icon="uil:times" />
                  </button>
                </div>
                <span class="d-block pl-1">
                  {{ item.title }}
                </span>
              </span>
              <LiveSearch
                v-if="recons.length < 1 && isOpen"
                ref="livesearch"
                class="py-1 d-block"
                empty="Buscar conciliación..."
                value=""
                :enabled="isOpen"
                :livesearch-values="searchresults"
                @searchValueChanged="onReconSearch"
                @livesearchItemClick="addReconToQuotation"
              />
            </div>
          </div>
          <div class="row mt-4 py-1 border-dark">
            <div class="col-6">
              <h4>Archivos</h4>
            </div>
          </div>
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-12">
              <FilesTable
                :id="currentData._id"
                :refresh="refreshKey"
              />
            </div>
          </div>
          <div class="row mt-4 py-1 border-dark">
            <div class="col-6">
              <h4>Comentarios</h4>
            </div>
          </div>
          <div class="row border-dark py-1 pl-2">
            <div class="col-12">
              <CommentsPane
                objecttype="quotations"
                :parentid="currentData._id"
                :refresh="refreshKey"
                @comment="pushComment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable camelcase */
import { mapMutations, mapState } from "vuex";
import Vuetable from "vuetable-2";
import { Icon } from "@iconify/vue2";

import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";

import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import LiveSearch from "@/components/LiveSearch.vue";

import DefaultTable from "@/components/rocket/CnfTableDefaults";
import QuotationsApi from "./Quotations.service";
// import FilesCommentsBox from '../../components/FilesCommentsBox.vue';

// Lazy loaders
const CommentsPane = () => import("@/components/CommentsPane.vue");
const FilesTable = () => import("./QuotationPdfFiles.vue");
const SingleQuotation = () => import("./QuotationSingle.vue");

export default {
  name: "Quotations",
  components: {
    Vuetable,
    VuetablePagination,
    SingleQuotation,
    FilesTable,
    CommentsPane,
    Icon,
    YearMonthDropdown,
    SearchBar,
    LiveSearch
  },
  data() {
    return {
      fields: [
        {
          name: "description",
          titleClass: "d-none"
        }
      ],
      css: DefaultTable.css,
      cssPages: DefaultTable.cssPagination,
      sortOrder: [
        {
          field: "createdAt",
          direction: "asc"
        }
      ],
      sort: [
        {
          field: "quote_sequence",
          sortField: "quote_sequence",
          direction: "desc"
        }
      ],
      currentData: {},
      refreshKey: 1, // watcher, si cambia refresca
      currentUnsaved: false,
      currentPage:1,

      searchresults: [],
      searching: false,
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT", "user", "quotationsParams"]),
    isOpen() {
      const { status = "open" } = this.currentData ?? {};
      return status === "open";
    },
    recons() {
      const { recons: r = [] } = this.currentData ?? {};
      return r;
    },
    params() {
      const {
        filterDateYear,
        filterDatePrevYear,
        filterDateMonth,
        filterDatePrevMonth,
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
        search = ""
      } = this.quotationsParams ?? {};

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth = filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        year,
        month,
        toyear,
        tomonth,
        search: search.length > 2 ? search : null
      };

      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      const p = new URLSearchParams(filtredParams).toString();
      return p;
    }
  },
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, this.currentData.quote_sequence, to.params.id);
        if (typeof to.params.id === "undefined") {
          this.currentData = null; // hide single
          return;
        }
        if (this.currentData) {
          const { quote_sequence = null } = this.currentData;
          // eslint-disable-next-line camelcase
          if (quote_sequence && quote_sequence === to.params.id) {
            return;
          }
        }
        // console.log("Force fetch", to.params.id, );
        QuotationsApi.findOneBySeq(to.params.id).then(res => {
          if (!res) {
          // console.log("Got empty");
            this.currentData = null;
            // hide single
            return;
          }
          // console.log("Got", res);
          this.currentData = { ...res };
        // show single
        });
      }
    },
  },

  methods: {
    ...mapMutations([
      "SetQuotationsParams",
    ]),
    confirmAddContact() {
      let text = "¿Quieres crear una nueva?";
      if (this.currentUnsaved) {
        text = "Se descartarán los cambios actuales <br><strong>¿Quieres crear una nueva?</strong>";
      }
      this.$swal({
        title: "Crear cotización",
        html: text,
        icon: "question",
        confirmButtonText: "Crear",
        cancelButtonText: "No",
        // cancelButtonColor: "",
        showCancelButton: true,
        reverseButtons: true
      }).then(result => {
        // console.log(result);
        if (result.value) {
          QuotationsApi.create({ name: "Nueva cotización" }).then(res => {
            this.$refs.quotationsTable.refresh();
            this.currentData = res;
            this.$router.push({
              name: "QuotationsSingle",
              params: { id: res.quote_sequence }
            });
          });
        }
        else if (result.isDismissed && result.dismiss === "cancel") {
          // this.$emit("cancelled"); // Hay cambios pero se cancelaron
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onRowClicked(row, ev) {
      // this.$set(this, "currentData", row.data);
      // this.currentData = row; // usar el cacheado
      // console.log("Push", row.quote_sequence);
      if (this.currentUnsaved) {
        this.$swal({
          title: "Hay cambios sin guardar",
          html: `¿Estás seguro que quieres descartar los cambios de la cotización <strong>SO${this.currentData.quote_sequence}</strong>?`,
          icon: "warning",
          confirmButtonText: "Si, descartar",
          cancelButtonText: "No",
          // cancelButtonColor: "",
          showCancelButton: true,
          reverseButtons: true
        }).then(result => {
          console.log(result);
          const { isConfirmed = false } = result;
          if (isConfirmed) {
            this.$router
              .push({
                name: "QuotationsSingle",
                params: { id: row.data.quote_sequence }
              })
              .catch(() => {});
          }
        });
      }
      else {
        this.$router
          .push({
            name: "QuotationsSingle",
            params: { id: row.data.quote_sequence }
          })
          .catch(() => {}); // evitar el error de doble navegación
      }
    },
    // eslint-disable-next-line no-unused-vars
    onRowClass(dataItem, index) {
      let cls = "cursor-pointer";

      if (dataItem.status === "accepted") {
        cls += " text-primary";
      }
      else if (dataItem.status === "trash") {
        cls += " text-muted text-striked";
      }

      if (!this.currentData) {
        return cls;
      }
      const { _id = null } = this.currentData;
      if (_id === dataItem._id) {
        cls += " bg-dark";
      }
      return cls;
    },
    onRefreshPdfs() {
      this.refreshKey += 1;
    },
    onDbUpdatedRefresh() {
      // Already saved via API
      // console.log("Refreshing");
      this.$refs.quotationsTable.refresh();
      this.$refs.quotationsTable.changePage(this.currentPage);
      const { quote_sequence: seq = "" } = this.currentData;
      if (!seq) {
        return;
      }
      QuotationsApi.findOneBySeq(seq).then(res => {
        if (!res) {
          this.currentData = null;
          return;
        }
        this.currentData = { ...res };
      });
    },
    onPaginationData(paginationData) {
      this.$refs.quotationsTablePag.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.currentPage = page;
      this.$refs.quotationsTable.changePage(this.currentPage);
    },
    onSearchRequested(val) {
      console.log(val);
    },
    getQuotationTotals(quotes) {
      // Recibe un array de quotes y calcula iva, subtotal, total y formateado
      const subtotal = quotes
        .filter(x => !!x) // filtrar nulos
        .reduce((acc, curr) => {
          // encontrar total cotizado (sin impuestos)
          const { amount = 0.0 } = curr;
          const { quantity = 0.0 } = curr;
          const { discount = 0.0 } = curr;
          return (
            acc
            + parseFloat(amount)
              * parseFloat(quantity)
              * (1.0 - 0.01 * parseFloat(discount))
          );
        }, 0.0);

      const tax = subtotal * 0.16;
      const total = (subtotal + tax).toFixed(2);
      // Separar miles
      const totalFmt = String(total).replace(/^\d+/, number => [...number]
        .map(
          (digit, index, digits) => (!index || (digits.length - index) % 3 ? "" : ",") + digit
        ) // Sabra Dios que hace, pero sirve
        .join(""));
      // console.log("Calculating", subtotal, tax, total);

      // TODO: Aqui tambien se podria calcular el icono correcto
      return {
        tax,
        subtotal,
        total,
        totalFmt
      };
    },
    quotationTransformData(data) {
      // Aqui se recibe TODO el paquete de rows
      // Calcular el total con base en las lineas de la cotizacion, para cada row
      // console.log("TRANSFORM!", data);
      // const data2 = {
      //   total: 5,
      //   rows:[1, 2, 3, 4, 5]
      // };
      const { rows = [] } = data;

      const transfRows = rows.map(it => {
        const { quotes = [] } = it;
        const totals = this.getQuotationTotals(quotes);
        return { ...it, ...totals }; // Se le anexan los campos de totals y cfdis a cada row
      });
      // console.log("New Rows", transfRows);

      // Finalmente entregamos el nuevo data con las rows mejoradas
      const tData = data;
      delete tData.rows;
      tData.rows = transfRows;
      return tData;
    },
    onReconSearch(val) {
      // console.log("onReconSearch", val);
      if (!this.searching) {
        this.searching = true;
        QuotationsApi.searchRecons({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: `C${it.recon_sequence} ${it.title}: ${it.amount}`,
              item: it
            }));
            // console.log("SEARCH", res);
            this.searchresults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    addReconToQuotation(id, recon) {
      const { _id: idQuotation = "" } = this.currentData ?? {};
      const { recon_sequence: reconSeq = 0, title = "" } = recon ?? {};
      const name = `C${reconSeq} ${title}`;
      this.searchresults = [];
      this.searching = false;
      QuotationsApi.addReconById(idQuotation, { reconid: id })
        .then(() => {
          // console.log("dbUpdated", res);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.onDbUpdatedRefresh(); // revisar este refresh
          this.messageAlert({
            icon: "success",
            title: `Conciliación: <strong>${name}</strong> agregada correctamente`,
          });
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          // console.log("RECON ADD FAILED ", errStr);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          console.log(`${errStr}, CODE: ${status}`);
          this.messageAlert({
            icon: "error",
            title: "No se pudo agregar conciliación",
            text: `${errStr}, CODE: ${status}`,
          });
        });
    },
    removeReconFromQuotation(item) {
      const { _id: idQuotation = "" } = this.currentData ?? {};
      const { _id: reconid = "", recon_sequence: reconSeq = 0, title = "" } = item ?? {};
      const name = `C${reconSeq} ${title}`;
      if (!reconid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo eliminar conciliación",
          text: "El Id de conciliación está vacio.",
        });
        return;
      }
      this.$swal({
        icon: "warning",
        title: "Eliminar conciliación",
        html: `Conciliación: <strong>${name}</strong>.`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí, Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ff7674",
        cancelButtonColor: ""
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          QuotationsApi.removeReconById(idQuotation, { reconid })
            .then(() => {
              this.onDbUpdatedRefresh(); // revisar este refresh
              this.messageAlert({
                icon: "success",
                title: `Conciliación: <strong>${name}</strong> eliminada correctamente`,
              });
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              // console.log("RECON DELETE FAILED ", errStr);
              this.messageAlert({
                icon: "error",
                title: "No se pudo eliminar conciliación",
                text: `${errStr}, CODE: ${status}`,
              });
            });
        }
      });
    },
    onSearchChanged(val) {
      this.SetQuotationsParams({ search: val });
    },
    redirectToRecon(recSeq, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log("Redirect to C", recSeq);
      this.$router.push({
        name: "ReconsSingle",
        params: { id: recSeq }
      });
    },
    // eslint-disable-next-line no-unused-vars
    pushComment(comment) {
      // console.log("Push comment", comment);
    },
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.SetQuotationsParams({
        filterDateYear: year,
        filterDateMonth: month,
        filterDateFullYear: false,
        filterDateApply: true,
        filterTwoMonth: false
      });
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      const now = new Date();
      this.SetQuotationsParams({
        filterDateApply: false,
        filterTwoMonth: false,
        filterDateFullYear: false,
        filterDateYear: now.getFullYear(),
        filterDateMonth: now.getMonth() + 1
      });
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.SetQuotationsParams({
        filterDateApply: false,
        filterDateFullYear: true,
        filterTwoMonth: false,
        filterDateYear: year
      });
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.SetQuotationsParams({
        filterDateYear: curryear,
        filterDateMonth: currmonth,
        filterDatePrevYear: prevyear,
        filterDatePrevMonth: prevmonth,
        filterDateFullYear: false,
        filterDateApply: true,
        filterTwoMonth: true
      });
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 2500, position = "bottom-end"
      } = info ?? {};
      this.$swal({
        icon,
        title,
        timer,
        position,
        html: text,
        toast: true,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    },
  }
};
</script>
