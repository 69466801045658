import Comp from "./RedesSociales.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    console.log("[RedesSoc] Registering", options);
    const { router = null, store = null } = options;
    const { path = "/redessoc", name = "RedesSoc" } = options;

    if (!router || !store) {
      console.warn("Redes Sociales Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Comp,
      meta: {
        label: "Redes Sociales",
        title: "Redes Sociales",
        icon: "fluent:people-chat-20-filled",
        privileges: ["ROCKET-USER"],
      },
      // eslint-disable-next-line no-unused-vars
      redirect: to => ({ name: "RedesSoc", params: { playlist: "manana" } }),
      children: [
        {
          path: ":playlist", // /redessoc/:playlist
          name: "RedesSoc",
          component: Comp,
          meta: {
            title: "Redes Sociales",
            label: "Redes Sociales",
            icon: "fluent:people-chat-20-filled",
            privileges: ["ROCKET-USER"]
          },
        }
      ]
    };

    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    // r.name += "Single";
    // r.path += "/:id";
    // router.addRoutes([r]);
  }
};
