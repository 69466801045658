<template>
  <div>
    <h4 class="control-sidebar-subheading">
      {{ title }}
      <span class="label float-right" :class="formatedLabelClass">{{ formatedProgress }}%</span>
    </h4>

    <div class="progress progress-xxs">
      <div class="progress-bar" :class="formatedProgressClass" :style="{ width: `${formatedProgress}%` }"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "",
      type: String,
    },
    progress: {
      default: 0,
      type: Number,
    },
    level: {
      default: "",
      type: String,
    },
  },
  computed:{
    formatedProgress() {
      const { progress } = this;
      let limit =  progress;
      limit = Math.min(limit, 1);
      limit = Math.max(limit, 0);
      return (limit * 100).toFixed(0);
    },
    formatedLabelClass() {
      const { level } = this;
      const level2color = {
        primary: "label-primary",
        danger: "label-danger",
        success: "label-success",
        warning: "label-warning",
        default: "label-primary",
      };
      return level2color[level] ?? level2color.default;
    },
    formatedProgressClass() {
      const { level } = this;
      const level2color = {
        primary: "label-primary",
        danger: "label-danger",
        success: "label-success",
        warning: "label-warning",
        default: "label-primary",
      };
      return level2color[level] ?? level2color.default;
    }
  }
};
</script>
