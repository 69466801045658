<style scoped>
.spinner-border {
  margin-top: 54.45%;
  vertical-align: middle;
}
a.disabled {
  pointer-events: none;
}
.btn:disabled {
  pointer-events: none;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 mb-3">
      <div class="d-flex justify-content-center">
        <div v-show="loading" class="spinner-border" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
      <div class="row">
        <div v-show="!loading && currPlayer===0" class="embed-responsive embed-responsive-16by9">
          <video
            ref="videoPlayer0"
            :src="sources[0]"
            preload="auto"

            @playing="updatePaused($event, 0)"
            @pause="updatePaused($event, 0)"
            @ended="onEnded($event, 0)"
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div v-show="!loading && currPlayer===1" class="embed-responsive embed-responsive-16by9">
          <video
            ref="videoPlayer1"
            :src="sources[1]"
            preload="auto"

            @playing="updatePaused($event, 1)"
            @pause="updatePaused($event, 1)"
            @ended="onEnded($event, 1)"
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-2">
          <vue-slider
            ref="slider"
            v-model="segment"
            :min="0"
            :max="totalSeconds"
            :min-range="10"
            :interval="1"
            :tooltip-formatter="formatLabel"
            :disabled="isBtnPlay || disabled"
            @dragging="dragSlider"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p class="text-center">
            Segmento:
            <a v-if="showStartMin" href="#" :class="{disabled: disabled}" @click.prevent="addStartSecond(-1)">
              <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.4em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
            </a>
            {{ startTime }}
            <a v-if="showStartMax" href="#" :class="{disabled: disabled}" @click.prevent="addStartSecond(1)">
              <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.4em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" /></svg>
            <a v-if="showEndMin" href="#" :class="{disabled: disabled}" @click.prevent="addEndSecond(-1)">
              <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.4em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
            </a>
            {{ endTime }}
            <a v-if="showEndMax" href="#" :class="{disabled: disabled}" @click.prevent="addEndSecond(1)">
              <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.4em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
            </a>
            <br>
            {{ `Duraci&oacute;n: ${secondsToFormat(duration)}` }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p :disabled="!loading" class="text-center">
            <button
              type="button"
              class="btn btn-default btn-lg btn-round d-inline m-2"
              :disabled="disabled"
              @click.prevent="goToStart"
            >
              <img src="img/gostart.svg">
            </button>
            <button
              v-show="!isBtnPlay"
              type="button"
              class="btn btn-default btn-lg btn-round m-2"
              :disabled="disabled"
              @click.prevent="play"
            >
              <img src="img/play.svg">
            </button>
            <button
              v-show="isBtnPlay"
              type="button"
              class="btn btn-default btn-lg btn-round m-2"
              :disabled="disabled"
              @click.prevent="pause"
            >
              <img src="img/pause.svg">
            </button>
            <button
              type="button"
              class="btn btn-default btn-lg btn-round d-inline m-2"
              :disabled="disabled"
              @click.prevent="goToEnd"
            >
              <img src="img/goend.svg">
            </button>
          </p>
        </div>
      </div>

      <div v-if="false" class="media-list media-list-hover media-list-divided mt-3 mb-0">
        <a
          v-for="(video, index) in videoList"
          :key="index"
          :href="video"
          :drag-on-click="true"
          class="media media-single"
          @click.prevent="videoSelected(video, index)"
        >
          <span class="title">{{ video }}</span>
          <span v-show="(videoIdx == index && isPlaying)" class="badge badge-pill badge-success">play</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import {
  format, addSeconds, addMinutes, parse, differenceInMinutes
} from "date-fns";
import { es } from "date-fns/locale";
import debounce from "lodash/debounce"; // no se puede lazy load

// eslint-disable-next-line import/no-unresolved
import "@/plugins/Nemos/vslider.css";

export default {
  name: "NemPlayer",
  components: {
    VueSlider
  },
  props: {
    videoList: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    fromDate: {
      type: Date,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      videoIdx: -1,
      isPlaying: false,
      isBtnPlay: false,
      currPlayer: 0,
      sources: ["", ""],
      players: [
        this.$refs.videoPlayer0,
        this.$refs.videoPlayer1
      ],
      segment: [0, 500],
      duration: 500,
      diffMinTimezone: 360,
      vid0DiffMin: 0
    };
  },
  computed: {
    videoUrl() {
      return this.videoList[this.videoIdx] ?? "";
    },
    player() {
      return this.players[this.currPlayer];
    },
    totalSeconds() {
      if (this.videoList.length < 1) {
        return 100;
      }
      return 30 * this.videoList.length;
    },
    startTime() {
      if (!(this.fromDate instanceof Date)) {
        return format(new Date(), "HH:mm:ss", { locale: es });
      }
      const [start = 0] = this.segment;
      const date = addSeconds(this.fromDate, start + (this.vid0DiffMin * 60));
      return format(date, "HH:mm:ss", { locale: es });
    },
    endTime() {
      if (!(this.fromDate instanceof Date)) {
        return format(new Date(), "HH:mm:ss", { locale: es });
      }
      const [, end = 0] = this.segment;
      const date = addSeconds(this.fromDate, end + (this.vid0DiffMin * 60));
      return format(date, "HH:mm:ss", { locale: es });
    },
    showStartMin() {
      const [start = 0] = this.segment;
      return start > 0 && start < (this.totalSeconds - 1);
    },
    showStartMax() {
      const [start = 0] = this.segment;
      return start >= 0 && start < (this.totalSeconds - 1);
    },
    showEndMin() {
      const [, end = this.totalSeconds] = this.segment;
      return end > 1 && end <= this.totalSeconds;
    },
    showEndMax() {
      const [, end = this.totalSeconds] = this.segment;
      return end > 1 && end < this.totalSeconds;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    videoList(newVideolist, oldVideoList) {
      const vCount = newVideolist.length;
      // console.log("change List", vCount);
      this.segment = [0, vCount * 30];
      this.duration = vCount * 30;
      const [v0 = ""] = newVideolist;
      this.vid0DiffMin = this.getMinDiffFromUrl(v0);
      this.start();
    }
  },

  methods: {
    videoSelected(video, index) {
      this.pause();
      this.videoIdx = index;
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad();
      this.play();
    },
    pause() {
      this.players[0].pause();
      this.players[1].pause();
      this.isPlaying = false;
      this.setButtonPlay(this.isPlaying);
    },
    start() {
      this.pause();
      this.isPlaying = false;
      this.setButtonPlay(this.isPlaying);
      this.videoIdx = 0;
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad(0);
      // this.play();
    },
    requestLoad(seconds = 0) {
      this.sources[this.currPlayer] = this.videoList[this.videoIdx] ?? "";
      this.player.currentTime = seconds % 30;

      const prepareNextIdx = this.videoIdx + 1;
      if (prepareNextIdx < this.videoList.length) {
        const modByNext = prepareNextIdx % 2;
        this.sources[modByNext] = this.videoList[prepareNextIdx] ?? "";
        this.players[modByNext].currentTime = 0;
      }
    },
    play() {
      if (this.isPlaying) {
        return;
      }
      const playPromise = this.player.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.player.play();
            // console.log("PLAY");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    goToStart() {
      this.pause();
      const [start = 0] = this.segment;
      this.videoIdx = Math.max(parseInt(start / 30, 10), 0);
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad(start);
      setTimeout(() => {
        this.player.currentTime = start % 30;
        // this.play();
      }, 400);
    },
    goToEnd() {
      this.pause();
      const [, end = this.totalSeconds] = this.segment;
      this.videoIdx = Math.max(parseInt(end / 30, 10), 0);
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad(end);
      setTimeout(() => {
        this.player.currentTime = end % 30;
        // this.play();
      }, 400);
    },
    next() {
      let nextIdx = this.videoIdx;
      nextIdx += 1;
      nextIdx = Math.max(0, nextIdx);
      nextIdx = Math.min(nextIdx, this.videoList.length - 1);

      this.videoIdx = nextIdx;
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad();
      this.play();
    },
    // eslint-disable-next-line no-unused-vars
    updatePaused(ev, pIndex) {
      this.isPlaying = !ev.target.paused;
      this.setButtonPlay(this.isPlaying);
      // console.log("updatePaused", ev, this.isPlaying);
    },
    // eslint-disable-next-line no-unused-vars
    onEnded(ev, pIndex) {
      // console.log("onEnded", ev);
      if (this.videoIdx === (this.videoList.length - 1)) {
        console.log("lastvideo", this.videoIdx);
        return;
      }

      this.next();
    },
    formatLabel(value) {
      const [v0 = ""] = this.videoList;
      const diff = this.getMinDiffFromUrl(v0);
      const date = addSeconds(this.fromDate, value + (diff * 60));
      // console.log(value, diff, this.fromDate, date);
      const dateFormat = format(date, "HH:mm:ss", { locale: es });
      return dateFormat;
    },
    secondsToFormat(seconds = 0) {
      const hours = Math.floor(seconds / (60 * 60)).toFixed(0).padStart(2, "0");
      const min = Math.floor((seconds / 60) % 60).toFixed(0).padStart(2, "0");
      const sec = (seconds % 60).toString().padStart(2, "0");
      if (hours === "00") {
        return `${min}:${sec}`;
      }
      return `${hours}:${min}:${sec}`;
    },
    getMinDiffFromUrl(url) {
      if (!url) {
        return 0;
      }
      const name = url.split("/").pop().substring(5, 20);
      let date = parse(name, "yyyy-DDD_HHmmss", this.fromDate);
      date = addMinutes(date, this.diffMinTimezone);
      const diff = differenceInMinutes(date, this.fromDate, { roundingMethod: "floor" });
      // console.log(diff, date, this.fromDate);
      if (Number.isNaN(diff)) {
        return 0;
      }
      return diff;
    },
    dragSlider(val, index) {
      this.$emit("cutChanged", val);
      const [start = 0, end = 0] = val ?? [];
      let sec = start;
      if (index === 1) {
        sec = end;
      }
      this.videoIdx = Math.max(parseInt(sec / 30, 10), 0);
      this.currPlayer = this.videoIdx % 2;
      this.duration = Math.abs(end - start);

      const seconds = sec === this.totalSeconds ? 30 : (sec % 30);
      this.requestLoad(seconds);
    },
    // eslint-disable-next-line func-names
    setButtonPlay: debounce(function (val) {
      this.isBtnPlay = val;
    }, 250),
    addStartSecond(val) {
      this.pause();
      const [start = 0, end = this.totalSeconds] = this.segment;
      const newstart = Math.max(0, start + val);
      this.segment = [newstart, end];
      this.videoIdx = Math.max(parseInt(newstart / 30, 10), 0);
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad(newstart);
      setTimeout(() => {
        this.player.currentTime = newstart % 30;
        // this.play();
      }, 400);
    },
    addEndSecond(val) {
      this.pause();
      const [start = 0, end = this.totalSeconds] = this.segment;
      const newend = Math.min(this.totalSeconds, end + val);
      this.segment = [start, newend];
      this.videoIdx = Math.max(parseInt(newend / 30, 10), 0);
      this.currPlayer = this.videoIdx % 2;
      this.requestLoad(newend);
      setTimeout(() => {
        this.player.currentTime = newend % 30;
        // this.play();
      }, 400);
    }
  },
  mounted() {
    this.players = [
      this.$refs.videoPlayer0,
      this.$refs.videoPlayer1
    ];
    const utc = Date.UTC(1970, 0, 1, 0, 0, 0);
    const datetimezone = new Date(1970, 0, 1, 0, 0, 0);
    this.diffMinTimezone = differenceInMinutes(utc, datetimezone, { roundingMethod: "floor" }) ?? -360;
    // const { timeZone = "America/Mexico_City" } = Intl.DateTimeFormat().resolvedOptions();
    // console.log(timeZone, this.diffMinTimezone);
  },
};
</script>
