<style scoped>
.dropdown-cell{
  /* background-color: red; */
  padding: 5px !important;
  min-height: 30px !important;
}
.dropdown-year{
  flex-basis: 66.666666% !important;
}
.dropdown-grid{
  min-width: 200px !important;
}
.dropdown-fullwidth{
  flex-basis: 100% !important;
}
.icon-align{
  vertical-align: sub !important;
}
</style>
<template>
  <div class="dropdown">
    <button class="btn  btn-sm  btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
      <Icon v-if="!applied && !fullyear && !twoMonthsChecked" icon="mdi:filter" />
      <span v-if="!twoMonthsChecked" class="hidden-xs-down">
        {{ title }}
      </span>
      <span v-if="twoMonthsChecked" class="hidden-xs-down">
        {{ twoMonthTitle }}
      </span>
      <span class="hidden-sm-up">
        <Icon v-if="fullyear" class="icon-align" icon="ph:calendar-light" />
        <span>
          {{ responsiveYear }}
        </span>
      </span>
    </button>
    <div class="dropdown-menu dropdown-grid" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 37px, 0px); top: 0px; left: 0px; will-change: transform;">
      <p class="dropdown-item dropdown-cell dropdown-year" href="#">
        <span class="title">
          <a href="#" @click="onYearClic(-1, ...arguments)">
            <Icon icon="ic:outline-arrow-left" />
          </a>
          {{ year }}
          <a href="#" @click="onYearClic(+1, ...arguments)">
            <Icon icon="ic:outline-arrow-right" />
          </a>
        </span>
      </p>
      <a class="dropdown-item dropdown-cell" href="#" @click.prevent="onClear">
        <span class="title"><Icon icon="mdi:clear" /></span>
      </a>
      <a
        v-for="(it, idx) in months"
        :key="it"
        :class="{active: idx==month-1 && year >= 0 && !fullyear && applied && !twoMonthsChecked}"
        class="dropdown-item dropdown-cell" href="#"
        @click="onMonthClic(idx + 1, ...arguments)"
      >
        <span class="title">{{ it }}</span>
      </a>
      <p class="dropdown-item dropdown-cell dropdown-year" :class="{active: fullyear}">
        <span class="title">
          <a href="#" @click="onFullYearClic">
            Todo el {{ year }}
          </a>
        </span>
      </p>
      <a
        :class="{active: twoMonthsChecked && !fullyear && applied}"
        class="dropdown-item dropdown-cell" href="#"
        @click="onTwoMonthClic"
      >
        {{ twoMonthTitle }}
      </a>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "YearMonthDropdown",
  components: {
    Icon,
  },
  props: {
    year: {
      type: Number,
      default() {
        return 2023;
      }
    },
    month: {
      type: Number,
      default() {
        return 1;
      }
    },
    fullyear: {
      // todo el año, ignorar el mes
      type: Boolean,
      default() {
        return false;
      }
    },
    applied:{
      type: Boolean,
      default() {
        return false;
      }
    },
    twoMonthsChecked:{
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      months: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
    };
  },
  computed: {
    title() {
      if (this.fullyear) {
        return `Todo ${this.year}`;
      }
      if (this.applied) {
        return `${this.months[this.month - 1]} ${this.year}`;
      }
      return "";
    },
    responsiveYear() {
      const year = `${this.year}`.slice(-2);
      if (this.fullyear) {
        return `'${year}`;
      }
      if (this.applied && !this.twoMonthsChecked) {
        return `${this.months[this.month - 1]} '${year}`;
      }
      if (this.twoMonthsChecked) {
        return `🥸 ${this.months[this.month - 1]} '${year}`;
      }
      return "";
    },
    twoMonthTitle() {
      const now = new Date();
      const currMonth = now.getMonth();
      return  `${this.months[this.prevMonth - 1]}-${this.months[currMonth]}`;
    },
    prevMonth() {
      const now = new Date();
      const currMonth = now.getMonth();
      const prev = currMonth === 0 ? 12 : now.getMonth();
      return  prev;
    },
    prevYear() {
      const { year = 0, prevMonth = 0 } = this ?? {};
      const prev = prevMonth === 12 ? year - 1 : year;
      return  prev;
    }
  },
  methods:{
    onYearClic(sum, ev) {
      // console.log(sum, ev);
      ev.stopPropagation(); // Evita que se quite el dropdown
      ev.preventDefault();  // Evita que se refresque la pagina
      // El padre decidira si nos cambia el prop o no
      if (!this.fullyear) {
        this.$emit("dateChanged", this.year + sum, this.month);
        return;
      }
      this.$emit("fullYearClicked", this.year + sum, this.month);
    },
    onMonthClic(month, ev) {
      // Aqui el clic es directo al mes
      ev.preventDefault();  // Evita que se refresque la pagina
      // El padre decidira si nos cambia el prop o no
      this.$emit("dateChanged", this.year, month);
    },
    onFullYearClic(ev) {
      ev.preventDefault();  // Evita que se refresque la pagina
      // El padre decidira si nos cambia el prop o no
      this.$emit("fullYearClicked", this.year, this.month);
    },
    onTwoMonthClic(ev) {
      ev.preventDefault();  // Evita que se refresque la pagina
      const now = new Date();
      const currMonth = now.getMonth() + 1;
      const {
        prevYear = 0,
        prevMonth = 0,
        year:currYear = 0,
      } = this ?? {};
      this.$emit("twoMonthClicked", prevYear, prevMonth, currYear, currMonth);
    },
    onClear() {
      this.$emit("clearRequested", this.year, this.month);
    }
  }
};
</script>
