<style scoped>
</style>

<template>
  <div class="row">
    <div class="col-12 pr-1">
      <div class="row">
        <div class="col-12">
          <img
            v-for="(img, index) in currentPlotsUrls" :key="index"
            :src="img"
            @error="plotFillBrokenImage"
          >
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8 pr-1">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Resumen Anual
          </h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-12">
              <YearPicker
                class="d-sm-inline"
                :year="filterDateYear"
                @yearChanged="onDateFilterFullYear"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <BanksAnualTable
                ref="banksAnualTable"
                :apiurl="apiurl"
                :year="filterDateYear"
                @rowClicked="showSingleBank"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import YearPicker from "@/components/YearPicker.vue";
import BanksAnualTable from "@/plugins/Banks/BanksAnualTable.vue";

const plot404 = require("@/plugins/Devices/plotnotfound.png");

export default {
  name: "BanksAnualPanel",
  components: {
    YearPicker,
    BanksAnualTable
  },
  props: {
    apiurl: {
      type: String,
      default: "/banks"
    }
  },
  data() {
    return {
      current: null,
      filterDateYear: 2023,
      plots: [
        "banks_amount", "banks_count"
      ]
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    currentPlotsUrls() {
      const urls = this.plots.map(it => {
        // Armar toda la URL aqui
        const full = `${this.API_ROOT}/api/banks/plots/${it}`;
        return full;
      });
      return urls;
    },
  },
  methods: {
    refreshBanksAnual() {
      this.$nextTick(() => {
        this.$refs.banksAnualTable.refresh();
      });
    },
    showSingleBank(row) {
      const { _id: id = "" } = row ?? {};
      console.log("yearmonth", id);
      this.current = row;
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.refreshBanksAnual();
    },
    plotFillBrokenImage(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = plot404;
    }
  },
  mounted() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
  },
};
</script>
