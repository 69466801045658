<style scoped>
.toggle-actions{
  display: inline;
}
.btn:disabled {
  pointer-events: none;
}
.font-huge-stats {
    font-size: 2rem !important;
    line-height: 1.2;
    font-weight: 200 !important;
}
.bg-altgray {
  background-color: #35393e;
}
</style>

<template>
  <div class="container-fluid px-0">
    <div class="row px-1">
      <div class="col-xl-2 col-md-6 col-12 ">
        <div class="box mx-auto box-body border bg-dark-info border-dark">
          <div class="flexbox">
            <peity
              :type="'pie'"
              :options="{ 'fill': ['#33cabb', '#ef5350'],
                          stroke: '#a97d10fa',
                          width: 60,
                          height:60,
                          max: 9,
                          strokewidth: 1,
                          innerRadius: 0,
                          radius: 40 }"
              :data="linkedPlotdata.toString()"
            />
            <span class="font-huge-stats">
              {{ linkedPlotdata[1] }}
            </span>
          </div>
          <div class="text-right">
            <span v-if="linkedPlotdata[1] !== 0">
              Sin asociar
            </span>
            <span v-else>
              Todo asociado
            </span>
            <Icon v-if="linkedPlotdata[1] == 0" icon="fluent-emoji-flat:partying-face" style="height: 1.5em; width: 1.5em" />
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-6 col-12 ">
        <div class="box mx-auto box-body border bg-dark-info border-dark">
          <div class="flexbox">
            <peity
              :type="'pie'"
              :options="{ 'fill': ['#33cabb', '#ef5350'],
                          stroke: '#a97d10fa',
                          width: 60,
                          height:60,
                          max: 9,
                          strokewidth: 1,
                          innerRadius: 0,
                          radius: 40 }"
              :data="taxclassPlotdata.toString()"
            />
            <span class="font-huge-stats">
              {{ taxclassPlotdata[1] }}
            </span>
          </div>
          <div class="text-right">
            <span v-if="taxclassPlotdata[1] !== 0">
              Sin clasificar
            </span>
            <span v-else>
              Todo clasificado
            </span>
            <Icon v-if="taxclassPlotdata[1] == 0" icon="fluent-emoji-flat:partying-face" style="height: 1.5em; width: 1.5em" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-7">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">
              CFDI
            </h3>
            <div class="box-controls float-right d-print-none">
              <div class="box-header-actions">
              &nbsp;
              </div>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <button
                      type="button" class="btn btn-inline btn-default btn-sm"
                      title="Emitidas o recibidas"
                      @click="invertFilterEmisorReceptor"
                    >
                      <Icon v-if="filterRfcEmisor==myRfc" icon="fluent:arrow-step-out-20-filled" :inline="true" class="text-primary" />
                      <Icon v-if="filterRfcReceptor==myRfc" icon="fluent:arrow-step-in-20-filled" :inline="true" class="" />
                      {{ filterRfcEmisor==myRfc?'EM':'RE' }}
                    </button>
                    <div class="dropdown d-inline">
                      <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        {{ filterMetodo }}
                        <Icon v-show="filterMetodo.length < 3" icon="vaadin:invoice" :inline="true" />
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <h6 class="dropdown-header">
                          Método:
                        </h6>
                        <a class="dropdown-item" href="#" @click="filterMetodo = 'PUE'">PUE</a>
                        <a class="dropdown-item" href="#" @click="filterMetodo = 'PPD'">PPD</a>
                        <a class="dropdown-item" href="#" @click="filterMetodo = ''">Todo</a>
                      </div>
                    </div>
                    <div class="dropdown d-sm-inline">
                      <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        <Icon icon="mdi:dollar" :inline="true" />
                        {{ filterMoneda }}
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <a class="dropdown-item" href="#" @click="filterMoneda = 'MXN'">MXN</a>
                        <a class="dropdown-item" href="#" @click="filterMoneda = 'USD'">USD</a>
                      </div>
                    </div>
                    <YearMonthDropdown
                      class="d-sm-inline"
                      :year="filterDateYear"
                      :month="filterDateMonth"
                      :applied="filterDateApply"
                      :fullyear="filterDateFullYear"
                      :two-months-checked="filterTwoMonth"
                      @dateChanged="onDateFilterChanged"
                      @clearRequested="onDateFilterClear"
                      @fullYearClicked="onDateFilterFullYear"
                      @twoMonthClicked="onTwoMonthFilter"
                    />
                    <button
                      type="button" class="btn btn-sm btn-default d-none d-sm-block float-right"
                      @click.prevent="refreshListCfdi()"
                    >
                      <Icon icon="ic:outline-refresh" />
                    </button>
                    <!-- SearchBar -->
                    <SearchBar
                      :value="search"
                      @search-changed="onSearchChanged"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col mt-3">
                    <CfdiTable
                      ref="cfdiBrowser"
                      :apiurl="`${apiurl}?${params}`"
                      :limit-items="Object.keys(params).length > 0 ? 500:200"
                      @rowClicked="showSingleCfdi"
                      @statsChanged="onStatisticsChanged"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="row">
          <div class="col-12">
            <CfdiSingle
              v-if="current"
              class="bg-altgray"
              :cfdi="current"
              @cfdiUpdated="onSingleCfdiUpdated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import Peity from "vue-peity";
import CfdiTable from "@/plugins/Cfdi/CfdiTable.vue";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import CfdiSingle from "./CfdiSingle.vue";
import ServiceApi from "./Cfdi.service";

export default {
  name: "CfdiPanel",
  components: {
    Peity,
    Icon,
    CfdiTable,
    YearMonthDropdown,
    SearchBar,
    CfdiSingle
  },
  props: {
    cfdi: {
      type: Object,
      default() {
        return {
          title: "",
          recon_sequence: 0,
          nombre_emisor: "",
          conceptos: "",
          recons: [],
        };
      }
    },
    myRfc: {
      type: String,
      default: "DNR160608127"
    }
  },
  data() {
    return {
      linkedPlotdata: [0, 0],
      taxclassPlotdata: [0, 0],
      apiurl: "/cfdi",
      current: null,
      search: null,
      filterDateApply: true,
      filterDateFullYear: false,
      filterTwoMonth: false,
      filterDateYear: 2023,
      filterDatePrevYear: 2023,
      filterDateMonth: 2,
      filterDatePrevMonth: 2,
      filterRfcEmisor: this.myRfc,
      filterRfcReceptor: null,
      filterMoneda: "MXN",
      filterMetodo: "",
      currUuid: null
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    filterRfc() {
      if (this.filterRfcEmisor) return this.filterRfcEmisor;
      if (this.filterRfcReceptor) return this.filterRfcReceptor;

      return "";
    },
    params() {
      const {
        filterRfcEmisor: rfc_emisor = null,
        filterRfcReceptor: rfc_receptor = null,
        search = null,
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
        filterDateYear = null,
        filterDatePrevYear = null,
        filterDateMonth = null,
        filterDatePrevMonth = null,
        filterMoneda = null,
        filterMetodo = ""
      } = this;

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth =  filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        rfc_emisor,
        rfc_receptor,
        search,
        year,
        month,
        toyear,
        tomonth,
        moneda: filterMoneda.length >= 3 ? this.filterMoneda : null,
        metodo_de_pago: ["PUE", "PPD"].indexOf(filterMetodo.toUpperCase()) > -1 ? filterMetodo : null
      };
      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      // console.log("filterDateApply", filterDateApply);
      const p = new URLSearchParams(filtredParams).toString();
      return p;
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, to.params.id, this);
        const { uuid: paramid = "" } = to.params ?? {};
        if (!paramid) {
          this.current = null; // hide single
          return;
        }
        // console.log("Force fetch", to.params.id);
        this.currUuid = paramid;
        this.fillInitial();
      },
      immediate: true
    }
  },
  methods: {
    fillInitial() {
      const { currUuid: paramid } = this;
      ServiceApi.getByUuid(paramid).then(res => {
        if (!res) {
          this.current = null;
          return;
        }
        this.current = res;
      });
    },
    invertFilterEmisorReceptor() {
      console.log("Inverting", this.myRfc, this.filterRfcEmisor, this.filterRfcReceptor);
      if (this.filterRfcEmisor) {
        this.filterRfcReceptor = this.myRfc;
        this.filterRfcEmisor = null;
      }
      else {
        this.filterRfcEmisor = this.myRfc;
        this.filterRfcReceptor = null;
      }
    },
    clearFilterRfc() {
      this.filterRfcReceptor = null;
      this.filterRfcEmisor = null;
    },
    onStatisticsChanged(stats) {
      const { assoc, classified, total } = stats;
      this.linkedPlotdata = [assoc, total - assoc];
      this.taxclassPlotdata = [classified, total - classified];
      console.log("Statistics:", stats);
    },
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = false;
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterTwoMonth = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
      this.filterTwoMonth = false;
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.filterDateYear = curryear;
      this.filterDateMonth = currmonth;
      this.filterDatePrevYear = prevyear;
      this.filterDatePrevMonth = prevmonth;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = true;
    },
    setRfcEmisorFilter(rfc) {
      // console.log("Set RFC Emisor", rfc);
      this.filterRfcEmisor = rfc;
      this.filterRfcReceptor = null;
      this.refreshListCfdi();
    },
    setRfcReceptorFilter(rfc) {
      // console.log("Set RFC Receptor", rfc);
      this.filterRfcEmisor = null;
      this.filterRfcReceptor = rfc;
      this.refreshListCfdi();
    },
    onSearchChanged(val) {
      this.search = val;
    },
    showSingleCfdi(cfdi) {
      const { uuid = "" } = cfdi ?? {};
      const { uuid: paramUuid = "" } = this.$route.params ?? {};
      if (uuid && uuid === paramUuid) {
        console.log("current uuid is same", uuid, paramUuid);
        return;
      }

      console.log("Clicked:", uuid);
      this.$router.push({
        name: "CFDISingle",
        params: { uuid }
      });
    },
    refreshListCfdi() {
      this.$nextTick(() => {
        this.fillInitial();
        this.$refs.cfdiBrowser.refresh();
      });
    },
    onSingleCfdiUpdated(cfdi) {
      this.current = cfdi;
      this.refreshListCfdi();
    }
  },
  beforeMount() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
    this.filterDateMonth = now.getMonth() + 1;
  },
};
</script>
