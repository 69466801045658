import Folders from "./Folders.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options.,
  install(Vue, options) {
    const {
      router = null, store = null, path = "/folders", name = "Folders"
    } = options;

    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Folders,
      meta: {
        title: "Archivos",
        privileges: ["FILES-FOLDERS"],
        label: "Archivos",
        icon: "ph:folder-light",
      },
      children: [
        {
          path: ":id", // /folders/:id
          name: "FoldersSingle",
          component: Folders,
          meta: {
            title: "Archivos",
            privileges: ["FILES-FOLDERS"],
            label: "Archivos",
            icon: "ph:folder-light",
          },
        },
        {
          path: ":id/:subdir", // /folders/:id/:subdir
          name: "FoldersSingleSubdir",
          component: Folders,
          meta: {
            title: "Archivos",
            privileges: ["FILES-FOLDERS"],
            label: "Archivos",
            icon: "ph:folder-light",
          },
        },
      ]
    };
    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    // r.name += "Single";
    // r.path += "/:id";
    // router.addRoutes([r]);
  }
};
