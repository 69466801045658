import Devices from "./Devices.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    console.log("[Devices] Registering", options);
    const { router = null, store = null } = options;
    const { path = "/devices", name = "Devices" } = options;

    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Devices,
      meta: {
        title: "Dispositivos",
        label: "Dispositivos",
        icon: "carbon:ibm-cloud-internet-services",
        privileges: ["ROCKET-USER"],
      },
      children: [
        {
          path: ":id", // /devices/:id
          name: "DevicesSingle",
          component: Devices,
          meta: {
            title: "Dispositivos",
            label: "Dispositivos",
            icon: "carbon:ibm-cloud-internet-services",
            privileges: ["ROCKET-USER"]
          }
        }
      ]
    };

    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    // r.name += "Single";
    // r.path += "/:id";
    // router.addRoutes([r]);
  }
};
