// import store from "@/store";
import Banks from "./Banks.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    const { router = null, store = null } = options;
    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path: "/banks",
      name: "Banks",
      component: Banks,
      meta: {
        title: "Bancos",
        label: "Bancos",
        icon: "clarity:piggy-bank-line",
        privileges: ["ROCKET-USER", "BANKS-USER"]
      },
      children: [
        {
          path: ":hash", // /banks/:hash
          name: "BanksSingle",
          component: Banks,
          meta: {
            title: "Bancos",
            label: "Bancos",
            icon: "clarity:piggy-bank-line",
            privileges: ["ROCKET-USER", "BANKS-USER"]
          }
        }
      ]
    };
    store.dispatch("RegisterRoute", { path:r.path, ...r.meta });
    router.addRoutes([r]);
  }
};
