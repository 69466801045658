var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"row px-1"},[_c('div',{staticClass:"col-xl-2 col-md-6 col-12"},[_c('div',{staticClass:"box mx-auto box-body border bg-dark-info border-dark"},[_c('div',{staticClass:"flexbox"},[_c('peity',{attrs:{"type":'pie',"options":{ 'fill': ['#33cabb', '#ef5350'],
                        stroke: '#a97d10fa',
                        width: 60,
                        height:60,
                        max: 9,
                        strokewidth: 1,
                        innerRadius: 0,
                        radius: 40 },"data":_vm.linkedPlotdata.toString()}}),_c('span',{staticClass:"font-huge-stats"},[_vm._v(" "+_vm._s(_vm.linkedPlotdata[1])+" ")])],1),_c('div',{staticClass:"text-right"},[(_vm.linkedPlotdata[1] !== 0)?_c('span',[_vm._v(" Sin asociar ")]):_c('span',[_vm._v(" Todo asociado ")]),(_vm.linkedPlotdata[1] == 0)?_c('Icon',{staticStyle:{"height":"1.5em","width":"1.5em"},attrs:{"icon":"fluent-emoji-flat:partying-face"}}):_vm._e()],1)])]),_c('div',{staticClass:"col-xl-2 col-md-6 col-12"},[_c('div',{staticClass:"box mx-auto box-body border bg-dark-info border-dark"},[_c('div',{staticClass:"flexbox"},[_c('peity',{attrs:{"type":'pie',"options":{ 'fill': ['#33cabb', '#ef5350'],
                        stroke: '#a97d10fa',
                        width: 60,
                        height:60,
                        max: 9,
                        strokewidth: 1,
                        innerRadius: 0,
                        radius: 40 },"data":_vm.taxclassPlotdata.toString()}}),_c('span',{staticClass:"font-huge-stats"},[_vm._v(" "+_vm._s(_vm.taxclassPlotdata[1])+" ")])],1),_c('div',{staticClass:"text-right"},[(_vm.taxclassPlotdata[1] !== 0)?_c('span',[_vm._v(" Sin clasificar ")]):_c('span',[_vm._v(" Todo clasificado ")]),(_vm.taxclassPlotdata[1] == 0)?_c('Icon',{staticStyle:{"height":"1.5em","width":"1.5em"},attrs:{"icon":"fluent-emoji-flat:partying-face"}}):_vm._e()],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-7"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('YearMonthDropdown',{staticClass:"d-sm-inline",attrs:{"year":_vm.filterDateYear,"month":_vm.filterDateMonth,"applied":_vm.filterDateApply,"fullyear":_vm.filterDateFullYear},on:{"dateChanged":_vm.onDateFilterChanged,"clearRequested":_vm.onDateFilterClear,"fullYearClicked":_vm.onDateFilterFullYear}}),_c('button',{staticClass:"btn btn-sm btn-default d-none d-sm-block float-right",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.refreshListCfdi()}}},[_c('Icon',{attrs:{"icon":"ic:outline-refresh"}})],1),_c('SearchBar',{attrs:{"value":_vm.search},on:{"search-changed":_vm.onSearchChanged}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mt-3"},[_c('CfdiNominasTable',{ref:"cfdiBrowser",attrs:{"apiurl":`${_vm.apiurl}?${_vm.params}`,"limit-items":Object.keys(_vm.params).length > 0 ? 500:200},on:{"rowClicked":_vm.showSingleCfdi,"statsChanged":_vm.onStatisticsChanged}})],1)])])])])])]),_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.current)?_c('CfdiNominasSingle',{staticClass:"bg-altgray",attrs:{"cfdi":_vm.current},on:{"cfdiUpdated":_vm.onSingleCfdiUpdated}}):_vm._e()],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-header"},[_c('h3',{staticClass:"box-title"},[_vm._v(" CFDI Nóminas ")]),_c('div',{staticClass:"box-controls float-right d-print-none"},[_c('div',{staticClass:"box-header-actions"},[_vm._v("   ")])])])
}]

export { render, staticRenderFns }