<style scoped>

</style>

<template>
  <div class="box">
    <div class="box-header border-bottom border-dark">
      <h3 class="box-title">
        {{ cfdi.nombre_emisor }}
      </h3>
      <div class="box-controls float-right mt-2">
        <Toggle
          class="btn btn-toggle btn-xs btn-primary"
          :value="!isOpen"
          @click="clickToggleStatus(cfdi)"
        />
        &nbsp;
        <Icon icon="bx:bxs-lock-alt" :inline="true" class="text-default" />
      </div>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row py-1 border-dark ">
            <div class="col-6">
              <h3>
                &nbsp;
              </h3>
            </div>
            <div class="col-6 text-right">
              <h3 class="text-warning">
                $ {{ cfdi.total }}
              </h3>
            </div>
          </div>
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-4">
              <p> UUID:</p>
            </div>
            <div class="col-8">
              {{ cfdi.uuid }}<br>
              <!-- <span
                class="badge badge-sm badge-pill pt-1"
                :class="{'badge-default':cfdi.metodo_de_pago=='PUE', 'badge-secondary': cfdi.metodo_de_pago!=='PUE'}"
              >
                {{ cfdi.metodo_de_pago }}
              </span> -->
              <span
                class="badge badge-sm badge-pill pt-1 badge-default "
              >
                {{ cfdi.uso_cfdi }}
              </span>
              <span
                class="badge badge-sm badge-pill badge-warning pt-1"
                :class="{'badge-danger':taxclass.value=='','badge-default': taxclass.value==='sinretencion'}"
              >
                {{ taxclass.text }}
              </span>
            </div>
          </div>
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-4">
              <p class="mb-0">
                <Icon class="text-blue" icon="ph:circles-four-fill" width="1.5em" height="1.5em" style="margin-bottom: 3px" />
                <span class="">SAT</span>
              </p>
            </div>
            <div class="col-8">
              <span class="badge badge-sm badge-pill pt-1" :class="{'badge-dark': cfdi.estado_sat, 'badge-danger': !cfdi.estadosat}">
                <span :class="{'text-primary': cfdi.estado_sat}">
                  {{ estadosat }}
                </span>
              </span>
              <span class="badge badge-sm badge-pill pt-1" :class="{'badge-dark': cfdi.cancelable, 'badge-danger': !cfdi.cancelable}">
                <span :class="{'text-primary': cfdi.cancelable}">
                  {{ cancelablesat }}
                </span>
              </span>
              &nbsp;
              <a class="btn btn-xs btn-default float-right" :class="{'disabled':loading}" href="#" @click.prevent="verifyCfdi(cfdi)">
                <Icon icon="ic:outline-refresh" :inline="true" />
                Verificar
              </a>
              <br>
              <small class="text-muted">
                <i>
                  {{ verificadoLastCheck }}
                </i>
              </small>
            </div>
          </div>
          <div class="row py-1 border-bottom border-dark pl-2">
            <div class="col-4 my-1">
              Retenciones:
            </div>
            <div class="col-8 my-1">
              <div v-if="cfdi && isOpen" class="btn-group">
                <button class="btn btn-sm btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  {{ taxclass.text }}
                </button>
                <div class="dropdown-menu">
                  <a
                    v-for="it in taxclassOptions"
                    :key="it.value"
                    :title="it.text"
                    href="#"
                    class="dropdown-item"
                    @click.prevent="onTaxclassChanged(it)"
                  >
                    {{ it.text }}
                  </a>
                </div>
              </div>
              <span v-else>
                {{ taxclass.text }}
              </span>
              <span v-if="totalRetenido>0" class="float-right px-1">
                {{ cfdi.total_retenidos }}
              </span>
            </div>
          </div>
          <div class="row py-1 border-bottom border-dark pl-2">
            <div class="col-4">
              Emisión
            </div>
            <div class="col-4">
              Timbrado
            </div>
            <div class="col-4">
              En Sistema
            </div>
            <div class="col-4 text-primary">
              {{ dateFormat(cfdi.fecha_emision) }}
            </div>
            <div class="col-4 text-muted">
              {{ dateFormat(cfdi.fecha_timbrado) }}
            </div>
            <div class="col-4 text-muted">
              {{ dateFormat(cfdi.createdAt) }}
            </div>
          </div>
          <div class="row mt-4 py-1 border-bottom border-dark ">
            <div class="col-12">
              <h3>Conceptos</h3>
              <p class="px-2 text-primary">
                {{ cfdi.conceptos }}
              </p>
            </div>
          </div>
          <div class="row mt-4 py-1 border-dark ">
            <div class="col-6">
              <h3>Relaciones</h3>
            </div>
          </div>
          <div class="row py-1 border-bottom border-dark pl-2">
            <div class="col-6">
              De:
            </div>
            <div class="col-6">
              Para:
            </div>
            <div class="col-6">
              <span class="text-primary"> {{ cfdi.rfc_emisor }} </span>
              <br>
              {{ cfdi.nombre_emisor }}
            </div>
            <div class="col-6">
              <span class="text-primary"> {{ cfdi.rfc_receptor }} </span><br>
              {{ cfdi.nombre_receptor }}
            </div>
          </div>
          <div class="row border-bottom border-dark py-1 pl-2">
            <div class="col-3 py-1 ">
              Conciliación:
            </div>
            <div class="col-9">
              <span
                v-for="item in recons" :key="item._id"
                class="py-1 d-block xborder-bottom xborder-dark"
              >
                <a class="badge badge-sm badge-pill badge-dark py-1" href="#" @click.prevent="reconClicked(item)">
                  {{ `C${item.recon_sequence}` }}
                  <p class="d-inline pl-3 text-primary">$ {{ item.amount }}</p>
                </a>
                <div class="box-tools float-right">
                  <button v-if="isOpen" type="button" class="btn btn-box-tool" title="Eliminar" @click.prevent="removeReconFromCfdi(item)">
                    <Icon icon="uil:times" />
                  </button>
                </div>
                <span class="d-block pl-1">
                  {{ item.title }}
                </span>
              </span>
              <LiveSearch
                v-if="recons.length < 1 && isOpen"
                ref="livesearch"
                class="py-1 d-block"
                empty="Buscar conciliación..."
                value=""
                :enabled="isOpen"
                :livesearch-values="searchresults"
                @searchValueChanged="onReconSearch"
                @livesearchItemClick="addReconToCfdi"
              />
            </div>
          </div>

          <div class="row mt-4 py-1 border-dark ">
            <div class="col-6">
              <h3>Archivos</h3>
            </div>
          </div>
          <div class="row py-1 border-bottom border-dark pl-2">
            <div class="col-3">
              Descargar:
            </div>
            <div class="col-9">
              <p>
                <a href="#" @click.stop.prevent="openPdf(cfdi)">
                  <Icon icon="bi:file-pdf" :inline="true" /> PDF
                </a>
                &nbsp;
                <a href="#" @click.stop.prevent="downloadXml(cfdi)">
                  <Icon icon="bi:filetype-xml" :inline="true" /> XML
                </a>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ReconCommentsTable
                v-if="cfdi"
                :apiurl="currentReconLogsUrl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import { format, parseISO, formatRelative } from "date-fns";
import esmx from "date-fns/locale/es";

import Toggle from "@/components/Toggle.vue";
import LiveSearch from "@/components/LiveSearch.vue";
import ServiceApi from "./Cfdi.service";

const ReconCommentsTable = () => import("@/plugins/Recons/ReconCommentsTable.vue");

export default {
  name: "CfdiSingle",
  components: {
    Icon,
    Toggle,
    LiveSearch,
    ReconCommentsTable
  },
  props: {
    cfdi: {
      type: Object,
      default() {
        return {
          title: "",
          recon_sequence: 0,
          nombre_emisor: "",
          conceptos: "",
          status: "open",
          recons: []
        };
      }
    },
    myRfc:{
      type: String,
      default: "DNR160608127"
    }
  },
  data() {
    return {
      searchresults: [],
      searching: false,
      loading: false
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    recons() {
      const { recons: r = [] } = this.cfdi;
      return r;
    },
    currentReconLogsUrl() {
      const { recons = [] } = this.cfdi ?? {};
      const { _id = "" } = recons[0] ?? {};
      if (!_id) {
        return "";
      }

      const url = `${this.API_ROOT}/api/recons/${_id}/logs`;
      return url;
    },
    taxclassOptions() {
      const { taxClasses = {} } = ServiceApi;
      return Object.keys(taxClasses).map(it => ({ value: it, text: taxClasses[it] ?? it }));
    },
    taxclass() {
      const { taxclass: t = "" } = this.cfdi ?? {};
      const { taxClasses = {} } = ServiceApi;
      return { value: t, text: taxClasses[t] ?? "Desconocido" };
    },
    isOpen() {
      const { status = "open" } = this.cfdi ?? {};
      return status === "open";
    },
    totalRetenido() {
      const { total_retenidos = "0" } = this.cfdi ?? {};
      let val = parseFloat(total_retenidos) || 0;
      if (Number.isNaN(val)) {
        val = 0;
      }
      return val;
    },
    verificadoLastCheck() {
      const { verificado_lastcheck: verifLastCheck = "" } = this.cfdi ?? {};
      console.log("verificadoLastCheck", this.cfdi);
      if (!verifLastCheck) {
        return "31/dic/1999";
      }
      const date = formatRelative(new Date(verifLastCheck), new Date(),  { locale: esmx } );
      return date;
    },
    estadosat() {
      const { estado_sat = "" } = this.cfdi ?? {};
      const estados = {
        V: "Vigente",
        CP: "Cancelación en Proceso",
        C: "Cancelado"
      };
      return estados[estado_sat] ?? "Desconocido";
    },
    cancelablesat() {
      const { cancelable = "" } = this.cfdi ?? {};
      const estados = {
        N: "No cancelable",
        A: "Cancelable con aceptación",
        S: "Cancelable sin aceptación"
      };
      return estados[cancelable] ?? "Desconocido";
    }
  },
  methods: {
    verifyCfdi(cfdi) {
      const { uuid = null } = cfdi;
      if (!uuid) {
        return;
      }
      console.log("Re verify SAT", uuid, cfdi);
      const { uuid: paramid = null } = this.$route.params;

      if (!paramid || paramid !== uuid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo verificar el CFDI",
          text: "El uuid no coincide con la ruta del navegador"
        });
        return;
      }
      // console.log(uuid);
      this.loading = true;
      ServiceApi.verifyCfdi(uuid, {})
        .then(res => {
          console.log("CFDI info", res);
          this.$emit("cfdiUpdated", res);
          this.loading = false;
          this.messageAlert({
            icon: "success",
            title: "CFDI Verificado",
            text: ""
          });
        })
        .catch(err => {
          this.loading = false;
          const { response = {}, message = "Revise su conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo verificar el CFDI",
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    openPdf(elem) {
      const { uuid = "" } = elem ?? {};
      const url = `${this.API_ROOT}/api/cfdi/${uuid}/pdf`;
      window.open(url, uuid);
    },
    downloadXml(elem) {
      const {
        uuid = "", fecha_emision = new Date(), tipo = "", rfc_emisor: emisor = "", rfc_receptor: receptor = "", moneda = "", xml = ""
      } = elem ?? {};

      if (!uuid) {
        this.showAlert({
          icon: "error",
          title: "No se pudo descargar el XMLDownload XML",
          msg: "CFDI request uuid empty, CODE: 400"
        });
        return;
      }

      const d = parseISO(fecha_emision);
      const fechaemision = format(d, "yyyy-MM-dd",  { locale: esmx } );
      const name = `${fechaemision}_${tipo}_${emisor}_${receptor}_${moneda}_${uuid}.xml`;

      const element = document.createElement("a");
      const blob = new Blob([xml], { type: "text/plain;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      element.setAttribute("href", url);
      element.setAttribute("download", name);
      element.click();
    },
    clickToggleStatus(cfdi) {
      const { uuid = null, status = "open" } = cfdi ?? {};
      console.log("TOGGLE STATUS", uuid, status);
      const { uuid: paramid = null } = this.$route.params;

      const statuschanged = status === "open" ? "locked" : "open";
      if (!paramid || paramid !== uuid) {
        this.messageAlert({
          icon: "error",
          title: `No se pudo ${status === "open" ? "activar" : "desactivar"} edición de CFDI`,
          text: "El identificador no coincide con la ruta del navegador"
        });
        return;
      }

      ServiceApi.updateByUuid(uuid, { status: statuschanged })
        .then(res => {
          console.log("CFDI info", res);
          this.$emit("cfdiUpdated", res);
        })
        .catch(err => {
          const { response = {}, message = "Revise su conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: `No se pudo ${status === "open" ? "activar" : "desactivar"} edición de CFDI`,
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    onReconSearch(val) {
      console.log("onReconSearch", val);
      if (!this.searching) {
        this.searching = true;
        ServiceApi.searchRecons({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: `C${it.recon_sequence} ${it.title}: ${it.amount}`,
              item: it
            }));
            // console.log("SEARCH", res);
            this.searchresults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    addReconToCfdi(id, recon) {
      console.log("Request recon swap to", id, recon);
      this.searchresults = [];
      this.searching = false;
      ServiceApi.addReconByUuid(this.cfdi.uuid, { reconid: id })
        .then(res => {
          // console.log("dbUpdated", res);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.$emit("cfdiUpdated", res);
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("RECON ADD FAILED ", errStr);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo agregar conciliación",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    removeReconFromCfdi(item) {
      const { _id: reconid = "", recon_sequence: reconSeq = 0, title = "" } = item ?? {};
      const name = `C${reconSeq} ${title}`;
      if (!reconid) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "No se pudo eliminar conciliación",
          text: "El Id de conciliación está vacio.",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
        return;
      }
      this.$swal({
        icon: "warning",
        title: "Eliminar conciliación",
        html: `Conciliación: <strong>${name}</strong>.`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí, Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ff7674",
        cancelButtonColor: ""
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          ServiceApi.removeReconByUuid(this.cfdi.uuid, { reconid })
            .then(res => {
              // console.log("dbUpdated", res);
              this.$emit("cfdiUpdated", res);
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              console.log("RECON DELETE FAILED ", errStr);
              this.$swal({
                position: "bottom-end",
                toast: true,
                icon: "error",
                title: "No se pudo eliminar conciliación",
                text: `${errStr}, CODE: ${status}`,
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
              });
            });
        }
      });
    },
    reconClicked(item) {
      const { recon_sequence: reconSeq = 0 } = item ?? {};
      this.$router.push({
        name: "ReconsSingle",
        params: { id: reconSeq }
      });
    },
    onTaxclassChanged(option) {
      const { value: val = "" } = option ?? {};
      console.log("taxclass:", val);
      const { uuid: routeid = "" } = this.$route.params;
      const { uuid = "" } = this.cfdi ?? {};
      if (!uuid || routeid !== uuid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo cambiar <b>Retenciones</b>",
          text: "Los identificadores no coinciden, route uuid y cfdi uuid"
        });
        return;
      }
      ServiceApi.updateByUuid(uuid, { taxclass: val })
        .then(res => {
          // console.log(res);
          this.$emit("cfdiUpdated", res);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(status, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo cambiar <b>Retenciones</b>",
            text: `${errstr}, CODE: ${status}`
          });
        });
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 3000, position = "bottom-end"
      } = info ?? {};
      this.$swal({
        icon,
        title,
        timer,
        position,
        html: text,
        toast: true,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    },
    dateFormat(value) {
      if (!value) {
        return "-";
      }
      const date = format(new Date(value), "d LLL, yyyy",  { locale: esmx } );
      return date;
    }
  }
};
</script>
