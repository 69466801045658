import Recons from "./Recons.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options.,
  install(Vue, options) {
    const {
      router = null, store = null, path = "/recons", name = "Recons"
    } = options;

    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Recons,
      meta: {
        title: "Conciliaciones",
        label: "Conciliaciones",
        icon: "ph:arrows-in-simple-light",
        privileges: ["ROCKET-USER", "RECONS-USER"],
        query: { type: "porpagar", status: "!closed", year: new Date().getFullYear() }
      },
      children: [
        {
          path: ":id",
          name: "ReconsSingle",
          component: Recons,
          meta: {
            title: "Conciliaciones",
            label: "Conciliaciones",
            icon: "ph:arrows-in-simple-light",
            privileges: ["ROCKET-USER", "RECONS-USER"]
          }
        }
      ]
    };
    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue
  }
};
