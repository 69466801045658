<style scoped>
.dropdown-menu {
  min-width: 200px;
}
.dropdown-menu.columns-2 {
  min-width: 200px;
}
.dropdown-menu.columns-3 {
  min-width: 600px;
}
.dropdown-menu li a {
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
}
.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  white-space: normal;
}
.multi-column-dropdown li a:hover {
  text-decoration: none;
  background-color: #999;
}
.single-recon {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-5 col-xl-4">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Dispositivos
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              <small v-if="filtertype.tincCapable" class="text-primary d-none d-sm-block" :class="{'text-muted':!filtertype.lastActives}"> {{ filtertype.lastActives ? "Activos" : "Todos" }} </small>
              <Toggle v-if="filtertype.tincCapable" class="btn-xs btn-default" :value="filtertype.lastActives" @click="onChangeFilterLastActives" />
              <SearchBar
                :value="search"
                @search-changed="onSearchChanged"
              />
              <div class="dropdown">
                <button
                  v-if="privileges && (privileges.indexOf('DEVICES-CREATE') > -1)"
                  id="amountype-toggle"
                  type="button" data-toggle="dropdown" aria-expanded="false" class="btn btn-sm btn-default d-sm-none"
                  @click="confirmAdd()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" data-v-4e249eba="" data-icon="carbon:add-alt" data-inline="true " class="iconify"
                    style="vertical-align: -0.125em; transform: rotate(360deg);"
                  >
                    <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16S9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2z" fill="currentColor" />
                    <path d="M24 15h-7V8h-2v7H8v2h7v7h2v-7h7z" fill="currentColor" />
                  </svg>
                </button>
                <button
                  v-if="privileges && (privileges.indexOf('DEVICES-CREATE') > -1)"
                  id="amountype-toggle"
                  type="button" dsata-toggle="dropdown" aria-expanded="false" class="btn btn-sm btn-default d-none d-sm-block"
                  @click="confirmAdd()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
                    preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" data-v-4e249eba="" data-icon="carbon:add-alt" data-inline="true " class="iconify"
                    style="vertical-align: -0.125em; transform: rotate(360deg);"
                  >
                    <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16S9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2z" fill="currentColor" />
                    <path d="M24 15h-7V8h-2v7H8v2h7v7h2v-7h7z" fill="currentColor" />
                  </svg>
                  Nuevo
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body">
          <ul class="nav nav-tabs nav-tabs-light-mode" role="tablist">
            <li class="nav-item">
              <a
                :class="{active: filtertype.tincCapable}" class="nav-link" data-toggle="tab" href="#devices" role="tab" aria-expanded="false"
                @click.prevent="onChangeFilterTincCapable(true)"
              >
                Con VPN
              </a>
            </li>
            <li class="nav-item">
              <a
                :class="{active: !filtertype.tincCapable}" class="nav-link" data-toggle="tab" href="#devices" role="tab" aria-expanded="false"
                @click.prevent="onChangeFilterTincCapable(false)"
              >
                Sin VPN
              </a>
            </li>
          </ul>
          <div class="tab-content tab-content-border">
            <div class="tab-pane pad active" role="tabpanel">
              <div class="row">
                <DevicesBrowser
                  ref="devicesBrowser"
                  class="col"
                  :apiurl="`${apiurl}?${urlparams}`"
                  @device-clicked="showSingleEditor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-7 col-xl-8">
      <Single
        v-if="currentSingleData"
        class=""
        :item="currentSingleData"
        @requestRefresh="$refs.devicesBrowser.refresh()"
        @licenseClicked="onLicenseClicked"
        @tincFileChanged="onTincfileChanged"
      />
    </div>
    <div class="col-md-12 col-lg-9" style="display: none;">
      <div class="box">
        <div class="box-body">
          <div id="vis">
            .
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import { Icon } from "@iconify/vue2";

import Toggle from "@/components/Toggle.vue";
import SearchBar from "@/components/SearchBar.vue";
import tincSpec from "./tincplot.spec";

import ServiceApi from "./Devices.service";

// Lazy loader
const DevicesBrowser = () => import("./DevicesBrowser.vue");
const Single = () => import("./DevicesSingle.vue");

export default {
  components: {
    DevicesBrowser,
    Single,
    Toggle,
    SearchBar
    // Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/devices"
    }
  },
  data() {
    return {
      currentSingleData: null,
      search: "",
      filtertype: {
        tincCapable: true,
        lastActives: true
      },
      nodes: [
        "adcasttvtampico",
        "minsColorScan",
        "tvzacatecas",
        "uaehkiosco2",
        "projecttensor1",
        "uaehkiosco1",
        "rkt",
        "minsBreakout",
        "adcastpruebas01",
        "tvveracruz",
        "weatherMicro",
        "minsPlayerColorScan",
        "sol",
        "tvtampico",
        "tvecortv",
        "tvtorreon1",
        "projecttensor0",
        "minsSlide",
        "edisWeatherSpare",
        "adcastLite00",
        "tvjuarez",
        "tvqueretaro",
        "tvmatamorosrack",
        "betas",
        "uaehmesa3",
        "minsInteractiveFloor",
        "uaehmesa2",
        "tvtijuana",
        "edis",
        "adcastapi",
        "tvejalisco",
        "mytpiso3",
        "tvmorelos",
        "uaehserv2",
        "tvacapulco",
        "nemos13",
        "minsXray",
        "tvguadalaj",
        "titan",
        "nemos11",
        "adcastLeon",
        "io",
        "uaehmesa1",
        "tvguadalaj2",
        "edisnx",
        "tvtoluca "],
      marks: [
        {
          name: "nodes",
          type: "symbol",
          zindex: 1,
          from: { data: "node-data" },
        }
      ]
    };
  },
  computed: {
    ...mapState(["user", "privileges"]),
    urlparams() {
      const { search = "" } = this;
      const { tincCapable: tinccapable, lastActives } = this.filtertype;
      const params = {
        search,
        tinccapable,
        lastactives: tinccapable ? lastActives : "false"
      };
      const urlparams = new URLSearchParams(params).toString();
      return urlparams;
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, to.params.id, this);
        const { id: paramid = "" } = to.params ?? {};
        if (!paramid) {
          this.currentSingleData = null; // hide single
          return;
        }
        // console.log("Force fetch", to.params.id);
        ServiceApi.getById(paramid).then(res => {
          if (!res) {
            // console.log("Got empty");
            this.currentSingleData = null;
            // hide single
            return;
          }
          // console.log("Got", res);
          this.currentSingleData = res;
          // show single
        });
      },
      immediate: true
    },
  },
  methods: {
    confirmAdd() {
      this.$swal({
        title: "Nuevo dispositivo",
        html: "<b class=\"text-danger\">¡Cuidado!</b><br>Ésto crea un número de serie.<br><br>Confirma con <b>Crear</b>",
        icon: "question",
        confirmButtonText: "Crear",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
        type: "input",
        input: "text",
        inputAutoTrim: true,
        allowEnterKey: false,
        focusConfirm: false,
        // eslint-disable-next-line consistent-return
        inputValidator: (val) => {
          if (val.length < 1) {
            return "Nombre vacío";
          }
          const alphanum = /^[a-zA-Z0-9:]+$/i;
          if (!alphanum.test(val)) {
            return "Solo acepta dígitos alfanuméricos";
          }
        }
      }).then((result) => {
        const { isConfirmed = false, value = "" } = result;
        if (isConfirmed) {
          const lfxuid = value.trim();
          ServiceApi.create({ name: lfxuid, hwuid: lfxuid })
            .then(res => {
              this.currentSingleData = res;
              this.$refs.devicesBrowser.refresh();
              this.$router.push({
                name: "DevicesSingle",
                params: { id: res._id }
              });
            })
            .catch(err => {
              const { response = {}, message = "" } = err;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              // console.log(status, err);
              this.$swal({
                position: "bottom-end",
                toast: true,
                icon: "error",
                title: "No se pudo crear",
                text: `${errStr}, CODE: ${status}`,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
              });
            });
        }
        else if (result.isDismissed && result.dismiss === "cancel") {
          // this.$emit("cancelled"); // Hay cambios pero se cancelaron
        }
      });
    },
    // eslint-disable-next-line no-unused-vars
    onLicenseClicked(id, hwserial) {
      console.log("Getting license", id);
    },
    // eslint-disable-next-line no-unused-vars
    onTincfileChanged(id, tincfile ) {

    },
    onRowsChecked(rows) {
      console.log("rows", rows); // is a dict
    },
    showSingleEditor(item) {
      // console.log("showSingleEditor", item, this.$router.getRoutes()); // is a dict
      const { id:paramid = "" } = this.$route.params;
      if (paramid && paramid === item._id) {
        // console.log("open");
        return;
      }

      // this.currentSingleData = item;
      this.$router.push({
        name: "DevicesSingle",
        params: { id: item._id }
      });
    },
    onChangeFilterLastActives() {
      this.filtertype.lastActives = !this.filtertype.lastActives;
    },
    onChangeFilterTincCapable(filter) {
      this.filtertype.tincCapable = filter;
    },
    onSearchChanged(val) {
      this.search = val;
    },
    transformNodes() {
      const centerIdx = this.nodes.indexOf("sol");
      const nodes = this.nodes.map((node, index) => {
        // console.log("Node", node, node.trim() === "sol");
        let group = 1;
        if (node === "sol") { group = 2; }
        else if (node.startsWith("tv") ) { group = 3; }
        else if (node.startsWith("tve") ) { group = 4; }
        else if (node.startsWith("adc") ) { group = 5; }

        return { name: node, index, group };
      });
      const edges = this.nodes.map((node, index) => (
        {
          source: index,
          target: centerIdx,
          value: 0.11
        }));
      // console.log("Graph", graph);
      return [{
        name:"node-data",
        values: nodes
      }, {
        name:"link-data",
        values: edges
      }
      ];
    }
  },
  mounted() {
    console.log("R:", this.nodes, this.nodes.indexOf("adcasttvtampico"), this.transformNodes());
    tincSpec.data = this.transformNodes();
    // embed(
    //   "#vis",
    //   tincSpec
    // );
  },
};
</script>
