// Primero Bootstrap porque queremos sobreescribir sus CSS
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/main.css";
import "sweetalert2/dist/sweetalert2.min.css";

// import "typeface-roboto/index.css";
// import es from "date-fns/locale/es";
import Vue from "vue";
import Vuex from "vuex";

// import KyXmpp from "@/services/Kitoy.service.xmpp";

import { LoaderPlugin } from "vue-google-login";
import Vue2TouchEvents from "vue2-touch-events";
import VueSweetalert2 from "vue-sweetalert2";
import VueCookies from "vue-cookies";
import VueNativeSock from "vue-native-websocket";
import store from "@/store";
import router from "@/router";

import Quotations from "@/plugins/Quotations/";
import Recons from "@/plugins/Recons/";
import Banks from "@/plugins/Banks/";
import Devices from "@/plugins/Devices/";
import Cfdi from "@/plugins/Cfdi/";
import Contacts from "@/plugins/Contacts/";

import Folders from "@/plugins/Folders/";
import Polls from "@/plugins/Polls/";
import Nemos from "@/plugins/Nemos/";
import Weather from "@/plugins/Weather/";
import RedesSoc from "@/plugins/RedesSociales/";

import Tarengo from "@/plugins/Tarengo/";

import App from "./App.vue";

Vue.use(LoaderPlugin, {
  client_id: process.env.VUE_APP_GOOGLECLIENTID
});

Vue.use(Weather, { router, store, path: "/weather" });
Vue.use(RedesSoc, { router, store, path: "/redessoc" });
Vue.use(Tarengo, { router, store, path: "/tarengo" });
Vue.use(Nemos, { router, store, path: "/nemos" });
Vue.use(Devices, { router, store, path: "/devices" });
Vue.use(Polls, { router, store, path: "/polls" });
Vue.use(Folders, { router, store, path: "/folders" });
Vue.use(Banks, { router, store });
Vue.use(Cfdi, { router, store, path: "/cfdi" });
Vue.use(Recons, { router, store, path: "/recons" });
Vue.use(Quotations, { router, store, path: "/quotations" });
Vue.use(Contacts, { router, store, path: "/contacts" });

Vue.use(VueNativeSock, store.state.WS_ROOT, {
  store,
  // format: "json",
  connectManually: true,
  reconnection: true,
  reconnectionAttempts: 5, // (Infinity),
  reconnectionDelay: 3000,
});
// --- Stripe ---
window.Vue = Vue;

Vue.use(VueCookies); // Use this.$cookies
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
  reverseButtons: true,
  heightAuto: false  // Esto descompone el CSS de <body>, dejar en `false`
});
Vue.use(Vue2TouchEvents, {
  disableClick: true
});

// window.localeDateFns = es;
Vue.config.devtools = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  computed: {
    ...Vuex.mapState(["WS_ROOT", "privileges", "token", "user"])
  },
  methods: {
    checkLocalStorageLogin() {
      const user = JSON.parse(localStorage.getItem("rkt_user"));
      const tok = localStorage.getItem("rkt_token");
      const priv = localStorage.getItem("rkt_privileges");

      console.log("Check localStorage....", this.token, tok);
      if (tok) {
        console.log("localStorage, token exists on localStorage, testing....", tok);
        store.commit("SetUser", user);
        store.commit("SetToken", tok);
        store.commit("SetPrivileges", JSON.parse(priv));
      }
    }
  },
  beforeCreate() {
    this.$store.commit("InitStore");
  },
  mounted() {
    // this.checkLocalStorageLogin();
    // console.log("APP MAIN mounted, privileges", this.privileges);
    console.log(`%cVersion: ${process.env.VUE_APP_GITHASH}`, "background: #222; color: #bada55");
    // this.$connect(`${this.WS_ROOT}/ws`);
    Vue.GoogleAuth.then(auth2 => {
      if (!auth2.isSignedIn.get()) {
        return;
      }
      // https://developers.google.com/identity/sign-in/web/reference#googleusergetauthresponseincludeauthorizationdata
      // Aqui se recibe un GoogleAuth y en un loginSucess se recibe un GoogleUser
      const googleUser = auth2.currentUser.get();
      console.log("[G] Already signed in, recovering session" /* , googleUser.getAuthResponse() */);
      store.dispatch("InitSessionFromGoogle", googleUser.getAuthResponse());
    });
    store.subscribe((mutation, state) => {
      // only params from store
      const {
        reconsParams = null,
        quotationsParams = null,
        cfdisParams = null,
        banksParams = null,
        foldersParams = null,
        devicesParams = null
      } = state;
      // Store the state object as a JSON string
      localStorage.setItem("rocket_store", JSON.stringify({
        reconsParams,
        quotationsParams,
        cfdisParams,
        banksParams,
        foldersParams,
        devicesParams
      }));
    });
  },
  render(h) { return h(App); }
}).$mount("#app");
