import Repos from "./Nemos.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    console.log("[Nemos] Registering", options);
    const { router = null, store = null } = options;
    const { path = "/nemos", name = "Nemos" } = options;

    if (!router || !store) {
      console.warn("Nemos Needs store and router, not installing", this);
    }
    const r = {
      path,
      name,
      component: Repos,
      meta: {
        label: "Nemos",
        title: "Nemos",
        icon: "material-symbols:video-library-outline-sharp",
        privileges: ["ROCKET-USER", "NEMOS-USER"],
      }
    };

    // RegisterRoute: La pone en el menu lateral
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]); // la registra en Vue

    // r.name += "Single";
    // r.path += "/:id";
    // router.addRoutes([r]);
  }
};
