<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
/* tr { */
  /* display: block; */
  /* cursor: pointer; */
  /* background-color: blue; */
/* } */
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
  color: #fff;
  border: 1px solid #398bf7;
  background-color: #398bf7;
}
.table-tools {
  display: inline-block;
  padding-top: 5px;
}
a[disabled].pag-button {
  pointer-events: none;
}
</style>
<template>
  <div>
    <table class="table table-hover inshadow ">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <th>&nbsp;</th>
          <!-- <th>
            <span class="float-right">&nbsp;</span>
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-if="rows.length < 1">
          <td colspan="2" class="text-center">
            Sin registros...
          </td>
        </tr>
        <tr v-for="it in rows" :key="it._id" @click="onItemClick(it,...arguments)">
          <td>
            <div class="row pl-3">
              <div class="col-12">
                <small>
                  <span class="text-primary" title="Contable">
                    {{ it.name }}
                  </span>
                  <span v-if="false" class="text-default pl-2" title="mas">
                    <Icon icon="clarity:piggy-bank-line" :inline="true" />
                    {{ it.timeStart }}
                  </span>
                  <strong class="pl-3 text-purple" title="Huella Rocket">
                    <Icon icon="fa6-solid:fingerprint" :inline="true" class="mr-0" /> {{ it.hash }}
                  </strong>
                  <span v-if="true" class="text-muted d-block">
                    {{ it.timeStart }} - {{ it.timeEnd }}
                  </span>
                  <span class="text-danger d-block">
                    {{ it.error }}
                  </span>
                </small>
              </div>
            </div>
          </td>
          <!-- <td>
            <span class="text-dark">$</span>
            <strong class="float-right text-nowrap">
              {{ it.amount_fmt }}
            </strong>
          </td> -->
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0 text-right">
            <!-- <Icon icon="mdi:sigma" :inline="true" /> -->
            &nbsp;
          </td>
          <!-- <td class="pb-0 text-right">
            {{ sum }}
          </td> -->
        </tr>
      </tfoot>
    </table>
    <div v-if="enableTools" class="table-tools" role="status" aria-live="polite">
      <div class="btn-group">
        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
          <a
            class="dropdown-item" href="#"
          >
            Copiar celdas
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from "vuex";
import { format } from "date-fns";
import es from "date-fns/locale/es";
import { Icon } from "@iconify/vue2";

const murmur = require("murmurhash-js");

// import ServiceApi from "./Tarengo.service";

export default {
  name: "TarengoUploadTable",
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/tarengo"
    },
    limitItems: {
      type: Number,
      default: 200
    },
    enableTools: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      data: [],
      orderBy: "accountingdate",
      orderAsc: true,
      page: 1,
      search: ""
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    rows() {
      const { rows = [] } = this.data;
      return rows;
    }
  },

  watch: {
    items: {
      immediate: true,
      handler(val /* , old */ ) {
        console.log("Refilling from data", val.length);
        this.data = this.transformData({ rows: val, total: val.length });
      }
    }
  },
  methods: {
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      // ServiceApi.find({
      //   per_page: this.limitItems,
      //   page: this.page,
      //   sort:`${this.orderBy}|${this.orderAsc ? "asc" : "desc"}`
      // }).then(res => {
      //   if (!res) {
      //     console.log("Got empty");
      //     return;
      //   }

      //   console.log("Got", res);
      //   this.data = this.transformData(res);
      //   // this.$emit("statsChanged", { count, total: rows.length });
      // });
    },
    transformData(dataInfo) {
      let  { rows = [] } = dataInfo;
      const yyyymm = format(Date.now(), "yyyyMMdd", { locale: es });
      rows = rows.map((it) => {
        const { timeStart = "", timeEnd = "", name = "" } = it;
        let hash = "";
        let hashSrc = `${timeStart}|${timeEnd}|${name}`;
        hashSrc = hashSrc.replace(/\s+/g, ""); // \s is the regex for "whitespace"
        hash = `${yyyymm}.${murmur
          .murmur3(hashSrc)
          .toString(36)
          .toUpperCase()}`;
        return {
          ...it,
          hash
        };
      });
      return { ...dataInfo, rows };
    }
  },
  mounted() {}
};
</script>
