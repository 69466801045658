<style scoped>
</style>

<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <ul class="nav nav-tabs nav-tabs-info" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#tarengo" role="tab" @click="onClickedTab('tarengo')">
              Parrilla Digital Tarengo
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tarengoUpload" role="tab" @click="onClickedTab('tarengoUpload')">
              Carga Parrilla Digital
            </a>
          </li>
        </ul>
        <div class="box-body tab-content">
          <div id="tarengo" class="tab-pane fade active show">
            <TarengoPanel />
          </div>
          <div id="tarengoUpload" class="tab-pane fade">
            <TarengoUploadPanel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TarengoPanel from "@/plugins/Tarengo/TarengoPanel.vue";
import TarengoUploadPanel from "@/plugins/Tarengo/TarengoUploadPanel.vue";

export default {
  components: {
    TarengoPanel,
    TarengoUploadPanel
  },
  props: {},
  data() {
    return {
      currentTab: "tarengo"
    };
  },
  computed: {},
  methods: {
    onClickedTab(tab) {
      this.currentTab = tab;
    }
  },
  mounted() {}
};
</script>
