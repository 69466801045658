<!-- eslint-disable vue/attribute-hyphenation -->
<style scoped>
.lookup-right::before {
  padding-top: 6px;
}
.liveedit-inline {
  display: inline-block;
  width: 60%;
}
.modalviewer {
  height: 100%;
}
.modalx-bg {
  position: fixed; /* Stay in place */
  z-index: 1050; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}
.modalx {
  font-family: "Lora", serif;
  /* background-color:#607025 !important; */
  color: white;
  overflow-x: clip;
  margin: auto;
  padding: 0rem;
  width: 50vw;
  max-height: 80%;
  overflow-y: auto;
  transition: all 1s linear;
}
/* .modalx >>> a{
  color: floralwhite;
} */
.blurred {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  transition: all 1s linear;
}

@media only screen and (max-width: 800px) {
  .modalx {
    width: 80vw;
    padding: 1rem;
  }
  .thumb {
    width: 30%;
    height: 5%;
    margin: 5px 5px 5px 5px;
  }
  h2 {
    padding-bottom: 1rem;
  }
}

</style>

<template>
  <div>
    <div class="modalviewer" :class="{blurred: viewSingle}"></div>
    <div v-show="viewSingle" class="modalx-bg" @click.prevent="closeModal">
      <div ref="modalx" class="modalx" @click.stop="()=>{}">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">
              EDIS Redes Sociales
            </h3>
            <div class="box-controls float-right d-print-none">
              <div class="box-header-actions text-info">
              &nbsp;
              </div>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-12 pl-2">
                <div class="media media-single pl-0">
                  <a href="#">
                    <img class="avatar-xl" src="https://loremflickr.com/120/120" alt="...">
                  </a>
                  <div class="media-body">
                    <h6 class="pl-3">
                      <Icon icon="mdi:message-reply-text" />
                      <LiveEdit
                        class="liveedit-inline"
                        empty="Sin usuario"
                        :value="user"
                        @valueChanged="onUserChanged"
                      />
                    </h6>
                    <!-- <small class="text-green">
                  <Icon icon="ic:baseline-whatsapp" />
                  Whatsapp
                </small> -->
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <Icon :icon="typeIcon" inline="true" /> {{ typeName }}
                        <span class="caret"></span>
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
                        <a class="dropdown-item" href="#" @click.prevent="onTypeClicked('whatsapp')">WhatsApp</a>
                        <a class="dropdown-item" href="#" @click.prevent="onTypeClicked('facebook')">Facebook</a>
                        <a class="dropdown-item" href="#" @click.prevent="onTypeClicked('twitter')">Twitter</a>
                        <a class="dropdown-item" href="#" @click.prevent="onTypeClicked('instagram')">Instagram</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 pr-1 pl-3">
                <img src="https://dummyimage.com/600x400/b0b0b0/ededed.png&text=" class="img-fluid">
              </div>
              <div class="col-md-8 pl-1">
                <textarea v-model="msg" class="form-control" rows="7" placeholder="Escriba su mensaje ..."></textarea>
              </div>
              <div class="col-12 pt-3">
                <button v-if="_id" class="btn btn-sm btn-primary float-right" @click.prevent="onSaveClicked">
                  Guardar
                </button>
                <button v-if="!_id" class="btn btn-sm btn-primary float-right" @click.prevent="onSaveClicked">
                  Crear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import LiveEdit from "@/components/LiveEdit.vue";

export default {
  components: {
    Icon,
    LiveEdit
  },
  props: {
    message: {
      type: Object,
      default: () => ({})
    },
    types: {
      type: Object,
      default: () => ({
        whatsapp: { icon: "ic:baseline-whatsapp", name: "WhatsApp" },
        twitter: { icon: "mdi:twitter", name: "Twitter" },
        facebook: { icon: "mdi:facebook", name: "Facebook" },
        instagram: { icon: "mdi:instagram", name: "Instagram" }
      })
    }
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _id: "",
      name: "",
      user: "",
      msg: "",
      type: "whatsapp",
      playlist: "",
      viewSingle: false,
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    typeIcon() {
      const { icon = "ic:baseline-whatsapp" } = this.types[this.type] ?? {};
      return icon;
    },
    typeName() {
      const { name = "WhatsApp" } = this.types[this.type] ?? {};
      return name;
    }
  },
  watch: {
    message: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Reload message", old, val);
        this.clear();
        const keys = ["_id", "name", "user", "msg", "type", "playlist"];
        Object.keys(val).forEach(key => {
          if (keys.indexOf(key) > -1) {
            this[key] = val[key];
          }
        });
      }
    }
  },
  methods: {
    clear() {
      this._id = "";
      this.name = "";
      this.user = "";
      this.msg = "";
      this.type = "whatsapp";
      this.playlist = "manana";
    },
    onUserChanged(val) {
      this.user = val;
      this.name = val;
    },
    onTypeClicked(val) {
      this.type = val;
    },
    onSaveClicked() {
      if (this.user.length < 2 || this.msg.length < 2) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "Crear mensaje",
          text: "Usuario o mensaje vacio, mínimo 3 letras",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
        return;
      }
      const msg = {
        _id: this._id,
        name: this.name,
        user: this.user,
        msg: this.msg,
        type: this.type,
        playlist: this.playlist
      };
      this.$emit("saveMessage", msg);
    },
    closeModal() {
      this.viewSingle = false;
      this.clear();
    },
    showModal() {
      this.viewSingle = true;
    }
  },
  beforeMount() {
  },
  mounted() {
  }
};
</script>
