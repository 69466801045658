const transacciones = {
  AA: "CAPITAL FINANCIADO",
  AB: "ABONO",
  ABS: "ABONOS",
  AC: "COMISIONES",
  AD: "BANCOS (PAGO AL PROVEEDOR)",
  AE: "IVA PAGO EXTRAORDINARIO",
  AF: "ABONO CHQ. FORANEO",
  AG: "IVA CAPITAL",
  AH: "ABONOS A SHCP",
  AI: "APLICACION DE IVA",
  AJ: "ABONO EDUCAFIRME",
  AK: "FALTANTE PAGO INICIAL",
  AL: "ABONO LOCAL SBC",
  AM: "DIAS DE FINANCIAMIENTO",
  AN: "DEPOSITO DE NOMINA",
  AO: "IVA COMISIONES",
  AP: "ABONO POR ORDEN DE PAGO",
  AQ: "DEPOSITO EN GARANTIA",
  AR: "ACREEDOR AL CONTRATO",
  AS: "ABONO X SPEUA",
  AT: "IVA DIAS DE FINANCIAMIENTO",
  AU: "SEGUROS",
  AV: "CARGO CUENTA CHEQUES",
  AW: "CARGO TRANSFERENCIA AUTOPLAZO",
  AX: "PAGO EXTRAORDINARIO",
  AY: "ABONO TRANSFERENCIA AUTOPLAZO",
  AZ: "DEPOSITO REMESA SBC",
  A6: "ABONO IDE",
  A7: "PAGO SHCP",
  A8: "ABONO REPO. CHEQUERA ESPECIAL",
  A9: "PAGO TESOFE",
  BJ: "AJUSTE BONUS",
  CA: "CARGO",
  CAS: "CARGO",
  CC: "CARGO CHEQUE DE CAJA",
  CD: "CHEQUE DEVUELTO",
  CE: "CARGO EDUCAFIRME",
  CF: "CARGO CHQ. FORANEO",
  CG: "CARGO POR GIRO",
  CH: "CARGOS A SHCP",
  CI: "ENTRADA DE EFECTIVO",
  CIS: "ENTRADA DE EFECTIVO",
  CJ: "CARGO CHEQUE CERTIFICADO",
  CK: "CHEQUE No 123456789 OTRO BANCO",
  CKS: "CHEQUE NO.",
  CL: "CARGO LOCAL SBC",
  CN: "DISPERSION DE FONDOS",
  CO: "SALIDA DE EFECTIVO",
  COS: "SALIDA DE EFECTIVO",
  CP: "CARGO ORDEN DE PAGO",
  CR: "DEV. INSUFICIENCIA DE FONDOS",
  CS: "CARGO X SPEUA",
  CV: "CANCELACION DE IVA",
  C1: "CORRECCION DEPOSITO NOMINA",
  C2: "CORRECC DEPOSITO AGUINALDO",
  C3: "CORRECC DEP FONDO DE AHORRO",
  C6: "CARGO IDE",
  C7: "PAGO SHCP",
  C8: "CARGO REPO. CHEQUERA ESPECIAL",
  C9: "PAGO TESOFE",
  DA: "DEPOSITO DE AGUINALDO",
  DB: "DISPOSICION DE EFECTIVO TC - CARGO",
  DC: "CONFIRMACION DE INVERSION",
  DD: "PAGO ANTICIPADO DE CARTERA",
  DE: "ABONO A CAPITAL",
  DF: "DEPOSITO DE FONDO DE AHORRO",
  DG: "DISPOSICION DE EFECTIVO TC - ABONO",
  DI: "INCREMENTO DE CONTRATO",
  DN: "DEPOSITO DE NOMINA",
  DO: "DISP EFVO",
  DP: "DEPOSITO",
  DPS: "DEPOSITO",
  DR: "COMPENSACION DEFINITIVA",
  DS: "DEV SPEI",
  DT: "DEV. TRANSFERENCIA INTERNACIONAL",
  DV: "COMISION POR DEV. CHEQUES LOCALES",
  DX: "CANCELACION DE DOCUMENTOS",
  DY: "NOMINA EXTRAORDINARIA",
  D0: "COMISION DEPOSITO REFERENCIADO",
  D1: "DEPOSITO AL COBRO DEL DIA",
  EF: "DEPOSITO EN EFECTIVO",
  EX: "CHEQUE XXXXXXXXX DEV CAUSA XX",
  FX: "ENTRADAS DE POSICION",
  GC: "PAGO DE GASTOS DE COBRANZA",
  GJ: "RETIRO TARJETA DE DEBITO",
  GL: "TRANSACCION  ON",
  HA: "ABONOS SHCP SBC",
  HB: "ESTABLECIMIENTO DE CCI",
  HC: "CARGOS SHCP SBC",
  HD: "REGISTRO POR ACEPTACION DE CCI",
  HE: "CIERRE PARCIAL DE APERTURA DE CCI",
  HF: "CIERRE DE ACEPTACION DE CCI",
  HJ: "CANCELACION DE APERTURA DE CCI",
  HK: "INTERESES DE CREDITO",
  HL: "INTERESES COMPLEMENTARIOS",
  HM: "GASTOS DE TRANSMISION DE CCI",
  IA: "CANCELACION DE INTERESES ANTICIPADO",
  IC: "CARGO DEPOSITO REFERENCIADO IMSS",
  ID: "ABONO DEPOSITO REFERENCIADO IMSS",
  IM: "CARGO",
  IP: "PAGO DE INTERES",
  I1: "IDEA CARGO",
  I2: "IDEA DEV. CARGO",
  I3: "IDEA ABONO",
  I4: "IDEA DEV. ABONO",
  LA: "TRANSFERENCIA ENVIADA A",
  LB: "TRANSFERENCIA INTERBANCARIA ENVIADA",
  LC: "TRANSFERENCIA RECIBIDA DE",
  LE: "TRASPASO RECIBIDO DE",
  LG: "TRASPASO DE CUENTA DE CHEQUES",
  LH: "TRASPASO A CUENTA DE CHEQUES",
  LI: "TRASPASO DE TARJETA DE AHORRO-INV",
  LJ: "TRASPASO A TARJETA DE AHORRO-INV",
  LK: "TRASPASO A INVERSION A LA VISTA",
  LM: "TRASPASO A INVERSION A LA VISTA",
  LN: "TRASPASO DE INVERSION A PLAZO",
  LO: "TRASPASO A INVERSION A PLAZO",
  LP: "TRASPASO A CUENTA DE AHORROS",
  LQ: "TRASPASO A CUENTA DE AHORROS",
  MB: "MEMBRESIA",
  MC: "CREDITOS MISCELANEOS",
  MD: "DEBITOS MISCELANEOS",
  M1: "ABONO DE ISR DE VTOS DE MESA DE DIN",
  M2: "CARGO CAPITAL DE VTOS DE MESA DE DI",
  M3: "CARGO DE INT VENCIMIENTOS MESA D DI",
  M4: "CANCELACION DE INV DE MESA DE DINER",
  ND: "NUEVO CONTRATO",
  OF: "CHEQUE DE CAJA",
  OP: "CANCELACION ORDEN DE PAGO",
  PA: "PAGOS ANTICIPADOS DE CARTERA",
  PB: "CARGO PAGO INTERBANCARIO",
  PC: "PAGO INTERBANCARIO CREDITO",
  PD: "PAGO INTERBANCARIO DEBITO",
  PE: "CARGO NOMINA INTERBANCARIA",
  PF: "PAGO DE FACTURA",
  PG: "DEVOLUCION PAGO INTERBANCARIO",
  PH: "DEVOLUCION NOMINA INTERBANCARIA",
  PI: "PAGO PRESTAMO DEMANDA (INTERESES)",
  PJ: "CARGO PENSIONES INTERBANCARIAS",
  PK: "DEVOLUCION PENSIONES",
  PL: "PAGO A ARRENDADORA ########",
  PM: "CREDITO PAGO INTERBANCARIO",
  PN: "CREDITO NOMINA INTERBANCARIA",
  PO: "CREDITO PENSION INTERBANCARIA",
  PP: "PAGO PRESTAMO DEMANDA",
  PQ: "ABONO DOMICILIACION",
  PR: "CARGO POR DOMICILIACION",
  PS: "PAGO DE PRIMA DE SEGUROS",
  PT: "PAGO DE TENENCIA",
  PU: "CARGOS POR TENENCIA",
  PV: "DEVOLUCIONES DOMICILIACION",
  PW: "REVERSAS DOMICILIACION",
  QA: "RETIRO DE EFECTIVO POS RED",
  QB: "RETIRO EN CAJERO AFIRME",
  QC: "RETIRO EN CAJERO RED",
  QD: "REVERSO DE EFECTIVO POS RED",
  QE: "REVERSO DE RETIRO EN CAJERO AFIRME",
  QF: "REVERSO DE RETIRO EN CAJERO RED",
  QG: "SU PAGO AL",
  QH: "COMPRA O CONSUMO CON SU TARJETA",
  QI: "REVERSO COMPRA/CONSUMO CON TARJETA",
  QJ: "CANC. DEP. EN TERMINAL P. VTA.",
  QK: "RETIRO DLS. EN CAJERO AFIRME",
  QL: "RETIRO DLS. EN CAJERO RED",
  QM: "VENTA GENERICA EN CAJERO AFIRME",
  QN: "VENTA GENERICA EN CAJERO RED",
  QO: "REVERSO RETIRO DLS. CAJERO AFIRME",
  QP: "REVERSO RETIRO DLS. CAJERO RED",
  QQ: "CONDONACION QUITA",
  QR: "REVERSO VTA. GENERICA CAJERO AFIRME",
  QS: "REVERSO VTA. GENERICA CAJERO RED",
  QT: "PAGO DE TENENCIA ATM AFIRME",
  QU: "PAGO DE TENENCIA ATM OTROS EN MIS",
  QV: "REVERSO PAGO TENENCIA ATM AFIRME",
  QW: "REVERSO PAGO TENENCIA OTROS EN MIS",
  QX: "COMISION IMPRESION EDO. CTA. CAJERO",
  QY: "VENTA TIEMPO AIRE ATM AFIRME",
  QZ: "VENTA TIEMPO AIRE ATM RED",
  Q1: "REVERSO VENTA TIEMPO AIRE ATM",
  Q2: "REVERSO VENTA TIEMPO AIRE ATM RED",
  Q3: "COMISION TIEMPO AIRE TELCEL",
  Q4: "PAGO DE PREDIAL ATM AFIRME",
  Q5: "PAGO DE PREDIAL ATM OTROS EN MIS",
  Q6: "REVERSO PAGO PREDIAL ATM AFIRME",
  Q7: "REVERSO PAGO PREDIAL OTROS EN MIS",
  Q8: "PAGO DE MULTAS ATM AFIRME",
  Q9: "PAGO DE MULTAS ATM OTROS EN MIS",
  RA: "AJUSTE DE TASA",
  RAS: "AJUSTE DE TASA",
  RB: "RECOMPENSA",
  RCS: "CARGO POR CUENTA SWIFTT",
  RE: "RETIRO",
  RI: "REVERSION DE INTERESES",
  RIS: "REVERSION DE INTERESES",
  RL: "PAGO AUTOMATICO DE INVERSIONES",
  RM: "REMESAS",
  RP: "TRASPASO A CUENTAS PROPIAS ATM",
  RQ: "TRASPASO DE CUENTAS PROPIAS ATM",
  RS: "TRASPASO A CUENTAS TERCEROS ATM",
  RTS: "CHEQUE DEVUELTO",
  RV: "TRASPASO DE CUENTAS TERCEROS ATM",
  RW: "BONUS PAGO TARJETA CREDITO CARGO",
  RX: "BONUS PAGO TARJETA CREDITO ABONO",
  RY: "RV BONUS PAGO TARJETA CREDITO CARGO",
  RZ: "RV BONUS PAGO TARJETA CREDITO ABONO",
  R0: "REV COMIS RETIRO CAJERO AFIRME",
  R1: "REV COMISION RETIRO CAJERO RED",
  R2: "REVERSO IVA COMISION ANTERIOR",
  R3: "RECAUDACION 2% SOBRE NOMINA",
  R4: "REVERSO PAGO MULTAS ATM AFIRME",
  R5: "REVERSO PAGO MULTAS OTROS EN MIS",
  R6: "PAGO DE TARJETA DE CREDITO (CARGO)",
  R7: "PAGO DE TARJETA DE CREDITO (ABONO)",
  R8: "REV PAGO TARJETA DE CREDITO (CARGO)",
  R9: "REV PAGO TARJETA DE CREDITO (ABONO)",
  SA: "ABONOS AL IMSS",
  SB: "CARGOS P/SERVICIO XXXXXXXXXXXX",
  SC: "CARGOS AL IMSS",
  SD: "SPID DEBITO",
  SE: "SPID ABONO",
  SP: "SUSPENSION DE PAGO",
  SPS: "SUSPENSION DE PAGO",
  SW: "O.P. RECIBIDAS",
  TB: "ABONO",
  TC: "CARGO",
  TD: "BONIFICACION IVA COMISION",
  TE: "BONIF. DE IVA INTERESES ANTER.",
  TF: "BONIF DE IVA CUOTA ANUAL",
  TI: "IVA DE INTERES ANTERIOR",
  TJ: "IVA DE CUOTA ANUAL",
  TK: "DEPOSITO CHEQUE DE CAJA",
  TL: "COBRO DE IVA CUENTA XXXXXXXXXXXX",
  TP: "TRANSFERENCIA/PRESTAMO DEMANDA",
  TX: "IVA DE COMISION ANTERIOR",
  TZ: "IVA DE COMISION ANTERIOR",
  T0: "PAGO TC (SBC)",
  T1: "PAGO MI NOMINA (SBC)",
  T3: "DOMICILIACION PAGO TELMEX - CARGO",
  T4: "DOMICILIACION PAGO TELMEX - ABONO",
  U: "CODIGO RENOVACION",
  UD: "DEVOLUCION COMPRAS",
  VA: "COMISION DIA ACTUAL FAX",
  VB: "VOZ COMISION MES ACTUAL FAX",
  VC: "VOZ COMISION MES ANTERIOR FAX",
  VE: "TRANSF. DE CUENTA",
  VF: "TRANSF. DE CUENTA",
  VG: "TRANSFERENCIA A TERCEROS",
  VH: "TRANSFERENCIA A TERCEROS",
  VI: "DISPERSION DE FONDOS",
  VJ: "DISPERSION DE FONDOS",
  VK: "CONCENTRACION DE FONDOS",
  VL: "CONCENTRACION DE FONDOS",
  VM: "INVERSION DIARIA",
  VN: "INVERSION DIARIA",
  VO: "CARGO TRANSFERENCIA VALORES",
  VP: "ABONO TRANSFERENCIA VALORES",
  VQ: "REVERSA PAGO TARJETA DE CREDITO",
  VR: "REVERSA PAGO TARJETA DE CREDITO",
  VS: "CARGO COMISION TELECOMM",
  VT: "ABONO COMISION TELECOMM",
  V0: "PAGO TELEFONO (S. VOZ)",
  V1: "PAGO TELEFONO (S. VOZ)",
  V2: "PAGO T.C (S. VOZ)",
  V3: "PAGO T.C (S. VOZ)",
  V4: "TRANSFERENCIA (S. VOZ)",
  V5: "TRANSFERENCIA (S. VOZ)",
  V6: "PAGO DOC. CREDITO (S. VOZ)",
  V7: "PAGO DOC. CREDITO (S. VOZ)",
  V8: "PAGO SHCP (S. VOZ)",
  V9: "PAGO SHCP (S. VOZ)",
  WC: "TRANSFERENCIA DE CREDITO",
  WCS: "TRANSFERENCIA DE CREDITO",
  WD: "TRANSFERENCIA DE DEBITO",
  WDS: "TRANSFERENCIA DE DEBITO",
  WT: "CARGO POR TRANSFERENCIA",
  WTS: "CARGO POR TRANSFERENCIA",
  XA: "SU PAGO SUMINISTRO DE AGUA",
  XB: "SU PAGO SEGUROS AFIRME",
  XC: "SU PAGO A CREDITO",
  XD: "SU PAGO A INTERESES MORATORIOS",
  XE: "PAGO A TELETRANSMISION",
  XF: "PAGO DE ACEPTACION DE CCI",
  XG: "DEPOSITO CON VOUCHERS",
  XH: "CARGO NOTAS DE DEVOLUCION",
  XP: "PAGO DE INTS. POR MORA",
  "0D": "COMISION DEPOSITO REFERENCIADO",
  "1C": "CORRECCION DEPOSITO NOMINA",
  "3A": "INTERESES PAGADO CT XXXXXXXXXXXX",
  "3B": "CUENTA SWIFTT",
  "3BS": "CUENTA SWIFTT",
  "3C": "CARGO POR CUENTA SWIFTT",
  "3D": "COMISION AFIRME CONS.CAJ.RED",
  "3E": "INTERESES POR SOBREGIRO PENDIENTES",
  "3F": "COMISION AFIRME RET.CAJ.RED",
  "3H": "CANCELA INVERSION",
  "3I": "CARGO POR CUENTA INACTIVA",
  "3L": "INTERESES",
  "3N": "CARGO X SOBREGIRO",
  "3NS": "CARGO X SOBREGIRO",
  "3O": "ABONO DE INTERESES AL XX.XX %",
  "3P": "CARGO X LINEA DE CREDITO",
  "3PS": "CARGO X LINEA DE CREDITO",
  "3Q": "RETENCION ISR",
  "3R": "RETENCION IDE",
  "3S": "RETENCION DE ISR",
  "3T": "ISR MESA DE DINERO",
  "3U": "INVERSIONES X LA NOCHE",
  "3V": "VENCIMIENTO DE MESA DE DINERO",
  "3X": "APERTURA DE CONTRATO",
  "3Y": "INTERES MENSUAL",
  "3Z": "RETENCION INTERES NR XXXXXXXXX",
  34: "CARGOS DE ADMINISTRACION",
  "4A": "COM CHEQUE DEVUELTO",
  "4B": "COM PEND CHEQ DEV",
  "4C": "COMISION CONSULT CAJERO AFIRME",
  "4D": "COMISION INTERBAN.CONS.CAJ.RED",
  "4E": "COMISION RETIRO CAJERO AFIRME",
  "4F": "COMISION INTERBAN.RET.CAJ.RED",
  "4G": "COMISION XXX CHEQUES EN EXCESO",
  "4H": "COMISION POR ESTADO DE CUENTA",
  "4I": "CARGO CUENTA INACTIVA",
  "4J": "CARGO MANEJO DE CUENTA",
  "4K": "CARGO CUOTA ANUAL",
  "4L": "CARGO USO DEL R.B.S.",
  "4M": "CARGO MANEJO DE FONDOS",
  "4N": "CARGO COMUNICACIONES",
  "4O": "CARGO ADMINISTRACION",
  "4P": "COMISION DEPOSITO",
  "4Q": "COMISION CHEQUE CERTIFICADO",
  "4R": "COMISION CHEQUE DE CAJA",
  "4S": "COMISION EMISION DE GIRO",
  "4T": "COMISION ORDEN DE PAGO",
  "4U": "COMISION PROTECCION DE SOBREGIRO",
  "4V": "OTROS CARGOS",
  "4W": "COMISION TRANSFERENCIA",
  "4X": "COM DEV SBC",
  "4Y": "COMISION ORDEN PAGO RECIBIDA",
  "4Z": "USO DE PROTECCION DE SOBREGIROS",
  40: "COMISION POR APERTURA PRESTAMO",
  41: "COMISION ANT (AL %)",
  42: "COMISION APERTURA DE CUENTA",
  43: "CUOTA DE CAJA DE SEGURIDAD",
  44: "INTERES DE DEPOSITO EN FIRME",
  45: "COMISION INTENTO SOBREGIRO",
  46: "COMISION ACEPT DOCTOS EXTRAN",
  47: "COMISION PAGO INTERBANCARIO",
  48: "COMISION NOMINA INTERBANCARIA",
  49: "COMISION PENSIONES",
  "5@": "CARTA DE CREDITO",
  "5@S": "CARTAS DE CREDITO",
  "5A": "COM FACT.ELEC.PAQ.",
  "5B": "CH. P/CAMARA",
  "5C": "COMISION SOLICITUD COPIA CHEQ",
  "5E": "REVALUACION",
  "5ES": "REVALUACION",
  "5F": "COMISION TRANSF ADQUIRIENTE TPV",
  "5G": "COMISION COBRANZA REMESA S/EXT",
  "5I": "MEMBRESIA",
  "5J": "PAGO / NEGOC ACEPTN",
  "5K": "GARANTIA EN EFECTIVO",
  "5KS": "GARANTIA EN EFECTIVO",
  "5L": "COMISION POR CANCELACION",
  "5LS": "COMISION POR CANCELACION",
  "5N": "COMISION POR ADVERTENCIA",
  "5NS": "COMISION POR ADVERTENCIA",
  "5O": "COMISION POR CONFIRMACION",
  "5OS": "COMISION POR CONFIRMACION",
  "5P": "COMISION PYME",
  "5Q": "COM SERV.TPV  PAQ.",
  "5R": "COMISION REMESA DEVUELTA S/EXT",
  "5T": "COMISION POR ACEPTACION",
  "5TS": "COMISION POR ACEPTACION",
  "5U": "COMISION POR PAGO DIFERIDO",
  "5US": "COMISION POR PAGO DIFERIDO",
  "5V": "PAGO DE CARTAS DE CREDITO",
  "5X": "COMISION PAGO SERVICIOS",
  50: "COMISION POR NEGOCIACION",
  "50S": "COMISION POR NEGOCIACION",
  51: "COMISION DOMICILIACION",
  52: "COMISION BANCA EN INTERNET",
  53: "COMISION ANUALIDAD TOKEN",
  54: "BANCA MOVIL ENVIO AVISOS P/CORREO",
  55: "COMISION BANCA MOVIL",
  56: "COMISION ORDEN DE PAGO",
  57: "COMISION ALERTAS TRANSACCIONALES",
  58: "ALERTAS ENVIO AVISOS P/CORREO",
  59: "COMISION EMISION CARTAS DE CREDITO",
  "6A": "COMISION CONSULTA CAJERO EXTRAN",
  "6B": "COMISION REPOSICION TARJETA",
  "6C": "COMISION EMISION DE TARJETA",
  "6D": "COMISION REEXPEDICION DE NIP",
  "6E": "COMISION RETIRO CAJERO EXTRAN",
  "6F": "COMISION TRANSF ADQUIRIENTE TPV",
  "6G": "COMISION TRANSF ADQUIRIENTE MANUAL",
  "6H": "COMISION BANCA EN LINEA",
  "6I": "COMISION BANCA INTERNET",
  "6J": "COMISION BANCA EN PUNTO",
  "6K": "RENTA DE T. P. V.",
  "6L": "GASTOS DE CREDITO",
  "6M": "CHEQUE DEVUELTO",
  "6N": "COMISION POR APERTURA DE CCI",
  "6O": "COMISION POR CANCELACION DE CCI",
  "6P": "COM. CONFIRMACION DEL CORRESPONSAL",
  "6Q": "GASTOS TRANSMISION",
  "6R": "COMISION DISPERSION NOMINA",
  "6S": "CUOTA ANUAL SERVICIO NOMINA",
  "6T": "COMISION GASTO DE INVESTIGACION",
  "6U": "COMISION POR CHEQUE DEVUELTO",
  "6V": "COMISION POR RETIRO DE AHORRO",
  "6W": "COMISION CHEQUE ABN",
  "6X": "COM PEND CHEQUE DEVUELTO",
  "6Y": "CARGO MANEJO DE CUENTA",
  "6Z": "COMISION XXX CHEQUES EN EXCESO",
  60: "GASTOS DE AVALUO HIPOTECARIO",
  61: "CANC. COMISION OPS. TER. P. VTA.",
  62: "COMISION APERTURA CTE MI NOMINA",
  63: "COMISION RETIRO TD VENTANILLA",
  64: "COMISION COPIA ESTADO CTA",
  65: "COMISION COPIA ESTADO CTA (USD)",
  66: "COM REPOSICION T. DEBITO",
  67: "COM DEV SBC",
  68: "COMISION PAGOS DIFERIDOS ADQ. TPV",
  69: "REV. COMISION PAGOS DIFERIDOS TPV",
  "7A": "BONIF COM CHEQUE DEVUELTO",
  "7C": "BONIF COM CONS CAJERO AFIRME",
  "7D": "BONIF COM CONS CAJERO RED",
  "7E": "BONIF COM RETIRO CAJERO AFIRME",
  "7F": "BONIF COM RETIRO CAJERO RED",
  "7G": "BONIF COM CHEQUES GIRADOS",
  "7I": "BONIF COMISION COPIA ESTADO CUENTA",
  "7J": "BONIF COM MANEJO CUENTA",
  "7K": "BONIF CUOTA ANUAL",
  "7L": "OTROS CREDITOS",
  "7M": "RECOMPENSA PROGRAMA BONU$",
  "7N": "RECOMPENSA BONU$ DEBITO",
  "7O": "BONIF. COMISION MULTIMONEDA TPV",
  "7P": "REV.BONIF. COMISION MULTIMONEDA TPV",
  "7Q": "BONIF COM CERTIF CHEQUE",
  "7R": "BONIF COM EMISION CHEQUE CAJA",
  "7S": "BONIF COM GIRO BANCARIO",
  "7T": "BONIF COMISION ORDEN DE PAGO",
  "7W": "BONIF COM TRANSFERENCIA",
  70: "BONIF COM APERTURA CREDITO",
  71: "BONIF COMISION ANT (AL %)",
  72: "BONIF COM APERTURA DE CUENTA",
  73: "BONIF CUOTA CAJA SEGURIDAD",
  74: "BONIF INTERES DEP EN FIRME",
  75: "BONIF COM INT. SOBREGIRO",
  76: "BONIF COM ACEP DOCTOS EXTRAN",
  77: "BONIF COM CHEQUE VIAJERO",
  78: "BONIF COM RENOVACION CREDITO",
  79: "BONIFICACION DE INTERESES",
  "8A": "BONIF COM CONS CAJERO EXTRAN",
  "8B": "BONIF COM REPOSICION TARJETA",
  "8C": "BONIF COM EMISION TARJETA",
  "8E": "BONIF COM RETIRO CAJERO EXTRAN",
  "8F": "BONIF COM ADQUIRIENTE T.P.V.",
  "8G": "BONIF COM ADQUIRIENTE MANUAL",
  "8H": "BONIF COM BANCA EN LINEA",
  "8I": "BONIF COM BANCA EN INTERNET",
  "8J": "BONIF COM BANCA EN PUNTO",
  "8K": "BONIF DE RENTA DE T.P.V.",
  "8L": "BONIF GASTOS DE CREDITO",
  "8M": "BENEFICIO PUNTO DE VENTA",
  "8R": "BONIF COMISION X DISPERSION",
  "8S": "BONIFICACION CUOTA ANUAL",
  "8T": "BONIFICACION ISR MESA DE DINERO",
  "8U": "TE PREMIAMOS",
  "8V": "BONIF. TE PREMIAMOS",
  "8W": "APERTURA SERVICIO DISPERSION NOMINA",
  "8X": "APERTURA CTA ADICIONAL NOMINA",
  "8Y": "MTTO Y ADMON ANUAL SERVICIO DISPERS",
  87: "COMISION ANUALIDAD TOKEN PERSONAS",
  "9A": "COMISION OPERACION DDA",
  "9B": "COMISION CONTRATACION DDA",
  "9C": "COMISION RENTA ANUAL DDA",
  "9D": "COMISION ADMON DE PERSONAL",
  "9E": "MEMBRESIA BILLU",
  "9F": "RECOMPENSA BILLU"
};
module.exports = transacciones;
