import axios from "axios";
import store from "@/store";

const UsersApi = {
  baseurl() {
    return `${store.state.API_ROOT}/api/quotations`;
  },
  findOneBySeq(seq) {
    // console.log("Try to fetch", seq)
    const query = {
      // La api puede buscar con patron: Cnnnn
      search: `SO${seq}`
    };
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/quotations/`;
      // console.log("GETT", url)
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          if (res.data.total < 1) {
          // console.log("Empty res", res.data.total);
            resolve(null);
            return;
          }

          // console.log("Got", res.data.total);
          if (res.data.total > 1) {
            console.warn(`Se encontró mas de uno para SO${seq}!`);
          }
          resolve(res.data.rows[0]);
        })
        .catch(err => {
          console.log("ERROR Searching quotation", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/quotations/${id}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          console.log("ERROR updating contact", err);
          reject(err);
        });
    });
  },
  create(data) {
    const query = data;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/quotations`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating quotation", err);
          reject(err);
        });
    });
  },

  createPdf(data) {
    const query = data;
    // const payload = {
    //   title: singleQuotation.jsondata.title, // TODO This should be inside singleQuotation, everything in one place
    //   quoteid: `SO${padNumber(singleQuotation.jsondata.quote_sequence, 4)}`,
    //   css: "",
    //   b64html,
    //   brand: brandcompany,
    //   parent: quotationid
    // };
    return new Promise((resolve, reject) => {
      const { _id: id } = data;
      const url = `${store.state.API_ROOT}/api/quotations/${id}/pdf`;
      // return resolve(url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating quotation PDF", err);
          reject(err);
        });
    });
  },

  getPdfsFor(query) {
    // console.log("Finding PDF for", query);
    return new Promise((resolve, reject) => {
      const { parent = null } = query;
      // TODO: Check null, fail fast...
      const url = `${store.state.API_ROOT}/api/quotations/${parent}/pdf`;
      // return resolve(url);
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating quotation PDF", err);
          reject(err);
        });
    });
  },
  getPdfDownloadLink(query) {
    // console.log("Finding Signed PDF for downloading", query);
    return new Promise((resolve, reject) => {
      const { parent = null, fileid = null } = query;
      // TODO: Check nulls, fail fast...
      const url = `${store.state.API_ROOT}/api/quotations/${parent}/pdf/${fileid}`;
      // return resolve(url);
      axios.get(url, { /* params: query, */ withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating quotation PDF", err);
          reject(err);
        });
    });
  },
  toggleSharing(quotationid, user) {
    console.log("Toggling share", quotationid, user);
    const { id = null, sharing = true } = user; // Por default quitar

    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error("Invalid id"));
      }
      // Si esta compartido, quitar de compartir
      if (sharing) {
        this.removeSharedUser(quotationid, id)
          .then(res => resolve(res))
          .catch(err => reject(err));
        return;
      }

      this.addSharedUser(quotationid, id)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addSharedUser(quotationid, userid) {
    const url = `${store.state.API_ROOT}/api/quotations/${quotationid}/share`;
    return new Promise((resolve, reject) => {
      const query = {
        id: userid
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("Resolving share", res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeSharedUser(quotationid, userid) {
    const url = `${store.state.API_ROOT}/api/quotations/${quotationid}/share/${userid}`;
    return new Promise((resolve, reject) => {
      axios.delete( url, { headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  searchRecons(query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  addReconById(id, data) {
    const query = data;
    console.log(id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/quotations/${id}/recons`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },
  removeReconById(id, data) {
    const { reconid = "" } = data;
    return new Promise((resolve, reject) => {
      if (!reconid || !id) {
        reject(new Error("Missing reconid or id"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/quotations/${id}/recons/${reconid}`;
      axios.delete(url, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating CFDI array", err);
          reject(err);
        });
    });
  },

};

export default UsersApi;
