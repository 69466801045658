<style scoped>
.dropdown-menu {
  min-width: 200px;
}
.dropdown-menu.columns-2 {
  min-width: 200px;
}
.dropdown-menu.columns-3 {
  min-width: 600px;
}
.dropdown-menu li a {
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
}
.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  white-space: normal;
}
.multi-column-dropdown li a:hover {
  text-decoration: none;
  background-color: #999;
}
</style>

<template>
  <div class="btn-group">
    <button
      id="amountype-toggle" type="button" data-toggle="dropdown" aria-expanded="false"
      class="btn btn-sm btn-default dropdown-toggle d-sm-none" :title="title"
    >
      <svg
        v-if="!isIcon"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
        preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" data-v-4e249eba="" data-icon="carbon:add-alt" data-inline="true " class="iconify"
        style="vertical-align: -0.125em; transform: rotate(360deg);"
      >
        <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16S9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2z" fill="currentColor" />
        <path d="M24 15h-7V8h-2v7H8v2h7v7h2v-7h7z" fill="currentColor" />
      </svg>
      <Icon v-if="isIcon" :icon="icon" />
      <span class="caret"></span>
    </button>
    <button
      id="amountype-toggle" type="button" data-toggle="dropdown" aria-expanded="false"
      class="btn btn-sm btn-default dropdown-toggle d-none d-sm-block" :title="title"
    >
      <svg
        v-if="!isIcon"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
        preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" data-v-4e249eba="" data-icon="carbon:add-alt" data-inline="true " class="iconify"
        style="vertical-align: -0.125em; transform: rotate(360deg);"
      >
        <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16S9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2z" fill="currentColor" />
        <path d="M24 15h-7V8h-2v7H8v2h7v7h2v-7h7z" fill="currentColor" />
      </svg>
      <Icon v-if="isIcon" :icon="icon" />
      <span v-if="title==='Nuevo'">
        {{ title }}
      </span>
      <span class="caret"></span>
    </button>
    <ul class="dropdown-menu multi-column columns-2" x-placement="bottom-start" style="">
      <div class="row">
        <div class="col-sm-12 col-msd-6">
          <ul class="multi-column-dropdown">
            <h6 class="dropdown-header">
              <span class="badge badge-pill badge-success">
                POR COBRAR </span>
            </h6>
            <li>
              <a
                class="amountype-selector-btn" title="CONACYT, INADEM y convenios asociados con las universidades" href="#"
                @click="clicked('porcobrar', 'subsidio')"
              >Subsidios/apoyos</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Ventas reales, no subsidios federales" href="#"
                @click="clicked('porcobrar', 'ventas')"
              >Ventas 🛍 </a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Pagos a nosotros mismos, entre cuentas" href="#"
                @click="clicked('porcobrar', 'intra')"
              >...intracuentas</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Rara vez nos reembolsan, hay errores, se inyecta capital o cobramos préstamos temporales" href="#"
                @click="clicked('porcobrar', 'otro')"
              >...otros</a>
            </li>
            <h6 class="dropdown-header">
              <span class="badge badge-pill badge-danger">
                POR PAGAR BASICO </span>
            </h6>
            <li>
              <a
                class="amountype-selector-btn" title="Becas y otros apoyos estudiantiles" href="#"
                @click="clicked('porpagar', 'becas')"
              >Becas</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Bonos inusuales, préstamos o festejos" href="#"
                @click="clicked('porpagar', 'especial')"
              >Bonos/préstamos</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn"
                title="Insumos para experimentar y mejorar, herramientas, cómputo, electrónica y a veces carpintería/herrería" href="#"
                @click="clicked('porpagar', 'investigacion')"
              >Investigación &amp; Desarrollo 🧠</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Impresión, congresos, publicidad y comidas de cortesía" href="#"
                @click="clicked('porpagar', 'marketing')"
              >Marketing (y comidas) 🎁</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Nomina, honorarios" href="#"
                @click="clicked('porpagar', 'sueldos')"
              >Sueldos y salarios</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Renta, CFE, tel, aseo" href="#"
                @click="clicked('porpagar', 'operativos')"
              >Serv. esenciales (CFE, etc)</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Entre cuentas propias" href="#"
                @click="clicked('porpagar', 'intra')"
              >Intracuentas</a>
            </li>
          </ul>
          <!-- </div>
                    <div class="col-sm-5"> -->
          <ul class="multi-column-dropdown">
            <h6 class="dropdown-header">
              <span class="badge badge-pill badge-danger">
                GASTOS OPERATIVOS </span>
            </h6>
            <!-- <div class="dropdown-divider d-none d-md-block"></div> -->
            <li>
              <a
                class="amountype-selector-btn" title="Mejoras y gastos de obra civil, electricidad y muebles" href="#"
                @click="clicked('porpagar', 'construccion')"
              >
                Herramientas y construcción</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Papel, toner, agua, jabon, etc" href="#"
                @click="clicked('porpagar', 'consumibles')"
              >
                Consumibles</a>
            </li>
            <!-- <li><a class="amountype-selector-btn" @click="clicked('porpagar', 'bancarios')" title="Comisiones, cuotas e intereses" href="#">Costo bancario</a></li> -->
            <li>
              <a
                class="amountype-selector-btn" title="Fletes, mudanzas, DHL, Fedex, UPS   (EXCEPTO impuestos)" href="#"
                @click="clicked('porpagar', 'couriers')"
              >
                Envios de objetos
              </a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="En instalación: cables, partes faltantes" href="#"
                @click="clicked('porpagar', 'instalacion')"
              >
                Extras de instalaciones</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="IVA, ISR, IMSS, Estatales, etc." href="#"
                @click="clicked('porpagar', 'impuestos')"
              >
                Impuestos o com. bancarias</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Inversiones especiales: PEI, INADEM, y otros proyectos especiales" href="#"
                @click="clicked('porpagar', 'proyectosx')"
              >Inversión a proy. Especial</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Insumos, partes, circuitos, carpintería y herrería para producir" href="#"
                @click="clicked('porpagar', 'produccion')"
              >Por producción para venta 🛒</a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Vuelos, hoteles, comidas de mkt o traslados de gente" href="#"
                @click="clicked('porpagar', 'viajes')"
              >
                Viático, hotel o pasaje
              </a>
            </li>
            <li>
              <a
                class="amountype-selector-btn" title="Créditos bancarios, adeudos por ajustes o pagos de daños a terceros" href="#"
                @click="clicked('porpagar', 'deuda')"
              >
                Créditos o adeudos
              </a>
            </li>
            <li>
              <a class="amountype-selector-btn" title="No deberías tener de estos, distorsiona las cuentas." href="#" @click="clicked('porpagar', 'mezclado')">
                ⚠️...mezclado/otros</a>
            </li>
          </ul>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon,
    // LiveSearch,
    // Toggle
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    }
  },
  data() {
    return {

    };
  },
  computed:{
    isIcon() {
      return this.icon.length > 0;
    }
  },
  methods: {
    clicked(recontype, recontag) {
      this.$emit("tagClicked", recontype, recontag );
    }
  },
};
</script>
