import axios from "axios";
import filesize from "filesize";
import Vue from "vue";
import store from "@/store";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export default {
  maxfilesize: 1024 * 1024,
  jpegThumbQuality: 85,
  acceptedImageTypes: ["image/jpeg", "image/png"],
  acceptedFileTypes: ["application/pdf", "application/xml", "text/xml"],
  thumb: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/DfGAAGNwIzdH8kZAAAAABJRU5ErkJggg==",

  vm: new Vue(),
  // file: null,
  // fileReader: new FileReader(),
  baseurl() {
    return `${store.state.API_ROOT}/api/recons`;
  },
  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
    }

    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      // eslint-disable-next-line no-param-reassign
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
  },
  findOneByIdSeq(reconIdSeq) {
    return reconIdSeq.length === 24 ? this.findOneById(reconIdSeq) : this.findOneBySeq(reconIdSeq);
  },
  findOneBySeq(seq) {
    // console.log("Try to fetch", seq)
    const query = {
      // La api puede buscar con patron: Cnnnn
      search: `C${seq}`
    };
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/`;
      // console.log("GETT", url)
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          // eslint-disable-next-line prefer-const
          let { rows = [], total = 0 } = res.data ?? {};
          if (total < 1) {
            // console.log("Empty res", res.data.total);
            resolve(null);
            return;
          }

          // console.log("Got", res.data.total);
          if (total > 1) {
            console.warn(`Se encontró mas de uno para C${seq}!`);
            rows = rows.filter(it => it.recon_sequence === parseInt(seq, 10));
          }
          const [recon = null] = rows;
          resolve(recon);
        })
        .catch(err => {
          console.log("ERROR Searching recon", err);
          reject(err);
        });
    });
  },
  findOneById(reconid) {
    // console.log("Try to fetch", reconid);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${reconid}`;
      // console.log("GETT", url)
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching recon", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${id}`;
      // console.log("POsTT", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          console.log("ERROR updating recon", err);
          reject(err);
        });
    });
  },
  triggerFileAutomation(reconid, fileitem) {
    const {
      _id: fileid,
      contentType
    } = fileitem;

    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${reconid}/${fileid}/auto`;
      axios.post(url, {
        id: fileid,
        contentType,
        action: "attach" // Ayudara a determinar qué automatización simular
      }, { withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          console.log("ERROR triggering file automations", err);
          reject(err);
        });
    });
  },
  autoAttachContactToRecon(reconid, query) {
    // const { reconid, ...data } = query;
    console.log("Auto attach", reconid, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${reconid}/contact`;
      // console.log("POsTT", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res);
        })
        .catch(err => {
          console.log("ERROR updating recon-contact", err);
          reject(err);
        });
    });
  },
  create(recontype, tags, amount) {
    const query = {
      tags: tags.length === 0 ? ["operativos"] : tags,
      title: `[BORRADOR] Pago de ${tags}`,
      type: recontype === "porpagar" ? "porpagar" : "porcobrar",
      amount
    };
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating recon", err);
          reject(err);
        });
    });
  },
  pushCommentToId(id, comment = "", amount = 0) {
    // eslint-disable-next-line no-param-reassign
    comment = comment.trim();

    return new Promise((resolve, reject) => {
      if (comment.length < 4 || typeof id === "undefined") {
        reject(new Error("Invalid id or comment too short"));
        return;
      }

      const query = {
        comment,
        amount: parseFloat(amount) || 0
      };
      const url = `${store.state.API_ROOT}/api/recons/${id}/logs`;
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("COM", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  markReconComment(comm) {
    // el id será un id de logs, no de recon
    const { status = "shown", id, parent } = comm;
    const url = `${store.state.API_ROOT}/api/recons/${parent}/logs/${id}`;
    // console.log("Toggling log", id, status, parent, url);
    return new Promise((resolve, reject) => {
      const query = {
        status
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  toggleSharing(reconid, user) {
    // console.log("Toggling share", reconid, user);
    const { id = null, sharing = true } = user; // Por default quitar

    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error("Invalid id"));
      }
      // Si esta compartido, quitar de compartir
      if (sharing) {
        this.removeSharedUser(reconid, id)
          .then(res => resolve(res))
          .catch(err => reject(err));
        return;
      }

      this.addSharedUser(reconid, id)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addSharedUser(reconid, userid) {
    const url = `${store.state.API_ROOT}/api/recons/${reconid}/share`;
    return new Promise((resolve, reject) => {
      const query = {
        id: userid
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("Resolving share", res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeSharedUser(reconid, userid) {
    const url = `${store.state.API_ROOT}/api/recons/${reconid}/share/${userid}`;
    return new Promise((resolve, reject) => {
      axios.delete( url, { headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processFileUpload(file) {
    this.file = file;
    console.log("[RECON] Processing file", file.name, file.type);
    return new Promise((resolve, reject) => {
      if (!file) {
        console.warn("File is invalid!");
        reject(new Error("Invalid file"));
        return;
      }
      if (file.size > 1024 * 1024 * 20) {
        console.warn("[RECON] File too big (>20MB):", file.name, filesize(file.size));
        this.vm.$emit("file-load-error");
        reject(new Error("File too big"));
        return;
      }
      if (this.acceptedImageTypes.indexOf(file.type) < 0
        && this.acceptedFileTypes.indexOf(file.type) < 0
      ) {
        console.warn("[RECON] Rejected file type:", file.name, file.type);
        this.vm.$emit("file-rejected", file.type);
        reject(new Error("File type not accepted"));
        return;
      }

      const reader = new FileReader();
      reader.onprogress = e => this.vm.$emit("file-load-progress", e);
      reader.onerror = e => {
        this.vm.$emit("file-load-error", e);
        reject(e);
      };
      reader.onload = e => {
        this.fileLoaded(e, file);
        resolve(e, file);
      };
      reader.readAsDataURL(file);
    });
  },
  fileLoaded(e, file) {
    // console.log("[RECON] File ready:", e, file, file.type);
    const dataUrl = e.target.result;

    this.vm.$emit("file-loaded", e.target.result, file);

    if (file.type.startsWith("image/")) {
      console.log("[RECON] File is image, scaling");
      this.scaleImage(dataUrl, 1600, 1600)
        .then((thumb) => {
          console.log("[RECON] Image was rescaled, now:", filesize(thumb.length));
          this.vm.$emit("image-scaled", { thumb, file });
        })
        .catch(() => {
          this.vm.$emit("image-scale-error");
        });
    }
    else {
      this.vm.$emit("file-ready", { file, b64:e.target.result });
    }
  },
  scaleImage(b64data, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      // We create an image to receive the Data URI
      const img = document.createElement("img");

      // When the event "onload" is triggered we can resize the image.
      img.onload =  () => {
        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        // Mantenemos el aspect-ratio
        let targetWidth = img.width * ratio;
        let targetHeight = img.height * ratio;

        if (maxWidth >= img.width || maxHeight >= img.height) {
          // Ésto evita el upscaling, pero obliga ser JPG, pierde la transparencia
          // ya veremos si necesitamos la transparencia un día.
          targetWidth = img.width;
          targetHeight = img.height;
        }

        // console.log("Image  size", img.width, img.height, "aspect:", ratio);
        // console.log("Target size", targetWidth, targetHeight);
        // We create a canvas and get its context.
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // We set the dimensions at the target size.
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // Si ya cargó la imagen, el draw no deberia fallar
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
        const dataURI = canvas.toDataURL("image/jpeg", this.jpegQuality); // FIXME: Si cambias esto debes hacer deteccion automatica en la carga S3
        resolve(dataURI);
      };
      img.onerror = () => {
        reject(new Error("Cannot load image from base64"));
      };
      // We put the Data URI in the image's src attribute
      img.src = b64data;
    });
  },
  async attachFile(reconid, filename, mime, base64, comment) {
    console.log("[Recon] Trying to upload", filename, mime, base64);
    const s3res = await this.savetoS3(reconid, filename, mime, base64)
      .catch(e => e); // will get feed into s3res
    if (s3res instanceof Error) {
      this.vm.$emit("file-upload-error", s3res);
    }
    const { bucket, key, status } = s3res;
    this.vm.$emit("file-upload-done", {
      filename, bucket, key, status
    });

    console.log("[Recon] Confirming upload", filename, bucket, key, comment);
    const confirmRes = await this.confirmUpload(reconid, bucket, key, filename, mime, comment)
      .catch(e => {
        console.log("[RECONS] error confirming", e);
        return e;
      }); // will get feed into confirmRes

    console.log("[Recon] Confirmed upload!!", confirmRes);
    return confirmRes; // test result with:  res instanceof Error)
  },
  async savetoS3(reconid, filename, mime = null, base64 = null) {
    console.log("[Recon] Trying to save S3", filename, base64 ? base64.length : base64, mime);

    // 0. Antes de poder guardar se necesita la SignedUrl (desde backend)
    const type = mime;

    if (!base64) {
      return new Error("ERR: No data provided");
    }

    const block = base64.split(";");
    const data = block[1].split(",")[1];
    const [, b64type] = block[0].split(":");
    const contents = b64toBlob(data, type);
    const { size: bytesize } = contents;

    console.warn("[Recon] IGNORING PARAMETER mime, extracting mime from base64:", mime, b64type);

    console.log("[Recon] Trying to getSignedUrl", contents, filename, bytesize, mime, b64type);
    //  OJO OJO OJO: Ignoramos el mime del parametro, confiamos en el mime del base 64
    const post = await this.getSignedUrl(reconid, filename, bytesize, b64type);
    if (!post) {
      console.error("[Recon] Imposible to getSignedUrl:", post); // post is an error
      return Error("Imposible to getSignedUrl", { cause: post });
    }

    console.log("[Recon] Signed post data:", filename, post);
    // 1. Los campos del PresignedPost vienen en json pero se necesita en FormData
    const formData = new FormData();
    const keys = Object.keys(post.fields);
    keys.forEach((k) => {
      formData.append(k, post.fields[k]);
    });

    const { email, _id: myid } = store.state.user;
    formData.append("x-amz-meta-userid", myid);
    formData.append("x-amz-meta-email", email);
    formData.append("x-amz-meta-reconid", reconid);
    formData.append("x-amz-meta-parent", "recons");
    // 2. El 'file' debe ir al final forzosamente
    //    A. Si es un File (traido de un <input>) carga el archivo
    //       ver: https://developer.mozilla.org/en-US/docs/Web/API/File
    //    B. Si son datos, guardará los datos dentro del archivo
    formData.append("file", contents);

    // 3. Hacer el post con "Content-Type": "multipart/form-data"
    //    (es necesario si se usa s3.createPresignedPost)
    // console.log("FORM HEADERS", formData.getHeaders());
    const axiosS3 = axios.create(); // NECESITAMOS una instancia nueva axios sin el header Bearer
    delete axiosS3.defaults.headers.Authorization;

    console.log("[Recon] Uploading to S3 now! for ", email, "recon", reconid);

    const { bucket, key } = post.fields; // los decide el back, pero los necesitamos
    // este axios es una instancia desechable
    const upres = await axios
      .post(post.url, formData, {
        headers: undefined // { "Content-Type": "multipart/form-data" } // sin `withCredentials` porque va a Amazon
      })
      .catch(e => {
        console.log("[Recon] ERROR Uploading to S3 now", e);
        return Error("ERR: Uploading to S3", { cause: e });
      });
    const { status = 500 } = upres;
    if (status >= 200 && status < 300) {
      // 4. AWS no regresa nada https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
      //    Solo HTTP: 200, 201, o 204 (default)
      console.log("[Recon] Posted to S3 res", upres.status);
      return { bucket, key, status };
    }
    return  { bucket, key, status };
  },
  async getSignedUrl(id, name, size, type) {
    console.log("[Recons] Getting S3 SignedUrl for:", id, name, size, type);
    if (!name || !size || !type) {
      console.warn("[Recon] Ignoring, one is null: ", name, size, type);
      // reject(new Error("[Recon] Ignoring, missing fields"));
      return new Error("[Recon] Ignoring, missing fields");
    }

    const query = { originalname:name, size, type };
    // Internamente el servidor atará la subida al userid de la sesión
    const url = `${store.state.API_ROOT}/api/recons/${id}/s3sign`;
    const r = await axios.post(url, query, { withCredentials: true })
      .then(res => {
        console.log("[Recons] getSignedUrl, OK, res", res);
        return (res.data);
      })
      .catch(e => {
        this.vm.$emit("file-getsignedurl-error", e);
        console.log("[Recons] getSignedUrl, error", e);
        return e;
      });
    return r;
  },
  confirmUpload(reconid, bucket, key, originalname, contentType, comment) {
    console.log("[Recon] Confirming upload now to backend hope this works!", reconid, bucket, key, originalname, contentType, comment);

    const url = `${store.state.API_ROOT}/api/recons/${reconid}/s3confirm`;
    const query = {
      id: reconid,
      bucket,
      key,
      originalname,
      contentType,
      comment
    };
    return axios.post(url, query, { withCredentials: true });
  },
  getFilesForId(reconid) {
    console.log("[Recon] Refetch attached files");
    const query = {
      // search: `algo`
    };
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${reconid}/files`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          if (res.data.total < 1) {
            // console.log("Empty res", res.data.total);
            resolve([]);
            return;
          }
          // console.log("Got", res.data.total);
          resolve(res.data.rows);
        })
        .catch(err => {
          console.log("ERROR Searching recon", err);
          reject(err);
        });
    });
  },
  getSignedDownloadUrl(reconid, fileid, view = "download") { // download (or empty), direct
    // console.log("[Recon] Get Signed file link for", reconid, fileid);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/recons/${reconid}/files/${fileid}`;
      axios.get(url, { params: { view }, withCredentials: true })
        .then((res) => {
          if (res.data.total < 1) {
            // console.log("Empty res", res.data.total);
            resolve([]);
            return;
          }
          // console.log("Got", res.data.total);
          resolve(res.data.rows);
        })
        .catch(err => {
          // console.log("ERROR Searching recon", err);
          reject(err);
        });
    });
  }
};
