<!-- eslint-disable vue/attribute-hyphenation -->
<style scoped>
.lookup-right::before {
  padding-top: 6px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 pl-2">
      <div class="media media-single pl-0">
        <div>
          <img class="avatar-xl" src="https://loremflickr.com/120/120" alt="...">
        </div>
        <div class="media-body">
          <h6>
            <span class="text-white" href="#">
              <Icon icon="mdi:message-reply-text" />
              {{ name == user ? user:`${name} - ${user}` }}
            </span>
          </h6>
          <small class="text-green">
            <Icon :icon="typeIcon" inline="true" />
            {{ typeName }}
          </small>
        </div>
      </div>
    </div>
    <div class="col-md-4 pr-1 pl-3">
      <img src="https://dummyimage.com/600x400/b0b0b0/ededed.png&text=" class="img-fluid">
    </div>
    <div class="col-md-8 pl-1">
      {{ msg }}
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format, addSeconds } from "date-fns";
import { es } from "date-fns/locale";
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon
  },
  props: {
    message: {
      type: Object,
      default: () => ({})
    },
    types: {
      type: Object,
      default: () => ({
        whatsapp: { icon: "ic:baseline-whatsapp", name: "WhatsApp" },
        twitter: { icon: "mdi:twitter", name: "Twitter" },
        facebook: { icon: "mdi:facebook", name: "Facebook" },
        instagram: { icon: "mdi:instagram", name: "Instagram" }
      })
    }
  },
  data() {
    return {
      name: "",
      user: "",
      msg: "",
      type: "whatsapp"
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    typeIcon() {
      const { icon = "ic:baseline-whatsapp" } = this.types[this.type] ?? {};
      return icon;
    },
    typeName() {
      const { name = "WhatsApp" } = this.types[this.type] ?? {};
      return name;
    }
  },
  watch: {
    message: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Reload message", old, val);
        this.clear();
        const keys = ["name", "user", "msg", "type"];
        Object.keys(val).forEach(key => {
          if (keys.indexOf(key) > -1) {
            this[key] = val[key];
          }
        });
      }
    }
  },
  methods: {
    clear() {
      this.name = "";
      this.user = "";
      this.msg = "";
      this.type = "whatsapp";
    },
  },
  beforeMount() {

  },
  mounted() {
  }
};
</script>
