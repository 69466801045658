<style scoped>
.dropdown-cell{
  /* background-color: red; */
  padding: 5px !important;
  min-height: 30px !important;
}
.dropdown-year{
  flex-basis: 66.666666% !important;
}
.dropdown-grid{
  min-width: 200px !important;
}
.dropdown-fullwidth{
  flex-basis: 100% !important;
}
</style>
<template>
  <div class="dropdown">
    <div class="btn btn-sm btn-default ">
      <a href="#" @click="onYearChange(-1, ...arguments)">
        <Icon icon="ic:outline-arrow-left" />
      </a>
      {{ title }}
      <a href="#" @click="onYearChange(+1, ...arguments)">
        <Icon icon="ic:outline-arrow-right" />
      </a>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "YearMonthDropdown",
  components: {
    Icon,
  },
  props: {
    year: {
      type: Number,
      default: 2023
    },
    minyear: {
      type: Number,
      default: 2000,
    },
    maxyear: {
      // todo el año, ignorar el mes
      type: Number,
      default: 2100,
    }
  },
  // data() {
  // },
  computed: {
    title() {
      return `${this.year}`;
    }
  },
  methods:{
    onYearChange(sum, ev) {
      // console.log(sum, ev);
      ev.stopPropagation(); // Evita que se quite el dropdown
      ev.preventDefault();  // Evita que se refresque la pagina
      if (   this.year + sum > this.maxyear
          && this.year + sum < this.minyear) {
        return; // Do nothing
      }
      // El padre decidira si nos cambia el prop o no
      this.$emit("yearChanged", this.year + sum);
    },
  }
};
</script>
