<template>
  <div class="row">
    <div class="col-xl-4 col-12">
      <div class="box">
        <div class="box-header with-border">
          <h5 class="box-title">
          </h5>
        </div>

        <div class="box-body">
          <!-- <ul class="flexbox flex-justified text-center mt-30">
            <li class="br-1">
              <div class="font-size-20 text-primary">953</div>
              <small>New York</small>
            </li>
            <li class="br-1">
              <div class="font-size-20 text-info">813</div>
              <small>Los Angeles</small>
            </li>
            <li>
              <div class="font-size-20 text-yellow">369</div>
              <small>Dallas</small>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Dashboard",
  components: {

  },
  data() {
    return {
    };
  },

  computed: {
  },
  methods: {},
  mounted() {
  }
};
</script>

<style scoped>
.bg-adcast {
  background-color: #3456b8 !important;
}
</style>
