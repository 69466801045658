<style scoped>
.task-header {
  font-weight: 300;
  font-size: 16px;
  padding: 1em 0px 0px 0px;
  /*top right bottom left */
}

.task-list {
  list-style: none;
  padding: 0;
  /* TOP/BOTTOM only shadow */
  box-shadow: inset 0px 0px 0px 0px #000, inset 0px -11px 8px -10px #00000057;
  overflow-y: scroll;
  height: 16.6em;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
</style>

<template>
  <div>
    <div v-if="privileges.indexOf('TASKS-USER') > -1" class="row">
      <div class="col-12">
        <h3 class="task-header mb-0">
          Tareas
        </h3>
        <h5 class="task-header">
          <span class="d-inline-block text-truncate" style="max-width: 130px;">{{ label }}</span>
          <a class="float-right btn btn-xs btn-default d-md-none" href="#" style="margin-top: -4px;" @click.prevent="onNewTask()">
            <span class="iconify" data-icon="carbon:add-alt" data-inline="true"></span>
          </a>
          <a
            class="float-right btn btn-xs btn-default d-none d-md-inline" href="#" style="margin-top: -3px;"
            @click.prevent="onNewTask()"
          >
            <span class="iconify" data-icon="carbon:add-alt" data-inline="true"></span>
            Nueva
          </a>
        </h5>
        <ul class="task-list">
          <li v-for="task in listOfTask" :key="task._id">
            <Task
              :taskid="task._id"
              :title="task.title"
              :status="task.status"
              :users="task.users"
              :available-users="availableUsersToShare"
              @statusChanged="onStatusTaskChanged"
              @deleteClicked="onDeleteTask"
              @editClicked="onEditTaskTitle"
              @shareClicked="toggleSharing"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ul class="control-sidebar-menu">
          <li v-for="(item, index) in listOfStats" :key="index">
            <a href="#">
              <TasksProgress
                :title="item.name" :progress="item.percentage" :level="item.level"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// import { Icon } from "@iconify/vue2";
import { mapState } from "vuex";
import TasksProgress from "@/components/TasksProgress.vue";
import Task from "@/components/Task.vue";

import TasksApi from "@/services/Tasks.service";
import UsersApi from "@/services/Users.service";

export default {
  components: {
    TasksProgress,
    Task,
    // Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/tasks"
    }
  },
  data() {
    return {
      label: "/",
      listOfTask: [],
      listOfStats: [],
      availableUsersToShare: [],
      currentApiTask: this.apiurl
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT", "privileges", "user"]),
  },
  watch: {
    $route(to, /* from */) {
      const { apiurl = "" } = TasksApi.getApiSettingsByRoute(this.apiurl, this.$route);
      this.currentApiTask = apiurl;
      this.label = to.path;
      if (this.privileges.indexOf("TASKS-USER") === -1) {
        console.warn("Necesitas el privilegio TASKS-USER para ver tareas");
        return;
      }
      this.fillInitial(this.currentApiTask);
    }
  },
  methods: {
    getStats() {
      TasksApi.stats(`${this.apiurl}/stats`)
        .then(res => {
          this.listOfStats = this.transformProgress(res ?? {});
        }).catch(e => {
          console.log(e);
        });
    },
    transformProgress(resStats) {
      const levelMap = ["primary", "danger", "success", "warning"];
      let listStats = Object.keys(resStats).map(key => resStats[key]);
      listStats = listStats.map((element, index) => {
        let { count = 0, open = 0, percentage = 0 } = element;
        count = parseInt(count, 10);
        open = parseInt(open, 10);
        if (count === 0 || Number.isNaN(count) || Number.isNaN(open)) {
          return { ...element, percentage };
        }
        percentage = open / count;
        percentage = Math.min(percentage, 1);
        percentage = Math.max(percentage, 0);

        const level = levelMap[index % 4];
        return { ...element, percentage, level };
      });
      return listStats;
    },
    // eslint-disable-next-line no-unused-vars
    fillInitial(apiurl) {
      TasksApi.find(this.apiurl)
        .then(res => {
          const { rows = [] } = res ?? {};
          this.listOfTask = this.transformData(rows);
        }).catch(e => {
          console.log(e);
        });
    },
    transformData(resData) {
      const taskDone = resData.filter((element) => element.status === "done").splice(0, 10);
      const taskOpen = resData.filter((element) => element.status === "open");
      const listFiltered = [...taskOpen, ...taskDone];
      return listFiltered;
    },
    fillUserList() {
      // Rellenar los posibles usuarios para compartir
      UsersApi.getUserList().then(res => {
        const { total = 0, rows = [] } = res.data;
        if (total < 1) {
          console.warn("WARNING: No users on userlist or total mismatch, this is a security BUG! STOP NOW!");
          return;
        }
        if (rows.length !== total) {
          console.warn("WARNING: Total user count and rows total mismatch, this is a security BUG!");
        }
        const { _id: myid } = this.user;
        this.availableUsersToShare = rows
          .filter(it => it._id !== myid) // ignorarse a si mismo
          .filter(it => it.privileges.indexOf("ROCKET-ACTIVE") > -1)
          .map(it => {
            const { _id: id, email, name } = it;
            return {
              id,
              email,
              name
            };
          });
      });
    },
    onStatusTaskChanged(id, taskTitle, status) {
      const { id: taskid = null } = { id };
      if (!taskid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo eliminar tarea",
          text: "El Id de tarea está vacio."
        });
        return;
      }

      TasksApi.updateById(taskid, { status })
        .then(res => {
          this.fillInitial(this.currentApiTask);
          const { status:resStatus = "", title:resTitle = "" } = res;
          let title = `La tarea <strong>${resTitle}</strong> tiene estatus indefinido`;
          if (resStatus === "done") {
            title = `La tarea <strong>${resTitle}</strong> fue terminada`;
          }
          else if (resStatus === "open") {
            title = `La tarea <strong>${resTitle}</strong> fue reabierta`;
          }
          this.messageAlert({
            icon: "success",
            title
          });
        }).catch(e => {
          this.listOfTask = [];
          this.fillInitial(this.currentApiTask);
          console.log(e);
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status: errStatus = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          this.messageAlert({
            icon: "error",
            title: `No se pudo cambiar el estatus de la tarea <strong>${taskTitle}</strong>`,
            text: `${errStr}, CODE: ${errStatus}`
          });
        });
    },
    onNewTask() {
      this.$swal({
        title: "Crear nueva tarea",
        inputLabel: "Escribe la tarea",
        input: "text",
        confirmButtonText: "Crear",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "Tarea sin nombre";
          }
        }
      }).then((result) => {
        if (result.value) {
          // const {
          //   parentschema = "", parentid = "", apiurl = ""
          // } = TasksApi.getApiSettingsByRoute(this.apiurl, this.$route);
          // this.currentApiTask = apiurl;
          // TasksApi.create(this.apiurl, { title: result.value, parentschema, parentid })
          TasksApi.create(this.apiurl, { title: result.value, parentschema: "", parentid: "" })
            .then(() => {
              this.fillInitial(this.currentApiTask);
            }).catch(e => {
              console.log(e);
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              this.messageAlert({
                icon: "error",
                title: "No se pudo crear tarea",
                text: `${errStr}, CODE: ${status}`
              });
            });
        }
      });
    },
    onDeleteTask(id, title) {
      const { id: taskid = null } = { id };
      if (!taskid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo eliminar tarea",
          text: "El Id de tarea está vacio."
        });
        return;
      }

      this.$swal({
        icon: "warning",
        title: "Eliminar tarea",
        html: `¿Confirmas que quieres eliminar la tarea <strong>${title}</strong>?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí, Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ff7674",
        cancelButtonColor: ""
      }).then((result) => {
        if (result.isConfirmed) {
          TasksApi.updateById(taskid, { status: "trash" })
            .then(() => {
              this.fillInitial(this.currentApiTask);
              this.messageAlert({
                icon: "success",
                title: `Se elimin&oacute; <strong>${title}</strong>`
              });
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              this.messageAlert({
                icon: "error",
                title: `No se pudo eliminar la tarea <strong>${title}</strong>`,
                text: `${errStr}, CODE: ${status}`
              });
            });
        }
      });
    },
    onEditTaskTitle(id, taskTitle) {
      const { id: taskid = null } = { id };
      if (!taskid) {
        this.messageAlert({
          icon: "error",
          title: "No se pudo eliminar tarea",
          text: "El Id de tarea está vacio."
        });
        return;
      }

      this.$swal({
        title: "Editar tarea",
        inputLabel: "Cambiar el nombre de la tarea",
        inputValue: taskTitle,
        input: "text",
        confirmButtonText: "Guardar",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "Tarea sin nombre";
          }
        }
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          TasksApi.updateById(taskid, { title: result.value })
            .then(() => {
              this.fillInitial(this.currentApiTask);
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              this.messageAlert({
                icon: "error",
                title: `No se pudo renombrar la tarea <strong>${taskTitle}</strong>`,
                text: `${errStr}, CODE: ${status}`
              });
            });
        }
      });
    },
    toggleSharing(task, user) {
      // contiene el actual status de sharing y hay que invertirlo
      console.log(user);
      const { taskid = "" } = task ?? {};
      const { email = "", sharing = false } = user ?? {};
      TasksApi.toggleSharing(taskid, user)
        .then(res => {
          console.log(res);
          this.fillInitial(this.currentApiTask);
          const shared = !sharing;
          let msg = `Se ha compartido con ${email}`;
          if (!shared) {
            msg = `Se ha dejado de compartir con ${email}`;
          }
          this.messageAlert({
            icon: "success",
            title: msg
          });
        })
        .catch((e) => {
          console.log(e);
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          this.messageAlert({
            icon: "error",
            title: `No se pudo compartir con <strong>${email}</strong>`,
            text: `${errStr}, CODE: ${status}`
          });
        });
    },
    messageAlert(props) {
      const {
        position = "bottom-end",
        toast = true,
        icon = "info",
        title = "sin definir",
        text = "",
        showConfirmButton = false,
        timer = 2500,
        timerProgressBar = true
      } = props ?? {};
      this.$swal({
        position,
        toast,
        icon,
        title,
        text,
        showConfirmButton,
        timer,
        timerProgressBar
      });
    },
  },
  mounted() {
    const { apiurl = "", path = "/" } = TasksApi.getApiSettingsByRoute(this.apiurl, this.$route);
    this.currentApiTask = apiurl;
    this.label = path;
    this.fillUserList();
    this.fillInitial(this.currentApiTask);
    this.getStats();
  }
};
</script>
