import axios from "axios";
import store from "@/store";

const testWa = `[11:45 a. m., 24/4/2023] Manuel Antonio EDIS: ok va
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Están mis compañeros
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Hay personal de 5:30 h a 01:05 h
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Sólo que mi turno termina al medio día
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Y entra mi compañero y también se encuentra el jefe de estación

[8:14 p. m., 24/4/2023] +52 1 222 506 5481: Buenas noches le envio la factura correspondiente
[8:15 p. m., 24/4/2023] +52 1 222 506 5481: Quedo pendiente para el otro concepto por favor
[8:15 p. m., 24/4/2023] +52 1 222 506 5481: Para poder emitir mañana la factura

[11:45 a. m., 24/4/2023] Manuel Antonio EDIS: ok va
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Están mis compañeros
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Hay personal de 5:30 h a 01:05 h
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Sólo que mi turno termina al medio día
[11:49 a. m., 24/4/2023] Manuel Antonio EDIS: Y entra mi compañero y también se encuentra el jefe de estación

[10:49 a. m., 24/4/2023] Esther R2: Eso sí
[3:10 p. m., 25/4/2023] Esther R2: Hola gracias
[3:10 p. m., 25/4/2023] Esther R2: 🐥
[3:10 p. m., 25/4/2023] Esther R2: 🐥
`;

const Detector = {
  // Probarlos: https://regex101.com/
  regs: [
    {
      id: "wa_block",
      reg: /(\[.*\]\s)(.*:\s)(.*)/mi,
      comment: "Bloque copiado de Whatsapp (2023), que tiene fechas en corchetes"
    },
    {
      id: "tw_user",
      reg: /(https:\/\/)(.*)(twitter\.com)(\/\w*)[^/]/gmi,
      comment: "Link de usuario twitter"
    },
    {
      id: "tw_statlink",
      reg: /(https:\/\/)(.*)(twitter\.com)(\/\w*)\/status(\/\w.*)/gmi,
      comment: "Link de un twit (status)"
    },
    {
      id: "ig_userpost",
      reg : /(^[\w](?!.*?\.{2})[\w.]{1,28}[\w])(\s)(.*)/gmi,
      comment: "Entrada de Instagram tipo: 'usuario Hola 🌿 a todos'"
    }
  ],

  isPhone(str) {
    const phoneRe = /[1-9]\d.*$/; // numeros que no inician con 0
    const clean = str
      .replaceAll("+", "")
      .replaceAll(" ", "")
      .replaceAll(":", "");
    // console.log(clean, phoneRe.test(clean));
    return (phoneRe.test(clean));
  },
  detectText(text) {
    // Puede ser un link de Twitter o de otro lado
    // o puede ser texto especial de Whatsapp
    // o puede ser json que signifique cosas para nosotros
    // console.log("Detecting", text);
    const results = this.regs
      .map((it) => {
      // console.log(it);
        const res = {
          match: it.reg.exec(text),
          ...it
        };
        return res;
      })
      .filter(it => it.match != null);
    // Aqui solo deberia haber 1 resultado, si no tomar el primero
    console.log(results.length, results[0]);
    const [first = null] = results;
    return first;
  },
  extractWhatsapp2023(text) {
    // trae fecha, numero o contacto y los mensajes
    // si es numero, truncar con terminacion: 📱-NNNN
    // si es usuario, pues el usuario (peligro?)
    console.log("X");
    const str = text;// = text.split(/\r?\n/);
    let m;
    const regex = this.regs[0].reg;
    // eslint-disable-next-line no-cond-assign
    let  lines = text.split(/\r?\n/);
    // console.log(lines);
    // Aplicar regex a cada linea
    lines = lines.map(it => regex.exec(it)).filter(it => it);
    // console.log(lines);
    // Cada Regex da un array, extraer a diccionarios
    lines = lines.map((it) => {
      const [, dateStr = "", who = "", message = null] = it;
      console.log(`: ${dateStr}: ${who} : ${message} / `);
      if (message) {
        // Limpiamos un poco antes de retornar el objeto
        return {
          dateStr: dateStr.replaceAll("[", "").replaceAll("]", "").trim(),
          who: who.substr(0, str.length - 1).trim(), // El último ':'
          message: message.trim()
        };
      }
      return null; // Si no hay mensaje no nos interesa nada
    }).filter(it => it); // Eliminar los de mensaje nulo
    if (lines.length < 1) {
      return {
        total:0,
        sender:null,
        lines:[]
      };
    }
    const { who } = lines[0];
    const { dateStr } = lines[0]; // Contamos la fecha cuando el primero
    const messages = lines.map(it => it.message);

    this.isPhone(who);
    return {
      total: messages.length,
      sender: who,
      dateStr,
      messages
    };
  },
  extractTwitterStatus() {
    // el tradicional extractor twitter
  },
  getTwitterUserPosts() {
    // Traer los ultimos 5 posts
  },
  extractIgPost() {
    // partir el usuario, y traer foto de la api IG
    // tal vez sanitizar
  }
};

const ServiceApi = {
  baseurl() {
    return `${store.state.API_ROOT}/api/redessoc`;
  },
  find(apiurl, query = {}) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR free finding", err);
          reject(err);
        });
    });
  },
  getById(id, query = {}) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching", err);
          reject(err);
        });
    });
  },
  create(query = {}) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/redessoc`;
      if (!query || Object.keys(query).length === 0) {
        reject(new Error("Object is empty"));
        return;
      }
      // console.log("create", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR create new", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  changeProgram(playlist) {
    const { id = "" } = playlist;
    console.log("Try to change program", id);
    // return new Promise((resolve, reject) => {
    //   const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
    //   // console.log("updating", url);
    //   axios.post(url, query, { withCredentials: true })
    //     .then((res) => {
    //       resolve(res.data);
    //     })
    //     .catch(err => {
    //       console.log("ERROR updating ", err);
    //       reject(err);
    //     });
    // });
  },
  changeTheme(theme) {
    const { name = "" } = theme;
    console.log("Try to change theme", name);
    // return new Promise((resolve, reject) => {
    //   const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
    //   // console.log("updating", url);
    //   axios.post(url, query, { withCredentials: true })
    //     .then((res) => {
    //       resolve(res.data);
    //     })
    //     .catch(err => {
    //       console.log("ERROR updating ", err);
    //       reject(err);
    //     });
    // });
  },
  changePoll(poll) {
    const { name = "" } = poll;
    console.log("Try to change poll", name);
    // return new Promise((resolve, reject) => {
    //   const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
    //   // console.log("updating", url);
    //   axios.post(url, query, { withCredentials: true })
    //     .then((res) => {
    //       resolve(res.data);
    //     })
    //     .catch(err => {
    //       console.log("ERROR updating ", err);
    //       reject(err);
    //     });
    // });
  },
  getBySearch(val) {
    console.log("Try to search", val);
    // return new Promise((resolve, reject) => {
    //   const url = `${store.state.API_ROOT}/api/redessoc/${id}`;
    //   axios.get(url, { params: query, withCredentials: true })
    //     .then((res) => {
    //       resolve(res.data);
    //     })
    //     .catch(err => {
    //       console.log("ERROR Searching", err);
    //       reject(err);
    //     });
    // });
  }
};

export { ServiceApi, Detector };
