import axios from "axios";
import store from "@/store";

const Api = {
  find(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR free finding", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/${id}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  findRelatedQuotation(id, search) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/${id}/quotations`;
      axios.get(url, { params: { search }, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching", err);
          reject(err);
        });
    });
  },
  getById(id) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/${id}`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching", err);
          reject(err);
        });
    });
  },
  getLicenseById(id) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/${id}/license`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Gettng License", err);
          reject(err);
        });
    });
  },
  getLabelZPL(id) {
    // Se debe mandar un archivo llamado <serial>-<hardware-id-8digit>.prn
    // por ej: 00000000125-ABCD-XYZW.prn
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/${id}/labelzpl`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Gettng Zpl Label", err);
          reject(err);
        });
    });
  },
  getScreenshotFromHost(ip) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/screens/${ip}`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  create(item) {
    const query = item;
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating Item", err);
          reject(err);
        });
    });
  },
  addDownloadList(tincIp, list) {
    const query = list;
    return new Promise((resolve, reject) => {
      if (!Array.isArray(query)) {
        reject(new Error("downloads must be an array"));
        return;
      }

      if (query.length === 0) {
        reject(new Error("list must be an element"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/devices/enqueue/${tincIp}`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating Item", err);
          reject(err);
        });
    });
  },
  getDownloadList(tincIp, query = {}) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/enqueue/${tincIp}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating Item", err);
          reject(err);
        });
    });
  },
  removeDownloadList(tincIp, query = []) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(query)) {
        reject(new Error("downloads must be an array"));
        return;
      }

      if (query.length === 0) {
        reject(new Error("list must be an element"));
        return;
      }
      const url = `${store.state.API_ROOT}/api/devices/enqueue/${tincIp}`;
      axios.delete(url, { data: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating Item", err);
          reject(err);
        });
    });
  },
  getChannels(tincIp, query = {}) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/devices/channels/${tincIp}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating Item", err);
          reject(err);
        });
    });
  },
  toggleSharing(deviceid, user) {
    // console.log("Toggling share", deviceid, user);
    const { id = null, sharing = true } = user; // Por default quitar

    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error("Invalid id"));
      }
      // Si esta compartido, quitar de compartir
      if (sharing) {
        this.removeSharedUser(deviceid, id)
          .then(res => resolve(res))
          .catch(err => reject(err));
        return;
      }

      this.addSharedUser(deviceid, id)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addSharedUser(deviceid, userid) {
    const url = `${store.state.API_ROOT}/api/devices/${deviceid}/share`;
    return new Promise((resolve, reject) => {
      const query = {
        id: userid
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("Resolving share", res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeSharedUser(deviceid, userid) {
    const url = `${store.state.API_ROOT}/api/devices/${deviceid}/share/${userid}`;
    return new Promise((resolve, reject) => {
      axios.delete( url, { headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
};

export default Api;
