<style scoped>
.bg-altgray {
  background-color: #35393e;
}
.font-huge-stats {
    font-size: 2rem !important;
    line-height: 1.2;
    font-weight: 200 !important;
}
</style>

<template>
  <div class="container-fluid px-0">
    <div class="row px-1">
      <div class="col-xl-2 col-md-6 col-12 ">
        <div class="box mx-auto box-body border bg-dark-info border-dark">
          <div class="flexbox">
            <peity
              :type="'pie'"
              :options="{ 'fill': ['#33cabb', '#ef5350'],
                          stroke: '#a97d10fa',
                          width: 60,
                          height:60,
                          max: 9,
                          strokewidth: 1,
                          innerRadius: 0,
                          radius: 40 }"
              :data="linkedPlotdata.toString()"
            />
            <span class="font-huge-stats">
              {{ linkedPlotdata[1] }}
            </span>
          </div>
          <div class="text-right">
            <span v-if="linkedPlotdata[1] !== 0">
              Sin asociar
            </span>
            <span v-else>
              Todo asociado
            </span>
            <Icon v-if="linkedPlotdata[1] == 0" icon="fluent-emoji-flat:partying-face" style="height: 1.5em; width: 1.5em" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">
              Registros Bancarios
            </h3>
            <div class="box-controls float-right d-print-none">
              <div class="box-header-actions">
              &nbsp;
              </div>
            </div>
          </div>
          <div class="box-body">
            <div class="row pt-3 pb-3">
              <div class="col">
                <div class="dropdown d-inline">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    {{ filterAccountName }}
                    <Icon v-if="!filterAccount" icon="vaadin:invoice" :inline="true" />
                  </button>
                  <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <a class="dropdown-item" href="#" @click.prevent="filterAccount = '062650163410076195'">Afirme MXN 6195</a>
                    <a class="dropdown-item" href="#" @click.prevent="filterAccount = '062650163410006149'">Afirme USD 6149</a>
                    <a class="dropdown-item" href="#" @click.prevent="filterAccount = '376702480221008'">TC Amex</a>
                    <a class="dropdown-item" href="#" @click.prevent="filterAccount = '030650900037432116'">BanBajío MXN 2116</a>
                    <!-- <a class="dropdown-item" href="#">Actinver MXN</a>
                    <a class="dropdown-item" href="#">Actinver USD</a>
                    <a class="dropdown-item" href="#">Efectivo</a> -->
                  </div>
                </div>
                <div class="dropdown d-inline">
                  <!--
                Es Ingreso o Egreso si es >=$0 (ingreso) o si es negativo (egreso)
                -->
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                    <Icon v-if="filterTipo!=='ingreso'" icon="fluent:arrow-step-out-20-filled" :inline="true" class="text-primary" />
                    <Icon v-if="filterTipo!=='egreso'" icon="fluent:arrow-step-in-20-filled" :inline="true" />
                    <span class="hidden-xs-down">
                      {{ filterTipo ? (filterTipo==='egreso' ? "Egresos" : "Ingresos") : "Todo" }}
                    </span>
                  </button>
                  <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <h6 class="dropdown-header">
                      Método:
                    </h6>
                    <a class="dropdown-item" href="#" @click.prevent="filterTipo = 'egreso'">Egresos</a>
                    <a class="dropdown-item" href="#" @click.prevent="filterTipo = 'ingreso'">Ingresos</a>
                    <a class="dropdown-item" href="#" @click.prevent="filterTipo = ''">Todo</a>
                  </div>
                </div>
                <YearMonthDropdown
                  class="d-sm-inline"
                  :year="filterDateYear"
                  :month="filterDateMonth"
                  :applied="filterDateApply"
                  :fullyear="filterDateFullYear"
                  :two-months-checked="filterTwoMonth"
                  @dateChanged="onDateFilterChanged"
                  @fullYearClicked="onDateFilterFullYear"
                  @clearRequested="onDateFilterClear"
                  @twoMonthClicked="onTwoMonthFilter"
                />
                <button
                  type="button"
                  class="btn btn-sm btn-default d-none d-sm-block float-right"
                  @click.prevent="refreshBanksTable()"
                >
                  <Icon icon="ic:outline-refresh" />
                </button>
                <SearchBar
                  :value="search"
                  @search-changed="onSearchChanged"
                />
                <Toggle class="btn-xs btn-default mx-1 mt-2 float-right" :value="!notTrash" @click="onFilterStatus" />
                <small class="mt-2 float-right" :class="{'text-primary':!notTrash}"> Ver basura </small>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <BanksTable
                  ref="banksTable"
                  :apiurl="`${apiurl}?${params}`"
                  :limit-items="500"
                  :tipo="filterTipo"
                  @rowClicked="showSingleBankline"
                  @statsChanged="onStatisticsChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <div class="row">
          <div class="col">
            <BanksSingle
              v-if="current"
              :bankline="current"
              class="bg-altgray"
              @bankUpdated="onSingleBanklineUpdated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Icon } from "@iconify/vue2";
import Peity from "vue-peity";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import Toggle from "@/components/Toggle.vue";
import BanksTable from "./BanksTable.vue";
import BanksSingle from "./BanksSingle.vue";

import ServiceApi from "./Banks.service";

export default {
  name: "BanksTablePanel",
  components: {
    Peity,
    Toggle,
    YearMonthDropdown,
    SearchBar,
    BanksTable,
    BanksSingle,
    Icon,
  },
  data() {
    return {
      currHash: null,
      apiurl: "/banks",
      current: null,
      search: null,
      filterDateApply: true,
      filterDateFullYear: false,
      filterTwoMonth: false,
      filterDateYear: 2023,
      filterDatePrevYear: 2023,
      filterDateMonth: 2,
      filterDatePrevMonth: 1,
      filterTipo: "egreso",
      filterStatus: "!trash",
      filterAccount: "062650163410076195",
      linkedPlotdata: [0, 0]
    };
  },
  computed: {
    params() {
      const {
        search = null,
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
        filterDateYear = null,
        filterDatePrevYear = null,
        filterDateMonth = null,
        filterDatePrevMonth = null,
        filterTipo = null,
        filterStatus = null,
        filterAccount = null
      } = this;

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth =  filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        search,
        tipo: filterTipo.length > 0 ? filterTipo : null,
        year,
        month,
        toyear,
        tomonth,
        account: filterAccount.length > 0 ? filterAccount : null,
        status: filterStatus
      };
      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      // console.log("filterDateApply", filterDateApply);
      const p = new URLSearchParams(filtredParams).toString();
      return p;
    },
    notTrash() {
      return this.filterStatus === "!trash";
    },
    filterAccountName() {
      const { filterAccount = "" } = this;
      return ServiceApi.accountsAlias[filterAccount] ?? "";
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, to.params.id, this);
        const { hash: paramHash = "" } = to.params ?? {};
        if (!paramHash) {
          this.current = null; // hide single
          return;
        }
        this.currHash = paramHash;
        // console.log("Force fetch", to.params.id);
        this.fillInitial();
      },
      immediate: true
    }
  },
  methods: {
    fillInitial() {
      const { currHash: paramHash } = this;
      ServiceApi.getByHash(paramHash).then(res => {
        if (!res) {
          this.current = null;
          return;
        }
        this.current = res;
      });
    },
    refreshBanksTable() {
      this.$nextTick(() => {
        this.fillInitial();
        this.$refs.banksTable.refresh();
      });
    },
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = false;
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
      this.filterTwoMonth = false;
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterTwoMonth = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.filterDateYear = curryear;
      this.filterDateMonth = currmonth;
      this.filterDatePrevYear = prevyear;
      this.filterDatePrevMonth = prevmonth;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = true;
    },
    onSearchChanged(val) {
      this.search = val;
    },
    showSingleBankline(bankline) {
      const { hash = "" } = bankline ?? {};
      const { hash: paramHash = "" } = this.$route.params ?? {};
      if (hash && hash === paramHash) {
        console.log("current hash is same", hash, paramHash);
        return;
      }

      console.log("Clicked:", hash);
      this.$router.push({
        name: "BanksSingle",
        params: { hash }
      });
    },
    onSingleBanklineUpdated(bankline) {
      this.current = bankline;
      this.refreshBanksTable();
    },
    onClickedFilterTipo() {
      const { filterTipo = "" } = this;
      if (filterTipo === "egreso") {
        this.filterTipo = "ingreso";
        return;
      }
      this.filterTipo = "egreso";
    },
    onStatisticsChanged(stats) {
      const { assoc, total } = stats;
      this.linkedPlotdata = [assoc, total - assoc];
      console.log("Statistics:", stats);
    },
    onFilterStatus() {
      this.filterStatus = this.filterStatus === "!trash" ? "" : "!trash";
    }
  },
  beforeMount() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
    this.filterDateMonth = now.getMonth() + 1;
  },
};
</script>
