<style scoped>
.file-droparea {
  background-color: #754a744f;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea.active {
  background-color: #754a74ff;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea > * > .icon{
  font-size:200%;
  /* display: block;
  margin: auto; */
}
.file-preupload > .thumb {
  min-height: 80px;
  min-width: 80px;
  display:block;
  margin:auto;
  border: unset;
}
.file-preupload > .filename {
  font-weight: 500;
  font-size: 125%;
}
.btn:disabled {
  pointer-events: none;
}
</style>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-8">
      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">
            Carga Masiva
          </h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-12">
              <button
                v-if="bankrecords.length>0 && !uploadDone"
                class="btn btn-info btn-sm float-right ml-2"
                :class="{disabled: loading}"
                :disabled="loading"
                @click.prevent="saveMultiple"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ loading ? "Actualizando registros...":"Guardar registros" }}
              </button>

              <button
                v-if="bankrecords.length>0"
                class="btn btn-default btn-sm float-left"
                :disabled="loading"
                @click.prevent="clearList"
              >
                Limpiar lista
              </button>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12">
              <BanksMulticashTable
                :apimode="false"
                :items="bankrecords"
                :enable-tools="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                v-if="bankrecords.length>0 && !uploadDone"
                class="btn btn-info btn-sm float-right ml-2"
                :class="{disabled: loading}"
                :disabled="loading"
                @click.prevent="saveMultiple"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ loading ? "Actualizando registros...":"Guardar registros" }}
              </button>

              <button
                v-if="bankrecords.length>0"
                class="btn btn-default btn-sm float-left"
                :disabled="loading"
                @click.prevent="clearList"
              >
                Limpiar lista
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-4">
      <div class="row">
        <div class="col-12">
          <div class="box">
            <div class="box-header with-border">
              <h3 class="box-title">
                Afirme Multicash
              </h3>
            </div>
            <div class="box-body bg-dark">
              <div class="row">
                <div class="col-12">
                  <Droparea
                    ref="myTxtDropzone"
                    class="file-droparea m-3"
                    @fileDropped="onFileDropped"
                  >
                    <p class="text-center pt-3">
                      <!-- <Icon icon="mdi:cloud-upload-outline" class="icon" /> -->
                      <svg
                        width="2.2em" height="2.2em" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                      >
                        <path d="M13 19v-4h3l-4-5l-4 5h3v4z" fill="currentColor" />
                        <path d="M7 19h2v-2H7c-1.654 0-3-1.346-3-3c0-1.404 1.199-2.756 2.673-3.015l.581-.102l.192-.558C8.149 8.274 9.895 7 12 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-3v2h3c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888C18.507 7.67 15.56 5 12 5C9.244 5 6.85 6.611 5.757 9.15C3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5z" fill="currentColor" />
                      </svg>
                      <br>
                      Cargar AUZUG/UMSATZ
                    </p>
                  </Droparea>
                </div>
              </div>
            </div>
            <br>
            <div class="pl-4">
              <h3 class="box-title">
                American Express
              </h3>
            </div>
            <div class="row py-2" @paste="onPaste">
              <div class="col-12">
                <input
                  class="form-control text-info"
                  type="text"
                  value=""
                  placeholder="Pega celdas aquí (Excel Amex)"
                  @keypress.prevent
                >
              </div>
            </div>
            <div class="pl-4">
              <h3 class="box-title pt-2">
                BanBajío
              </h3>
            </div>
            <div class="row py-2" @paste="onPasteBanbanjio">
              <div class="col-12">
                <input
                  class="form-control text-info"
                  type="text"
                  value=""
                  placeholder="Pega celdas aquí (Excel BanBajío)"
                  @keypress.prevent
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Droparea from "@/components/Droparea.vue";
import BanksMulticashTable from "./BanksMulticashTable.vue";

import BanksApi from "./Banks.service";

export default {
  name: "RocketBancos",
  components: {
    Droparea,
    BanksMulticashTable
  },
  data() {
    return {
      bankrecords: [],
      loading: false,
      uploadDone: false,
      pasteText: ""
    };
  },
  computed: {
  },
  methods: {
    onFileDropped(f) {
      const { name = "desconocido" } = f ?? {};
      console.log("[FOLDERS] FILE DROP ", name, f);
      // this.isPreUploading = true;
      // this.fileupload.name = name;
      // this.fileupload.size = size;
      this.uploadDone = false;
      this.hotLoadTextFile(f)
        .then((res) => {
          console.log("LOAD OK", name, res.e.target.result);
          this.layoutFileRead(name, res?.e?.target?.result);
        })
        .catch(e => {
          console.log("FILE DROP PREUPLOAD FAILED ", e);
          // this.isPreUploading = false;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "Tipo de archivo no admitido (Cargue un .txt)",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    onPaste(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        // console.log("kind", item.kind);
        if (item.kind === "string") {
          const text = ev.clipboardData.getData("Text");
          // console.log("TEXT DETECTED", text);
          this.pasteText = text;
        }
      }

      // https://stackoverflow.com/questions/62898644/regex-splitting-on-newline-outside-of-quotes
      let lines = this.pasteText.match(/[^"\r\n]+(?:"[^"]*"[^"\r\n]+)*/g);
      const totalLines = lines.length;
      const linesV1 = lines.map(l => BanksApi.amex2Record(l))
        .filter(k => k && k.srcaccount.length > 0);

      const linesV2 = lines.map(l => BanksApi.amex2RecordV2(l))
        .filter(k => k && k.srcaccount.length > 0);

      lines = linesV2.length > 0 ? linesV2 : linesV1;

      this.loading = false;
      this.uploadDone = false;
      this.bankrecords = lines;
      console.log(this.bankrecords);
      if (this.bankrecords.length === 0) {
        this.showAlert({
          title: "No se han detectado registros.",
          icon: "error"
        });
        return;
      }

      const omit = Math.abs(totalLines - this.bankrecords.length);
      this.showAlert({
        title: `Se han pegado ${this.bankrecords.length} registros, ${omit} omitidos`,
        icon: "info"
      });
    },
    onPasteBanbanjio(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      let text = "";
      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        // console.log("kind", item.kind);
        if (item.kind === "string") {
          text = ev.clipboardData.getData("Text");
        }
      }

      console.log("BANBAJIO", text);
      // https://stackoverflow.com/questions/62898644/regex-splitting-on-newline-outside-of-quotes
      const lines = text.match(/[^"\r\n]+(?:"[^"]*"[^"\r\n]+)*/g);

      this.bankrecords = lines.map(l => BanksApi.banbajio2Record(l))
        .filter(k => k && k.srcaccount.length > 0);
      this.loading = false;
      this.uploadDone = false;

      if (this.bankrecords.length === 0) {
        this.showAlert({
          title: "No se han detectado registros.",
          icon: "error"
        });
        return;
      }

      const omit = Math.abs(lines.length - this.bankrecords.length);
      this.showAlert({
        title: `Se han pegado ${this.bankrecords.length} registros, ${omit} omitidos`,
        icon: "info"
      });
    },
    hotLoadTextFile(file) {
      this.file = file;
      console.log("[FOLDERS] Processing file", file.name, file.type);
      return new Promise((resolve, reject) => {
        if (!file) {
          console.warn("File is invalid!");
          reject(new Error("Invalid file"));
          return;
        }
        if (file.size > 1024 * 1024 * 20) {
          console.warn("[FOLDERS] File too big (>20MB):", file.name);
          // this.vm.$emit("file-load-error");
          reject(new Error("File too big"));
          return;
        }
        if (file.type !== "text/plain") {
          console.warn("[FOLDERS] Rejected file type:", file.name, file.type);
          // this.vm.$emit("file-rejected", file.type);
          reject(new Error("File type not accepted"));
          return;
        }

        const reader = new FileReader();
        // reader.onprogress = e => this.vm.$emit("file-load-progress", e);
        reader.onerror = e => {
          // this.vm.$emit("file-load-error", e);
          reject(e);
        };
        reader.onload = e => {
          // this.fileLoaded(e, file);
          resolve({ e, file });
        };
        reader.readAsText(file);
      });
    },
    layoutFileRead(filename, text) {
      const umsatzReg = /umsatz/i;
      const auszugReg = /auszug/i;
      if (filename.match(umsatzReg)) {
        this.readUmsatz(text);
        return;
      }
      if (filename.match(auszugReg)) {
        this.readAuzug(text);
        return;
      }
      this.$swal({
        position: "bottom-end",
        toast: true,
        title: "No se reconoció el archivo",
        text: "Solo acepta UMSATZ.txt o AUSZUG.txt",
        icon: "error",
        showConfirmButton: false,
        timer: 3000
      });
    },
    readUmsatz(text) {
      console.log("PROCESANDO UMSATZ");
      let lines = text.split(/[\r\n]+/g);
      lines = lines.filter(l => l.length > 6);
      let umsatz = lines.map(row => BanksApi.umsatzLine2Record(row));
      umsatz = umsatz.filter(l => !!l); // filter non null
      console.log("umsatz", umsatz);
      this.$set(this, "bankrecords", umsatz);

      if (this.bankrecords.length === 0) {
        this.showAlert({
          title: "No se han detectado registros.",
          icon: "error"
        });
        return;
      }

      this.showAlert({
        title: `Se han cargado ${this.bankrecords.length} registros`,
        icon: "info"
      });
    },
    readAuzug(text) {
      console.log("PROCESANDO AUZUG");
      // AUZUG simplemente sirve para:
      //  1. Validar que UMSATZ esté correcto (numero de transacciones y montos)
      //  2. Verificar que la cuenta sea propia
      //  3. Tener algunos datos generales como la moneda, saldos y fechas
      let lines = text.split(/[\r\n]+/g);
      lines = lines.filter(l => l.length > 6);
      if (lines.length < 1) {
        console.warn("Auzug incorrecto");
        return;
      }
      const [row] = lines;
      const fields = row.split(";");
      if (fields.length < 18) {
        console.warn(
          "UMSATZ ignorando línea, campos insuficientes: [",
          row,
          "]"
        );
      }
      const headers = {
        srcbankid: fields[0], // ID del banco
        srcaccount: fields[1], // CLABE
        statement: fields[2], // Estado de cuenta
        statementDate: fields[3], // Fecha del estado de cuenta
        currency: fields[4], // Clave de moneda

        balanceIn: fields[5], // Saldo inicial
        debits: fields[6], // Debitos (depositos)
        credits: fields[7], // Creditos (retiros)
        balanceOut: fields[8], // Saldo final

        owner: fields[9], // Dueño de la cuenta
        special: fields[9], // Nombre especial de la cuenta

        validSince: fields[11], // Nombre especial de la cuenta
        validUntil: fields[12], // Nombre especial de la cuenta
        // 13, 14, 15 y 16 sin uso oficial
        count: fields[17] // Total de registros
      };
      this.$set(this, "bankheaders", headers);
    },
    saveMultiple() {
      this.loading = true;
      console.log("Pre insert:", this.bankrecords);
      BanksApi.create(this.bankrecords)
        .then(res => {
          console.log("Inserted:", res);
          const { response = [] } = res ?? {};
          this.loading = false;
          this.uploadDone = true;
          const recordsRejected = response.filter(it => it.status === "rejected")
            .map(it => {
              const { reason = "" } = it;
              const [, uuidrejected = ""] = reason.split(" ");
              return uuidrejected;
            });
          console.log(recordsRejected);
          this.bankrecords = this.bankrecords.map(it => {
            const { hash } = it;
            // eslint-disable-next-line no-param-reassign
            it.error = "";
            if (recordsRejected.indexOf(hash) > -1) {
              // eslint-disable-next-line no-param-reassign
              it.error = `Huella rocket: ${hash} ya existe, no se sobreescribirá`;
            }
            return it;
          });
          this.$emit("recordsCreated");

          const recordsAdded = response.length - recordsRejected.length;
          let title = `${recordsAdded} registros creados`;
          if (recordsRejected.length > 0) {
            title += `, ${recordsRejected.length} rechazados`;
          }
          this.showAlert({
            title,
            icon: "success"
          });
        })
        .catch(err => {
          this.loading = false;
          const { response = {}, message = "Internal Error" } = err;
          const { data = {}, status = 400 } = response;
          const { error_string: errStr = message } = data;
          this.showAlert({
            title: "No se pudieron insertar registros",
            msg: `${errStr}, CODE: ${status}`,
            icon: "error"
          });
        });
    },
    clearList() {
      this.loading = false;
      this.uploadDone = false;
      this.bankrecords = [];
    },
    showAlert(msg) {
      const {
        title = "", msg:html = "", icon = "", timer = 4000, position = "bottom-end", toast = true
      } = msg ?? {};
      this.$swal({
        title,
        html,
        icon,
        timer,
        position,
        toast,
        showConfirmButton: false,
        timerProgressBar: true
      });
    }
  }
};
</script>
