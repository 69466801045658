<style scoped>
.bg-altgray {
  background-color: #35393e;
}
</style>

<template>
  <div class="row">
    <div class="col-12 pr-1">
      <div class="row">
        <div class="col-12">
          <img
            v-for="(img, index) in currentPlotsUrls" :key="index"
            :src="img"
            @error="plotFillBrokenImage"
          >
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8 pr-1">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            CFDI Resumen
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-12">
              <button
                type="button" class="btn btn-inline btn-default btn-sm"
                title="Emitidas o recibidas"
                @click.prevent="invertFilterEmisorReceptor"
              >
                <Icon v-if="filterRfcEmisor==myRfc" icon="fluent:arrow-step-out-20-filled" :inline="true" class="text-primary" />
                <Icon v-if="filterRfcReceptor==myRfc" icon="fluent:arrow-step-in-20-filled" :inline="true" class="" />
                {{ filterRfcEmisor==myRfc?'EM':'RE' }}
              </button>
              <div class="dropdown d-inline">
                <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  {{ filterMetodo }}
                  <Icon v-show="filterMetodo.length < 3" icon="vaadin:invoice" :inline="true" />
                </button>
                <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <h6 class="dropdown-header">
                    {{ filterMoneda }}
                  </h6>
                  <a class="dropdown-item" href="#" @click.prevent="setFilterMetodo('PUE')">PUE</a>
                  <a class="dropdown-item" href="#" @click.prevent="setFilterMetodo('PPD')">PPD</a>
                  <a class="dropdown-item" href="#" @click.prevent="setFilterMetodo('')">Todo</a>
                </div>
              </div>
              <div class="dropdown d-sm-inline">
                <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  <Icon icon="mdi:dollar" :inline="true" />
                  {{ filterMoneda }}
                </button>
                <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <a class="dropdown-item" href="#" @click.prevent="setFilterMoneda('MXN')">MXN</a>
                  <a class="dropdown-item" href="#" @click.prevent="setFilterMoneda('USD')">USD</a>
                </div>
              </div>
              <YearPicker
                class="d-sm-inline"
                :year="filterDateYear"
                @yearChanged="onDateFilterFullYear"
              />
              <button
                type="button" class="btn btn-sm btn-default d-sm-inline float-right"
                @click.prevent="refreshCfdiAnual()"
              >
                <Icon icon="ic:outline-refresh" />
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <CfdiAnualTable
                ref="cfdiAnualTable"
                :apiurl="apiurl"
                :year="filterDateYear"
                :tipo="'I'"
                :emisor="filterRfcEmisor"
                :receptor="filterRfcReceptor"
                :moneda="filterMoneda"
                :metodo="filterMetodo"
                @rowClicked="showSingleCfdiTax"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <CfdiTaxMonthSingle
        v-if="current"
        ref="CfdiTaxMonthSingle"
        class="bg-altgray"
        :month-data="current"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import YearPicker from "@/components/YearPicker.vue";
import CfdiAnualTable from "@/plugins/Cfdi/CfdiAnualTable.vue";

const CfdiTaxMonthSingle = () => import("./CfdiTaxMonthSingle.vue");
const plot404 = require("@/plugins/Devices/plotnotfound.png");

export default {
  components: {
    YearPicker,
    CfdiAnualTable,
    CfdiTaxMonthSingle,
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/cfdi"
    },
    myRfc: {
      type: String,
      default: "DNR160608127"
    }
  },
  data() {
    return {
      filterRfcEmisor: null,
      filterRfcReceptor: this.myRfc,
      filterDateYear: 2023,
      filterMetodo: "",
      filterMoneda: "MXN",
      filterTipo: "I",
      current: null,
      plots: [
        "cfdi_counters", "cfdi_ret"
      ]
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    currentPlotsUrls() {
      const urls = this.plots.map(it => {
        // Armar toda la URL aqui
        const full = `${this.API_ROOT}/api/cfdi/plots/${it}`;
        return full;
      });
      return urls;
    },
  },
  methods: {
    showSingleCfdiTax(row) {
      const { _id: id = "" } = row ?? {};
      console.log("yearmonth", id);
      this.current = row;
    },
    refreshCfdiAnual() {
      this.$nextTick(() => {
        this.$refs.cfdiAnualTable.refresh();
      });
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.refreshCfdiAnual();
    },
    setFilterTipo(tipo) {
      this.filterTipo = tipo;
      this.refreshCfdiAnual();
    },
    setFilterMetodo(metodo) {
      this.filterMetodo = metodo;
      this.refreshCfdiAnual();
    },
    setFilterMoneda(moneda) {
      this.filterMoneda = moneda;
      this.refreshCfdiAnual();
    },
    invertFilterEmisorReceptor() {
      console.log("Inverting", this.myRfc, this.filterRfcEmisor, this.filterRfcReceptor);
      if (this.filterRfcEmisor) {
        this.filterRfcReceptor = this.myRfc;
        this.filterRfcEmisor = null;
      }
      else {
        this.filterRfcEmisor = this.myRfc;
        this.filterRfcReceptor = null;
      }
      this.refreshCfdiAnual();
    },
    plotFillBrokenImage(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = plot404;
    },
  },
  mounted() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
  },
};
</script>
