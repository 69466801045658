<template>
  <div class="container mb-2">
    <!-- Create the tabs -->
    <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
      <li v-for="tab in tabs" :key="tab.id" class="nav-item" :class="{ 'active': tab.active }">
        <a :href="`#${tab.id}`" data-toggle="tab" class="">
          <Icon :icon="tab.icon" />
        </a>
      </li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <component
        :is="tab.component" v-for="tab in tabs" :id="tab.id" :key="tab.id"
        :class="['tab-pane', { active: tab.active }]"
      >
        <template>
          <h3 class="task-header mb-0">
            {{ tab.id }}
          </h3>
        </template>
      </component>
    </div>
  </div>
</template>

<style>
@import "/css/m.css";
@import "/css/noanimation.css";
</style>

<script>
import { Icon } from "@iconify/vue2";
import TasksPanel from "@/components/TasksPanel.vue";

export default {
  components: {
    TasksPanel,
    Icon,
  },
  data() {
    return {
      tabs: [
        {
          id: "tasksPanel",
          icon: "icons8:todo-list",
          active: true,
          component: "TasksPanel",
        },
        // {
        //   id: "control-sidebar-home-tab",
        //   icon: "mdi:graph-bar",
        //   active: false,
        //   component: "",
        // },
        // {
        //   id: "control-sidebar-settings-tab",
        //   icon: "vaadin:cog-o",
        //   active: false,
        //   component: "",
        // },
      ],
    };
  },
};
</script>
