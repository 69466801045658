import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);
const API_ROOT = process.env.VUE_APP_APIROOT;
const WS_ROOT  = process.env.VUE_APP_WSAPI;
// const WS_CHAT  = process.env.VUE_APP_WSCHAT;

const COMPANYRFC = process.env.VUE_APP_COMPANYRFC;
const COMPANYNAME = process.env.VUE_APP_COMPANYNAME;
const COMPANYBRAND = process.env.VUE_APP_COMPANYBRAND;
const FRONT_VERSION = process.env.VUE_APP_GITHASH;
const GOOG_CLIENTID  = process.env.VUE_APP_GOOGLECLIENTID;
const GOOG_CLIENTSECRET  = process.env.VUE_APP_GOOGLECLIENTSECRET;
const NOW = new Date();

export default new Vuex.Store({
  state: {
    routes: [],
    token: null,
    privileges: [],
    user: {},
    avatar: null,
    version: {
      front: FRONT_VERSION
    },
    API_ROOT,
    COMPANYRFC,
    COMPANYNAME,
    COMPANYBRAND,
    WS_ROOT,
    GOOG_CLIENTID,
    GOOG_CLIENTSECRET,
    socket: {
      message: null,
      msgqueue: [],
      ws: null,
      isConnected: false,
      reconnectError: false,
      raw: "",    // raw message (for non-json)
      t: null,    // No lo uses, es el timer interno de ping/pong
    },
    reconsParams: {
      search: ""
    },
    quotationsParams: {
      search: "",
      filterDateApply: false,
      filterDateFullYear: true,
      filterTwoMonth: false,
      filterDateYear: NOW.getFullYear(),
      filterDateMonth: NOW.getMonth() + 1,
      filterDatePrevYear: 2000,
      filterDatePrevMonth: 1,
      currentPage: 1
    },
    cfdisParams: {
      search: ""
    },
    banksParams: {
      search: ""
    },
    foldersParams: {
      search: ""
    },
    devicesParams: {
      search: ""
    }
  },
  mutations: {
    // Mutations con inicial Mayuscula
    SetToken(state, tok) {
      state.token = tok;
    },
    SetUser(state, user) {
      state.user = user;
    },
    SetPrivileges(state, priv) {
      state.privileges = priv;
    },
    SetAvatar(state, url) {
      state.avatar = url;
    },
    SetSockMessage(state, message) {
      state.ws_message = message;
    },
    SetSockBus(state, bus) {
      // console.log("[Store] Kitoy Socket EventBus", bus);
      state.ws_bus = bus;
    },
    SetRoutes(state, routes) {
      // console.log("Routes dynamic", routes);
      // state.routes = routes;
      Vue.set(state, "routes", [...routes, ...state.routes]);
    },
    SetReconsParams(state, params) {
      state.reconsParams = params;
    },
    SetQuotationsParams(state, params) {
      const { ...allparams } = params;
      state.quotationsParams = { ...state.quotationsParams, ...allparams };
    },
    SetCfdisParams(state, params) {
      state.cfdisParams = params;
    },
    SetBanksParams(state, params) {
      state.banksParams = params;
    },
    SetFolderParams(state, params) {
      state.foldersParams = params;
    },
    SetDeviceParams(state, params) {
      state.devicesParams = params;
    },
    SOCKET_ONOPEN(state, event)  {
      // Cuidado, a veces tarda en abrir, verificar primero que este abierto
      // antes de mandar cosas. Incluso la propiedad puede aparecer pero aun
      // no estar abierto.
      console.log("[WS] OPEN", event.currentTarget);
      state.socket.ws = event.currentTarget;
      // Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      // Vue.prototype.$socket.sendObj("message");
      console.log("[WS] OPEN", state.socket);
      state.socket.t = setInterval(() => {
        // console.log("PING");
        state.socket.ws.send(""); // Fake ping
      }, 60 * 1000);
    },
    SOCKET_ONCLOSE(state, event)  {
      console.log("[WS] CLOSE", event);
      state.socket.isConnected = false;
      console.log("[WS] Clearing fake ping interval");
      clearInterval(state.socket.t);
      state.socket.t = null;
    },
    SOCKET_ONERROR(state, event)  {
      console.log("[WS] ERROR", event);
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message)  {
      console.log("[WS] MESSAGE", message);
      state.socket.raw = message.data;
      try {
        state.socket.message = JSON.parse(message.data);
        const now = new Date();
        // unshift() method adds new elements to the beginning of an array
        state.socket.msgqueue.unshift({ ...state.socket.message, _read: false, _recv: now.toISOString() });
        state.socket.msgqueue.slice(-100);
        localStorage.setItem("rkt_msgqueue", JSON.stringify(state.socket.msgqueue));
      }
      catch (e) {
        state.socket.message = null;
        console.warn("[WS] ... non json detected", message);
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.log("[WS] RECONNECT ", count);
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      console.log("[WS] RECONNECT ERROR", state);
      state.socket.reconnectError = true;
    },
    InitStore(state) {
      const {
        reconsParams = {},
        quotationsParams = {},
        cfdisParams = {},
        banksParams = {},
        foldersParams = {},
        devicesParams = {}
      } = state;

      if (!localStorage.getItem("rocket_store")) {
        this.replaceState(Object.assign(state, {
          reconsParams,
          quotationsParams,
          cfdisParams,
          banksParams,
          foldersParams,
          devicesParams
        }));
        return;
      }

      try {
        const stObj = JSON.parse(localStorage.getItem("rocket_store"));
        const {
          reconsParams: reconsStore = reconsParams,
          quotationsParams: quotationsStore  = quotationsParams,
          cfdisParams: cfdisStore = cfdisParams,
          banksParams: banksStore = banksParams,
          foldersParams: foldersStore = foldersParams,
          devicesParams: devicesStore = devicesParams
        } = stObj ?? {};

        this.replaceState(Object.assign(state, {
          reconsParams: reconsStore,
          quotationsParams: quotationsStore,
          cfdisParams: cfdisStore,
          banksParams: banksStore,
          foldersParams: foldersStore,
          devicesParams: devicesStore
        }));
      }
      catch (e) {
        console.warn("InitReplacing store FAILED!", localStorage.getItem("rocket_store"));
      }
    }
  },
  actions: {
    RegisterRoute({ commit }, r) {
      // console.log("Register dynamic route", r);
      commit("SetRoutes", [r]);
    },
    sendMessage(context, message) {
      // const { $socket } = Vue.prototype;
      // console.log("Sending message ", message, this.state.ws);
      // console.log("Sending message>", message, this.state.socket);
      // this.state.socket.ws.sendObj(message);
    },
    InitSessionFromGoogle({ commit, state }, authRes) {
      const url = `${state.API_ROOT}/api/session`;
      console.log("Init Google Login" /* , url, authRes */);
      // Requests from a different domain cannot set cookie values for their own domain
      // unless withCredentials is set to true before making the request.

      // Google solo necesita validar el id_token en backend
      // eslint-disable-next-line camelcase
      const { id_token } = authRes;
      axios
        .post(url, {
          origin: "google",
          id_token
        },
        { withCredentials: true, credentials: "include" })
        .then((response) => {
          const { data } = response;
          console.log("InitRocketSession: Rocket Login ok", /* "response:", data */);
          // Websockets
          const vm = new Vue();
          vm.$connect();

          try {
            const msgq = JSON.parse(localStorage.getItem("rkt_msgqueue"));
            if (!Array.isArray(msgq)) {
              console.log("[STORE] Msg queue wrong format, not Array", msgq);
            }
            else {
              state.socket.msgqueue = msgq;
              console.log("[STORE] Msg queue recovered");
            }
          }
          catch (e) {
            console.log("[STORE] Msg queue not recovered");
          }

          localStorage.setItem("rkt_privileges", JSON.stringify(data.privileges));
          localStorage.setItem("rkt_user", JSON.stringify(data));
          Vue.$cookies.set("rkt_token", data.token);
          commit("SetUser", data);
          commit("SetPrivileges", data.privileges); // Esto es solo un shortcut
          commit("SetToken", data.token);
        })
        .catch(e => {
          console.log(e);
        });
    },
    Logout({ commit }) {
      // console.log("ACTION logout");
      localStorage.removeItem("rkt_token");
      localStorage.removeItem("rkt_user");
      localStorage.removeItem("rkt_privileges");
      commit("SetUser", null);       // ESTE TIENE TODO
      commit("SetPrivileges", null); // pero este se usa como shortcut
      commit("SetToken", null);
    },
  }
});
