import axios from "axios";
import store from "@/store";

const TasksApi = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true; // Si no se pone no se manda la `cookie-session`
  },

  find(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  create(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api${apiurl}`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating bank line", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/tasks/${id}`;
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Creating bank line", err);
          reject(err);
        });
    });
  },
  stats(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  toggleSharing(id, user) {
    // console.log("Toggling share", taskid, user);
    const { id: userid = null, sharing = true } = user; // Por default quitar

    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error("Invalid id"));
      }
      // Si esta compartido, quitar de compartir
      if (sharing) {
        this.removeSharedUser(id, userid)
          .then(res => resolve(res))
          .catch(err => reject(err));
        return;
      }

      this.addSharedUser(id, userid)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addSharedUser(id, userid) {
    const url = `${store.state.API_ROOT}/api/tasks/${id}/share`;
    return new Promise((resolve, reject) => {
      const query = {
        id: userid
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("Resolving share", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeSharedUser(id, userid) {
    const url = `${store.state.API_ROOT}/api/tasks/${id}/share/${userid}`;
    return new Promise((resolve, reject) => {
      axios.delete( url, { headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getApiSettingsByRoute(apiurl, route) {
    const { path = "/", params = {} } = route ?? {};
    const { id = "" } = params;
    const [, pathname = ""] = path.split("/");
    let currentApiTask = pathname.length < 1 ? apiurl : `/${pathname}/tasks`;
    if (pathname.length > 0 && id.length > 0) {
      currentApiTask = `/${pathname}/${id}/tasks`;
    }
    if (pathname === "profile") {
      currentApiTask = apiurl;
    }

    return {
      path,
      apiurl: currentApiTask,
      parentschema: TasksApi.models[pathname] ?? "",
      parentid: id
    };
  },
  models: {
    contacts: "Contacts",
    profile: "Users",
    logs: "RocketLog",
    devices: "Lfxdevices"
  }
};

export default TasksApi;
