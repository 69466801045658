<style scoped>
.file-droparea {
  background-color: #754a744f;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea.active {
  background-color: #754a74ff;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea > * > .icon{
  font-size:200%;
  /* display: block;
  margin: auto; */
}
.file-droparea.disabled {
  pointer-events: none;
}
.file-preupload > .thumb {
  min-height: 80px;
  min-width: 80px;
  display:block;
  margin:auto;
  border: unset;
}
.file-preupload > .filename {
  font-weight: 500;
  font-size: 125%;
}

.table-borderless, .table-borderless  > * , .vuetable-td-name{
  border-top: unset !important;
  border-bottom: unset;
}
[draggable] {
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}
.btn {
  display: inline-block;
  margin: 1rem 0 0;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.8rem 2rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  background: #47cedf;
  border: none;
  outline: none;
}
.btn:hover,
.btn:focus {
  background: #24bccf;
  cursor: pointer;
}
.btn:active {
  transform: translateY(2px);
}
.ui {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 95vh;
  background: #fff;
  overflow: hidden;
}
.is-invalid input{
  color: red;
  background-color: #dc354555;
  border-color: #dc3545 !important;
  border-width: 2px;
  text-decoration: line-through
}
.lookup-right::before {
  padding-top: 6px;
}
.btn:disabled {
  pointer-events: none;
}
a.disabled {
  pointer-events: none;
}
</style>
<template>
  <div class="row">
    <div class="col-lg-3 col-12 pr-1">
      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">
            <a href="#" @click.prevent="onClickFolder({})">
              <Icon icon="ic:sharp-cloud-circle" width="1.5em" height="1.5em" />
              /
            </a>
          </h3>

          <!-- <div class="box-tools">
            <button type="button" class="btn btn-box-tool" data-widget="collapse">
              <i class="fa fa-minus" />
            </button>
          </div> -->
        </div>
        <div class="box-body no-padding mailbox-nav">
          <ul class="nav nav-pills flex-column">
            <li v-for="it in tree" :key="it.path" class="nav-item">
              <a class="nav-link" :class="{ active: folder.startsWith(it.path) }" href="javascript:void(0)" @click.prevent="onClickFolder(it)">
                <Icon
                  :icon="specialFolderIcon(it)"
                  class="icon"
                  :class="specialFolderIconClass(it)"
                />
                {{ it.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-12 col-lg-9 pl-1">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            {{ folderName }} <Icon icon="ion:chevron-forward-sharp" :inline="true" />
          </h3>
        </div>
        <div class="box-body">
          <div class="row mx-0">
            <div class="ml-auto p-1">
              <form>
                <div class="col-asuto form-row align-items-center">
                  <SearchBar
                    :value="search.name"
                    @search-changed="onSearchChanged"
                  />
                  <YearMonthDropdown
                    class="d-sm-inline"
                    :year="filterDateYear"
                    :month="filterDateMonth"
                    :applied="filterDateApply"
                    :fullyear="filterDateFullYear"
                    :two-months-checked="filterTwoMonth"
                    @dateChanged="onDateFilterChanged"
                    @clearRequested="onDateFilterClear"
                    @fullYearClicked="onDateFilterFullYear"
                    @twoMonthClicked="onTwoMonthFilter"
                  />
                </div>
              </form>
            </div>
          </div>
          <FolderBrowser
            ref="foldersTable"
            :apiurl="`/api/folders?folder=${folder}&search=${search.name}&${params}`"
            :users="availableUsersToShare"
            :tree="tree"
            :rootfolder="folder"
            @file-clicked="showSingleFile"
            @file-fav="onFileFav"
            @file-download="downloadFile"
            @file-field-change="onFieldChanged"
            @file-sharing="onFileSharing"
            @file-delete="onFileDelete"
            @file-restore="onFileRestore"
            @file-move="onFileMove"
            @directory-clicked="subdirectoryClicked"
          />
          <div v-if="folder && folder!=='starred' && folder!=='trash'" class="box-body ml-auto col-sm-12 col-md-6">
            <Droparea class="file-droparea p-2" :class="{disabled: isSavingFile}" @fileDropped="onFileDropped">
              <p v-if="!isPreUploading" class="text-center pt-3">
                <Icon icon="mdi:cloud-upload-outline" class="icon" />
                <br>
                Cargar archivo a carpeta <strong>{{ folderName }} </strong>...
              </p>
              <div v-if="isPreUploading" class="file-preupload">
                <img class="thumb">
                <p class="filename text-center">
                  {{ fileupload.name }}
                  <br> {{ preUploadHumanSize }}
                </p>
              </div>
            </Droparea>
            <form v-if="isPreUploading" class="form-horizontal form-element">
              <!-- <h3>Subir archivo</h3> -->
              <div class="box-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="inputYear">Año <a href="#" :class="{disabled: isSavingFile}" @click.prevent="resetUploadDate()">( ahora )</a></label>
                    <select id="inputYear" v-model="fileupload.year" class="form-control" :disabled="isSavingFile">
                      <option v-for="(year, idx) in years" :key="idx" :value="year.toString()">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputMonth">
                      Mes
                    </label>
                    <select id="inputMonth" v-model="fileupload.month" :disabled="isSavingFile" class="form-control">
                      <option v-for="(month, idx) in months" :key="idx" :value="idx.toString()">
                        {{ month }}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="form-group col-md-6">
                    <label for="inputTags" class="text-fade">Etiquetas</label>
                    <input id="inputTags" type="text" class="form-control" disabled>
                  </div> -->
                </div>
                <div class="form-group">
                  <label class="control-label">Comentario para ubicar de qué trata... </label>
                  <textarea
                    v-model="fileupload.comment"
                    tabindex="1" placeholder="" rows="2" class="form-control" :disabled="isSavingFile"
                  ></textarea>
                </div>
              </div>
              <div class="box-footer">
                <button :disabled="isSavingFile" type="button" class="btn btn-sm btn-info" @click.prevent="cancelPreUpload()">
                  Cancelar
                </button>
                <button :disabled="isSavingFile || !fileupload.year || !Number.isInteger(parseInt(fileupload.month, 10))" type="button" class="btn btn-sm btn-info float-right" @click.prevent="saveFileInFolder">
                  <svg
                    v-if="isSavingFile" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" style="vertical-align: -0.125em;"
                    width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                  >
                    <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z" opacity=".5" fill="currentColor" />
                    <path d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z" fill="currentColor">
                      <animateTransform attributeName="transform" type="rotate" from="0 12 12" to="360 12 12" dur="1s" repeatCount="indefinite" />
                    </path>
                  </svg>
                  Cargar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import debounce from "lodash/debounce"; // no se puede lazy load
import { humanFileSize } from "@/services/Tools";
import Droparea from "@/components/Droparea.vue";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import UsersApi from "@/services/Users.service";
import FoldersApi from "./Folders.service";

const FolderBrowser = () => import("./FolderBrowser.vue");

export default {
  components: {
    Droparea,
    Icon,
    YearMonthDropdown,
    SearchBar,
    FolderBrowser
  },
  data() {
    return {
      folder: "",
      tree: [],
      search: {
        name: ""
      },
      preUploadFile: null,
      fileupload: {
        name: "",
        folderid: "",
        comment: "",
        originalname: "",
        contentType: "",
        bucket: null,
        key: null,
        tags: [],
        size: 0,
        year: "",
        month: ""
      },

      filterDateApply: false,
      filterDateFullYear: false,
      filterTwoMonth: false,
      filterDateYear: 2000,
      filterDatePrevYear: 2000,
      filterDateMonth: 1,
      filterDatePrevMonth: 1,

      isPreUploading: false,
      isSavingFile: false,
      availableUsersToShare: [],
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    };
  },
  computed: {
    ...mapState(["API_ROOT", "user", "privileges"]),
    params() {
      const {
        filterDateYear,
        filterDatePrevYear,
        filterDateMonth,
        filterDatePrevMonth,
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
      } = this;

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth =  filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        year,
        month,
        toyear,
        tomonth
      };

      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      const p = new URLSearchParams(filtredParams).toString();
      // console.log(p);
      return p;
    },
    preUploadHumanSize() {
      const { size = 0 } = this.fileupload ?? {};
      return humanFileSize(size);
    },
    years() {
      const year = (new Date()).getFullYear();
      return [year, year, year, year, year, year].map((y, idx) => y - idx);
    },
    folderName() {
      return this.getNameFolder(this.folder);
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to);
        const { id = "", subdir = "" } = to.params;
        if (!id) {
          this.folder = "";
          return;
        }

        let folder = id;
        if (subdir) {
          folder = `${id}/${subdir}`;
        }
        this.folder = folder;
      },
      immediate: true
    }
  },

  methods: {
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = false;
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterTwoMonth = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
      this.filterTwoMonth = false;
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.filterDateYear = curryear;
      this.filterDateMonth = currmonth;
      this.filterDatePrevYear = prevyear;
      this.filterDatePrevMonth = prevmonth;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = true;
    },
    specialFolderIconClass(folder) {
      const { path } = folder;
      // console.log("this.folder", folder);
      return {
        "text-yellow": path === "starred",
        "text-dark": path === "trash",
      };
    },
    specialFolderIcon(folder) {
      const { path = "" } = folder;
      const icons = {
        starred: "ic:sharp-star",
        trash: "bi:trash-fill",
        incoming: "mdi:tray-upload"
      };
      return icons[path] ?? "fa-solid:folder";
    },
    fillInitial() {
      FoldersApi.getTreeFolders().then(res => {
        console.log(res);
        const { rows = [] } = res;
        rows.push({
          path: "starred",
          name: "Destacados",
          description: "Archivos marcado como destacados",
          children: []
        });
        rows.push({
          path: "trash",
          name: "Eliminados",
          description: "Archivos eliminados",
          children: []
        });
        this.tree = rows;
      });
    },
    fillUserList() {
      // Rellenar los posibles usuarios para compartir
      UsersApi.getUserList().then(res => {
        const { total = 0, rows = [] } = res.data;
        if (total < 1) {
          console.warn("WARNING: No users on userlist or total mismatch, this is a security BUG! STOP NOW!");
          return;
        }
        if (rows.length !== total) {
          console.warn("WARNING: Total user count and rows total mismatch, this is a security BUG!");
        }
        const { _id: myid } = this.user;
        this.availableUsersToShare = rows
          .filter(it => it._id !== myid) // ignorarse a si mismo
          .filter(it => it.privileges.indexOf("ROCKET-ACTIVE") > -1)
          .map(it => {
            const { _id: id, email, name } = it;
            // console.log("SH", _id, currentUsers);
            return {
              id,
              email,
              name
            };
          });
        console.log("USERS", this.availableUsersToShare);
      });
    },
    onFileDropped(f) {
      const { name = "desconocido", size = 0 } = f ?? {};
      console.log("[FOLDERS] FILE DROP ", name, f);

      this.isPreUploading = true;
      this.fileupload.name = name;
      this.fileupload.size = size;

      FoldersApi.processFileUpload(f, this.user).catch(e => {
        console.log("FILE DROP PREUPLOAD FAILED ", e);
        const { message = "Invalid file" } = e ?? {};
        this.isPreUploading = false;
        this.$swal({
          toast: true,
          position: "bottom-end",
          icon: "error",
          title: "Archivo no admitido",
          text: message,
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
      });
    },
    onClickFolder(val) {
      const { path = "" } = val ?? {};
      if (!path) {
        this.$router.push({
          name: "Folders",
          replace: true
        });
        return;
      }
      this.$router.push({
        name: "FoldersSingle",
        params: { id: path },
        replace: true
      });
    },
    cancelPreUpload() {
      this.fileupload = {
        name: "",
        folderid: "",
        comment: "",
        originalname: "",
        contentType: "",
        bucket: null,
        key: null,
        size: 0,
        tags: [],
        year: "",
        month: ""
      };
      this.preUploadFile = null;
      this.isPreUploading = false;
      this.isSavingFile = false;
    },
    onFileFav(folderid, fav) {
      const query = {
        flag: fav === "info" ? "" : "info"
      };
      FoldersApi.updateById(folderid, query)
        // eslint-disable-next-line no-unused-vars
        .then(file => {
          // console.log("File:", file);
          this.$refs.foldersTable.refresh();
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("FILE UPDATE FAV FAILED ", errStr);

          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo marcar como favorito",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    downloadFile(folderid, fileid, view = "") {
      if (!folderid || !fileid) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "el identificador de archivo no es válido",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
        return;
      }
      FoldersApi.getSignedFile({ folderid, fileid, view })
        .then(signedFile => {
          console.log("Signed:", signedFile.data);
          const { url = "" } = signedFile.data ?? {};
          if (url) {
            window.open(url);
          }
        })
        .catch(e => {
          console.log("FILE SIGN FAILED ", e);
          const { message = "File sign failed, internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo firmar descarga de archivo",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    saveFileInFolder() {
      const { type = "" } = this.preUploadFile ?? {};
      if (type === "application/zip" && this.folder !== "incoming" && this.privileges.indexOf("FILES-ALLTYPES") === -1) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "Archivo no admitido",
          html: "Los archivos zip solo se aceptan en el folder <strong>Subida Preliminar</strong>",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
        return;
      }

      this.isSavingFile = true;
      let { year = null, month = null } = this.fileupload;
      year = parseInt(year, 10);
      month = parseInt(month, 10);
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      if (!Number.isNaN(year)) {
        date.setFullYear(year);
      }
      if (!Number.isNaN(month)) {
        date.setMonth(month, 1);
      }
      // console.log("DATE", year, month, date.toISOString());
      FoldersApi.savetoS3({ folder: this.folder, file: this.preUploadFile, date: date.toISOString() })
        .then(s3signature => {
          console.log("signed & upload", s3signature);
          const { fields = {}, folder = {} } = s3signature;
          const { bucket = "", key = "" } = fields;
          const { _id: folderid } = folder;
          this.fileupload.bucket = bucket;
          this.fileupload.key = key;
          this.fileupload.folderid = folderid;
          return FoldersApi.saveFileToFolder(this.fileupload);
        })
        .then(res => {
          console.log("SAVED:", res.data);
          this.preUploadFile = null;
          this.isPreUploading = false;
          this.fileupload = {
            name: "",
            folderid: "",
            comment: "",
            originalname: "",
            contentType: "",
            bucket: null,
            key: null,
            size: 0,
            tags: [],
            year: "",
            month: ""
          };
          this.isSavingFile = false;
          this.$refs.foldersTable.refresh();
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Archivo guardado",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("FILE SAVING UPLOAD FAILED ", e);
          // this.isPreUploading = false;
          this.isSavingFile = false;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo subir archivo",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    showSingleFile(item) {
      const { _id: folderid = null, file = {} } = item ?? {};
      const { _id: fileid = null } = file ?? {};
      if (!folderid || !fileid) {
        console.log("VIEW FILE FAILED EMPTY ID", folderid, fileid);
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "No se pudo mostrar archivo",
          text: `El identificador de archivo es inválido, folder: ${folderid}, file: ${fileid}`,
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
        return;
      }

      this.downloadFile(folderid, fileid, "direct");
    },
    onSearchChanged(val) {
      this.search.name = val;
    },
    // eslint-disable-next-line func-names
    onFieldChanged: debounce(function (id, field, val) {
      // https://stackoverflow.com/questions/45178621/how-to-correctly-use-vue-js-watch-with-lodash-debounce
      // 1. Preferir no hacer debouce en el watcher
      // 2. No usar funciones flecha (=>) porque se pierde 'this'
      console.log("onFieldChanged", id, field, val);

      const query = {};
      query[field] = val;
      FoldersApi.updateById(id, query)
        .then(file => {
          console.log("File:", file);
          this.$refs.foldersTable.refresh();
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("FILE UPDATE FAILED ", errStr);

          const fields = {
            filename: "nombre",
            comment: "comentario",
            date: "fecha (año o mes)"
          };
          const fl = fields[field] ?? "";
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: `No se pudo editar propiedad ${fl}`,
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    }, 600),
    onFileSharing(_id, user) {
      FoldersApi.toggleSharing(_id, user)
        .then(res => {
          console.log("Toggle sharing OK:", res);
          this.$refs.foldersTable.refresh();
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 500 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("FILE SHARING FAILED ", e);
          // this.isPreUploading = false;
          this.isSavingFile = false;
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo compartir archivo",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    onFileDelete(file) {
      const { _id: id = "", filename: name = "" } = file ?? {};
      // console.log(file, name);
      if (id) {
        this.$swal({
          icon: "warning",
          title: "Eliminar archivo",
          input: "text",
          inputLabel: "",
          inputValue: "",
          html: `Archivo: <strong>${name}</strong>.<br>Para confirmar escriba la palabra <strong>eliminar</strong>.`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          // eslint-disable-next-line consistent-return
          inputValidator: (value) => {
            if (!value) {
              return "Escriba: eliminar";
            }
            if (value.toLowerCase() !== "eliminar") {
              return `Palabra incorrecta: ${value}`;
            }
          }
        // eslint-disable-next-line consistent-return
        }).then((result) => {
          // console.log(result);
          if (result.isConfirmed) {
            // console.log("Eliminar:", id);
            FoldersApi.deleteById(id, { deleted: true })
              .then(res => {
                console.log("Eliminado:", res);
                this.$refs.foldersTable.refresh();
              })
              .catch(e => {
                const { message = "Internal server error", response = {} } = e;
                const { data: dataResponse = {}, status = 400 } = response;
                const { error_string: errStr = message } = dataResponse;
                console.log("FILE DELETE FAILED ", errStr);
                this.$swal({
                  position: "bottom-end",
                  toast: true,
                  icon: "error",
                  title: "No se pudo eliminar archivo",
                  text: `${errStr}, CODE: ${status}`,
                  showConfirmButton: false,
                  timer: 2500,
                  timerProgressBar: true
                });
              });
          }
        });
      }
    },
    onFileRestore(file) {
      const { _id: id = "", filename: name = "" } = file ?? {};
      // console.log(file, name);
      if (id) {
        this.$swal({
          icon: "info",
          title: "Restaurar archivo",
          html: `Archivo: <strong>${name}</strong>.<br>¿Quieres recuperar el archivo?`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "Cancelar",
        // eslint-disable-next-line consistent-return
        }).then((result) => {
          // console.log(result);
          if (result.isConfirmed) {
            FoldersApi.updateById(id, { deleted: false })
              .then(res => {
                console.log("Restaurado:", res);
                this.$refs.foldersTable.refresh();
              })
              .catch(e => {
                const { message = "Internal server error", response = {} } = e;
                const { data: dataResponse = {}, status = 400 } = response;
                const { error_string: errStr = message } = dataResponse;
                console.log("FILE DELETE FAILED ", errStr);
                this.$swal({
                  position: "bottom-end",
                  toast: true,
                  icon: "error",
                  title: "No se pudo restaurar archivo",
                  text: `${errStr}, CODE: ${status}`,
                  showConfirmButton: false,
                  timer: 2500,
                  timerProgressBar: true
                });
              });
          }
        });
      }
    },
    onFileMove(file) {
      const { _id: id = "", filename: name = "", folder = "" } = file ?? {};
      // console.log(file, name);
      const folders = this.tree.filter(it => (it.path !== "trash" && it.path !== "starred") )
        .reduce((prev, curr) => {
          const subdir = {};
          const { children = [], path = "", name: cname = "" } = curr ?? {};
          subdir[path] = cname;
          const ch = children.reduce((acc, c) => {
          // eslint-disable-next-line no-param-reassign
            acc[`${path}/${c.path}`] = `${cname} / ${c.name}`;
            return acc;
          }, subdir);
          // eslint-disable-next-line no-param-reassign
          prev[cname] = ch;
          return prev;
        }, {});
      // console.log(folders);
      if (id) {
        this.$swal({
          icon: "warning",
          title: "Mover archivo",
          input: "select",
          inputLabel: "",
          inputValue: folder,
          inputPlaceholder: "",
          inputOptions: folders,
          html: `Archivo: <strong>${name}</strong>.<br>Seleccione folder para mover archivo.`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
          // eslint-disable-next-line consistent-return
          inputValidator: (value) => {
            if (!value) {
              return "Seleccione un folder";
            }
          }
        // eslint-disable-next-line consistent-return
        }).then((result) => {
          // console.log(result);
          if (result.isConfirmed) {
            console.log("Mover:", id, result.value);
            FoldersApi.updateById(id, { folder: result.value })
              .then(res => {
                // console.log("Se movio:", res);
                this.$refs.foldersTable.refresh();
                this.$swal({
                  position: "bottom-end",
                  icon: "success",
                  title: "Mover a",
                  html: `<strong>${name}</strong> se movió a <strong>${this.getNameFolder(res.folder)}</strong>`,
                  showConfirmButton: false,
                  toast: true,
                  timer: 2500,
                  timerProgressBar: true
                });
              })
              .catch(e => {
                const { message = "Internal server error", response = {} } = e;
                const { data: dataResponse = {}, status = 400 } = response;
                const { error_string: errStr = message } = dataResponse;
                console.log("FILE MOVE FAILED", errStr);
                this.$swal({
                  position: "bottom-end",
                  toast: true,
                  icon: "error",
                  title: "No se pudo mover archivo",
                  text: `${errStr}, CODE: ${status}`,
                  showConfirmButton: false,
                  timer: 2500,
                  timerProgressBar: true
                });
              });
          }
        });
      }
    },
    resetUploadDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth();

      this.fileupload.year = year.toString();
      this.fileupload.month = month.toString();
    },
    subdirectoryClicked(directory) {
      const { path: subpath = "" } = directory ?? {};
      const paths = this.folder.split("/");
      const path = paths.shift();

      let name = "FoldersSingle";
      const params = {
        id: path
      };
      if (subpath) {
        name = "FoldersSingleSubdir";
        params.subdir = subpath;
      }
      this.$router.push({
        name,
        params,
        replace: true
      });
    },
    getNameFolder(path) {
      const dirs = path.split("/");
      const [rootdir = "", subdir = ""] = dirs ?? [];
      const [folder = {}] = this.tree.filter(f => f.path === rootdir);
      const { name = "", children = [] } = folder;
      if (rootdir && subdir) {
        const [subfolder = {}] = children.filter(f => f.path === subdir);
        const { name:subname = "" } = subfolder;
        return `${name} / ${subname}`;
      }
      return name;
    }
  },
  beforeDestroy() {
    // Hay que desconectar las señales para evitar duplicidad
    FoldersApi.vm.$off("file-ready");
  },
  beforeMount() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
    this.filterDateMonth = now.getMonth() + 1;
    this.filterDateFullYear = false;
  },
  mounted() {
    this.fillInitial();
    this.fillUserList();
    FoldersApi.vm.$on("file-ready", res => {
      const { file, b64 } = res;
      const { name = "Sin nombre.jpg", size = 0, type } = file;
      console.log("[] NONIMAGE:", name, type, size, b64.length);
      this.preUploadFile = file;
      this.fileupload = {
        name,
        folderid: "",
        comment: "",
        originalname: name,
        contentType: type,
        bucket: null,
        key: null,
        size,
        tags: [],
        year: this.fileupload.year,
        month: this.fileupload.month
      };
    });
  },
};
</script>
