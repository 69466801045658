import axios from "axios";
import filesize  from "filesize";
import Vue from "vue";
import store from "@/store";

export default {
  maxfilesize: 1024 * 1024,
  jpegThumbQuality: 85,
  acceptedImageTypes: [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/svg+xml" // .svg
  ],
  acceptedFileTypes: [
    "application/pkix-cert", // .cer de certificados SAT y PKI
    "application/vnd.apple.keynote", // tambien .key de certificados salen así (?)
    "text/csv",
    "text/plain", // .txt
    "text/xml", // .xml
    "application/xml", // .xml
    "application/pdf", // .pdf
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/json",
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/zip", // no queremos esto por ahora, son opac
    "application/illustrator", // .ai
    "image/vnd.adobe.photoshop", // .psd
    "application/pkix-cert", // .cer de certificados SAT y PKI
    "application/vnd.apple.keynote", // tambien .key de certificados salen así (wtf?)
    "audio/wav", // .wav
    "audio/mpeg", // .mp3
    "video/mp4" // .mp4
  ],
  vm: new Vue(),

  baseurl() {
    return `${store.state.API_ROOT}/api/folders`;
  },
  find(apiurl, query) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}${apiurl}`;
      axios.get(url, { params: query, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR free finding", err);
          reject(err);
        });
    });
  },
  getById(id) {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/folders/${id}`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching", err);
          reject(err);
        });
    });
  },
  getTreeFolders() {
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/folders/tree`;
      axios.get(url, { params: {}, withCredentials: true })
        .then((res) => {
          if (res.data.total < 1) {
          // console.log("Empty res", res.data.total);
            resolve(null);
            return;
          }
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR Searching base folders", err);
          reject(err);
        });
    });
  },
  updateById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/folders/${id}`;
      // console.log("updating", url);
      axios.post(url, query, { withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR updating ", err);
          reject(err);
        });
    });
  },
  deleteById(id, query) {
    // console.log("Try to update", id, query);
    return new Promise((resolve, reject) => {
      const url = `${store.state.API_ROOT}/api/folders/${id}`;
      console.log("deleteById", url, query);
      axios.delete(url, { data: query, headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR delete:", err);
          reject(err);
        });
    });
  },
  getSignedFile(info) {
    const { folderid = "", fileid = "", view = "" } = info ?? {};
    const url = `${store.state.API_ROOT}/api/folders/${folderid}/file/${fileid}?view=${view}`;
    return axios.get(url, { params: {}, withCredentials: true });
  },
  toggleSharing(folderid, user) {
    // console.log("Toggling share", folderid, user);
    const { id = null, sharing = true, email = "" } = user; // Por default quitar

    return new Promise((resolve, reject) => {
      if (!id) {
        reject(new Error("Invalid id"));
      }
      // Si esta compartido, quitar de compartir
      if (sharing) {
        this.removeSharedUser(folderid, id, email)
          .then(res => resolve(res))
          .catch(err => reject(err));
        return;
      }

      this.addSharedUser(folderid, id, email)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addSharedUser(id, userid, email) {
    const url = `${store.state.API_ROOT}/api/folders/${id}/share`;
    return new Promise((resolve, reject) => {
      const query = {
        id: userid,
        email
      };
      axios.post(url, query,  { withCredentials: true })
        .then((res) => {
          // console.log("Resolving share", res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  removeSharedUser(id, userid, email) {
    const url = `${store.state.API_ROOT}/api/folders/${id}/share/${userid}`;
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const query = { email };
      axios.delete( url, { data: query, headers: null, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  processFileUpload(file, user) {
    this.file = file;
    const { privileges = [] } = user ?? {};
    const maxFileSize = privileges.indexOf("FILES-BIGFILE") > -1 ? 314572800 : 31457280; // 300MB : 30MB
    console.log("[FOLDERS] Processing file", file.name, file.type);
    return new Promise((resolve, reject) => {
      if (!file) {
        console.warn("File is invalid!");
        reject(new Error("Invalid file"));
        return;
      }
      if (file.size < 50) {
        console.warn("[FOLDERS] File to short (<50 bytes):", file.name, filesize(file.size));
        this.vm.$emit("file-load-error");
        reject(new Error("Tamaño mínimo: 50 bytes"));
        return;
      }
      if (file.size > maxFileSize) {
        console.warn(`[FOLDERS] File too big ${maxFileSize === 31457280 ? "(>30MB)" : "(>300MB)"}:`, file.name, filesize(file.size));
        this.vm.$emit("file-load-error");
        reject(new Error(`Tamaño máximo: ${maxFileSize === 31457280 ? "30MB" : "300MB"}`));
        return;
      }
      if (this.acceptedImageTypes.indexOf(file.type) < 0
        && this.acceptedFileTypes.indexOf(file.type) < 0
        && privileges.indexOf("FILES-ALLTYPES") < 0) {
        console.warn("[FOLDERS] Rejected file type:", file.name, file.type);
        this.vm.$emit("file-load-error", file.type);
        reject(new Error("File type not accepted"));
        return;
      }

      const reader = new FileReader();
      reader.onprogress = e => this.vm.$emit("file-load-progress", e);
      reader.onerror = e => {
        this.vm.$emit("file-load-error", e);
        reject(e);
      };
      reader.onload = e => {
        // const dataUrl = e.target.result;
        // this.vm.$emit("file-loaded", dataUrl, file);
        this.fileLoaded(e, file);
        resolve(e, file);
      };
      reader.readAsDataURL(file);
    });
  },
  fileLoaded(e, file) {
    console.log("[FOLDERS] File ready:", e, file, file.type);
    // const dataUrl = e.target.result;
    // this.vm.$emit("file-loaded", dataUrl, file);
    this.vm.$emit("file-ready", { file, b64:e.target.result });
  },
  savetoS3(fileInfo) {
    // console.log("[FOLDER] Trying to save S3", file);
    const { folder = "", file = null, date = null } = fileInfo ?? {};
    return new Promise((resolve, reject) => {
      if (!folder) {
        reject(new Error(`No se pudo subir a servidor, directorio inválido: ${folder}`));
        return;
      }
      if (!file) {
        reject(new Error("No se pudo subir a servidor, archivo inválido"));
        return;
      }
      // 0. Antes de poder guardar se necesita la SignedUrl (desde backend)
      const { size = 0, name = "", type = "binary/octet-stream" } = file;
      const contents = file;
      // console.log("[FOLDER] Try to load to S3 file", userid, name);

      this.getSignedUrl({
        folder, name, size, type, date
      })
        .then(post => {
          const { bucket = "", key = "", folder: folderCreated = {} } = post;
          const { _id: folderid = "" } = folderCreated;
          console.log("[FOLDER] Signed post data:", post);
          console.log("[FOLDER] Signed post data:", bucket, key);
          // 1. Los campos del PresignedPost vienen en json pero se necesita en FormData
          const formData = new FormData();
          const keys = Object.keys(post.fields);
          keys.forEach((k) => {
            formData.append(k, post.fields[k]);
          });

          const { email, _id: myid } = store.state.user;
          formData.append("x-amz-meta-original-name", name);
          formData.append("x-amz-meta-email", email);
          formData.append("x-amz-meta-userid", myid);
          formData.append("x-amz-meta-parentid", folderid);
          formData.append("x-amz-meta-parent", "vfs");
          // 2. El 'file' debe ir al final forzosamente
          //    A. Si es un File (traido de un <input>) carga el archivo
          //       ver: https://developer.mozilla.org/en-US/docs/Web/API/File
          //    B. Si son datos, guardará los datos dentro del archivo
          formData.append("file", contents);

          // 3. Hacer el post con "Content-Type": "multipart/form-data"
          //    (es necesario si se usa s3.createPresignedPost)
          const axiosS3 = axios.create(); // NECESITAMOS una instancia nueva axios sin el header Bearer
          delete axiosS3.defaults.headers.Authorization;

          // este axios es una instancia desechable
          axiosS3
            .post(post.url, formData, {
              headers: { "Content-Type": "multipart/form-data" } // sin `withCredentials` porque va a Amazon
            })
            .then( () => {
              // console.log("[AdcastApi] Posted to S3!");
              // 4. AWS no regresa nada https://docs.aws.amazon.com/AmazonS3/latest/API/RESTObjectPOST.html
              //    Solo HTTP: 200, 201, o 204 (default)
              resolve(post);
              // OJO: Aun falta enviar al backend y asociar el S3 Object con el registro real
              //      o se quedará un archivo huérfano!
            })
            // eslint-disable-next-line no-unused-vars
            .catch(e => {
              const { message = "No se pudo subir a S3, verificar conexión" } = e ?? {};
              // console.log(e);
              reject(new Error(message));
            });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          // console.log("error", e.message);
          const { message = "No se pudo obtener link de firma para subida a servidor, verificar conexión" } = e ?? {};
          reject(new Error(message));
        });
    });
  },
  saveFileToFolder(fileInfo) {
    const {
      name = "", folderid = "", bucket = null, key = null, originalname = "", contentType = "", comment = "", size = 0
    } = fileInfo ?? {};
    console.log("[FOLDER] Confirming upload now to backend hope this works!", folderid, bucket, key, originalname, contentType, comment);

    const url = `${store.state.API_ROOT}/api/folders/s3confirm`;
    const query = {
      folderid,
      name,
      bucket,
      key,
      originalname,
      contentType,
      comment,
      size
    };
    return axios.post(url, query, { withCredentials: true });
  },
  getSignedUrl(info) {
    const {
      folder = "", name = "", size = 0, date = (new Date()).toISOString()
    } = info ?? {};

    let { type = "binary/octet-stream" } = info ?? {};
    if (type.length === 0) {
      type = "binary/octet-stream";
    }
    // console.log("[FILES] Getting S3 SignedUrl for post", name, size, type);
    const promise = new Promise((resolve, reject) => {
      if (!folder || !name || !size || !type) {
        // console.warn("[FOLDER] Ignoring, one is null: ", name, size, type);
        reject(new Error("Ignoring, missing fields, category, name, size, type"));
        return;
      }

      const query = {
        folder, name, size, type, date
      };
      const url = `${store.state.API_ROOT}/api/folders/s3sign`;
      // Internamente el servidor atará la subida al userid de la sesión
      axios
        .post(url, query, { withCredentials: true })
        .then(res => {
          // console.log("[FOLDER] getSignedUrl, OK");
          resolve(res.data);
        })
        .catch(e => {
          const { message = "No se pudo obtener link de firma para subida a servidor, verificar conexión", response = {} } = e;
          const { data: dataResponse = {} } = response;
          const { error_string: errStr = message } = dataResponse;
          // console.log("[FOLDER] getSignedUrl, error", e);
          reject(new Error(errStr));
        });
    });
    return promise;
  }
};
