<style scoped>
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>

<template>
  <ul class="dropdown-menu scale-up">
    <!-- <li class="header">
      5 sin leer
    </li> -->
    <li>
      <div>
        <ul class="menu inner-content-div" style="width: auto; height: 400px;    min-height: 400px;">
          <li v-for="(it, idx) in collapsedQueue" :key="idx">
            <!-- start message -->
            <a href="javascript:;" class="message-link">
              <!-- <div class="float-left"> -->
              <!-- <img src="https://picsum.photos/64" class="rounded-circle" alt="User Image"> -->
              <!-- </div> -->
              <div class="mail-content">
                <h4>
                  {{ it.title }}
                  <span v-show="it._multiple && it._multiple > 1 " class="badge badge-pill badge-default text-small">{{ it._multiple }}</span>
                  <small><i class="fa fa-clock-o"></i> {{ it.received }}</small>
                </h4>
                <span>{{ it.text }}</span>
              </div>
            </a>
          </li>
          <!-- end message -->
        </ul>
      </div>
    </li>
    <!-- <li class="footer">
                  <a href="#">See all e-Mails</a>
                </li> -->
  </ul>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import { mapState } from "vuex";
import {  parseISO, intlFormatDistance } from "date-fns";

import esmx from "date-fns/locale/es";

export default {
  components: {
  },
  props:{
    refresh: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      queue: [],
    };
  },
  computed:{
    ...mapState(["socket"]),
    collapsedQueue() {
      return this.collapseSimilar(this.queue);
    }
  },
  watch: {
    refresh: {
      immediate: false,
      handler(newVal, oldVal) {
        this.queue = this.recalculateQueueTimes(this.queue);
      }
    },
    "socket.msgqueue": {
      // requiere el mapState porque socket viene del $store
      // deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        // console.log("##### Watcher on MESSAGE QUEUE", oldVal, newVal, "D", intlFormatDistance);
        const { msgqueue: queue = [] } = this.socket;
        this.queue = this.recalculateQueueTimes(queue);
      }
    },
    "socket.message": {
      // requiere el mapState porque socket viene del $store
      // deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        // state => state.isConnected,
        // (oldVal, newVal) => {
        // console.log("##### Watcher on MESSAGE", oldVal, newVal);
        if (!newVal) {
          return;
        }
        this.onMessage(newVal);
      }
    }
  },
  methods: {
    collapseSimilar(model) {
      const collapsedModel = [];
      let objHelper = null;
      let id;
      let schema;
      let title;
      let text;
      let icon;
      let _read;
      let _multiple;

      model.forEach(obj => {
        // Inicializa para empezar a comparar
        if (!objHelper) {
          objHelper = { _multiple:1, ...obj };
          ({
            id = "", schema = "", title = "", text = "", icon = "", _read = false, _multiple = 1
          } = objHelper);
          return;
        }

        // Compara los propiedades para determinar que es igual
        const {
          id:idCurr = "", schema:schemaCurr = "", title:titleCurr = "", text:textCurr = "", icon:iconCurr = "", _read:_readCurr = false, _multiple:_multipleCurr = 1
        } = obj;
        if (id === idCurr && schema === schemaCurr && title === titleCurr && text === textCurr && icon === iconCurr) {
          // Acumula contador y bandera si son iguales
          _multiple += _multipleCurr;
          _read = _readCurr && _read;
        }
        else {
          // Cuando encuentra un diferente lo guarda en la nueva lista y toma el actual para comparar
          objHelper._multiple = _multiple;
          objHelper._read = _read;
          collapsedModel.push({ ...objHelper });
          objHelper = { _multiple:1, ...obj };
          ({
            id = "", schema = "", title = "", text = "", icon = "", _read = false, _multiple = 1
          } = objHelper);
        }
      });

      // Se guarda el ultimo que se quedo
      if (objHelper) {
        objHelper._multiple = _multiple;
        objHelper._read = _read;
        collapsedModel.push({ ...objHelper });
      }

      return collapsedModel;
    },
    recalculateQueueTimes(queue) {
      // console.log("recalculateQueueTimes");
      return queue.map(it => {
        const { _read:isRead = false, _recv: recv = null } = it;
        let timeago = "-";
        if (recv) {
          timeago = intlFormatDistance(parseISO(recv), Date.now());
        }
        return { ...it, isRead, received: timeago };
      });
    },
    onMessage(msg) {
      const { title, text } = msg;
      this.$swal({
        // icon: "info",
        title,
        text,
        timer: 2000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timerProgressBar: true
      });
    }
  },
  mounted() {
    const { msgqueue: queue = [] } = this.socket;
    this.queue = queue;
  }
};
</script>
