<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
/* tr { */
  /* display: block; */
  /* cursor: pointer; */
  /* background-color: blue; */
/* } */
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
  color: #fff;
  border: 1px solid #398bf7;
  background-color: #398bf7;
}
.table-tools {
  display: inline-block;
  padding-top: 5px;
}
a[disabled].pag-button {
  pointer-events: none;
}
</style>
<template>
  <div>
    <table class="table table-hover inshadow ">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <th></th>
          <th>
            <span class="float-right">Monto</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="rows.length < 1">
          <td colspan="2" class="text-center">
            Sin registros...
          </td>
        </tr>
        <tr v-for="it in rows" :key="it._id" @click="onItemClick(it,...arguments)">
          <td>
            <div class="row pl-3">
              <div class="col-12">
                <small>
                  <span class="text-primary" title="Contable">
                    {{ it.accountingdate_fmt }}
                  </span>
                  <span v-if="it.bankdate_fmt != it.accountingdate_fmt" class="text-default pl-2" title="En banco">
                    <Icon icon="clarity:piggy-bank-line" :inline="true" />
                    {{ it.bankdate_fmt }} en banco
                  </span>
                  <strong class="pl-3 text-purple" title="Huella Rocket">
                    <Icon icon="fa6-solid:fingerprint" :inline="true" class="mr-0" /> {{ it.hash }}
                  </strong>
                  <span v-if="it.dsttransid" class="text-muted d-block">
                    {{ it.dsttransid }}: {{ it.dsttransid_fmt }}
                  </span>
                  <span v-else class="text-muted d-block">
                    {{ it.bankname }}
                  </span>
                  <span v-if="it?.reference.length > 0" class="">
                    {{ it.reference }}
                    <br>
                  </span>
                  <span class="">
                    {{ it.fullnotes }}
                  </span>
                  <br>
                  <span class="text-danger">
                    {{ it.error }}
                  </span>
                </small>
              </div>
            </div>
          </td>
          <td>
            <span class="text-dark">$</span>
            <strong class="float-right text-nowrap">
              {{ it.amount_fmt }}
            </strong>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0 text-right">
            <Icon icon="mdi:sigma" :inline="true" />
            Total
          </td>
          <td class="pb-0 text-right">
            {{ sum }}
          </td>
        </tr>
      </tfoot>
    </table>
    <div v-if="rows.length > 0" class="row">
      <div class="col-12">
        <span class="float-right pb-3 px-2">Total: {{ rows.length }}</span>
      </div>
    </div>
    <div v-if="enableTools" class="table-tools" role="status" aria-live="polite">
      <div class="btn-group">
        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
          <a
            class="dropdown-item" href="#"
          >
            Copiar celdas
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import { Icon } from "@iconify/vue2";

import transactDict from "./transactions";
import ServiceApi from "./Banks.service";

export default {
  name: "BanksTable",
  components: {
    Icon
  },
  props: {
    apimode: {
      type: Boolean,
      default: true
    },
    apiurl: {
      type: String,
      default: "/banks"
    },
    limitItems: {
      type: Number,
      default: 200
    },
    enableTools: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      data: [],
      orderBy: "accountingdate",
      orderAsc: true,
      page: 1,
      search: ""
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    rows() {
      const { rows = [] } = this.data;
      return rows;
    },
    sum() {
      const { rows = [] } = this.data;
      let sum = rows.reduce((accum, it) => {
        let {
          amount = 0
        } = it;

        amount = parseFloat(amount) || 0;
        return accum + amount;
      }, 0);

      // Esto les pone comas a los numeros
      const moneyOpts = {
        symbol: "$", precision: 2, thousand: ",", decimal: "."
      };
      sum = formatMoney(sum, moneyOpts);
      return sum;
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val /* , old */ ) {
        if (!this.apimode) {
          return;
        }
        console.log("Reload API banks", val);
        this.orderBy = "accountingdate";
        this.orderAsc = true;
        this.fillInitial();
      }
    },
    items: {
      immediate: true,
      handler(val /* , old */ ) {
        if (this.apimode) {
          return;
        }
        console.log("Refilling from data", val);
        this.data = this.transformData({ rows: val, total: val.length });
      }
    }
  },
  methods: {
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      ServiceApi.find({
        per_page: this.limitItems,
        page: this.page,
        sort:`${this.orderBy}|${this.orderAsc ? "asc" : "desc"}`
      }).then(res => {
        if (!res) {
          console.log("Got empty");
          return;
        }

        console.log("Got", res);
        this.data = this.transformData(res);
        // this.$emit("statsChanged", { count, total: rows.length });
      });
    },
    refresh() {
      if (!this.apimode) {
        return;
      }
      this.fillInitial();
    },
    transformData(dataInfo) {
      let  { rows = [] } = dataInfo;
      const transactionList = Object.keys(transactDict); // para no hacerlo tantas veces
      rows = rows.map((it) => {
        const {
          bankdate = null, accountingdate = null, dsttransid, amount = 0
        } = it;

        let bankdate_fmt = "";
        let accountingdate_fmt = "";
        let dsttransid_fmt = "";
        if (accountingdate) {
          const ad = parseISO(accountingdate);
          accountingdate_fmt = format(ad, "d LLL, yy",  { locale: esmx } );
        }
        if (bankdate) {
          const bd = parseISO(bankdate);
          bankdate_fmt = format(bd, "d LLL, yy",  { locale: esmx } );
        }
        if (transactionList.indexOf(dsttransid)) {
          dsttransid_fmt = transactDict[dsttransid];
        }
        return {
          ...it,
          bankdate_fmt,
          accountingdate_fmt,
          dsttransid_fmt,
          amount_fmt: formatMoney(amount, { symbol: "$",  format: "%v" })
        };
      });
      return { ...dataInfo, rows };
    }
  }
};
</script>
