<style scoped>
.lookup-right::before {
  padding-top: 0px !important;
}
.lookup-sm::before {
  font-size: 0px;
  width: 29px;
}
</style>
<template>
  <div class="lookup lookup-sm lookup-right d-lg-block float-right">
    <input type="text" name="search" placeholder="Buscar" :value="search" @input="setSearchQuery($event.target.value)">
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "SearchBar",
  props: {
    value: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      search: this.value,
      currSearchVal: ""
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    setSearchQuery: debounce(function (val) {
      // https://stackoverflow.com/questions/45178621/how-to-correctly-use-vue-js-watch-with-lodash-debounce
      // 1. Preferir no hacer debouce en el watcher
      // 2. No usar funciones flecha (=>) porque se pierde 'this'
      // console.log("Search", val);

      if (val.length > 2 && val !== this.currSearchVal) {
        this.currSearchVal = val;
      }
      if (val.length < 3) {
        this.currSearchVal = "";
      }
      this.$emit("search-changed", this.currSearchVal);
    }, 600)
  },
  mounted() {
    // Nos aseguramos que asigne el valor de prop al inicio
    this.search = this.value;
  }
};
</script>
