<style scoped>
.box-slot {
  background-color: unset;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0rem;
}
.nomargins {
  margin-bottom: 0px;
}
.table {
  color: unset;
}
</style>
<style>
@media screen and (max-width: 767px) {
  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    white-space: unset;
  }
}
</style>
<template>
  <div class="dataTables_wrapper">
    <Vuetable
      ref="reconsTable"
      :api-url="`${API_ROOT}${apiurl}`"
      :css="css"
      :sort-order="sortDefault"
      :per-page="perPage"
      :fields="fields"
      :http-options="{withCredentials: true}"
      :row-class="onRowClass"
      :transform="transformData"
      track-by="recon_sequence"
      data-path="rows"
      class="table-hover"
      pagination-path=""
      @vuetable:pagination-data="onPaginationData"
      @vuetable:cell-clicked="onCellClicked"
    >
      <template slot="check" slot-scope="props">
        <div class="checkbox" @click="onCellCheck(props)">
          <input type="checkbox" :checked="selectedRows.indexOf(props.rowData._id) > -1 ">
          <label for="Checkbox_1"></label>
        </div>
      </template>
      <template slot="description" slot-scope="props">
        <div class="box nomargins box-slot col" @click="onCellClicked(props)">
          <div class="flexbox">
            <p class="nomargins">
              <span class="text-primary">
                <!-- <input type="checkbox" >
            <label for="Checkbox_1"></label> -->
                <strong>
                  C{{ props.rowData.recon_sequence }}
                </strong>
              </span>
              <span :class="[props.rowData.status==='closed'?'text-muted':' ']">
                {{ props.rowData.title }}
              </span>

              <Icon v-show="props.rowData.status==='info'" width="1.25em" height="1em" icon="ic:sharp-incomplete-circle" :inline="true" class="text-info" />
              <Icon v-show="props.rowData.status==='waiting'" width="1.25em" height="1em" icon="ic:sharp-hourglass-top" :inline="true" class="text-warning" />
              <Icon v-show="props.rowData.status==='warn'" width="1.25em" height="1em" icon="material-symbols:warning" :inline="true" class="text-danger" />
              <svg
                v-show="props.rowData.users.length>1" class="text-warning" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"
              >
                <path
                  d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                  fill="currentColor"
                />
              </svg>
            </p>
            <p class="nomargins text-nowrap" :class="{ 'text-danger': props.rowData.pend > 0.0, 'text-warning': props.rowData.pend < 0.0, 'text-muted': props.rowData.pend === 0.0 }">
              $ {{ (parseFloat(props.rowData.pend) || 0).toFixed(2) }}
            </p>
          </div>
          <!-- <div class="small flexbox d-none d-sm-block">
            <span v-if="props.rowData.tags && props.rowData.tags.length > 1" class="small d-none d-sm-inline badge badge-pill badge-default">
              {{props.rowData.tags[0]}}
            </span>
          </div> -->
          <span class="nomargins  ">
            <span v-if="(props.rowData.contact&&props.rowData.contact.name)" class="nomargins text-info small">
              <!-- <Icon icon="carbon:caret-right" /> -->
              {{ props.rowData.contact.name }} <span class="text-default"> ({{ props.rowData.contact.rfc }})</span>
            </span>
            <span v-else class="nomargins text-warning small">
              Sin contacto destino
            </span>
            <small class="float-right text-default">
              Inicial:
              <span class="text-default">
                {{ (parseFloat(props.rowData.amount) || 0).toFixed(2) }}
              </span>
            </small>
          </span>
          <!-- <small class="text-muted">
            {{props.rowData.accountingdate}}
          </small> -->

          <div v-if="props.rowData.rlogs && props.rowData.rlogs.length > 0 && props.rowData.status==='open' " class="d-none d-md-block text-muted small">
            <span v-if=" props.rowData.rlogs.length > 1" class="nomargins">
              {{ `• ${props.rowData.rlogs[1].comment.slice(0, 60)} ${props.rowData.rlogs[0].comment.length>80?"...":" "}` }}
            </span> <br>
            <span class="nomargins">
              {{ `• ${props.rowData.rlogs[0].comment.slice(0, 60)} ${props.rowData.rlogs[0].comment.length>120?"...":" "}` }}
            </span>
          </div>
        </div>
      </template>
      <template slot="tableHeader" slot-scope="">
        <!-- define your own table row -->
        <tr></tr>
      </template>
    </Vuetable>
    <VuetablePagination
      ref="tablePagination"
      @vuetable-pagination:change-page="onChangePage"
    />

    <div class="table-tools" role="status" aria-live="polite">
      <div class="btn-group">
        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown dropdown-menu" x-placement="bottom-start">
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="generateXls"
          >
            <Icon icon="file-icons:microsoft-excel" :inline="true" />
            Excel
          </a>
          <a
            class="dropdown-item"
            href="#"
            @click.prevent="copyTableToClipboard"
          >
            <Icon icon="fluent:clipboard-task-20-regular" :inline="true" />
            Copiar celdas
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";

import writeXlsxFile from "write-excel-file";

import Vuetable from "vuetable-2";
import VuetablePagination from "@/components/rocket/RktVuetablePagination.vue";

import DefaultTable from "@/components/rocket/CnfTableDefaults";

export default {
  components: {
    Vuetable,
    VuetablePagination,
    Icon
  },
  props: {
    perPage: {
      type: Number,
      default: 50
    },
    sortDefault: {
      type: Array,
      default() {
        return [];
      }
    },
    apiurl: {
      type: String,
      default: "/api/recons"
    }
  },
  data() {
    return {
      fields: [
        // {
        //   name: "recon_sequence",
        //   title: "",
        //   titleClass: "d-none",
        //   dataClass: "p text-primary",
        //   callback: DefaultTable.fmt.reconId
        // },
        {
          name: "check",
          titleClass: "center aligned"
        },
        {
          name: "description",
          titleClass: "d-none"
        }
      ],
      css: DefaultTable.css,
      cssPages: DefaultTable.cssPagination,
      sortOrder: [
        {
          field: "createdAt",
          direction: "asc"
        }
      ],
      currentData: {},
      selectedRows: [],
      selectedRowsData: {},
      curentsRecons: {}
    };
  },
  computed: {
    ...mapState(["API_ROOT"])
  },
  methods: {
    transformData(data) {
      this.curentsRecons = data;
      return this.curentsRecons;
    },
    copyTableToClipboard(ev) {
      ev.preventDefault();
      // Tab separated values sirve para pegar en Sheets o Excel
      const { rows = {} } = this.curentsRecons;
      const cells = rows.map((it) => {
        // console.log("uniendo");
        // Cnnn, title, contacto, monto, pagos, saldo
        const {
          recon_sequence: reconSequence = "0",
          title = "-",
          contact = {},
          amount = " ",
          paid = "-",
          pend = " "
        } = it;
        const { name = "Sin contacto destino", rfc = "" } = contact;
        const contacto = `${name} ${rfc}`;
        let merged = `C${reconSequence}\t${title}\t${contacto}\t${amount}`;
        merged += `\t${paid}\t${pend}`; // Agregar todos los campos y en el orden correcto
        return merged;
      })
        .reduce((accum = "", curr) => `${accum}\n${curr}`, "");
      // console.log(cells);
      navigator.clipboard.writeText(cells);
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Conciliaciones copiadas al portapapeles",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    },
    // eslint-disable-next-line no-unused-vars
    onRowClass(dataItem, index) {
      const { _id = null } = this.currentData;
      return _id === dataItem._id ? "bg-dark" : "cursor-pointer";
    },
    onPaginationData(paginationData) {
      this.$refs.tablePagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.reconsTable.changePage(page);
    },
    onCellClicked(props) {
      const { rowData: data, rowField: field, rowIndex: index } = props;
      // console.log("row-clicked", data, field.name, index);
      this.$emit("row-clicked", { index, field: field.name, data });
    },
    onCellCheck(props) {
      // eslint-disable-next-line no-unused-vars
      const { rowData: data, rowField: field, rowIndex: index } = props;
      const { _id: reconid = null } = data;
      // console.log("Check", reconid, index);
      if (this.selectedRows.indexOf(reconid) < 0) {
        this.selectedRows.push(reconid);
        this.selectedRowsData[reconid] = data;
        this.$emit("rows-checked", this.selectedRowsData);
        return;
      }
      this.selectedRows = this.selectedRows.filter(item => item !== reconid);
      delete this.selectedRowsData[reconid];
      this.$emit("rows-checked", this.selectedRowsData);
    },
    refresh() {
      Vue.nextTick(() => this.$refs.reconsTable.refresh());
    },
    generateXls() {
      console.log("Generating", this.$refs.reconsTable.tableData);
      const { tableData = [] } = this.$refs.reconsTable;
      const header = [
        {
          value: "ID",
          fontWeight: "bold",
          width: 10
        },
        {
          value: "Monto",
          fontWeight: "bold"
        },
        {
          value: "Pagado",
          fontWeight: "bold"
        },
        {
          value: "Saldo",
          fontWeight: "bold"
        },
        {
          value: "Titulo",
          fontWeight: "bold",
          width: 60
        },
        {
          value: "Abierta ",
          fontWeight: "bold"
        },
        {
          value: "Comentarios",
          fontWeight: "bold",
          width: 60
        }
      ];
      const cells = tableData.map(it => {
        console.log(2);
        const { rlogs = [] } = it;
        let comments = rlogs.map(k => `${k.email}: ${k.comment}`);
        comments = comments.join("\n");
        return [
          {
            value: parseInt(it.recon_sequence, 10),
            type: Number,
            format: "##0",
            alignVertical: "top"
          },
          {
            value: parseFloat(it.amount),
            type: Number,
            format: "#,##0.00",
            alignVertical: "top"
          },
          {
            value: parseFloat(it.paid),
            type: Number,
            format: "#,##0.00",
            alignVertical: "top"
          },
          {
            value: parseFloat(it.pend),
            type: Number,
            format: "#,##0.00",
            alignVertical: "top"
          },
          {
            value: it.title,
            type: String,
            alignVertical: "top"
          },
          {
            value: it.status === "open",
            type: Boolean,
            alignVertical: "top"
          },
          {
            value: comments,
            type: String,
            alignVertical: "top"
          }
        ];
      });
      const sheet = [header, ...cells];
      const columns = [
        { width: 6 },
        { }, {}, { },
        { width: 50 }, // in characters
        {},
        { width: 50 }
      ];

      console.log(sheet);
      writeXlsxFile(sheet, {
        columns,
        fileName: "file.xlsx"
      }).then(res => {
        console.log(res);
      });
    }
  },
  mounted() {}
};
</script>
