import axios from "axios";
import store from "@/store";

const Api = {
  baseurl: `${store.state.API_ROOT}/api`,
  find(apiurl, params) {
    return new Promise((resolve, reject) => {
      const url = `${Api.baseurl}${apiurl}`;
      axios.get(url, { params, withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log("ERROR finding", err);
          reject(err);
        });
    });
  },
  findByDates(dates, query) {
    return new Promise((resolve, reject) => {
      if (!Array.isArray(dates)) {
        reject(new Error("Missing array year and month"));
        return;
      }
      const urls = dates.map(d => `${Api.baseurl}/tarengo?startdate=${d}`);
      if (!urls) {
        reject(new Error("Empty array"));
        return;
      }
      axios.all(urls.map((url) => axios.get(url, { params: query, withCredentials: true })))
        .then((res) => {
          // console.log(res);
          const data = res.map((it) => {
            const { data: d = [] } = it;
            return d;
          });
          resolve(data);
        }).catch(err => {
          console.log("ERROR get CFDI sum", err);
          reject(err);
        });
    });
  }
};

export default Api;
