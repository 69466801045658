<style scoped>
.nemschedule{
  background-color: var(--gray-dark);
  padding: 0px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid black;
}
.nemtuner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.nemtuner .cell{
  color: var(--gray);
  flex-grow: 1;
  flex-basis: 0.1em;
  padding-top: 0.60em;
  text-align: center;
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  border-right: 1px solid #00000077;
}
.nemtuner .cell:last-child{
  border-right: none;
}
.nemrow{
  min-height: 3em;
  height: 4em;
  display: flex;
  color: var(--gray);
  border-bottom: 1px solid #00000077;
  padding-left: 0;
}
.nemrow.titles{
  background-color: #00000077;
  height: 3em;
}
.nemrow.months{
  background-color: #000000AA;
  border: unset;
  height: 3em;
}

.nemrow:last-child{
  border-bottom: unset;
}
.titlerow > div:hover{
  background-color: #222233;
}
.tunername{
  background-color:  #00000077;
  text-align: unset !important;
  overflow-x: auto;
  padding-left: 0.5em;
  flex-basis: 5em;
  flex-grow: 1;     /* do not grow   - initial value: 0 */
  flex-shrink: 0;
}
.datecell{
  flex-basis: 5em;
  flex-grow: 2;
  flex-shrink: 0;
  border-right: none !important;
  /* border: unset !important; */
}
.datecell:hover{
  background-color: unset !important;
}
.altbg{
  background-color: #273345e3;
}
.cell.datecell.altbg:hover {
  background-color: #273345e3 !important;
}
.cell:hover{
  background-color:  #222233;
}
.cell.selected{
  background-color:  #222233;
  border: 1px dotted var(--primary);
}
.cell.division {
  background-color: red;
  width: 5px;
  height: 100px;
  min-width: 1px;
  min-height: 100px;
  padding: 1px;
}
.cell.disabled {
  pointer-events: none;
  /* background-color:  #00000099; */
}
.bordermonth {
  border-left: 1px solid var(--gray);
}
a.disabled {
  pointer-events: none;
}
.btn:disabled {
  pointer-events: none;
}
</style>

<template>
  <div class="nemschedule">
    <div class="nemtuner">
      <div class="nemrow months">
        <div class="tunername cell">
          &nbsp;
        </div>
        <div
          v-for="(it, idx) in headerDays" :key="it.label" class="cell datecell text-capitalize"
          :class="{bordermonth: it.daymonth === 1, altbg: (it.month%2===1)}"
        >
          <a
            v-if="idx==0"
            href="#"
            class="float-left"
            :class="{disabled: disabled}"
            @click.prevent="slideMonths(-1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
          </a>
          {{ it.daymonth === 1 || idx === 0 ? it.month_fmt : "" }}
          <a
            v-if="idx==5"
            href="#"
            class="float-right"
            :class="{disabled: disabled}"
            @click.prevent="slideMonths(1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
          </a>
        </div>
      </div>
      <div ref="schedules" class="nemrow titles">
        <div class="tunername cell">
          &nbsp;
        </div>
        <div
          v-for="(it, idx) in headerDays"
          ref="cDays"
          :key="idx" class="cell text-capitalize"
          :class="{bordermonth: it.daymonth === 1}"
        >
          <a
            v-if="idx==0"
            href="#"
            class="float-left"
            :class="{disabled: disabled}"
            @click.prevent="slideDays(-1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
          </a>
          <small>{{ it.day_fmt }}</small>
          <a
            v-if="idx==5"
            href="#"
            class="float-right"
            :class="{disabled: disabled}"
            @click.prevent="slideDays(1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
          </a>
        </div>
      </div>
      <div v-for="(it, idx) in schedule" :key="idx" class="nemrow">
        <div class="tunername cell">
          {{ it.name }}
        </div>
        <div
          v-for="cday in it.days"
          :key="cday.daynum"
          class="cell"
          :class="{selected: cday.dateid === selectedDate, bordermonth: cday.daymonth === 1, disabled: disabled}"
          @click="onCellClick(cday)"
        >
          <span v-for="(p, index) in cday.programs" :key="index+1" class="d-block"> {{ `${p.hour} ${p.name}` }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  add, format, parseISO
} from "date-fns";
import { es } from "date-fns/locale";
// import { Icon } from "@iconify/vue2";

import ServiceApi from "./Tarengo.service";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      start: Date.now(),
      end: new Date(2023, 9, 9, 20, 0, 0, 0),
      selectedDate: null,
      selectedRow: -1,
      currentHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      currentDays: [0, 1, 2, 3, 4, 5],
      wdays: {
        1: "l",
        2: "m",
        3: "x",
        4: "j",
        5: "v",
        6: "s",
        7: "d"
      },
      rawData: []
    };
  },
  computed: {
    headerDays() { // Solo para header
      const startDate = new Date(this.start);
      startDate.setHours(0, 0, 0, 0);

      const days = this.currentDays.map((day) => {
        const delta = add(startDate.getTime(), { days: day });
        const daymonth = parseInt(format(delta, "d"), 10);
        const month = parseInt(format(delta, "M"), 10);
        const daynum = parseInt(format(delta, "D", { useAdditionalDayOfYearTokens: true }), 10);
        // console.log(daynum, h, delta);
        return {
          daynum,
          daymonth,
          month,
          datetime: delta,
          day_fmt: format(delta, "EEEE dd MMM", { locale: es }),
          month_fmt: format(delta, "MMMM yyyy", { locale: es }),
          // isLastDayOfMonth: isLastDayOfMonth(delta)
        };
      });
      return days;
    },
    schedule() {
      const startDate = new Date(this.start);
      startDate.setHours(0, 0, 0, 0);

      const info = this.currentHours.map((hr) => {
        const delta = add(startDate, { hours: hr });
        const name = format(delta, "HH:mm 'hrs'");

        const days = this.currentDays.map((day) => {
          const deltaday = add(delta, { days: day });
          const weekday = this.wdays[parseInt(format(deltaday, "ii"), 10)] ?? "";
          const hour = parseInt(format(deltaday, "H"), 10);
          const daynum = parseInt(format(deltaday, "D", { useAdditionalDayOfYearTokens: true }), 10);
          const daymonth = parseInt(format(deltaday, "d"), 10);
          const data = this.rawData[weekday] ?? [];
          const dateid = format(deltaday, "yyyyMMddHH", { locale: es });
          return {
            datetime: deltaday, hour, daynum, weekday, daymonth, programs: this.getScheduleByDate(data, weekday, deltaday), dateid
          };
        });

        return { name, days };
      });

      console.log("info", info);
      return info;
    }
  },
  methods: {
    initial() {
      const startDate = new Date(this.start);
      // vamos a pedir 5 fechas por separado
      const dates = this.currentDays.map(day => {
        const delta = add(startDate, { days: day });
        return delta.toISOString();
      });

      // ServiceApi.find("/tarengo", { startdate: new Date(2023, 9, 16, 14, 0, 0).toISOString() })
      ServiceApi.findByDates(dates, {})
        .then(res => {
          this.rawData = res.reduce((json, val) => {
            const { weekday = "", rows = [] } = val;
            // eslint-disable-next-line no-param-reassign
            json[weekday] = rows;
            return json;
          }, {});
          console.log("tarengo", this.rawData);
        })
        .catch(err => {
          const { response = {}, message = "Verificar conexión" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error: errStr = message } = dataResponse;
          console.log(err);

          this.$swal({
            position: "bottom-end",
            title: "",
            html: `<p class="mt-3 p-1"> <strong>${errStr}</strong>, CODE ${status}</p>`,
            icon: "error",
            toast: true,
            showConfirmButton: false,
            showCancelButton: false,
            timer: 4000
          });
        });
    },
    getScheduleByDate(data, wd, startdate) {
      // console.log("DAY", format(startdate, "ii"), wd);
      const hour1start = new Date(2000, 0, 1, startdate.getHours(), startdate.getMinutes());
      const hour1end = new Date(2000, 0, 1, startdate.getHours() + 1, startdate.getMinutes());
      const info = data.filter(it => {
        const { startTime = null, endTime = null, runDays = [] } = it;
        if (!startTime || !endTime) {
          return false;
        }
        const stDate = parseISO(startTime, { locale: es });
        const hour2start = new Date(2000, 0, 1, stDate.getHours(), stDate.getMinutes());

        const enDate = parseISO(endTime, { locale: es });
        const hour2end = new Date(2000, 0, 1, enDate.getHours(), enDate.getMinutes());
        if (hour2end.getHours() === 0 && hour2end.getMinutes() === 0) {
          hour2end.setHours(23, 59, 59, 999);
        }
        const maxStart = new Date(Math.max(hour1start, hour2start));
        const minEnd = new Date(Math.min(hour1end, hour2end));
        return runDays.indexOf(wd) > -1 && maxStart < minEnd;
      }).map(it => {
        const { name, startTime } = it;
        const dateParsed = parseISO(startTime, { locale: es });
        return { name, hour: format(dateParsed, "HH:mm", { locale: es }) };
      });
      // console.log(wd, hour1start, data, info);
      return info;
    },
    onCellClick(event) {
      // console.log(event);
      this.selectedDate = format(event.datetime, "yyyyMMddHH", { locale: es });
      this.$emit("segment-selected", { event });
    },
    slideDays(delta) {
      this.currentDays = this.currentDays.map((day) => {
        const fixed = day + delta;
        return fixed;
      });
      this.initial();
    },
    slideMonths(delta) {
      this.currentDays = [-1, 0, 1, 2, 3, 4];
      this.start = add(this.start, { months: delta });
      this.start.setDate(1);
      this.initial();
    }
  },
  mounted() {
    this.initial();
  }
};
</script>
