import { render, staticRenderFns } from "./BanksMulticashPanel.vue?vue&type=template&id=968b1b78&scoped=true"
import script from "./BanksMulticashPanel.vue?vue&type=script&lang=js"
export * from "./BanksMulticashPanel.vue?vue&type=script&lang=js"
import style0 from "./BanksMulticashPanel.vue?vue&type=style&index=0&id=968b1b78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968b1b78",
  null
  
)

export default component.exports