<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  /* display: block; */
  cursor: pointer;
  /* background-color: blue; */
}
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #67757c;
    border: 1px solid transparent;
    border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
    color: #fff;
    border: 1px solid #398bf7;
    background-color: #398bf7;
}
.table-tools {
    display: inline-block;
    padding-top: 5px;
}
</style>
<template>
  <div>
    <!-- {{ rows }} -->
    <table class="table table-hover inshadow ">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <th @click="toggleOrderbyName()">
            &nbsp;
          </th>
          <th class="text-nowrap text-right">
            Egresos
          </th>
          <th class="text-nowrap text-right">
            Ingresos
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(it,idx) in rows" :key="idx" @click="onItemClick(it,...arguments)">
          <td class="text-nowrap pl-2" style="width: 1%;">
            <p class="pl-2 my-0">
              <span v-if="it.status!=='trash'" class="">  {{ it.humanDate }} </span>
              <span class="text-muted">(x{{ it.egreso_count }}) (x{{ it.ingreso_count }})</span>
            </p>
          </td>
          <td class="text-nowrap text-right" style="width: 1%;">
            {{ stringNumberAbs(it.egreso_amount) }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ stringNumberAbs(it.ingreso_amount) }}
          </td>
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0">
            &nbsp;
            &nbsp;
            Anuales
            <Icon icon="mdi:sigma" :inline="true" />
          </td>
          <td class="pb-0 text-right">
            Egresos
          </td>
          <td class="pb-0 text-right">
            Ingresos
          </td>
        </tr>
        <tr>
          <td>
            <span class="text-muted mx-2">
              (x{{ sums.egreso_count }}) (x{{ sums.ingreso_count }})
            </span>
          </td>
          <td class="text-right">
            {{ sums.egreso_amount }}
          </td>
          <td class="text-right">
            {{ sums.ingreso_amount }}
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="paginate"></div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { format, parse } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Banks.service";

export default {
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/banks"
    },
    year: {
      type: Number,
      default: 2023
    }
  },
  data() {
    return {
      data: [],
      orderBy: "accountingdate",
      orderAsc: false,
      months: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
    };
  },
  computed: {
    isOpen() {
      const { status = "open" } = this.item;
      return status === "open";
    },
    rows() {
      const { rows = [] } = this.data;
      return rows;
    },
    shownpages() {
      const {
        per_page = 0, last_page = 0, current_page = 0, total = 0
      } = this.data;

      if (total <= per_page) {
        return [1];
      }
      const pages = [];
      let counter = 0;
      for (let p = current_page - 3; p < last_page; ++p) {
        if (p > 0 ) {
          pages.push(p);
          counter++;
        }
        if (counter > 5) {
          break;
        }
      }
      return pages;
    },
    sums() {
      const { rows = [] } = this.data;
      const sums = rows.filter(it => it.status !== "trash")
        .reduce((accum, it) => {
          let {
            egreso_amount = 0,
            egreso_count = 0,
            ingreso_amount = 0,
            ingreso_count = 0
          } = it;

          egreso_amount = parseFloat(egreso_amount) || 0;
          egreso_count = parseInt(egreso_count, 10) || 0;
          ingreso_amount = parseFloat(ingreso_amount) || 0;
          ingreso_count = parseInt(ingreso_count, 10) || 0;

          return {
            egreso_amount: accum.egreso_amount + egreso_amount,
            egreso_count: accum.egreso_count + egreso_count,
            ingreso_amount: accum.ingreso_amount + ingreso_amount,
            ingreso_count: accum.ingreso_count + ingreso_count
          };
        }, {
          egreso_amount: 0,
          egreso_count: 0,
          ingreso_amount: 0,
          ingreso_count: 0
        });
      // Esto les pone comas a los numeros
      const moneyOpts = {
        symbol: "", precision: 2, thousand: ",", decimal: "."
      };
      sums.egreso_amount = formatMoney(Math.abs(sums.egreso_amount), { ...moneyOpts, symbol: "$ " });
      sums.ingreso_amount = formatMoney(Math.abs(sums.ingreso_amount), moneyOpts);
      return sums;
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val /* , old */ ) {
        console.log("Reload cfdis", val);
        this.fillInitial();
      }
    }
  },
  methods: {
    toggleOrderbyName() {
      if (this.orderBy === "nombre_emisor") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "nombre_emisor";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyRfc() {
      if (this.orderBy === "rfc_emisor") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "rfc_emisor";
      this.orderAsc  = true;
      this.fillInitial();
    },
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    onNextPage() {
      console.log("Next page:");
    },
    onPrevPage() {
      console.log("Prev page");
    },
    onJumpToPage(i) {
      console.log("To page", i);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      // const { id:cid = null } = this.$route.params;
      // if (!cid) {
      //   console.log("Refusing to refill empty route");
      //   return;
      // }

      // const months = this.months.map((it, idx) => ({
      //   year: this.year,
      //   month: idx + 1
      // }));
      const months = this.months.map((it, idx) => ([{
        year: this.year,
        month: idx + 1,
        status: "!trash"
      },
      {
        year: this.year,
        month: idx + 1,
        status: "trash"
      }])).flat();
      // months = [{year:2023,month:1},{year:2023,month:2},{year:2023,month:3}...{year:N,month:1-12}]
      const jsonParams = {};
      ServiceApi.getSumMontly(months, jsonParams)
        .then(res => {
          if (!res) {
            console.log("Got empty");
            // this.item = {};
            return;
          }
          console.log("Got", res);
          const rows = res.map((it) => {
            const { _id: ym = "" } = it;
            const month = parseInt(ym.slice(-2), 10);
            const date = parse(ym, "yyyyMM", new Date(this.year, month - 1, 1), { locale: esmx });
            if (Number.isNaN(date)) {
              return it;
            }
            // eslint-disable-next-line no-param-reassign
            it.humanDate = format(date, "MMM, yyyy", { locale: esmx });
            return it;
          });
          this.data = { rows };
        });
    },
    refresh() {
      this.fillInitial();
    },
    stringNumberAbs(str) {
      const num = parseFloat(str);
      if (Number.isNaN(num)) {
        return 0;
      }
      return Math.abs(num);
    }
  }
};
</script>
