<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
/* tr { */
  /* display: block; */
  /* cursor: pointer; */
  /* background-color: blue; */
/* } */
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #67757c;
  border: 1px solid transparent;
  border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
  color: #fff;
  border: 1px solid #398bf7;
  background-color: #398bf7;
}
.table-tools {
  display: inline-block;
  padding-top: 5px;
}
a[disabled].pag-button {
  pointer-events: none;
}
.text-striked {
  text-decoration-line: line-through;
}
.icon-align {
  vertical-align: text-top;
}
</style>
<template>
  <div>
    <table class="table table-hover inshadow">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <!-- <th @click="toggleOrderbyName()">
            <Icon icon="material-symbols:frame-inspect-sharp" />
          </th> -->
          <th class="w-100">
            <span class="float-right">Subtotal</span>
          </th>
          <th class="text-nowrap mx-1 px-1" @click="toggleOrderBy('total_ieps')">
            IEPS
          </th>
          <th class="text-nowrap mx-1 px-1" @click="toggleOrderBy('iva_16')">
            IVA 16%
          </th>
          <th class="text-nowrap mx-1 px-1" @click="toggleOrderBy('total_retenidos')">
            <Icon icon="mdi:sigma" :inline="true" />Ret
          </th>
          <th class="text-nowrap mx-1 px-1" @click="toggleOrderBy('total_trasladados')">
            <Icon icon="mdi:sigma" :inline="true" />Trasl
          </th>
          <th class="mx-1 px-1" @click="toggleOrderBy('retenido_iva')">
            IVA Ret
          </th>
          <th class="mx-1 px-1" @click="toggleOrderBy('retenido_isr')">
            ISR Ret
          </th>
          <th class="text-nowrap mx-1 px-1" @click="toggleOrderBy('ish')">
            ISH
          </th>
          <th @click="toggleOrderBy('total')">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="it in rows" :key="it._id"
          :class="{'border-left border-danger': 1==2}"
          @click="onItemClick(it,...arguments)"
        >
          <td :class="it.estado_sat_fmtclassBorder" style="width: 30%;">
            <div class="row pl-3">
              <div class="col-12 text-nowrap">
                <small>
                  <span class="text-primary">
                    {{ it.fecha_emision_fmt }}
                  </span>
                  <span class="float-right" :class="{'text-striked': it.estado_sat=='C'}">
                    $ {{ it.subtotal }}
                  </span>
                  <br>
                  <span class="text-muted" :class="{'text-striked': it.estado_sat=='C'}">
                    {{ it.uuid }}
                  </span>
                </small>
              </div>
              <div class="col-md-6 text-nowrap text-info">
                <small :class="{'text-striked text-danger': it.estado_sat=='C'}">
                  {{ it.rfc_emisor }}
                  <Icon icon="material-symbols:arrow-right" />
                  {{ it.rfc_receptor }}
                </small>
              </div>
              <div class="col-md-12">
                <small>
                  {{ it.rfc_emisor == myRfc? it.nombre_receptor: it.nombre_emisor }}
                  <p class="text-rigsht">
                    <a href="#" @click.stop.prevent="openPdf(it)">
                      <Icon icon="bi:file-pdf" :inline="true" /> PDF
                    </a>
                    <a href="#" @click.stop.prevent="downloadXml(it)">
                      <Icon icon="bi:filetype-xml" :inline="true" /> XML
                    </a>
                    <span
                      class="badge badge-sm badge-pill pt-1 ml-2"
                      :class="it.taxclass_fmtclass"
                    >
                      {{ (it.taxclass_fmt?.length >0) ? it.taxclass_fmt: it.taxclass }}
                    </span>
                    <span
                      class="badge badge-sm badge-pill pt-1 ml-2"
                      :class="it.estado_sat_fmtclass"
                    >
                      <span class="hidden-sm-up">
                        <Icon class="icon-align" :class="it.estado_sat_fmttextclass" :icon="it.estado_sat_icon" />
                      </span>
                      <span class="hidden-xs-down" :class="it.estado_sat_fmttextclass">
                        {{ (it.estado_sat_fmt?.length > 0) ? it.estado_sat_fmt: it.estado_sat }}
                      </span>
                    </span>
                    <span
                      v-if="it.estado_sat && it.estado_sat !== 'C'"
                      class="badge badge-sm badge-pill pt-1 ml-2"
                      :class="it.cancelable_fmtclass"
                    >
                      <span class="hidden-sm-up">
                        <Icon v-if="it.cancelable === 'N'" class="icon-align" :class="it.cancelable_fmttextclass" icon="mdi:file-lock" />
                        <Icon v-if="it.cancelable === 'A'" class="icon-align" :class="it.cancelable_fmttextclass" icon="mdi:file-lock-outline" />
                        <Icon v-if="it.cancelable === 'S'" class="icon-align" :class="it.cancelable_fmttextclass" icon="mdi:file-lock-open" />
                      </span>
                      <span class="hidden-xs-down" :class="it.cancelable_fmttextclass">
                        {{ (it.cancelable_fmt?.length > 0) ? it.cancelable_fmt: it.cancelable }}
                      </span>
                    </span>
                  </p>
                </small>
                <div class="mb-1">
                  <a
                    v-for="(recon, idx) in it.recons"
                    :key="idx"
                    href="#"
                    class="d-inline my-0"
                    @click.stop.prevent="redirectToRecon(recon.recon_sequence, ...arguments)"
                  >
                    <span class="badge badge-sm badge-pill badge-dark py-1">
                      <Icon icon="ph:arrows-in-simple-light" />
                      C{{ recon.recon_sequence }}
                    </span>
                    <small class="pl-1">{{ recon.title.substring(0, 50) }}</small>
                  </a>
                  <p v-if="it.recons.length < 1" class="text-danger my-0 px-1">
                    <Icon icon="material-symbols:warning-outline" :inline="true" class="text-danger" />
                    Sin asociar conciliación
                  </p>
                </div>
              </div>
            </div>
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            <small>
              {{ it.total_ieps }}
            </small>
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            <small>
              {{ it.iva_16 }}
            </small>
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ it.total_retenidos }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ it.total_trasladados }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ it.retenido_iva }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ it.retenido_isr }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" style="width: 1%;">
            {{ it.ish }}
          </td>
          <td class="text-nowrap text-right border-left border-dark" :class="{'text-striked': it.estado_sat=='C'}" style="width: 1%;">
            <strong class="pl-1 text-secondary"> {{ it.total }}</strong>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0 text-right">
            <Icon icon="mdi:sigma" :inline="true" />
            Subtot
          </td>
          <td class="pb-0 text-right">
            IEPS
          </td>
          <td class="pb-0 text-right">
            IVA 16%
          </td>
          <th class="text-nowrap">
            <Icon icon="mdi:sigma" :inline="true" />Ret
          </th>
          <th class="text-nowrap">
            <Icon icon="mdi:sigma" :inline="true" />Trasl
          </th>
          <td class="pb-0 text-right">
            IVA Ret
          </td>
          <td class="pb-0 text-right">
            ISR Ret
          </td>
          <td class="pb-0 text-right">
            ISH
          </td>
          <td class="pb-0 text-right">
            TOTAL
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <span v-if="sums.ignorados > 0" class="badge badge-sm badge-pill pt-1 ml-2 badge-warning text-black">
              Ignorados: {{ sums.ignorados }}
            </span>
            <span class="badge badge-sm badge-pill pt-1 mx-2 badge-dark text-primary">
              Vigentes: {{ sums.vigentes }}
            </span>
            {{ sums.subtotal }}
          </td>
          <td class="text-right">
            {{ sums.total_ieps }}
          </td>
          <td class="text-right">
            {{ sums.iva_16 }}
          </td>
          <td class="text-right">
            {{ sums.total_retenidos }}
          </td>
          <td class="text-right">
            {{ sums.total_trasladados }}
          </td>
          <td class="text-right">
            {{ sums.retenido_iva }}
          </td>
          <td class="text-right">
            {{ sums.retenido_isr }}
          </td>
          <td class="text-right">
            {{ sums.ish }}
          </td>
          <td class="text-right">
            {{ (sums.total ) }}
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="table-tools" role="status" aria-live="polite">
      <div class="btn-group">
        <button class="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
          <a
            class="dropdown-item" href="#"
            @click="copyTableToClipboard"
          >
            <Icon icon="fluent:clipboard-task-20-regular" :inline="true" />
            Copiar celdas
          </a>
        </div>
      </div>
    </div>
    <div class="paginate">
      Total: {{ data.total }}
      <a
        class="pag-button previous" aria-controls="pager" data-dt-idx="0"
        tabindex="0"
        :disabled="isFirstPage"
        @click.prevent="onPrevPage"
      >
        <Icon icon="ic:sharp-chevron-left" />
      </a>
      <span>
        <a
          v-for="(it) in shownpages" :key="it"
          class="pag-button" aria-controls="pager" :data-dt-idx="it" tabindex="0"
          :class="{current: it == data.current_page}"
          @click.prevent="onJumpToPage(it)"
        >
          {{ it }}
        </a>
      </span>
      <a
        class="pag-button next" aria-controls="pager" data-dt-idx="7"
        tabindex="0"
        :disabled="isLastPage"
        @click.prevent="onNextPage"
      >
        <Icon icon="ic:sharp-chevron-right" />
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Cfdi.service";

export default {
  name: "CfdiTable",
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/cfdi"
    },
    limitItems: {
      type: Number,
      default: 200
    },
    myRfc:{
      type: String,
      default: "DNR160608127"
    }
  },
  data() {
    return {
      data: [],
      orderBy: "fecha_emision",
      orderAsc: false,
      page: 1,
      estadosSat: {
        V: {
          text: "Vigente",
          classBadge: "badge-dark",
          classText: "text-primary",
          classBorder: "",
          icon: "icon-park-solid:check-one"
        },
        CP: {
          text: "Cancelación en Proceso",
          classBadge: "badge-dark",
          classText: "text-warning",
          classBorder: "border-left border-warning",
          icon: "mdi:file-clock"
        },
        C: {
          text: "Cancelado",
          classBadge: "badge-danger",
          classBorder: "border-left border-danger",
          icon: "iconamoon:sign-times-circle-fill"
        }
      },
      cancelableSat: {
        N: "No cancelable",
        A: "Cancelable con aceptación",
        S: "Cancelable sin aceptación"
      }
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    isOpen() {
      const { status = "open" } = this.item;
      return status === "open";
    },
    rows() {
      const { rows = [] } = this.data ?? {};
      return rows;
    },
    shownpages() {
      const {
        per_page = 0, last_page = 0, current_page = 0, total = 0
      } = this.data;

      if (total <= per_page) {
        return [1];
      }
      const pages = [];
      let counter = 0;
      for (let p = current_page - 3; p <= last_page; ++p) {
        if (p > 0 ) {
          pages.push(p);
          counter++;
        }
        if (counter >= 5) {
          break;
        }
      }
      return pages;
    },
    isLastPage() {
      const {
        last_page = 1, current_page = 1
      } = this.data;
      return current_page >= last_page;
    },
    isFirstPage() {
      const {
        current_page = 1
      } = this.data;
      return current_page <= 1;
    },
    sums() {
      const { rows = [] } = this.data;
      const rowsVig = rows.filter(it => {
        const { estado_sat = "" } = it;
        return estado_sat === "V";
      });
      const vigentes = rowsVig.length;
      const sums = rowsVig.reduce((accum, it) => {
        let {
          subtotal = 0,
          iva_16 = 0,
          retenido_iva = 0,
          retenido_isr = 0,
          ish = 0,
          total_ieps = 0,
          total_retenidos = 0,
          total_trasladados = 0,
          total_localretenido = 0,
          total_localtrasladado = 0,
          total
        } = it;
        // console.log("accum next", subtotal, iva_16, total);
        subtotal = parseFloat(subtotal) || 0;
        iva_16 = parseFloat(iva_16) || 0;
        retenido_iva = parseFloat(retenido_iva) || 0;
        retenido_isr = parseFloat(retenido_isr) || 0;
        ish = parseFloat(ish) || 0;
        total_ieps = parseFloat(total_ieps) || 0;
        total_retenidos = parseFloat(total_retenidos) || 0;
        total_trasladados = parseFloat(total_trasladados) || 0;
        total_localretenido = parseFloat(total_localretenido) || 0;
        total_localtrasladado = parseFloat(total_localtrasladado) || 0;
        total = parseFloat(total) || 0;

        return {
          subtotal: accum.subtotal + subtotal,
          iva_16: accum.iva_16 + iva_16,
          retenido_iva:        accum.retenido_iva + retenido_iva,
          retenido_isr:        accum.retenido_isr + retenido_isr,
          ish:                 accum.ish + ish,
          total_ieps:          accum.total_ieps + total_ieps,
          total_retenidos:     accum.total_retenidos + total_retenidos,
          total_trasladados:   accum.total_trasladados + total_trasladados,
          total_localretenido: accum.total_localretenido + total_localretenido,
          total_localtrasladado: accum.total_localtrasladado + total_localtrasladado,
          total: accum.total + total
        };
      }, {
        subtotal: 0,
        iva_16: 0,
        retenido_iva: 0,
        retenido_isr: 0,
        ish: 0,
        total_ieps: 0,
        total_retenidos: 0,
        total_trasladados: 0,
        total_localtrasladado: 0,
        total_localretenido: 0,
        total: 0,
      });
      // Esto les pone comas a los numeros
      const moneyOpts = {
        symbol: "", precision: 2, thousand: ",", decimal: "."
      };
      sums.subtotal = formatMoney(sums.subtotal, { ...moneyOpts, symbol: "$ " });
      sums.iva_16 = formatMoney(sums.iva_16, moneyOpts);
      sums.retenido_iva = formatMoney(sums.retenido_iva, moneyOpts);
      sums.retenido_isr = formatMoney(sums.retenido_isr, moneyOpts);
      sums.ish = formatMoney(sums.ish, moneyOpts);
      sums.total_ieps = formatMoney(sums.total_ieps, moneyOpts);
      sums.total_retenidos = formatMoney(sums.total_retenidos, moneyOpts);
      sums.total_trasladados = formatMoney(sums.total_trasladados, moneyOpts);
      sums.total_localretenido = formatMoney(sums.total_localretenido, moneyOpts);
      sums.total_localtrasladado = formatMoney(sums.total_localtrasladado, moneyOpts);
      sums.total = formatMoney(sums.total, moneyOpts);
      sums.vigentes = vigentes;
      sums.ignorados = rows.length - vigentes;
      return sums;
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val /* , old */ ) {
        console.log("Reload cfdis", val);
        this.orderBy = "fecha_emision";
        this.orderAsc = false;
        this.fillInitial();
      }
    }
  },
  methods: {
    copyTableToClipboard(ev) {
      ev.preventDefault();
      // Tab separated values sirve para pegar en Sheets o Excel
      const cells = ServiceApi.mergeTableIntoTSV(this.rows);
      // console.log("Copiado");
      // console.log(cells);
      navigator.clipboard.writeText(cells);
      this.$swal({
        position: "bottom-end",
        toast: true,
        icon: "success",
        title: "Celdas copiadas a portapapeles",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true
      });
    },
    toggleOrderBy(name) {
      if (this.orderBy === name) {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = name;
      this.orderAsc = false;
      this.fillInitial();
    },
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    onNextPage() {
      console.log("Next page:");
      const {
        last_page = 1
      } = this.data;
      this.page = Math.min(last_page, this.page + 1);
      this.refresh();
    },
    onPrevPage() {
      console.log("Prev page");
      this.page = Math.max(1, this.page - 1);
      this.refresh();
    },
    onJumpToPage(i) {
      console.log("To page", i);
      const {
        last_page = 1
      } = this.data;
      let page = Math.min(last_page, i);
      page = Math.max(1, i);
      this.page = page;
      this.refresh();
    },
    onFilterEmisor(rfc) {
      console.log("Filtro por emisor:", rfc);
      this.$emit("rfcEmisorClicked", rfc);
    },
    onFilterReceptor(rfc) {
      console.log("Filtro por receptor:", rfc);
      this.$emit("rfcReceptorClicked", rfc);
    },
    redirectToRecon(recSeq, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log("Redirect to C", recSeq);
      this.$router.push({
        name: "ReconsSingle",
        params: { id: recSeq }
      });
    },
    openPdf(elem) {
      const { uuid = "" } = elem ?? {};
      const url = `${this.API_ROOT}/api/cfdi/${uuid}/pdf`;
      window.open(url, uuid);
    },
    downloadXml(elem) {
      const { uuid: reqUuid = "" } = elem ?? {};
      if (!reqUuid) {
        this.showAlert({
          icon: "error",
          title: "No se pudo descargar el XML",
          msg: "CFDI request uuid empty, CODE: 400"
        });
        return;
      }

      ServiceApi.getByUuid(reqUuid)
        .then(res => {
          if (!res) {
            this.showAlert({
              icon: "error",
              title: "No se pudo descargar el XML",
              msg: "CFDI not found, CODE: 404"
            });
            return;
          }
          const {
            uuid = "", fecha_emision = new Date(), tipo = "", rfc_emisor = "", rfc_receptor = "", moneda = "", xml = ""
          } = res ?? {};

          const d = parseISO(fecha_emision);
          const fechaemision = format(d, "yyyy-MM-dd",  { locale: esmx } );
          const name = `${fechaemision}_${tipo}_${rfc_emisor}_${rfc_receptor}_${moneda}_${uuid}.xml`;

          const element = document.createElement("a");
          const blob = new Blob([xml], { type: "text/plain;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          element.setAttribute("href", url);
          element.setAttribute("download", name);
          element.click();
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string = message } = dataResponse;
          this.showAlert({
            icon: "error",
            title: "No se pudo descargar el XML",
            msg: `${error_string}, CODE: ${status}`,
          });
        });
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      //
      // OJO: En este modulo solo vemos "Ingresos" y segun la definición
      //      del SAT son facturas emitidas o recibidas, parece contradictorio
      //      pero es que "Egresos" se refiere a creditos y bonificaciones
      //
      ServiceApi.find(this.apiurl, {
        per_page: this.limitItems,
        page: this.page,
        tipo: "I", // OJO: En este modulo solo vemos "Ingresos" (facturas emitidas o recibidas)
        sort:`${this.orderBy}|${this.orderAsc ? "asc" : "desc"}`
      }).then(res => {
        if (!res) {
          console.log("Got empty");
          this.item = {};
          return;
        }
        console.log("Got", res);
        let  { rows = [] } = res;
        rows = rows.map((it) => {
          const {
            fecha_emision:fecha = null, taxclass = "", estado_sat = "", cancelable = ""
          } = it;
          if (!fecha) {
            return {
              ...it, fecha_emision_fmt: "", estado_sat, cancelable
            };
          }
          const d = parseISO(fecha);
          const fmt = format(d, "d LLL, yy",  { locale: esmx } );

          const { taxClasses = {} } = ServiceApi;
          let taxclass_fmt = "Sin clasificar";
          let taxclass_fmtclass = "badge-danger";
          if (Object.keys(taxClasses).indexOf(taxclass) > -1) {
            taxclass_fmt = taxClasses[taxclass];
            if (taxclass === "sinretencion") {
              taxclass_fmtclass = "badge-default";
            }
            else {
              taxclass_fmtclass = "badge-warning";
            }
          }

          const estado_sat_fmt = this.estadosSat[estado_sat]?.text ?? "Desconocido";
          const estado_sat_fmtclass = this.estadosSat[estado_sat]?.classBadge ?? "badge-danger";
          const estado_sat_fmttextclass = this.estadosSat[estado_sat]?.classText ?? "";
          const estado_sat_fmtclassBorder = this.estadosSat[estado_sat]?.classBorder ?? "border-left border-warning";
          const estado_sat_icon = this.estadosSat[estado_sat]?.icon ?? "";

          const cancelable_fmt = this.cancelableSat[cancelable] ?? "Desconocido";
          const cancelable_fmtclass = cancelable_fmt !== "Desconocido" ? "badge-dark" : "badge-danger";
          const cancelable_fmttextclass = cancelable_fmt !== "Desconocido" ? "text-primary" : "";

          return {
            ...it,
            fecha_emision_fmt: fmt,
            taxclass_fmt,
            taxclass_fmtclass,
            estado_sat,
            estado_sat_fmt,
            estado_sat_fmtclass,
            estado_sat_fmttextclass,
            estado_sat_fmtclassBorder,
            estado_sat_icon,
            cancelable_fmt,
            cancelable_fmtclass,
            cancelable_fmttextclass
          };
        });
        this.data = { ...res, rows };

        // Quien sabe si sea la mejor manera de sacar estadisticas
        // pero parece ser escalable a muchos tipos de conteos en el
        // mismo reduce
        const stats = rows.reduce((accum, currentValue) => {
          const { recons = [], taxclass = "" } = currentValue;
          // console.log("AC", recons);
          let { assoc, classified } = accum;

          if (recons.length > 0) {
            assoc += 1;
          }
          if (taxclass.length > 0) {
            classified += 1;
          }
          return {
            assoc, classified
          };
        }, { assoc: 0, classified: 0 });

        this.$emit("statsChanged", { ...stats, total: rows.length });
      });
    },
    refresh() {
      this.fillInitial();
    },
    showAlert(msg) {
      const {
        title = "", msg:html = "", icon = "", timer = 4000, position = "bottom-end"
      } = msg ?? {};
      this.$swal({
        title,
        html,
        icon,
        timer,
        position,
        showConfirmButton: false,
        showCancelButton: false,
        timerProgressBar: true,
        toast: true
      });
    }
  }
};
</script>
