// Éste modulo exporta los formatters y CSS por default
//
// import DefaulTable from '...../RocketTableCssDefault.mjs'
//
// DefaultTable.css
// DefaultTable.fmt
//
// css: DefaulTable.css,
//
import Autolinker from "autolinker";

export default {
  fmt:{
    date(value, fmt) {
      return value;
      /*
      if (value == null) return "";
      if (typeof fmt === "undefined") return moment(value).calendar();
      return moment(value, "YYYY-MM-DD").format(fmt);
      */
    },
    smallText(value) {
      return (`<small>${value}</small>` );
    },
    moneyText(value) {
      const val = parseFloat(value).toFixed(2);
      return (`<small>$ ${val}</small>` );
    },
    colorMoney(value, fmt) {
      const v = parseFloat(value).toFixed(2);
      if (value == null || value < 1.0 ) return `<span class="text-warning">$${v}</span>`;
      return `$${v}`;
    },
    smallTextAutolinks(value) {
      return (`<small>${Autolinker.link(value, { stripPrefix:true, truncate: { length: 32, location: "middle" } })}</small>` );
    },
  },
  css: {
    tableClass: "table table-hover",
    ascendingIcon: "++",
    descendingIcon: "--",
    handleIcon: "glyphicon glyphicon-menu-hamburger",
    keyword: "keyword"
  },
  cssPagination: {
    wrapperClass: "dataTables_paginate paging_simple_numbers float-right",
    activeClass: "current",
    disabledClass: "disabled",
    pageClass: "paginate_button",
    linkClass: "paginate_button",
    paginationClass: "pagination",
    paginationInfoClass: "float-left",
    dropdownClass: "form-control",
    icons: {
      first: "mdi mdi-chevron-double-left",
      prev: "mdi mdi-chevron-left",
      next: "mdi mdi-chevron-right",
      last: "mdi mdi-chevron-double-right",
    }
  },
};
