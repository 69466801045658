import Polls from "./Polls.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    const { router = null, store = null } = options;
    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path: "/polls",
      name: "Polls",
      component: Polls,
      meta: {
        title: "Encuestas",
        label: "Encuestas",
        icon: "healthicons:i-exam-multiple-choice-outline",
        // privileges: ["ROCKET-USER", "POLLS-USER"], // FIXME asignar permisos para polls
        privileges: ["ROCKET-USER"]
      },
      children: [
        {
          path: ":id", // /polls/:id
          name: "PollsSingle",
          component: Polls,
          meta: {
            title: "Encuestas",
            label: "Encuestas",
            icon: "healthicons:i-exam-multiple-choice-outline",
            // privileges: ["ROCKET-USER", "POLLS-USER"], // FIXME asignar permisos para polls
            privileges: ["ROCKET-USER"]
          }
        }
      ]
    };
    store.dispatch("RegisterRoute", { path: r.path, ...r.meta });
    router.addRoutes([r]);
  }
};
