<style scoped>

</style>

<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-9">
      <div class="box">
        <!-- <div class="box-header">
          <h3 class="box-title">
            &nbsp;
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              &nbsp;
            </div>
          </div>
        </div> -->
        <div class="box-body py-0 px-0">
          <div v-if="tarengoRows.length>0" class="row pb-3">
            <div class="col-12">
              <button
                v-if="!uploadDone"
                class="btn btn-info btn-sm float-right ml-2"
                :class="{disabled: loading}"
                :disabled="loading"
                @click.prevent="saveMultiple"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                </span>
                {{ loading ? "Actualizando registros...":"Guardar registros" }}
              </button>

              <button
                class="btn btn-default btn-sm float-left"
                :disabled="loading"
                @click.prevent="clearList"
              >
                Limpiar lista
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <TarengoUploadTable
                :items="tarengoRows"
                :enable-tools="false"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                v-if="tarengoRows.length>0 && !uploadDone"
                class="btn btn-info btn-sm float-right ml-2"
                :class="{disabled: loading}"
                :disabled="loading"
                @click.prevent="saveMultiple"
              >
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ loading ? "Actualizando registros...":"Guardar registros" }}
              </button>

              <button
                v-if="tarengoRows.length>0"
                class="btn btn-default btn-sm float-left"
                :disabled="loading"
                @click.prevent="clearList"
              >
                Limpiar lista
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="box">
        <!-- <div class="box-header">
          <h3 class="box-title">
            &nbsp;
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              &nbsp;
            </div>
          </div>
        </div> -->
        <div class="box-body py-0 px-0">
          <div class="row" @paste="onPaste">
            <div class="col-12">
              <input
                class="form-control text-info"
                type="text"
                value=""
                placeholder="Pega aquí"
                @keypress.prevent
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import TarengoUploadTable from "@/plugins/Tarengo/TarengoUploadTable.vue";

export default {
  components: {
    TarengoUploadTable
  },
  props: {
    apiurl: {
      type: String,
      default: "/tarengo"
    }
  },
  data() {
    return {
      rawPaste: "",
      loading: false,
      uploadDone: false,
      tarengoRows: []
    };
  },
  computed: {
    // ...mapState(["API_ROOT"]),
  },
  methods: {
    onPaste(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      const { items } = ev.clipboardData || ev.originalEvent.clipboardData;
      // eslint-disable-next-line guard-for-in
      for (const index in items) {
        const item = items[index];
        // console.log("kind", item.kind);
        if (item.kind === "string") {
          const text = ev.clipboardData.getData("Text");
          // console.log("TEXT DETECTED", text);
          this.rawPaste = text;
        }
      }

      let lines = this.rawPaste.split("\n");
      // ¿Por qué un FOR?
      // Necesitamos extraer la informacion de manera secuencial
      // debido a que se puede repetir el mismo nombre o
      // nombres vacios que significan que a esa hora
      // es el mismo programa que el anterior horario

      // const model = [];
      // let lastItem = {
      //   timeStart: "",
      //   timeEnd: "",
      //   name: "-"
      // };
      // let currItem = null;
      // for (let i = 0; i < lines.length; i++) {
      //   console.log("for:", lines[i]);
      //   let [
      //     timeStart = "",
      //     timeEnd = "",
      //     name = ""
      //   ] = lines[i].split("\t") ?? [];

      //   timeStart = timeStart.trim();
      //   timeEnd = timeEnd.trim();
      //   name = name.trim();

      //   currItem = {
      //     timeStart,
      //     timeEnd,
      //     name
      //   };

      //   if (name.length < 1 || name === lastItem.name) {
      //     currItem.name = lastItem.name;
      //     currItem.timeStart = lastItem.timeStart;
      //     lastItem = { ...currItem };
      //     if (i === (lines.length - 1)) {
      //       model.push(currItem);
      //     }
      //     console.log("last", lastItem);
      //     // eslint-disable-next-line no-continue
      //     continue;
      //   }

      //   lastItem = { ...currItem };
      //   model.push(currItem);
      // }
      // this.tarengoRows = model.filter(k => k.timeStart && k.timeStart);

      lines = lines.map((l) => {
        let [
          timeStart = "",
          timeEnd = "",
          name = ""
        ] = l.split("\t");

        timeStart = timeStart.trim();
        timeEnd = timeEnd.trim();
        name = name.trim();

        return {
          timeStart,
          timeEnd,
          name
        };
      }).filter(k => k.timeStart && k.timeStart)
        .map((it, idx, array) => {
          let {
            timeEnd = "",
            // eslint-disable-next-line prefer-const
            name = ""
          } = it;

          const { name: nameNext = "noname" } = array[idx + 1] ?? {};
          if (nameNext.length < 1 || name === nameNext) {
            const { timeEnd: lastTimeEnd = "" } = this.collapseSimilar(nameNext, idx + 1, array);
            timeEnd = lastTimeEnd;
          }

          return {
            ...it,
            timeEnd,
          };
        }).filter(k => k.name);
      this.tarengoRows = lines;

      // console.log(this.tarengoRows);
    },
    collapseSimilar(similar, idx, array) {
      // console.log(array, similar, idx);
      let idxctl = idx - 1;
      let item = {};
      for (let j = idx; j < array.length; j++) {
        const { name = "unk", timeStart = "", timeEnd = "" } = array[j] ?? {};
        if (name === similar && (j - 1) === idxctl && timeStart && timeEnd) {
          item = array[j];
          idxctl = j;
        }
      }
      return item;
    },
    clearList() {
      this.tarengoRows = [];
    },
    saveMultiple() {
      console.log("SAVE");
    }
  },
  mounted() {}
};
</script>
