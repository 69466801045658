<style scoped>
/* .media-list-hover > .media:not(.media-list-header):not(.media-list-footer):hover,
.media-list-hover .media-list-body > .media:hover {
  background-color: #21252900;
} */
.text-striked {
  text-decoration-line: line-through;
}
</style>

<template>
  <div class="box">
    <div class="box-header border-bottom border-dark">
      <h3 class="box-title" :class="{'text-muted':bankline.status==='trash'}">
        {{ bankline.hash }}
      </h3>
      <div class="box-controls float-right">
        <div class="btn-group">
          <button class="btn btn-sm dropdown-toggle" :class="statusClass" type="button" data-toggle="dropdown" aria-expanded="true">
            {{ statusText }}
          </button>
          <div class="dropdown-menu " x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
            <a class="dropdown-item" href="#" @click.prevent="setStatus('open', bankline)">
              <Icon icon="material-symbols:lock-open-right-sharp" :inline="true" class="text-primary" />
              Abierta
            </a>
            <a class="dropdown-item" href="#" @click.prevent="setStatus('warn', bankline)">
              <Icon icon="material-symbols:warning-outline" :inline="true" class="text-danger" />
              Atención
            </a>
            <a class="dropdown-item" href="#" @click.prevent="setStatus('locked', bankline)">
              <Icon icon="material-symbols:lock-sharp" :inline="true" class="text-white" />
              Cerrada
            </a>
            <div v-if="privileges.indexOf('BANKS-DELETE') > -1" class="dropdown-divider"></div>
            <a v-if="privileges.indexOf('BANKS-DELETE') > -1" class="dropdown-item" href="#" @click.prevent="setStatus('trash', bankline)">
              <Icon icon="material-symbols:delete-sharp" :inline="true" class="text-danger" />
              Basura
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="box-body">
      <h2>
        {{ accountAlias }}
      </h2>
      <div class="row py-1 pl-2 border-bottom border-dark">
        <div class="col-6">
          Origen:
        </div>
        <div class="col-6">
          Destino:
        </div>
        <div class="col-6">
          <span class="text-primary"> {{ tipo === "egreso" ? bankline.srcaccount : clabe }} </span>
          <br>
          {{ tipo === "egreso" ? accountClient : "" }}
        </div>
        <div class="col-6">
          <span class="text-primary"> {{ tipo === "egreso" ? clabe : bankline.srcaccount }} </span>
          <br>
          {{ tipo === "egreso" ? "" : accountClient }}
        </div>
      </div>
      <div class="row py-1 border-dark ">
        <div class="col-6">
          <h3 class="text-capitalize">
            {{ tipo }}
          </h3>
        </div>
        <div class="col-6 text-right">
          <h3 :class="{'text-primary': tipo === 'ingreso', 'text-warning': tipo === 'egreso'}">
            $ {{ amountAbs }}
          </h3>
        </div>
      </div>
      <div class="row border-bottom border-dark py-1 pl-2">
        <div class="col-12">
          <span class="text-bold"> {{ dsttransidHuman }}</span> <span class="text-muted">({{ bankline.dsttransid }})</span>
        </div>
      </div>
      <div class="row border-bottom border-dark py-1 pl-2">
        <div class="col-4">
          Contable
        </div>
        <div class="col-4">
          En Banco
        </div>
        <div class="col-4">
          En Sistema
        </div>
        <div class="col-4 text-primary">
          {{ formatDate(bankline.accountingdate) }}
        </div>
        <div class="col-4 text-muted">
          {{ formatDate(bankline.bankdate) }}
        </div>
        <div class="col-4 text-muted">
          {{ formatDate(bankline.createdAt) }}
        </div>
      </div>
      <div class="row border-bottom border-dark py-1 pl-2">
        <div class="col-4">
          <p class="my-1">
            Referencia:
          </p>
        </div>
        <div class="col-8">
          <p class="my-1">
            {{ bankline.reference }}
          </p>
        </div>
      </div>

      <div class="row mt-4 py-1 border-bottom border-dark">
        <div class="col-12 my-1">
          <h3>Concepto</h3>
          <p class="px-2 text-primary">
            {{ bankline.fullnotes }}
          </p>
        </div>
      </div>

      <div class="row mt-4 py-1 border-bottom border-dark ">
        <div class="col-6">
          <h3>Relaciones</h3>
        </div>
      </div>
      <div class="row py-1 border-bottom border-dark pl-2">
        <div class="col-3">
          Ejecutó:
        </div>
        <div class="col-9">
          {{ tipo === "egreso" ? bankline.srcaccount : clabe }} <br>
          {{ tipo === "egreso" ? accountClient : "" }}
        </div>
      </div>
      <div class="row border-bottom border-dark py-1 pl-2">
        <div class="col-3">
          Conciliaciones:
        </div>
        <div class="col-9">
          <div class="media-list media-list-divided media-list-hover">
            <div v-for="(item, idx) in recons" :key="item._id" class="media py-1" @click.prevent="reconIdx = idx">
              <div class="media-body">
                <p>
                  <a class="badge badge-sm badge-pill badge-dark py-1 text-secondary" href="#" @click.stop.prevent="reconClicked(item)">
                    <Icon icon="ph:arrows-in-simple-light" />
                    {{ `C${item.recon_sequence}` }}
                    <p class="d-inline pl-3 text-primary">$ {{ formatNumber(item.amount) }}</p>
                  </a>
                  <!-- <small class="sidetitle text-gray">Online</small> -->
                </p>
                <p class="text-gray">
                  {{ item.title }}
                </p>
              </div>
              <div class="media-right gap-items">
                <button v-if="isOpen" type="button" class="btn btn-box-tool media-action lead" title="Eliminar" @click.stop.prevent="removeReconFromBankline(item)">
                  <Icon icon="uil:times" />
                </button>
              </div>
            </div>
          </div>
          <LiveSearch
            v-if="isOpen"
            ref="livesearch"
            class="py-1 d-block"
            empty="Buscar conciliación..."
            value=""
            :enabled="isOpen"
            :livesearch-values="searchresults"
            @searchValueChanged="onReconSearch"
            @livesearchItemClick="addReconToBankline"
          />
        </div>
      </div>
      <div class="row border-bottom border-dark py-1 pl-2">
        <div class="col-3 py-1">
          CFDI:
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12">
              <div v-for="cfdi in cfdis" :key="cfdi.uuid" class="my-2 pl-3">
                <p class="my-0">
                  <a
                    class="badge badge-sm badge-pill badge-dark py-1"
                    :class="{'text-striked': cfdi.estado_sat=='C'}"
                    href="#"
                    @click.prevent="goToCfdi(cfdi)"
                  >
                    <Icon icon="la:file-invoice-dollar" :inline="true" />
                    {{ `${cfdi.uuid.slice(-5)}` }}
                    <p class="d-inline pl-3 text-primary">$ {{ formatNumber(cfdi.total) }}</p>
                  </a>
                  <span class="d-inline pl-3">
                    <a href="#" @click.stop.prevent="openPdf(cfdi)">
                      <Icon icon="bi:file-pdf" :inline="true" /> PDF
                    </a>
                    &nbsp;
                    <a href="#" @click.stop.prevent="downloadXml(cfdi)">
                      <Icon icon="bi:filetype-xml" :inline="true" /> XML
                    </a>
                  </span>
                </p>
                <small class="text-muted">
                  {{ cfdi.rfc_emisor }}
                  <Icon icon="material-symbols:arrow-right" />
                  {{ cfdi.rfc_receptor }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ReconCommentsTable
            v-if="recons.length>0"
            :apiurl="currentReconLogsUrl"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import LiveSearch from "@/components/LiveSearch.vue";

import ServiceApiCfdi from "@/plugins/Cfdi/Cfdi.service";
import transactDict from "./transactions";
import ServiceApi from "./Banks.service";

const ReconCommentsTable = () => import("@/plugins/Recons/ReconCommentsTable.vue");

export default {
  name: "BanksSingle",
  components: {
    Icon,
    LiveSearch,
    ReconCommentsTable
  },
  props: {
    bankline: {
      type: Object,
      default() {
        return {
          hash: "",
          status: "open",
          recons: []
        };
      }
    }
  },
  data() {
    return {
      searching: false,
      searchresults: [],
      reconIdx: 0
    };
  },
  computed: {
    ...mapState(["API_ROOT", "privileges"]),
    recons() {
      const { recons: r = [] } = this.bankline ?? {};
      return r;
    },
    cfdis() {
      const { recons: r = [] } = this.bankline ?? {};
      const cfdis = r.map(it => {
        const { cfdis: c = [] } = it;
        return c;
      }).flat();
      return cfdis;
    },
    isOpen() {
      const { status = "open" } = this.bankline ?? {};
      return status === "open";
    },
    dsttransidHuman() {
      const { dsttransid = "" } = this.bankline ?? {};
      const t = transactDict[dsttransid] ?? "";
      return t;
    },
    tipo() {
      const { amount = "" } = this.bankline ?? {};
      const amountNumber = parseFloat(amount, 10);
      if (Number.isNaN(amountNumber)) {
        return "";
      }
      if (amountNumber >= 0) {
        return "ingreso";
      }
      return "egreso";
    },
    amountAbs() {
      const { amount = 0 } = this.bankline ?? {};
      let amountNumber = parseFloat(amount, 10);
      if (Number.isNaN(amountNumber)) {
        amountNumber = 0;
      }
      return Math.abs(amountNumber);
    },
    currentReconLogsUrl() {
      const { recons = [] } = this.bankline ?? {};
      const { _id = "" } = recons[this.reconIdx] ?? {};
      if (!_id) {
        return "";
      }

      const url = `${this.API_ROOT}/api/recons/${_id}/logs`;
      return url;
    },
    clabe() {
      const { fullnotes = "" } = this.bankline ?? {};
      // Clave Bancaria Estandarizada https://es.wikipedia.org/wiki/CLABE
      const claberegex = /CLABE \b[0-9]{3}[0-9]{3}(?:[ ]?[0-9]{11})(?:[ ]?[0-9]{1})?\b/gm;
      const matches = claberegex.exec(fullnotes);
      const [m = ""] = matches ?? [];
      // Extraida la clabe se podria validar:
      // https://github.com/center-key/clabe-validator
      // https://github.com/cuenca-mx/clabe-js
      return m;
    },
    statusClass() {
      const { status = "open" } = this.bankline;
      return {
        "btn-primary": status === "open",
        "btn-danger": status === "warn",
        "btn-default": status === "locked",
        "btn-dark": status === "trash"
      };
    },
    statusText() {
      const st = {
        open: "Abierta",
        locked: "Cerrada",
        warn: "Atención",
        trash: "Basura"
      };
      const { status = "open" } = this.bankline;
      return st[status] ?? "No definido";
    },
    accountAlias() {
      const { srcaccount = "" } = this.bankline ?? {};
      return ServiceApi.accountsAlias[srcaccount] ?? "Cuenta desconocida";
    },
    accountClient() {
      const { srcaccount = "" } = this.bankline ?? {};
      return ServiceApi.accountsClientNames[srcaccount] ?? "Cliente desconocido";
    }
  },
  methods: {
    setStatus(status, bankline) {
      const { hash = null } = bankline ?? {};
      const { hash: paramid = null } = this.$route.params;
      console.log("SET STATUS", hash, status);
      const st = {
        open: "Abierto",
        locked: "Cerrado",
        warn: "Atención",
        trash: "Basura"
      };

      if (!hash || paramid !== hash) {
        this.messageAlert({
          icon: "error",
          title: `No se pudo actualizar estado a ${st[status] ?? "sin definir"}`,
          text: "El identificador no coincide con la ruta del navegador"
        });
      }
      if (Object.keys(st).indexOf(status) === -1) {
        this.messageAlert({
          icon: "error",
          title: `No se pudo actualizar estado a ${st[status] ?? "sin definir"}`,
          text: "Estado no reconocido"
        });
      }

      ServiceApi.updateByHash(hash, { status })
        .then(res => {
          console.log("Banks info", res);
          this.$emit("bankUpdated", res);
        })
        .catch(err => {
          const { response = {}, message = "Revise su conexión a internet" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: `No se pudo actualizar estado a ${st[status] ?? "sin definir"}`,
            text: `${errstr}, CODE: ${statusres}`
          });
        });
    },
    onReconSearch(val) {
      console.log("onReconSearch", val);
      if (!this.searching) {
        this.searching = true;
        ServiceApi.searchRecons({ search: val })
          .then(res => {
            this.searching = false;
            const { rows = [] } = res;
            const results = rows.map(it => ({
              id: it._id,
              label: `C${it.recon_sequence} ${it.title}: ${it.amount}`,
              item: it
            }));
            // console.log("SEARCH", res);
            this.searchresults = results;
          })
          .catch(err => {
            this.searching = false;
            console.log("Error in search", err);
          });
      }
    },
    addReconToBankline(id, recon) {
      console.log("Request recon swap to", id, recon);
      this.searchresults = [];
      this.searching = false;
      const { hash = "" } = this.bankline ?? {};
      ServiceApi.addReconByHash(hash, { reconid: id })
        .then(res => {
          // console.log("dbUpdated", res);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.$emit("bankUpdated", res);
        })
        .catch(e => {
          const { message = "Internal server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("RECON ADD FAILED ", errStr);
          this.$nextTick(() => {
            this.$refs.livesearch.v = "";
          });
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "error",
            title: "No se pudo agregar conciliación",
            text: `${errStr}, CODE: ${status}`,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    reconClicked(item) {
      const { recon_sequence: reconSeq = 0 } = item ?? {};
      this.$router.push({
        name: "ReconsSingle",
        params: { id: reconSeq }
      });
    },
    removeReconFromBankline(item) {
      const { _id: reconid = "", recon_sequence: reconSeq = 0, title = "" } = item ?? {};
      const name = `C${reconSeq} ${title}`;
      if (!reconid) {
        this.$swal({
          position: "bottom-end",
          toast: true,
          icon: "error",
          title: "No se pudo eliminar conciliación",
          text: "El Id de conciliación está vacio.",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        });
        return;
      }
      this.$swal({
        icon: "warning",
        title: "Eliminar conciliación",
        html: `Conciliación: <strong>${name}</strong>.`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sí, Eliminar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#ff7674",
        cancelButtonColor: ""
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          const { hash = "" } = this.bankline ?? {};
          ServiceApi.removeReconByHash(hash, { reconid })
            .then(res => {
              // console.log("dbUpdated", res);
              this.$emit("bankUpdated", res);
            })
            .catch(e => {
              const { message = "Internal server error", response = {} } = e;
              const { data: dataResponse = {}, status = 400 } = response;
              const { error_string: errStr = message } = dataResponse;
              console.log("RECON DELETE FAILED ", errStr);
              this.$swal({
                position: "bottom-end",
                toast: true,
                icon: "error",
                title: "No se pudo eliminar conciliación",
                text: `${errStr}, CODE: ${status}`,
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true
              });
            });
        }
      });
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 3000, position = "bottom-end"
      } = info ?? {};
      this.$swal({
        icon,
        title,
        timer,
        position,
        html: text,
        toast: true,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    },
    formatDate(value) {
      if (!value) {
        return "-";
      }
      const date = format(new Date(value), "d LLL, yyyy",  { locale: esmx } );
      return date;
    },
    goToCfdi(cfdi) {
      const { uuid = "" } = cfdi ?? {};
      this.$router.push({
        name: "CFDISingle",
        params: { uuid }
      });
    },
    openPdf(elem) {
      const { uuid = "" } = elem ?? {};
      const url = `${this.API_ROOT}/api/cfdi/${uuid}/pdf`;
      window.open(url, uuid);
    },
    downloadXml(elem) {
      const { uuid: reqUuid = "" } = elem ?? {};
      if (!reqUuid) {
        this.showAlert({
          icon: "error",
          title: "No se pudo descargar el XML",
          msg: "CFDI request uuid empty, CODE: 400"
        });
        return;
      }

      ServiceApiCfdi.getByUuid(reqUuid)
        .then(res => {
          if (!res) {
            this.showAlert({
              icon: "error",
              title: "No se pudo descargar el XML",
              msg: "CFDI not found, CODE: 404"
            });
            return;
          }
          const {
            uuid = "", fecha_emision = new Date(), tipo = "", rfc_emisor: rfcEm = "", rfc_receptor: rfcRe = "", moneda = "", xml = ""
          } = res ?? {};

          const d = parseISO(fecha_emision);
          const fechaemision = format(d, "yyyy-MM-dd",  { locale: esmx } );
          const name = `${fechaemision}_${tipo}_${rfcEm}_${rfcRe}_${moneda}_${uuid}.xml`;

          const element = document.createElement("a");
          const blob = new Blob([xml], { type: "text/plain;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          element.setAttribute("href", url);
          element.setAttribute("download", name);
          element.click();
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          this.showAlert({
            icon: "error",
            title: "No se pudo descargar el XML",
            msg: `${errStr}, CODE: ${status}`,
          });
        });
    },
    formatNumber(number) {
      const amountNum = parseFloat(number);
      if (Number.isNaN(amountNum)) {
        return "-";
      }
      return formatMoney(Math.abs(amountNum), { symbol: "", format: "%v" });
    }
  }
};
</script>
