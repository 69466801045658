<style scoped>
.nemschedule{
  background-color: var(--gray-dark);
  padding: 0px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border: 1px solid black;
}
.nemtuner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.nemtuner .cell{
  color: var(--gray);
  flex-grow: 1;
  flex-basis: 0.1em;
  padding-top: 0.60em;
  text-align: center;
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
  border-right: 1px solid #00000077;
}
.nemtuner .cell:last-child{
  border-right: none;
}
.nemrow{
  min-height: 3em;
  height: 3em;
  display: flex;
  color: var(--gray);
  border-bottom: 1px solid #00000077;
  padding-left: 0;
}
.nemrow.titles{
  background-color: #00000077;
}
.nemrow.months{
  background-color: #000000AA;
  border: unset;
}

.nemrow:last-child{
  border-bottom: unset;
}
.titlerow > div:hover{
  background-color: #222233;
}
.tunername{
  background-color:  #00000077;
  text-align: unset !important;
  overflow-x: auto;
  padding-left: 0.5em;
  flex-basis: 5em;
  flex-grow: 1;     /* do not grow   - initial value: 0 */
  flex-shrink: 0;
}
.datecell{
  flex-basis: 5em;
  flex-grow: 2;
  flex-shrink: 0;
  border: unset !important;
}
.datecell:hover{
  background-color: unset !important;
}
.altbg{
  background-color: #273345e3;
}
.cell.datecell.altbg:hover {
  background-color: #273345e3 !important;
}
.cell:hover{
  background-color:  #222233;
}
.cell.selected{
  background-color:  #222233;
  border: 1px dotted var(--primary);
}
.cell.division {
  background-color: red;
  width: 5px;
  height: 100px;
  min-width: 1px;
  min-height: 100px;
  padding: 1px;
}
.cell.disabled {
  pointer-events: none;
  /* background-color:  #00000099; */
}
.borderday {
  border-left: 1px solid var(--gray);
}
.needle {
  background-color: red;
  position: sticky;
  width: 1px;
  min-width: 1px;
  height: 118px;
  min-height: 118px;
  top: 0px;
  pointer-events: none;
}
.needle.time {
  background-color: var(--gray);
}
a.disabled {
  pointer-events: none;
}
.btn:disabled {
  pointer-events: none;
}
</style>

<template>
  <div class="nemschedule">
    <div class="nemtuner">
      <div class="nemrow months">
        <div class="tunername cell">
          &nbsp;
        </div>
        <div
          v-for="(hr, idx) in hours" :key="hr.label" class="cell datecell"
          :class="{borderday: isFirstHour(hr), altbg: (hr.daynum%2===1)}"
        >
          <a
            v-if="idx==0"
            href="#"
            class="float-left"
            :class="{disabled: disabled}"
            @click="slideHours(-24)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
          </a>
          {{ hours[idx].fmtdate }}
          <a
            v-if="idx==5"
            href="#"
            class="float-right"
            :class="{disabled: disabled}"
            @click="slideHours(24)"
            @mousemove="mouseMove"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
          </a>
        </div>
      </div>
      <div ref="schedules" class="nemrow titles">
        <div v-show="false" ref="pointneedle" class="needle time"></div>
        <div v-show="timeNeedleShown" ref="timeneedle" class="needle time"></div>
        <div class="tunername cell">
          <div class="btn-group">
            <button
              type="button" class="btn btn-xs btn-default" :disabled="disabled" title="Ahora" @click="resetNow()"
            >
              <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 512 512"><path fill="currentColor" d="M271.514 95.5h-32v178.111l115.613 54.948l13.737-28.902l-97.35-46.268V95.5z" /><path fill="currentColor" d="M256 16C123.452 16 16 123.452 16 256s107.452 240 240 240s240-107.452 240-240S388.548 16 256 16Zm0 448c-114.875 0-208-93.125-208-208S141.125 48 256 48s208 93.125 208 208s-93.125 208-208 208Z" /></svg>
              HOY
            </button>
          </div>
        </div>
        <div
          v-for="(it, idx) in hours"
          ref="cHours"
          :key="it.label" class="cell"
          :class="{borderday: isFirstHour(it)}"
          @mousemove="mouseMove"
        >
          <a
            v-if="idx==0"
            href="#"
            class="float-left"
            :class="{disabled: disabled}"
            @click="slideHours(-1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M15 19V5l-8 7z" /></svg>
          </a>
          <small>{{ it.hour }}</small>
          <a
            v-if="idx==5"
            href="#"
            class="float-right"
            :class="{disabled: disabled}"
            @click="slideHours(1)"
          >
            <svg xmlns="http://www.w3.org/2000/svg" style="vertical-align: -0.125em;" width="1.5em" height="1.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="m9 19l8-7l-8-7z" /></svg>
          </a>
        </div>
      </div>
      <div v-for="(rec,idx) in nemrecs" :key="idx" class="nemrow">
        <div class="tunername cell " @mousemove="mouseMove">
          {{ rec.name }}
        </div>
        <div
          v-for="(hr) in hours"
          :key="hr.label"
          class="cell"
          :class="{selected: isSelected(idx, hr.datetime), borderday: isFirstHour(hr), disabled: disabled}"
          @click="onCellClick(idx, hr, rec.recorder, rec.tuner)"
          @mousemove="mouseMove"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import add from "date-fns/add";
import format from "date-fns/format";
import { es } from "date-fns/locale";
// import setDefaultOptions from "date-fns/setDefaultOptions";

import { Icon } from "@iconify/vue2";
// import caretLeft from "@iconify/icons-ci/caret-left";
// import { min } from "date-fns/fp";

// setDefaultOptions({ locale: es });

export default {
  props: {
    nemrecs: {
      type: Array,
      default() {
        return ["Las Estrellas", "Canal 5", "Puebla"];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      start: Date.now(),
      end: "",
      selectedDate: Date.now(),
      selectedTuner: -1,
      currentHours: [0, 1, 2, 3, 4, 5],
      timeNeedleX: -1,
      timeNeedleXmax: -1,
      timeNeedleXmin: -1,
      icons: {
        // caretLeft,
      },
      intervalID: null
    };
  },
  computed: {
    hours() {
      let counter = 0;
      const startDate = new Date(this.start);
      startDate.setMinutes(0, 0, 0);

      const hours  = this.currentHours.map((hr) => {
        const delta = add(startDate.getTime(), { hours:hr });
        const h = parseInt(format(delta, "H"), 10);
        let fmtdate = "";
        if ( h !== 0 && h !== 23) {
          fmtdate = ""; // Por mera legibilidad
        }
        else {
          fmtdate = format(delta, "E. d, LLL",  { locale: es });
          counter++;
        }
        const daynum = parseInt(format(delta, "D", { useAdditionalDayOfYearTokens: true }), 10);
        // console.log(h, fmtdate, delta);
        return {
          datetime: delta, hour: format(delta, "h a"), fmtdate, daynum
        };
      });
      // console.log("counter", counter);
      if (counter < 2) {
        hours[0].fmtdate = format(hours[0].datetime, "E. d, LLL",  { locale: es });
      }
      return hours;
    },
    timeNeedleShown() {
      return this.timeNeedleX >= this.timeNeedleXmin
        && this.timeNeedleX <= this.timeNeedleXmax
        && this.nemrecs.length > 0;
    }
  },

  methods: {
    mouseMove(ev) {
      const { cHours = [] } = this.$refs;
      if (cHours.length < 1) {
        return;
      }
      const firstCell = cHours[0].getBoundingClientRect();
      // console.log("Mouse", firstCell, firstCell.x, firstCell.y);
      this.$emit("mouseMove", ev);
      const left = Math.max(ev.x, firstCell.x);
      const top =  firstCell.y;
      this.$refs.pointneedle.style.top = `${top}px`;
      this.$refs.pointneedle.style.left = `${left}px`;
      // this.setTimeNeedle();
    },
    setTimeNeedle() {
      const time = new Date();
      const epochStart = this.hours[0].datetime;
      const epochEnd = this.hours[this.hours.length - 1].datetime;
      epochEnd.setMinutes(59, 59, 999);
      if (time < epochStart || time > epochEnd) {
        this.timeNeedleX = -2;
        return;
      }

      const totalCells = this.currentHours.length;
      const { cHours = [] } = this.$refs;
      if (cHours.length < 1) {
        return;
      }
      const firstCell = cHours[0].getBoundingClientRect();
      const lastCell = cHours[totalCells - 1].getBoundingClientRect();
      // console.log("Mouse", firstCell, lastCell);

      this.timeNeedleXmin = firstCell.x;
      this.timeNeedleXmax = lastCell.x + lastCell.width;
      const w = this.timeNeedleXmax - this.timeNeedleXmin;
      const h = (this.nemrecs.length + 1) * 39;

      const left = Math.max(0, firstCell.x);
      // const top =  firstCell.y;
      // this.$refs.timeneedle.style.top = `${top}px`;
      this.$refs.timeneedle.style.left = `${left}px`;
      this.$refs.timeneedle.style.height = `${h}px`;
      const timePos = this.calcTimeNeedlePos(time, w);
      this.timeNeedleX = timePos + this.timeNeedleXmin;
      // console.log("POS:", w, timePos, this.timeNeedleX);
      this.$refs.timeneedle.style.left = `${this.timeNeedleX}px`;
    },
    calcTimeNeedlePos(time, intervalWidth) {
      // Recibe una fecha y un "ancho"
      // Retorna -1 si la hora no esta dentro del rango actual visible
      // o bien retorna la posicion en ese ancho
      const epochStart = this.hours[0].datetime;
      const epochEnd = this.hours[this.hours.length - 1].datetime;
      epochEnd.setMinutes(59, 59, 999);

      // console.log("H1", epochStart, "HFin", epochEnd);
      if (time < epochStart || time > epochEnd) {
        console.log("Not in window");
        return -1;
      }

      const epochReq = time.getTime();
      const lapseWidth = Math.abs(epochEnd.getTime() - epochStart.getTime());
      const norm = (epochReq - epochStart.getTime()) / lapseWidth;

      // console.log("Yes in window", intervalWidth, epochReq, epochStart, epochEnd, lapseWidth, norm);
      return norm * intervalWidth;
    },
    resetNow() {
      this.start = Date.now();
      this.currentHours = [-3, -2, -1, 0, 1, 2];
      this.setTimeNeedle();
    },
    slideHours(delta) {
      this.currentHours = this.currentHours.map((hr) => {
        const fixed = hr + delta;
        return fixed;
      });
      this.setTimeNeedle();
    },
    isSelected(tuner, dtime) {
      if (tuner !== this.selectedTuner) {
        return false;
      }
      const hourCurr = format(dtime, "yyyyMMddHH");
      const hourSel  = format(this.selectedDate, "yyyyMMddHH");

      return hourCurr === hourSel;
    },
    isFirstDay(dtime) {
      const day = format(dtime.datetime, "d");
      console.log("Day", day);
      return (parseInt(day, 10) === 0);
    },
    isFirstHour(dtime) {
      const hour = format(dtime.datetime, "H");
      return (parseInt(hour, 10) === 0);
    },
    formatDate(segment) {
      // console.log("Formatting", segment);
      let  hour = format(segment.datetime, "H");
      hour = parseInt(hour, 10);
      if ( hour !== 0 && hour !== 23) {
        return "";
      }
      const fmtdate = format(segment.datetime, "E. d, LLL",  { locale: es });
      return fmtdate;
    },
    onCellClick(recidx, segment, nemos, rectuner) {
      this.selectedTuner = recidx;
      this.selectedDate = segment.datetime;
      this.$emit("segment-selected", { tuner: rectuner, nemos, segment });
    }
  },
  mounted() {
    this.setTimeNeedle();
    const that = this;
    // eslint-disable-next-line func-names
    this.intervalID = setInterval(() => {
      that.setTimeNeedle();
    }, 1000);
  },
};
</script>
