<style scoped>
.dotted-text {
  text-decoration: underline dashed #33cabb88;
  color: unset;
}
.dotted-text:hover {
  text-decoration: unset;
  /* color: unset; */
}
.dropdown-item {
  font-weight: 500;
}
</style>
<template>
  <div class="live-edit-container">
    <slot></slot>
    <a
      v-show="!editing"
      href="#" class="hover-text-info"
      :class="{'text-danger': (v.length<1), 'dotted-text': enabled}"
      :style="{'pointer-events': (enabled ? 'auto':'none')}"
      @click.prevent="toggleEdit"
    >
      {{ v.length>0?v:empty }}
    </a>
    <div v-show="editing" class="controls">
      <div class="form-group input-group" :class="{ 'has-error': (error ==true)}">
        <!-- @keydown.enter.prevent="onAccepted"
          @blur="onBlur"
          -->

        <input
          ref="livefield"
          v-model="v"
          type="text"
          class="form-control form-control-sm"
          :placeholder="hint"
          :aria-label="hint"

          aria-describedby="basic-addon2" style="width:100%;"
          @keydown.enter.prevent="onAccepted"
          @keyup.enter.prevent="onAccepted"
          @keydown.esc="onCancel" @blur="onAccepted"
        >
        <div v-show="livesearch" class="dropdown-menu bg-dark text-secondary" style="width:100%; display: block; top: 30px;">
          <a
            v-for="val in livesearchValues" :key="val.id" href="#" class="dropdown-item"
            @click="onLiveItemClick"
          >
            - {{ val.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ""
    },
    enabled: {
      type: Boolean,
      default: true
    },
    hint: {
      type: String,
      default: ""
    },
    empty: { type: String, default: "(sin llenar)" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 128 },
    showButtons: {
      type: Boolean,
      default: true
    },

    livesearch: {
      type: Boolean,
      default: false
    },
    livesearchValues: {
      type: Array,
      default: () => [] // array de {id, label, item}
    }
  },
  data() {
    return {
      editing: false,
      v: "",
      old: "",
      error: false
    };
  },
  computed: {},
  watch: {
    v: {
      handler() {
        this.vWatcher(this.v);
        // Solo cuando esta en modo edición, evita que se lance al montar/crear
        if (this.livesearch && this.v.length > 2 && this.editing) {
          this.$emit("searchValueChanged", this.v);
        }
      }
    },
    value(val, old) {
      // console.log("·Value changed", old, ">", val);
      Vue.nextTick(() => {
        // DOM updated
        this.v = val;
        this.old = old;
      });
    }
  },
  methods: {
    vWatcher(_v) {
      // console.log("watcher of v in edit");
      // this.v = typeof this.v !== "undefined" ? this.v.trimLeft() : "";
      if (this.isValid()) {
        this.error = false;
      }
    },
    isValid() {
      if (!this.v || this.v.length < this.min) {
        return false;
      }
      if (this.v.length > this.max) {
        return false;
      }
      return true;
    },
    toggleEdit() {
      this.error = false;
      if (!this.enabled) {
        this.onCancel();
        return;
      }
      this.editing = !this.editing;
      if (this.editing) {
        this.old = this.v; // save old value to allow cancel
        const self = this;
        Vue.nextTick(() => {
          // No se puede directo porque se acaba de crear
          self.$refs.livefield.focus();
        });
      }
      this.$emit("editingChanged", this.editing);
    },
    onAccepted() {
      if (!this.isValid()) {
        this.error = true;
        return;
      }
      this.editing = false;
      this.v = this.v.trim();
      this.$emit("valueChanged", this.v);
    },
    onCancel(reset = true) {
      this.v = this.old;
      if (reset) {
        this.editing = false;
      }
      this.$emit("cancelled", this.v);
    },
  },
  mounted() {},
  beforeMount() {
    this.v = this.value;
    this.old = this.value;
  }
};
</script>
