<style scoped>
.btn-edit {
  opacity: 0.6;
  display: none;
  height: 10px;
  margin-top: -10px;
}
.checkbox:hover .btn-edit {
  opacity: 1.0;
  display: inline;
}
[type=checkbox]+label {
  padding-left: 24px;
  height: 10px;
}
</style>
<template>
  <div class="checkbox my-2">
    <input
      :id="taskid"
      class="form-check-input mt-0"
      type="checkbox"
      :checked="checked"
      @input="changeCheck($event)"
    >
    <label :for="taskid"></label>
    <div class="d-inline">
      {{ title }}
      <div class="btn-group ml-1">
        <a
          class="btn-edit"
          title="Compartir"
          href="#"
          data-toggle="dropdown"
          :class="{'text-warning': shareCount > 1}"
        >
          <Icon icon="fa-solid:users" />
        </a>
        <div class="dropdown dropdown-menu dropdown-sm">
          <h6 class="dropdown-header">
            <span class="badge badge-pill badge-sm badge-primary">Compartir con:</span>
          </h6>
          <a
            v-for="item in sharedUsers"
            :key="item.id"
            class="dropdown-item" :class="{'text-primary': item.sharing}" href="#"
            @click.prevent="$emit('shareClicked', { taskid, title }, item)"
          >
            <svg
              v-if="item.sharing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" style="vertical-align: -0.125em;"
              width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"
            >
              <g fill="currentColor">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </g>
            </svg>
            {{ item.email }}
          </a>
        </div>
      </div>
      <div class="btn-group ml-1">
        <a
          class="btn-edit"
          href="#"
          data-toggle="dropdown"
          @click.prevent
        >
          <Icon icon="bi:three-dots-vertical" />
        </a>
        <div class="dropdown dropdown-menu">
          <!-- <a class="dropdown-item" href="#" @click.prevent="onMoveClicked(it)">
            <Icon icon="fa-solid:users" inline="true" />
            Asignar a...
          </a> -->
          <!-- <div data-v-f646b5ae="" class="dropdown-divider" /> -->
          <a class="dropdown-item" href="#" @click.prevent="$emit('editClicked', taskid, title)">
            <Icon icon="carbon:edit" />
            Editar
          </a>
          <a class="dropdown-item text-danger" href="#" @click.prevent="$emit('deleteClicked', taskid, title)">
            <Icon icon="bi:trash-fill" inline="true" />
            Borrar
          </a>
          <!-- <a class="dropdown-item" href="#">Editor de tags</a>
                  <a class="dropdown-item" href="#">Enviar</a>
                  <a class="dropdown-item" href="#">Detalles</a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon
  },
  props: {
    taskid: {
      type: String,
      default: "-1"
    },
    title: {
      type: String,
      default: "New Task"
    },
    status: {
      type: String,
      default: "open"
    },
    users: {
      type: Array,
      default() {
        return [];
      }
    },
    availableUsers: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    checked() {
      const { status = "open" } = this;
      return status === "done";
    },
    sharedUsers() {
      // Usuarios con acceso permitido en este momento
      const { users = [] } = this;
      const currentUserIds = users.map(it => it._id);
      const sharedUsers = this.availableUsers.map(it => {
        const { id, email, name } = it;
        const sharing = currentUserIds.indexOf(id) > -1;
        return {
          id,
          email,
          name,
          sharing
        };
      });
      return sharedUsers;
    },
    shareCount() {
      const sharing = this.sharedUsers.filter(it => it.sharing);
      return sharing.length;
    },
  },
  methods: {
    changeCheck(event) {
      const { checked = false } = event.target;
      const { taskid = "-1", title = "New task" } = this;
      // De momento solo manejaremos status: open, done
      const status = checked ? "done" : "open";
      this.$emit("statusChanged", taskid, title, status);
    }
  }
};
</script>
