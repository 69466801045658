<style scoped>
.single-recon {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <div class="box">
        <div class="box-header">
          <h3 class="box-title">
            Pagos y cobros
          </h3>
          <div class="box-controls float-right d-print-none">
            <div class="box-header-actions">
              <ReconsBtnTags
                title="Nuevo"
                @tagClicked="onNewRecon"
              />
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col">
              <!-- <div class="dropdown d-inline">
                <button class="btn btn-sm btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  {{ filterAccountName }}
                  <Icon v-if="!filterAccount" icon="vaadin:invoice" :inline="true" />
                </button>
                <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; transform: translate3d(0px, 36px, 0px); top: 0px; left: 0px; will-change: transform;">
                  <a class="dropdown-item" href="#" @click.prevent="filterAccount = '062650163410076195'">Afirme MXN 6195</a>
                  <a class="dropdown-item" href="#" @click.prevent="filterAccount = '062650163410006149'">Afirme USD 6149</a>
                  <a class="dropdown-item" href="#" @click.prevent="filterAccount = '376702480221008'">TC Amex</a>
                </div>
              </div> -->
              <button
                type="button" class="btn btn-inline btn-default btn-sm"
                title="Egresos o Ingresos"
                @click="invertFilterEgresosIngresos"
              >
                <Icon v-if="filterType === 'porpagar'" icon="fluent:arrow-step-out-20-filled" :inline="true" class="text-primary" />
                <Icon v-if="filterType === 'porcobrar'" icon="fluent:arrow-step-in-20-filled" :inline="true" class="" />
                <span class="hidden-xs-down">
                  {{ filterType === 'porpagar' ? "Egresos" : "Ingresos" }}
                </span>
              </button>
              <YearMonthDropdown
                class="d-sm-inline"
                :year="filterDateYear"
                :month="filterDateMonth"
                :applied="filterDateApply"
                :fullyear="filterDateFullYear"
                :two-months-checked="filterTwoMonth"
                @dateChanged="onDateFilterChanged"
                @clearRequested="onDateFilterClear"
                @fullYearClicked="onDateFilterFullYear"
                @twoMonthClicked="onTwoMonthFilter"
              />
              <button
                type="button"
                class="btn btn-sm btn-default d-none d-sm-block float-right"
                @click.prevent="refreshAll()"
              >
                <Icon icon="ic:outline-refresh" />
              </button>
              <a
                v-if="currentSpeiLayout.length > 0"
                :href="'data:text/plain;charset=utf-8,'+ currentSpeiLayout"
                download="afirme-spei-masivo.txt"
                title="Descargar Layout Afirme Masivo"
                class="btn btn-sm btn-default text-primary float-right"
              >
                <Icon icon="uil:bill" :inline="true" />
                $ {{ currentSpeiTotal }}
              </a>
              <SearchBar
                :value="searchVal"
                @search-changed="onSearchChanged"
              />
              <Toggle class="btn-xs btn-default mx-1 mt-2 float-right" :value="notClosed" @click="onFilterStatus" />
              <small v-if="!notClosed" class="d-none d-sm-block mx-1 mt-2 float-right">Todo</small>
              <small v-else class="text-primary d-none d-sm-block mx-1 mt-2 float-right">Sin cerrar</small>
            </div>
          </div>
          <div class="row">
            <div class="col my-3">
              <ReconsTable
                ref="reconsTable"
                :sort-default="sortDefault"
                :per-page="25"
                :apiurl="`${apiurl}?${params}`"
                @rows-checked="onRowsChecked"
                @row-clicked="showSingleEditor"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6">
      <SingleRecon
        v-if="currentReconIdSeq"
        :curr-recon-id="currentReconIdSeq"
        :refresh="currentReconRefresh"
        class="single-recon"
        @singleReconFieldChanged="onSingleReconFieldChanged(...arguments)"
        @singleReconLogsChanged="onSingleReconLogsChanged(...arguments)"
        @reconFieldsChanged="onReconFieldsChanged(...arguments)"
        @requestRefresh="refreshAll()"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";

import axios from "axios";
import Toggle from "@/components/Toggle.vue";
import AfirmeTools from "@/components/rocket/AfirmeTools";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import { unformat } from "accounting-js";
import ReconsTable from "./ReconsTable.vue";
import ReconsBtnTags from "./ReconsBtnTags.vue";
import ReconsApi from "./Recons.service";
// Lazy loader
const SingleRecon = () => import("./ReconsSingle.vue");

export default {
  components: {
    ReconsBtnTags,
    ReconsTable,
    SingleRecon,
    YearMonthDropdown,
    SearchBar,
    Toggle,
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/recons"
    }
  },
  data() {
    return {
      sortDefault: [
        {
          field: "recon_sequence",
          sortField: "recon_sequence",
          direction: "desc"
        }
      ],
      showEditor: false,
      currentReconUrl: "",
      currentReconData: null,
      currentReconIdSeq: "",
      currentReconRefresh: 0,

      currentSpeiLayout:"",
      currentSpeiTotal:"",

      searchVal: "",
      filterType: "porpagar",
      filterStatus: "!closed",

      filterDateApply: false,
      filterDateFullYear: false,
      filterTwoMonth: false,
      filterDateYear: 2000,
      filterDatePrevYear: 2000,
      filterDateMonth: 1,
      filterDatePrevMonth: 1,

      filterAccount: ""
    };
  },
  computed: {
    ...mapState(["user"]),
    notClosed() {
      return this.filterStatus === "!closed";
    },
    params() {
      const {
        filterType = "porpagar",
        filterStatus = "!closed",
        searchVal = "",
        filterDateYear = new Date().getFullYear(),
        filterDatePrevYear = new Date().getFullYear(),
        filterDateMonth = 1,
        filterDatePrevMonth = 1,
        filterDateApply = false,
        filterDateFullYear = true,
        filterTwoMonth = false,
      } = this;

      let year = filterDateApply || filterDateFullYear ? filterDateYear : null;
      let month = filterDateApply && !filterDateFullYear ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes actual se convierten en toyear y tomonth
      const toyear = filterTwoMonth ? filterDateYear : null;
      const tomonth =  filterTwoMonth ? filterDateMonth : null;

      // Si esta checked el modo bimiestre el año y mes se convierten en prevMonth y prevYear
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        year = filterDatePrevYear;
        month = filterDatePrevMonth;
      }

      const jsonParams = {
        type: filterType,
        status: filterStatus,
        search: searchVal.length > 2 ? searchVal : null,
        year,
        month,
        toyear,
        tomonth
      };

      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      const p = new URLSearchParams(filtredParams).toString();
      return p;
    },
    filterAccountName() {
      const { filterAccount = "" } = this;
      return filterAccount;
    },
    routerOpts() {
      const {
        searchVal = "",
        filterType = "porpagar",
        currentReconIdSeq = "",
        filterStatus = "!closed",
        filterDateYear,
        filterDatePrevYear,
        filterDateMonth,
        filterDatePrevMonth,
        filterDateApply = false,
        filterDateFullYear = false,
        filterTwoMonth = false,
      } = this;

      let paramid = currentReconIdSeq;
      if (Number.isInteger(paramid)) {
        paramid = paramid.toString();
      }

      const reconName = (paramid.length > 0) ? "ReconsSingle" : "Recons";
      const opts = {
        name: reconName,
        params: { id: currentReconIdSeq }
      };
      const query = {
        type: filterType,
        status: filterStatus
      };
      if (searchVal.length > 2) {
        query.search = searchVal;
      }
      if ((filterDateApply || filterDateFullYear) && !filterTwoMonth) {
        query.year = filterDateYear;
      }
      if (filterDateApply && !filterDateFullYear && !filterTwoMonth) {
        query.month = filterDateMonth;
      }
      if (filterDateApply && filterTwoMonth && !filterDateFullYear) {
        query.year = filterDatePrevYear;
        query.month = filterDatePrevMonth;
        query.toyear = filterDateYear;
        query.tomonth = filterDateMonth;
      }
      opts.query = query;
      return opts;
    }
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, to.params);
        const {
          type = "porpagar",
          status = "!closed",
          search = "",
        } = to.query;
        let {
          year = "",
          month = "",
          toyear = "",
          tomonth = "",
        } = to.query;

        year = parseInt(year, 10);
        month = parseInt(month, 10);
        toyear = parseInt(toyear, 10);
        tomonth = parseInt(tomonth, 10);

        this.searchVal = search;
        this.filterType = type;
        this.filterStatus = status;

        const currYear = new Date().getFullYear();
        if (Number.isNaN(year) && Number.isNaN(month)) {
          this.filterDateApply = false;
          this.filterDateFullYear = false;
          this.filterTwoMonth = false;
          this.filterDateMonth = 1;
          this.filterDatePrevMonth = 1;
          this.filterDateYear = currYear;
          this.filterDatePrevYear = currYear;
        }
        else {
          this.filterDateYear = year > 2010 ? year : currYear;
          this.filterDateMonth = Number.isNaN(month) ? 1 : month;
          this.filterDateFullYear = true;
          this.filterDateApply = false;
          this.filterTwoMonth = !!toyear;

          if (this.filterTwoMonth) {
            this.filterDateYear = toyear > 2010 ? toyear : currYear;
            this.filterDateMonth = Number.isNaN(tomonth) ? 1 : tomonth;
            this.filterDatePrevYear = year > 2010 ? year : currYear;
            this.filterDatePrevMonth = Number.isNaN(month) ? 1 : month;
          }

          if (month > 0 && month < 13) {
            this.filterDateFullYear = false;
            this.filterDateApply = true;
          }
        }

        let { id: paramid = "" } = to.params;
        if (!paramid) {
          this.currentReconIdSeq = "";
          return;
        }
        if (Number.isInteger(paramid)) {
          paramid = paramid.toString();
        }
        this.currentReconIdSeq = paramid;
      },
      immediate: true
    }
  },

  methods: {
    onChangeFilterType(value) {
      this.filterType = value;
    },
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = false;
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterTwoMonth = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
      this.filterTwoMonth = false;
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onTwoMonthFilter(prevyear, prevmonth, curryear, currmonth) {
      this.filterDateYear = curryear;
      this.filterDateMonth = currmonth;
      this.filterDatePrevYear = prevyear;
      this.filterDatePrevMonth = prevmonth;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
      this.filterTwoMonth = true;
      this.$router.push(this.routerOpts).catch(() => { });
    },
    invertFilterEgresosIngresos() {
      this.filterType = (this.filterType === "porpagar") ? "porcobrar" : "porpagar";
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onNewRecon(recontype, recontag) {
      this.$swal({
        title: "Crear conciliación",
        input: "text",
        html: "¿Cual es el monto inicial?",
        icon: "question",
        confirmButtonText: "Crear",
        cancelButtonText: "No",
        showCancelButton: true,
        reverseButtons: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          const val = parseFloat(unformat(value, "."));
          if (!val && Number.isNaN(val)) {
            return "¡Es necesario una cantidad!";
          }
          if (val <= 0) {
            return "¡Es necesario una cantidad mayor a 0!";
          }
        }
      }).then(result => {
        if (result.value) {
          const amount = unformat(result.value, ".").toFixed(2);
          ReconsApi.create(recontype, [recontag], amount)
            .then(res => {
              this.$refs.reconsTable.refresh();
              const item = {
                data: res
              };
              this.showSingleEditor(item);
            })
            .catch(error => {
              console.log(error);
            });
        }
        else if (result.isDismissed && result.dismiss === "cancel") {
          // this.$emit("cancelled"); // Hay cambios pero se cancelaron
        }
      });
    },
    askCreateNewRecon() {
      // $(this.$refs.reconModal).modal("show")
    },
    // eslint-disable-next-line no-unused-vars
    onAskCreateHidden(value) {
      console.log("REF Hide", this.$refs.reconModal);
    },
    refreshAll() {
      this.$refs.reconsTable.refresh();
      this.currentReconRefresh += 1;
    },
    onRowsChecked(rows) {
      console.log("rows", rows); // is a dict
      let items = Object.values(rows);
      console.log("ITEMS", items, items.length);
      if (items.length < 1) {
        this.currentSpeiLayout = "";
        return;
      }
      items = items.map(it => {
        const { pend = 0, title = "", recon_sequence: seq = "0" } = it;
        // FIXME: Truncar a las longitudes posibles
        const obj = {
          banco: "012",
          monto: pend.toFixed(2).toString(),
          tipoCuenta: "40",
          cuenta: "012650026738939014",
          nombre: "Ariel Molina Rueda",
          rfc: "MORA820713U84",
          refEmisor: `NRG C${seq} ${title}`,
          titular: "Ariel M.",
          refNum: seq.toString(),
          refStr: `C${seq} ${title}` // Instrucciones de pago en Afirme
        };
        return obj;
      });
      const cuenta = {
        cuenta: "16341007619",
        razonsuc: "7619Desarrollos NRG S.A de C.V",
        rfc: "DNR100608127",
        titulo: "Desarrollos NRG S.A de C.V"
      };
      const total = items.reduce((accum, it) => accum + parseFloat(it.monto), 0).toFixed(2);

      console.log(
        "CONVERTIR A LAYOUT",
        total,
        items,
        AfirmeTools.GenerateLayout
      );

      console.log(AfirmeTools.GenerateLayout(cuenta, items, total));
      this.currentSpeiTotal = total;
      this.currentSpeiLayout = AfirmeTools.GenerateLayout(cuenta, items, total.toString());
    },
    showSingleEditor(item) {
      // eslint-disable-next-line no-unused-vars
      const { recon_sequence: seq = 0, _id: recId = "" } = item.data ?? "";
      // console.log("Show single:", seq, recId);
      this.currentReconIdSeq = seq ?? recId;
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onSingleReconFieldChanged(id, field, value) {
      // Beware some fields are forbidden to modify directly any invalid
      // query will be silently ignored by API and returning '200 OK'.
      // console.log("Field changed", id, field, value)
      const query = {};
      query[field] = value;

      ReconsApi.updateById(id, query)
        .then(() => {
          this.refreshAll();
          if (field === "amount") {
            // Force re-caculate of pend/paid
            // ReconsApi.findOneBySeq(id).then(res2 => {
            //   if (!res2) {
            //     this.currentReconData = null;
            //     return;
            //   }
            //   this.currentReconData = res2;
            // });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onReconFieldsChanged(id, query) {
      // Multiple fields changed, usually as a result of amount changed
      // console.log("Muti changed, posting", id, query);
      const vm = this;
      const url = `${this.apiurl}/${id}`;
      axios
        .post(url, query)
        .then(res => {
          console.log(res);
          vm.currentReconData = res.data;
          vm.$refs.reconsTable.refresh();
        })
        .catch(error => {
          console.log(error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    onSingleReconLogsChanged(id) {
      this.$refs.reconsTable.refresh();
    },
    onFilterStatus() {
      this.filterStatus = this.filterStatus === "!closed" ? "" : "!closed";
      this.$router.push(this.routerOpts).catch(() => { });
    },
    onSearchChanged(val) {
      this.searchVal = val;
      this.$router.push(this.routerOpts).catch(() => { });
    },
  }
};
</script>
