<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  /* display: block; */
  cursor: pointer;
  /* background-color: blue; */
}
th {
  border-bottom: 1px solid #343a40;
}
td {
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.cfdi {
  color: #8db4e588;
}
.striked{
  text-decoration:line-through;
  text-decoration-color: #ffffffA8;
  /* text-decoration-style: dotted; */
}
.paginate{
  float: right;
  text-align: right;
  padding-top: .25em;

}
.pag-button {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #67757c;
    border: 1px solid transparent;
    border-radius: 2px;
}
.pag-button.current, .pag-button:hover {
    color: #fff;
    border: 1px solid #398bf7;
    background-color: #398bf7;
}
.table-tools {
    display: inline-block;
    padding-top: 5px;
}
.text-striked{
  text-decoration: line-through;
}
</style>
<template>
  <div>
    <!-- {{ rows }} -->
    <table class="table table-hover inshadow ">
      <thead class="bg-dark">
        <tr class="prevent-select">
          <th @click="toggleOrderbyName()">
            &nbsp;
          </th>
          <!-- <th @click="toggleOrderbyName()">
            Tipo
          </th> -->
          <th class="text-nowrap text-right">
            Subtotal
          </th>
          <th class="text-nowrap text-right">
            IEPS
          </th>
          <th class="text-nowrap text-right">
            IVA 16%
          </th>
          <th class="text-nowrap text-right">
            <Icon icon="mdi:sigma" :inline="true" />Ret
          </th>
          <th class="text-nowrap text-right">
            <Icon icon="mdi:sigma" :inline="true" />Trasl
          </th>
          <th class="text-nowrap text-right">
            IVA Ret
          </th>
          <th class="text-nowrap text-right">
            ISR Ret
          </th>
          <th class="text-nowrap text-right">
            ISH
          </th>
          <th class="text-nowrap text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(it,idx) in rows" :key="idx" @click="onItemClick(it,...arguments)">
          <td class="text-nowrap pb-0" style="width: 1%;">
            <p class="ml-2">
              <span v-if="it.estado_sat === 'V'"> {{ it.humanDate }} </span>
            </p>
          </td>
          <td
            class="text-nowrap text-right"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            <span
              v-if="it.estado_sat !== 'V' && it.count>0"
              class="badge badge-sm badge-pill pt-1 mx-2 badge-warning text-black"
            >
              Ignorados: {{ it.count }}
            </span>
            <span
              v-if="it.estado_sat === 'V' && it.count>0"
              class="badge badge-sm badge-pill pt-1 mx-2 badge-dark text-primary"
            >
              Vigentes: {{ it.count }}
            </span>
            {{ it.subtotal || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.total_ieps || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.iva_16 || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.total_retenidos || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.total_trasladados || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.retenido_iva || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.retenido_isr || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            {{ it.ish || 0 }}
          </td>
          <td
            class="text-nowrap text-right border-left border-dark"
            :class="{'text-muted text-striked':it.estado_sat !== 'V'}"
            style="width: 1%;"
          >
            <strong class="pl-1 text-secondary"> {{ it.total || 0 }}</strong>
          </td>
        </tr>
      </tbody>
      <tfoot v-if="rows.length > 0" class="bg-default border border-dark insshadow">
        <tr>
          <td class="pb-0">
            <p class="ml-2">
              Anuales
              <Icon icon="mdi:sigma" :inline="true" />
            </p>
          </td>
          <td class="pb-0 text-right">
            Subtotal
          </td>
          <td class="pb-0 text-right">
            IEPS
          </td>
          <td class="pb-0 text-right">
            IVA 16%
          </td>
          <td class="pb-0 text-right">
            <Icon icon="mdi:sigma" :inline="true" />Ret
          </td>
          <td class="pb-0 text-right">
            <Icon icon="mdi:sigma" :inline="true" />Trasl
          </td>
          <td class="pb-0 text-right">
            IVA Ret
          </td>
          <td class="pb-0 text-right">
            ISR Ret
          </td>
          <td class="pb-0 text-right">
            ISH
          </td>
          <td class="pb-0 text-right">
            TOTAL
          </td>
        </tr>
        <tr>
          <td>
            <span class="badge badge-sm badge-pill pt-1 ml-2 badge-dark text-primary">
              Vigentes: {{ sums.vigentes }}
            </span>
            <span
              v-if="sums.ignorados > 0"
              class="badge badge-sm badge-pill pt-1 ml-2 mt-1 badge-warning text-black"
            >
              Ignorados: {{ sums.ignorados }}
            </span>
          </td>
          <td class="text-right">
            {{ sums.subtotal }}
          </td>
          <td class="text-right">
            {{ sums.total_ieps }}
          </td>
          <td class="text-right">
            {{ sums.iva_16 }}
          </td>
          <td class="text-right">
            {{ sums.total_retenidos }}
          </td>
          <td class="text-right">
            {{ sums.total_trasladados }}
          </td>
          <td class="text-right">
            {{ sums.retenido_iva }}
          </td>
          <td class="text-right">
            {{ sums.retenido_isr }}
          </td>
          <td class="text-right">
            {{ sums.ish }}
          </td>
          <td class="text-right">
            {{ (sums.total ) }}
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="paginate"></div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { format, parse } from "date-fns";
import esmx from "date-fns/locale/es";
import { formatMoney } from "accounting-js";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Cfdi.service";

export default {
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/cfdi"
    },
    year: {
      type: Number,
      default: 2023
    },
    tipo: {
      type: String,
      default: "I"
    },
    emisor: {
      type: String,
      default: null
    },
    receptor: {
      type: String,
      default: "DNR160608127"
    },
    moneda: {
      type: String,
      default: "MXN"
    },
    metodo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      data: [],
      orderBy: "nombre_emisor",
      orderAsc: false,
      months: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      // tipos: ["E", "I", "N", "P", "T"], // Egreso (devoluciones, anticipos, notas de credito), Ingreso (Ventas), Nomina, Pago, Traslado
      // year: 2023,
      // tipo: "I",
      // emisor: null,
      // receptor: "DNR160608127",
    };
  },
  computed: {
    isOpen() {
      const { status = "open" } = this.item;
      return status === "open";
    },
    rows() {
      const { rows = [] } = this.data;
      return rows;
    },
    shownpages() {
      const {
        per_page = 0, last_page = 0, current_page = 0, total = 0
      } = this.data;

      if (total <= per_page) {
        return [1];
      }
      const pages = [];
      let counter = 0;
      for (let p = current_page - 3; p < last_page; ++p) {
        if (p > 0 ) {
          pages.push(p);
          counter++;
        }
        if (counter > 5) {
          break;
        }
      }
      return pages;
    },
    sums() {
      const { rows = [] } = this.data;
      const sums = rows.reduce((accum, it) => {
        let {
          subtotal = 0,
          iva_16 = 0,
          retenido_iva = 0,
          retenido_isr = 0,
          ish = 0,
          total_ieps = 0,
          total_retenidos = 0,
          total_trasladados = 0,
          total_localretenido = 0,
          total_localtrasladado = 0,
          total = 0
        } = it;
        const {
          count = 0,
          estado_sat = ""
        } = it;
        // console.log("accum next", subtotal, iva_16, total);
        subtotal = parseFloat(subtotal) || 0;
        iva_16 = parseFloat(iva_16) || 0;
        retenido_iva = parseFloat(retenido_iva) || 0;
        retenido_isr = parseFloat(retenido_isr) || 0;
        ish = parseFloat(ish) || 0;
        total_ieps = parseFloat(total_ieps) || 0;
        total_retenidos = parseFloat(total_retenidos) || 0;
        total_trasladados = parseFloat(total_trasladados) || 0;
        total_localretenido = parseFloat(total_localretenido) || 0;
        total_localtrasladado = parseFloat(total_localtrasladado) || 0;
        total = parseFloat(total) || 0;

        return {
          subtotal: estado_sat === "V" ? accum.subtotal + subtotal : accum.subtotal,
          iva_16: estado_sat === "V" ? accum.iva_16 + iva_16 : accum.iva_16,
          retenido_iva: estado_sat === "V" ? accum.retenido_iva + retenido_iva : accum.retenido_iva,
          retenido_isr: estado_sat === "V" ? accum.retenido_isr + retenido_isr : accum.retenido_isr,
          ish: estado_sat === "V" ? accum.ish + ish : accum.ish,
          total_ieps: estado_sat === "V" ? accum.total_ieps + total_ieps : accum.total_ieps,
          total_retenidos: estado_sat === "V" ? accum.total_retenidos + total_retenidos : accum.total_retenidos,
          total_trasladados: estado_sat === "V" ? accum.total_trasladados + total_trasladados : accum.total_trasladados,
          total_localretenido: estado_sat === "V" ? accum.total_localretenido + total_localretenido : accum.total_localretenido,
          total_localtrasladado: estado_sat === "V" ? accum.total_localtrasladado + total_localtrasladado : accum.total_localtrasladado,
          total: estado_sat === "V" ? accum.total + total : accum.total,
          vigentes: estado_sat === "V" ? accum.vigentes + count : accum.vigentes,
          ignorados: estado_sat !== "V" ? accum.ignorados + count : accum.ignorados
        };
      }, {
        subtotal: 0,
        iva_16: 0,
        retenido_iva: 0,
        retenido_isr: 0,
        ish: 0,
        total_ieps: 0,
        total_retenidos: 0,
        total_trasladados: 0,
        total_localtrasladado: 0,
        total_localretenido: 0,
        total: 0,
        vigentes: 0,
        ignorados: 0
      });
      // Esto les pone comas a los numeros
      const moneyOpts = {
        symbol: "", precision: 2, thousand: ",", decimal: "."
      };
      sums.subtotal = formatMoney(sums.subtotal, { ...moneyOpts, symbol: "$ " });
      sums.iva_16 = formatMoney(sums.iva_16, moneyOpts);
      sums.retenido_iva = formatMoney(sums.retenido_iva, moneyOpts);
      sums.retenido_isr = formatMoney(sums.retenido_isr, moneyOpts);
      sums.ish = formatMoney(sums.ish, moneyOpts);
      sums.total_ieps = formatMoney(sums.total_ieps, moneyOpts);
      sums.total_retenidos = formatMoney(sums.total_retenidos, moneyOpts);
      sums.total_trasladados = formatMoney(sums.total_trasladados, moneyOpts);
      sums.total_localretenido = formatMoney(sums.total_localretenido, moneyOpts);
      sums.total_localtrasladado = formatMoney(sums.total_localtrasladado, moneyOpts);
      sums.total = formatMoney(sums.total, moneyOpts);
      return sums;
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val /* , old */ ) {
        console.log("Reload cfdis", val);
        this.fillInitial();
      }
    }
  },
  methods: {
    toggleOrderbyName() {
      if (this.orderBy === "nombre_emisor") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "nombre_emisor";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyRfc() {
      if (this.orderBy === "rfc_emisor") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "rfc_emisor";
      this.orderAsc  = true;
      this.fillInitial();
    },
    onItemClick(it) {
      this.$emit("rowClicked", it);
    },
    onNextPage() {
      console.log("Next page:");
    },
    onPrevPage() {
      console.log("Prev page");
    },
    onJumpToPage(i) {
      console.log("To page", i);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      // const { id:cid = null } = this.$route.params;
      // if (!cid) {
      //   console.log("Refusing to refill empty route");
      //   return;
      // }
      const months = this.months.map((it, idx) => ([{
        year: this.year,
        month: idx + 1,
        estado_sat: "V"
      },
      {
        year: this.year,
        month: idx + 1,
        estado_sat: "!V"
      }])).flat();
      // months = [{year:2023,month:1},{year:2023,month:2},{year:2023,month:3}...{year:N,month:1-12}]

      const jsonParams = {
        tipo: this.tipo,
        emisor: this.emisor,
        receptor: this.receptor,
        moneda: this.moneda,
        metodo_de_pago: this.metodo ? this.metodo : null
      };
      ServiceApi.getSumMontly(months, jsonParams)
        .then(res => {
          if (!res) {
            console.log("Got empty");
            // this.item = {};
            return;
          }
          // console.log("Got", res);
          const rows = res.map((it) => {
            const { _id: ym = "" } = it;
            const month = parseInt(ym.slice(-2), 10);
            const date = parse(ym, "yyyyMM", new Date(this.year, month - 1, 1), { locale: esmx });
            if (Number.isNaN(date)) {
              return it;
            }
            // eslint-disable-next-line no-param-reassign
            it.humanDate = format(date, "MMM, yyyy", { locale: esmx });
            return it;
          });
          this.data = { rows };
        });
    },
    refresh() {
      this.fillInitial();
    }
  }
};
</script>
