<style scoped>

</style>
<template>
  <div class="box">
    <div class="box single-contact">
      <div class="box-body box-profile">
        <div class="flexbox">
          <h6 class="">
            <LiveEdit
              class="text-primary"
              :value="contact.name"
              :show-buttons="false"
              :enabled="isOpen"
              @valueChanged="onCommonChanged('name', ...arguments)"
            />
          </h6>
          <h6>
            <Icon icon="bx:bxs-lock-alt" :inline="true" class="text-default" />
            &nbsp;
            <toggle
              class="box-controls float-right btn btn-toggle btn-xs btn-primary"
              :value="!isOpen"
              @click="onSingleToggleStatus(id, contact.status)"
            />
          </h6>
        </div>
        <!-- <img class="profile-user-img rounded-circle img-fluid mx-auto d-block" src="" alt="User profile picture"> -->
        <!-- <svg class="mx-auto d-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="50px" height="50px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M12 4a5 5 0 1 1-5 5a5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7a7 7 0 0 0-7-7z" fill="#626262"/><path d="M22 30h-2v-5a5 5 0 0 0-5-5H9a5 5 0 0 0-5 5v5H2v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7z" fill="#626262"/><path d="M22 4h10v2H22z" fill="#626262"/><path d="M22 9h10v2H22z" fill="#626262"/><path d="M22 14h7v2h-7z" fill="#626262"/></svg> -->
        <!-- <svg class="mx-auto d-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="50px" height="50px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1408 1472"><path d="M704 128q-144 0-225 106t-81 271q-1 205 132 325q17 16 12 41l-23 48q-11 24-32.5 37.5T396 995q-3 1-126.5 41T138 1080q-84 35-110 73q-28 63-28 319h1408q0-256-28-319q-26-38-110-73q-8-4-131.5-44T1012 995q-69-25-90.5-38.5T889 919l-23-48q-5-25 12-41q133-120 132-325q0-165-81-271T704 128z" fill="white"/></svg> -->
        <div class="row mt-2 py-3 border-top border-dark">
          <div class="col-12">
            <p class="text-muted text-center">
              Puesto:
              <LiveEdit
                class="text-primary"
                :value="contact.job"
                :show-buttons="false"
                :enabled="isOpen"
                @valueChanged="onCommonChanged('job', ...arguments)"
              />
              <br>
              <a href="#" class="link">
                <span
                  style="margin-right: 10px;"
                  class="iconify"
                  data-icon="fluent:cart-20-regular"
                  data-width="20px"
                  data-height="20px"
                  data-inline="false"
                ></span>
                254
              </a>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button type="button" class="btn float-right btn-default">
              <svg
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32" data-v-fdacdde6="" data-icon="carbon:add-alt"
                data-inline="true " class="iconify" style="vertical-align: -0.125em; transform: rotate(360deg);"
              ><path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16S9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2z" fill="currentColor" /><path d="M24 15h-7V8h-2v7H8v2h7v7h2v-7h7z" fill="currentColor" /></svg>
              Cotizar...
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="profile-user-info">
              <p class="profile-user-info-field">
                En el mail le decimos (Hola ...):
                <LiveEdit
                  class="text-primary"
                  :value="contact.alias"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('alias', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                Email
                <LiveEdit
                  class="text-primary"
                  :value="contact.email"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('email', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                Teléfono
                <LiveEdit
                  class="text-primary"
                  :value="contact.phone"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('phone', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                Sitio web / FB
                <LiveEdit
                  class="text-primary"
                  :value="contact.web"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('web', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                RFC para facturar
                <LiveEdit
                  class="text-primary"
                  :value="contact.rfc"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('rfc', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                Cuenta de ventanilla
                <LiveEdit
                  class="text-primary"
                  :value="contact.bankaccountLocal"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('bankaccountLocal', ...arguments)"
                />
              </p>
              <p class="profile-user-info-field">
                Cuenta SPEI (CLABE)
                <LiveEdit
                  class="text-primary"
                  :value="contact.bankaccountSpei"
                  :show-buttons="false"
                  :enabled="isOpen"
                  @valueChanged="onCommonChanged('bankaccountSpei', ...arguments)"
                />
              </p>
              <!-- <div class="map-box">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2805244.1745767146!2d-86.32675167439648!3d29.383165774894163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c1766591562abf%3A0xf72e13d35bc74ed0!2sFlorida%2C+USA!5e0!3m2!1sen!2sin!4v1501665415329" width="100%" height="150" frameborder="0" style="border:0" allowfullscreen=""></iframe>
              </div> -->
              <!-- <p class="margin-bottom">Social Profile</p>
              <div class="user-social-acount">
                <button class="btn btn-circle btn-social-icon btn-facebook"><i class="fa fa-facebook"></i></button>
                <button class="btn btn-circle btn-social-icon btn-twitter"><i class="fa fa-twitter"></i></button>
                <button class="btn btn-circle btn-social-icon btn-instagram"><i class="fa fa-instagram"></i></button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- /.box-body -->
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";
import Toggle from "@/components/Toggle.vue";
import LiveEdit from "@/components/LiveEdit.vue";
import ContactsApi from "./Contacts.service";

export default {
  components: {
    LiveEdit,
    Toggle,
    Icon,
  },
  props: {
  },
  data() {
    return {
      sortDefault: [
        {
          field: "createdAt",
          sortField: "createdAt",
          direction: "desc"
        }
      ],
      contact: {
        type: Object,
        default() {
          return {
            _id: "",
            status: "open",
            name: "<desconocido>",
            phone:"",
            email:"",
            job:"",
            web:""
          };
        }
      }
    };
  },
  computed: {
    id() {
      const { _id = null } = this.contact ?? {};
      console.log(_id);
      return _id;
    },
    isOpen() {
      const { status = "open" } = this.contact ?? {};
      return status === "open";
    },
    // currentReconLogsUrl() {
    //   console.log(`${ReconsApi.baseurl()}/${this.reconData._id}/logs`);
    //   return `${ReconsApi.baseurl()}/${this.reconData._id}/logs`;
    // },
    // decimalAmount() {
    //   const amount = parseFloat(this.reconData.amount) || 0;
    //   // console.log("Calculating 2 decimal amount", amount);
    //   return amount.toFixed(2);
    // },
  },

  watch: {
    "$route.params.id": {
      immediate: true,
      handler(val, old) {
        // console.log("Changed ROUTE", old, val);
        this.fillInitial();
      }
    }
  },
  methods: {
    onSingleToggleStatus(id, status) {
      console.log("TOGGLE STATUS", status);
      const { id: paramid = "" } = this.$route.params;
      console.log(paramid);
      if (!paramid || paramid !== id) {
        this.messageAlert({
          icon: "error",
          title: "El identificador no coincide con el elemento seleccionado"
        });
        return;
      }
      const statuschanged = status === "open" ? "locked" : "open";
      this.contact.status = statuschanged;
      ContactsApi.updateById(id, { status: statuschanged })
        .then(res => {
          console.log("res info", res.data);
          this.contact = res.data;
          this.$emit("requestRefresh", this.contact);
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status: statusres = 400 } = response;
          const { error_string: errstr = message } = dataResponse;
          console.log(statusres, errstr);
          this.messageAlert({
            icon: "error",
            title: "No se pudo Activar/Desactivar"
          });
        });
    },
    fillInitial() {
      console.log("Refilling from server", this.$route.params.id);
      const { id:cid = null } = this.$route.params;
      if (!cid) {
        console.log("Refusing to refill empty route");
        return;
      }
      ContactsApi.getById(this.$route.params.id).then(res => {
        if (!res) {
          // console.log("Got empty");
          this.contact = {};
          return;
        }
        // console.log("Got", res);
        this.contact = res;
      });
    },
    onCommonChanged(field, value) {
      // console.log("FieldVal", field, value);
      // Beware some fields are forbidden to modify directly any invalid
      // query will be silently ignored by API and returning '200 OK'.
      this.$emit("fieldChanged", this.contact._id, field, value);
    },
    isValidRfc(str) {
      const rfc = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
      console.log("testing RFC", rfc.test(str));
    },
    messageAlert(info) {
      const {
        icon = "", title = "", text = "", timer = 3000
      } = info ?? {};
      this.$swal({
        icon,
        title,
        timer,
        html: text,
        confirmButtonText: "Ok",
        toast: true,
        position: "bottom-end",
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true
      });
    }
  }
};
</script>
