import { render, staticRenderFns } from "./LiveSearch.vue?vue&type=template&id=18180a41&scoped=true"
import script from "./LiveSearch.vue?vue&type=script&lang=js"
export * from "./LiveSearch.vue?vue&type=script&lang=js"
import style0 from "./LiveSearch.vue?vue&type=style&index=0&id=18180a41&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18180a41",
  null
  
)

export default component.exports