import { render, staticRenderFns } from "./CfdiNominasTable.vue?vue&type=template&id=c7a8cdec&scoped=true"
import script from "./CfdiNominasTable.vue?vue&type=script&lang=js"
export * from "./CfdiNominasTable.vue?vue&type=script&lang=js"
import style0 from "./CfdiNominasTable.vue?vue&type=style&index=0&id=c7a8cdec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7a8cdec",
  null
  
)

export default component.exports