<!-- eslint-disable vue/attribute-hyphenation -->
<style scoped>
.dropdown-menu {
  min-width: 200px;
}
.dropdown-menu.columns-2 {
  min-width: 200px;
}
.dropdown-menu.columns-3 {
  min-width: 600px;
}
.dropdown-menu li a {
  padding: 5px 10px;
  font-family: Roboto, sans-serif;
}
.multi-column-dropdown {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
  display: block;
  clear: both;
  white-space: normal;
}
.multi-column-dropdown li a:hover {
  text-decoration: none;
  background-color: #999;
}
.single-recon {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}
</style>

<template>
  <div class="row">
    <div class="col-12">
      <!-- <div class="row">
        <div v-for="(plot, index) in plots" :key="index" class="col-3 col-lg-2">
          <div class="box">
            <div class="box-body p-0">
              <a href="#/plots">
                <Plot :source="`${PLOTS_ROOT}/${plot}`" />
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-md-12 col-lg-10">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                Grabaciones EDIS Nemos
              </h3>
              <div class="box-controls float-right d-print-none">
                <div class="box-header-actions">
              &nbsp;
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="row">
                <NemCalendar
                  class="col-sm-12"
                  :nemrecs="nemRecords"
                  :disabled="downloading"
                  @mouseMove="mouseMove"
                  @segment-selected="segmentSelected"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="selected" class="col-md-12 col-lg-6">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                Reproductor
              </h3>
            </div>
            <div class="box-body">
              <NemPlayer
                ref="nemPlayer"
                :videoList="videoFiles"
                :loading="loading"
                :fromDate="dateSelected"
                :disabled="downloading"
                @cutChanged="updateCut"
              />

              <div class="row">
                <div class="py-12 col-md-12">
                  <a
                    class="btn btn-info btn-sm float-right"
                    :class="{disabled: downloading}"
                    href="#"
                    role="link"
                    @click.prevent="cutVideo"
                  >
                    <span v-show="downloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                    </span>
                    {{ downloading ? "Descargando...":"Descargar" }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format, addSeconds } from "date-fns";
import { es } from "date-fns/locale";
// import { Icon } from "@iconify/vue2";

import NemPlayer from "@/plugins/Nemos/NemPlayer.vue";
// import Plot from "@/components/Plot.vue";
import ServiceApi from "@/plugins/Nemos/Nemos.service";
import NemCalendar from "@/plugins/Nemos/NemCalendar.vue";
import recorders from "@/plugins/Nemos/recorders.json";

export default {
  components: {
    NemCalendar,
    NemPlayer,
    // Plot
    // Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/videos"
    }
  },
  data() {
    return {
      nemRecords: [
        // "Tuner 1",
        // "Tuner 2"
        { name: "Tuner 1", tuner: 0, recorder: "local" },
        { name: "Tuner 2", tuner: 1, recorder: "local" }
      ],
      videoFiles: [
        "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_20MB.mp4",
        "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4"
      ],
      selected: null,
      loading: true,
      tolerance: 2,
      plots: [
        "cpuload_01h.png",
        "cputemp_01h.png",
        "tuner1_01h.png",
        "tuner2_01h.png"
      ],
      downloading: false,
      recorders: {}
    };
  },
  computed: {
    ...mapState(["API_ROOT", "VIDEO_ROOT", "PLOTS_ROOT"]),
    dateSelected() {
      const { fromDate = new Date() } = this.selected ?? {};
      if (!(fromDate instanceof Date)) {
        return new Date();
      }
      return fromDate;
    }
  },
  methods: {
    mouseMove(ev) {
      this.log = `${ev.target.className} \n${ev.x}, ${ev.y}`;
    },
    segmentSelected(sel) {
      if (!sel) {
        this.selected = null;
        return;
      }

      const { tuner = 0, segment = {}, nemos = "local" } = sel ?? {};
      this.selected = {
        fromDate: segment.datetime,
        duration: 60,
        tuner: String(tuner + 1),
        tolerance: this.tolerance,
        nemos
      };

      this.loading =  true;
      ServiceApi.getVideos(this.selected)
        .then(res => {
          console.log("List", res.data);
          const { files = [] } = res.data;

          let nemosbaseurl = this.recorders[nemos] ?? "";
          nemosbaseurl = nemosbaseurl ? `${nemosbaseurl}/edisk` : this.VIDEO_ROOT;

          this.videoFiles = files.map((f) => nemosbaseurl + f);
          this.loading = false;
          console.log(this.videoFiles);
        })
        .catch(err => {
          const { response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          let { error: errStr = err } = dataResponse;
          this.selected = null;
          this.loading =  false;

          console.log("Error:", dataResponse, errStr, status, err);
          if (status === 404 ) {
            errStr =  "No se han encontrado archivos";
          }

          this.$swal({
            position: "bottom-end",
            title: "",
            html: `<p class="mt-3 p-1"> <strong>${errStr}</strong>, CODE ${status}</p>`,
            icon: "error",
            toast: true,
            showConfirmButton: false,
            showCancelButton: false,
            timer: 4000
          });
        });
    },
    updateCut(cut) {
      this.selected.segment = cut;
    },
    cutVideo() {
      this.downloading = true;
      // this.$swal({
      //   title: "Descargando vídeo...",
      //   html: "<p class=\"mt-3 p-1\">Espere un momento mientras se completa la descarga del vídeo.</p>",
      //   icon: "info",
      //   showConfirmButton: true,
      //   showCancelButton: false,
      //   showCloseButton: false,
      //   timer: 10000
      // });
      ServiceApi.downloadVideo(this.selected)
        .then(res => {
          this.downloading = false;
          const url = (window.URL || window.webkitURL).createObjectURL( new Blob([res.data], { type: "video/mp4" }) );
          const { segment = [0, 100], fromDate = new Date() } = this.selected ?? {};
          const [start = 0] = segment;
          const date = addSeconds(fromDate, start - Math.abs(this.tolerance * 60));
          const a = document.createElement("a");
          a.href = url;
          a.download = format(date, "'NEMOS_'dd-MMM-yyyy_HH_mm_ss'.mp4'", { locale: es }); // url.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(err => {
          const { response = {} } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          let { error: errStr = err } = dataResponse;
          // this.selected = null;
          this.loading =  false;

          console.log("Error:", dataResponse, errStr, status, err);
          if (status === 404 ) {
            errStr =  "No se han encontrado archivos";
          }

          this.downloading = false;

          this.$swal({
            position: "bottom-end",
            title: "",
            html: `<p class="mt-3 p-1"> <strong>${errStr}</strong>, CODE ${status}</p>`,
            icon: "error",
            toast: true,
            showConfirmButton: false,
            showCancelButton: false,
            timer: 4000
          });
        });
    }
  },
  beforeMount() {
    // console.log("recorders:", recorders);
    this.recorders = recorders.reduce((prev, curr) => {
      // eslint-disable-next-line no-param-reassign
      prev[curr.id] = curr.apiroot;
      return prev;
    }, {});
  },
  mounted() {
    ServiceApi.init(this.API_ROOT, "");
    // console.log("API host:", this.API_ROOT);
    // console.log("Video host:", this.VIDEO_ROOT);

    const promises = recorders.map((it) => ServiceApi.getChannels(it.id));
    Promise.allSettled(promises)
      .then((results) => {
        // results.forEach((result) => console.log(result));
        this.nemRecords = recorders.reduce((prev, curr, idx) => {
          const stat = results[idx].status;
          const { value = {} } = results[idx] ?? {};
          const { data = [] } = value;
          const [first = "", second = ""] = data;
          if (stat === "fulfilled") {
            prev.push({ name: `[${curr.id}] ${first}`, tuner: 0, recorder: curr.id });
            prev.push({ name: `[${curr.id}] ${second}`, tuner: 1, recorder: curr.id });
          }
          else {
            prev.push({ name: `[${curr.id}] Tuner 1`, tuner: 0, recorder: curr.id });
            prev.push({ name: `[${curr.id}] Tuner 2`, tuner: 1, recorder: curr.id });
          }
          return prev;
        }, []);
      });
  }
};
</script>
