<style scoped>
.file-droparea {
  background-color: #754a744f;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea.active {
  background-color: #754a74ff;
  min-height: 40px;
  min-width: 50px;
  border: 1px solid #55444a62;
}
.file-droparea > * > .icon{
  font-size:200%;
  /* display: block;
  margin: auto; */
}
.file-preupload > .thumb {
  min-height: 80px;
  min-width: 80px;
  display:block;
  margin:auto;
  border: unset;
}
.file-preupload > .filename {
  font-weight: 500;
  font-size: 125%;
}

</style>
<template>
  <div class="row">
    <div class="col-12">
      <div class="box">
        <ul class="nav nav-tabs nav-tabs-info" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#banks" role="tab" @click="onClickedTab('banks')">
              Registros Bancarios
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#banks-anual" role="tab" @click="onClickedTab('banks-anual')">
              Resumen Anual
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#multicash" role="tab" @click="onClickedTab('multicash')">
              Carga Masiva
            </a>
          </li>
        </ul>
        <div class="box-body tab-content">
          <div id="banks" class="tab-pane fade active show">
            <BanksTablePanel
              v-if="currentTab==='banks'"
            />
          </div>
          <div id="banks-anual" class="tab-pane fade">
            <BanksAnualPanel
              v-if="currentTab==='banks-anual'"
            />
          </div>
          <div id="multicash" class="tab-pane fade">
            <BanksMulticashPanel />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BanksTablePanel from "./BanksTablePanel.vue";
import BanksAnualPanel from "./BanksAnualPanel.vue";
import BanksMulticashPanel from "./BanksMulticashPanel.vue";

export default {
  name: "RocketBancos",
  components: {
    BanksTablePanel,
    BanksAnualPanel,
    BanksMulticashPanel
  },
  data() {
    return {
      currentTab: "banks"
    };
  },
  methods: {
    onClickedTab(tab) {
      this.currentTab = tab;
      const { name = "" } = this.$route;
      if (name === "Banks" ) {
        return;
      }
      this.$router.push({
        name: "Banks"
      });
    }
  }
};
</script>
