<style scoped>
</style>

<template>
  <table class="table table-hover">
    <tbody>
      <tr v-for="it in messages" :key="it._id" @click="onRowClicked(it)">
        <td style="width:1em">
          <Icon v-if="it.status=='program'" icon="material-symbols:check-circle" class="text-primary" />
          <Icon v-else icon="material-symbols:circle-outline" class="text-default" />
        </td>
        <td>
          <Icon :icon="it.p_icon" inline="true" />
          {{ it.name == it.user ? it.user:`${it.name} - ${it.user}` }}
          <p>
            {{ it.msg }}
          </p>
        </td>
        <td style="width:1em">
          .
        </td>
      </tr>
      <tr v-if="messages.length<1">
        <td colspan="3" class="text-center">
          {{ stringError ? stringError : "" }}
          No hay mensajes aquí...
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { ServiceApi } from "./RedesSociales.service";

export default {
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/redessoc"
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      messages: {
        type: Array,
        default: () => []
      },
      orderBy: "createdAt",
      orderAsc: false,
      stringError: "",
      typeIcons: {
        twitter: "mdi:twitter",
        facebook: "mdi:facebook",
        whatsapp: "mdi:whatsapp",
      }
    };
  },
  computed: {},
  watch: {
    apiurl: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Reload files", old, val);
        this.fillInitial();
      }
    }
  },
  methods: {
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      ServiceApi.find(this.apiurl, { per_page:100, sort: `${this.orderBy}|${this.orderAsc ? "asc" : "desc"}` })
        .then(res => {
          this.stringError = "";
          if (!res) {
            console.log("Got empty");
            this.messages = [];
            return;
          }
          console.log("Got", res);
          const { rows = [] } = res ?? {};
          this.messages = rows.map(it => {
            const { type = "" } = it;
            const icon = this.typeIcons[type] ?? "emojione-monotone:letter-k";
            return {
              ...it,
              p_icon: icon
            };
          });
        })
        .catch(e => {
          const { message = "Server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("Messages fill FAILED ", status, errStr);
          this.messages = [];
          this.stringError = `(${errStr}, CODE: ${status})`;
        });
    },
    refresh() {
      this.fillInitial();
    },
    onRowClicked(row) {
      this.$emit("message-clicked", row);
    }
  },
  mounted() {}
};
</script>
