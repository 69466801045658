// import store from "@/store";
import Cfdi from "./Cfdi.vue";

export default {
  // The install method will be called with the Vue constructor as
  // the first argument, along with possible options
  install(Vue, options) {
    const { router = null, store = null } = options;
    if (!router || !store) {
      console.warn("Needs store and router, not installing", this);
    }
    const r = {
      path: "/cfdi",
      name: "CFDI",
      component: Cfdi,
      meta: {
        title: "CFDI",
        label: "CFDI",
        icon: "la:file-invoice-dollar",
        privileges: ["ROCKET-USER", "CFDI-USER"]
      },
      children: [
        {
          path: ":uuid", // /cfdi/:uuid
          name: "CFDISingle",
          component: Cfdi,
          meta: {
            title: "CFDI",
            label: "CFDI",
            icon: "la:file-invoice-dollar",
            privileges: ["ROCKET-USER", "CFDI-USER"]
          }
        }
      ]
    };
    store.dispatch("RegisterRoute", { path:r.path, ...r.meta });
    router.addRoutes([r]);
  }
};
