<style scoped>
.toggle-actions{
  display: inline;
}
.btn:disabled {
  pointer-events: none;
}
.font-huge-stats {
  font-size: 2rem !important;
  line-height: 1.2;
  font-weight: 200 !important;
}
.bg-altgray {
  background-color: #35393e;
}
</style>

<template>
  <div class="container-fluid px-0">
    <div class="row px-1">
      <div class="col-xl-2 col-md-6 col-12 ">
        <div class="box mx-auto box-body border bg-dark-info border-dark">
          <div class="flexbox">
            <peity
              :type="'pie'"
              :options="{ 'fill': ['#33cabb', '#ef5350'],
                          stroke: '#a97d10fa',
                          width: 60,
                          height:60,
                          max: 9,
                          strokewidth: 1,
                          innerRadius: 0,
                          radius: 40 }"
              :data="linkedPlotdata.toString()"
            />
            <span class="font-huge-stats">
              {{ linkedPlotdata[1] }}
            </span>
          </div>
          <div class="text-right">
            <span v-if="linkedPlotdata[1] !== 0">
              Sin asociar
            </span>
            <span v-else>
              Todo asociado
            </span>
            <Icon v-if="linkedPlotdata[1] == 0" icon="fluent-emoji-flat:partying-face" style="height: 1.5em; width: 1.5em" />
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-md-6 col-12 ">
        <div class="box mx-auto box-body border bg-dark-info border-dark">
          <div class="flexbox">
            <peity
              :type="'pie'"
              :options="{ 'fill': ['#33cabb', '#ef5350'],
                          stroke: '#a97d10fa',
                          width: 60,
                          height:60,
                          max: 9,
                          strokewidth: 1,
                          innerRadius: 0,
                          radius: 40 }"
              :data="taxclassPlotdata.toString()"
            />
            <span class="font-huge-stats">
              {{ taxclassPlotdata[1] }}
            </span>
          </div>
          <div class="text-right">
            <span v-if="taxclassPlotdata[1] !== 0">
              Sin clasificar
            </span>
            <span v-else>
              Todo clasificado
            </span>
            <Icon v-if="taxclassPlotdata[1] == 0" icon="fluent-emoji-flat:partying-face" style="height: 1.5em; width: 1.5em" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-7">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">
              CFDI Pagos
            </h3>
            <div class="box-controls float-right d-print-none">
              <div class="box-header-actions">
              &nbsp;
              </div>
            </div>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <button
                      type="button" class="btn btn-inline btn-default btn-sm"
                      title="Emitidas o recibidas"
                      @click="invertFilterEmisorReceptor"
                    >
                      <Icon v-if="filterRfcEmisor==myRfc" icon="fluent:arrow-step-out-20-filled" :inline="true" class="text-primary" />
                      <Icon v-if="filterRfcReceptor==myRfc" icon="fluent:arrow-step-in-20-filled" :inline="true" class="" />
                      {{ filterRfcEmisor==myRfc?'EM':'RE' }}
                    </button>
                    <YearMonthDropdown
                      class="d-sm-inline"
                      :year="filterDateYear"
                      :month="filterDateMonth"
                      :applied="filterDateApply"
                      :fullyear="filterDateFullYear"
                      @dateChanged="onDateFilterChanged"
                      @clearRequested="onDateFilterClear"
                      @fullYearClicked="onDateFilterFullYear"
                    />
                    <button
                      type="button" class="btn btn-sm btn-default d-none d-sm-block float-right"
                      @click.prevent="refreshListCfdi()"
                    >
                      <Icon icon="ic:outline-refresh" />
                    </button>
                    <SearchBar
                      :value="search"
                      @search-changed="onSearchChanged"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col mt-3">
                    <CfdiPagosTable
                      ref="cfdiBrowser"
                      :apiurl="`${apiurl}?${params}`"
                      :limit-items="Object.keys(params).length > 0 ? 500:200"
                      @rowClicked="showSingleCfdi"
                      @statsChanged="onStatisticsChanged"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="row">
          <div class="col-12">
            <CfdiPagosSingle
              v-if="current"
              class="bg-altgray"
              :cfdi="current"
              @cfdiUpdated="onSingleCfdiUpdated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import Peity from "vue-peity";
import CfdiPagosTable from "@/plugins/Cfdi/CfdiPagosTable.vue";
import YearMonthDropdown from "@/components/YearMonthDropdown.vue";
import SearchBar from "@/components/SearchBar.vue";
import CfdiPagosSingle from "./CfdiPagosSingle.vue";
import ServiceApi from "./Cfdi.service";

export default {
  name: "CfdiPanel",
  components: {
    Peity,
    Icon,
    CfdiPagosTable,
    YearMonthDropdown,
    SearchBar,
    CfdiPagosSingle
  },
  props: {
    cfdi: {
      type: Object,
      default() {
        return {
          title: "",
          recon_sequence: 0,
          nombre_emisor: "",
          conceptos: "",
          recons: [],
        };
      }
    },
    myRfc: {
      type: String,
      default: "DNR160608127"
    }
  },
  data() {
    return {
      linkedPlotdata: [0, 0],
      taxclassPlotdata: [0, 0],
      apiurl: "/cfdi",
      current: null,
      search: null,
      filterDateApply: false,
      filterDateFullYear: true,
      filterDateYear: 2023,
      filterDateMonth: 2,
      filterRfcEmisor: this.myRfc,
      filterRfcReceptor: null
    };
  },
  computed: {
    ...mapState(["API_ROOT"]),
    filterRfc() {
      if (this.filterRfcEmisor) return this.filterRfcEmisor;
      if (this.filterRfcReceptor) return this.filterRfcReceptor;

      return "";
    },
    params() {
      const {
        filterRfcEmisor: rfc_emisor = null,
        filterRfcReceptor: rfc_receptor = null,
        search = null,
        filterDateApply = false,
        filterDateFullYear = false,
        filterDateYear = null,
        filterDateMonth = null
      } = this;
      const jsonParams = {
        rfc_emisor,
        rfc_receptor,
        search,
        year: filterDateApply || filterDateFullYear ? filterDateYear : null,
        month: filterDateApply && !filterDateFullYear ? filterDateMonth : null
      };
      // https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
      // eslint-disable-next-line no-unused-vars
      const filtredParams = Object.fromEntries(Object.entries(jsonParams).filter(([_, v]) => v != null));
      // console.log("filterDateApply", filterDateApply);
      const p = new URLSearchParams(filtredParams).toString();
      return p;
    },
    currentReconLogsUrl() {
      const { recons = [] } = this.current ?? {};
      const { _id = "" } = recons[0] ?? {};
      if (!_id) {
        return "";
      }

      const url = `${this.API_ROOT}/api/recons/${_id}/logs`;
      console.log("url logs", url);
      return url;
    },
  },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, from) {
        // console.log("Route changes", from, to, to.params.id, this);
        const { uuid: paramid = "" } = to.params ?? {};
        if (!paramid) {
          this.current = null; // hide single
          return;
        }
        // console.log("Force fetch", to.params.id);
        ServiceApi.getByUuid(paramid).then(res => {
          if (!res) {
            this.current = null;
            return;
          }
          // const { taxclass = "" } = res;
          // this.taxclass = taxclass;
          this.current = res;
        });
      },
      immediate: true
    }
  },
  methods: {
    invertFilterEmisorReceptor() {
      console.log("Inverting", this.myRfc, this.filterRfcEmisor, this.filterRfcReceptor);
      if (this.filterRfcEmisor) {
        this.filterRfcReceptor = this.myRfc;
        this.filterRfcEmisor = null;
      }
      else {
        this.filterRfcEmisor = this.myRfc;
        this.filterRfcReceptor = null;
      }
    },
    clearFilterRfc() {
      this.filterRfcReceptor = null;
      this.filterRfcEmisor = null;
    },
    onStatisticsChanged(stats) {
      const { assoc, classified, total } = stats;
      this.linkedPlotdata = [assoc, total - assoc];
      this.taxclassPlotdata = [classified, total - classified];
      console.log("Statistics:", stats);
    },
    onDateFilterChanged(year, month) {
      // console.log("Month filter", year, month);
      this.filterDateYear = year;
      this.filterDateMonth = month;
      this.filterDateFullYear = false;
      this.filterDateApply = true;
    },
    onDateFilterClear() {
      // console.log("Clear date filter");
      this.filterDateApply = false;
      this.filterDateFullYear = false;
      const now = new Date();
      this.filterDateYear = now.getFullYear();
      this.filterDateMonth = now.getMonth() + 1;
    },
    onDateFilterFullYear(year) {
      // console.log("Full year filter", year);
      this.filterDateYear = year;
      this.filterDateApply = false;
      this.filterDateFullYear = true;
    },
    setRfcEmisorFilter(rfc) {
      // console.log("Set RFC Emisor", rfc);
      this.filterRfcEmisor = rfc;
      this.filterRfcReceptor = null;
      this.refreshListCfdi();
    },
    setRfcReceptorFilter(rfc) {
      // console.log("Set RFC Receptor", rfc);
      this.filterRfcEmisor = null;
      this.filterRfcReceptor = rfc;
      this.refreshListCfdi();
    },
    onSearchChanged(val) {
      this.search = val;
      this.refreshListCfdi();
    },
    showSingleCfdi(cfdi) {
      const { uuid = "" } = cfdi ?? {};
      const { uuid: paramUuid = "" } = this.$route.params ?? {};
      if (uuid && uuid === paramUuid) {
        console.log("current uuid is same", uuid, paramUuid);
        return;
      }

      console.log("Clicked:", uuid);
      this.$router.push({
        name: "CFDISingle",
        params: { uuid }
      });
    },
    refreshListCfdi() {
      this.$nextTick(() => {
        this.$refs.cfdiBrowser.refresh();
      });
    },
    onSingleCfdiUpdated(cfdi) {
      this.current = cfdi;
      this.refreshListCfdi();
    }
  },
  mounted() {
    const now = new Date();
    this.filterDateYear = now.getFullYear();
    this.filterDateMonth = now.getMonth() + 1;
  },
};
</script>
